import {
  Avatar,
  Button,
  Grid,
  InputAdornment,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  TextField,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import React, { useEffect, useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import { useNavigate } from "react-router-dom";
import {
  useGetContactsMutation,
} from "services/contact";

export const Contacts = ({ contacts }) => {
  const navigate = useNavigate();
  const [filteredData, setFilteredData] = useState([]);
  const [input, setInput] = useState("");
  const [getContacts, getContactsResults] = useGetContactsMutation();
  useEffect(() => {
    getContacts();
  }, []);
  useEffect(() => {
    if (getContactsResults?.isSuccess) {
      setFilteredData(getContactsResults?.data?.contacts);
    }
  }, [getContactsResults]);

  useEffect(() => {
    if (input?.length > 0) {
      setFilteredData(
        getContactsResults?.data?.contacts?.filter(
          (contact) =>
            contact?.user?.profile?.name
              ?.toLowerCase()
              ?.includes(input?.toLowerCase()) ||
            contact?.user?.profile?.middleName
              ?.toLowerCase()
              ?.includes(input?.toLowerCase()) ||
            contact?.user?.profile?.lastName
              ?.toLowerCase()
              ?.includes(input?.toLowerCase())
        )
      );
    } else {
      setFilteredData(getContactsResults?.data?.contacts);
    }
  }, [input]);

  const getColorBasedOnRole = (role) => {
    if (role === "customer" || role === 10) {
      return "rgba(255, 0, 0, 1)";
    }
    if (role === "restaurant" || role === 20 || role === "owner") {
      return "rgba(12, 118, 208, 1)";
    }
    if (role === "chef" || role === 30) {
      return "rgba(1, 175, 14, 1)";
    }
    if (role === "business" || role === 40) {
      return "#E6E077";
    }
  };
  return (
    <Grid container direction="row" justifyContent="center">
      <Grid
        container
        item
        xs={12}
        justifyContent="center"
        alignItems="center"
        sx={{ background: "#1291FC" }}
      >
        <Grid item xs={8} sx={{ my: 2, mx: 3 }}>
          <CssTextField
            placeholder="Search Contacts"
            onChange={(e) => setInput(e.target.value)}
            value={input}
            fullWidth
            // sx={{ backgroundColor: "#fff" }}
            inputProps={{
              style: {
                textTransform: "capitalize",
              },
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start" sx={{ ml: 1 }}>
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={2}>
          <Button sx={{ color: "#fff" }} onClick={() => navigate("/chat")}>
            Cancel
          </Button>
        </Grid>
      </Grid>
      <Grid xs={12} item container justifyContent="center" alignItems="center">
        <Grid item xs={12}>
          <Typography
            variant="h5"
            sx={{
              borderBottom: 1,
              borderColor: "#D0D0D0",
              color: "rgba(12, 118, 208, 1)",
              pb: 2,
              backgroundColor: "#f2f2f2f2",
              pt: 2,
            }}
          >
            Travelling
          </Typography>
          <List dense={false}>
            {filteredData
              ?.filter((contact) => contact?.user?.role?.name === "customer")
              ?.map((contact, idx) => {
                return (
                  <ListItem
                    onClick={() => {
                      navigate("/chat/" + contact?.conversationId);
                    }}
                    key={idx}
                    sx={{
                      borderBottom:
                        idx ===
                        filteredData?.filter(
                          (contact) => contact?.user?.role?.name === "customer"
                        )?.length -
                          1
                          ? ""
                          : "1px solid #D0D0D0",
                    }}
                  >
                    <ListItemAvatar>
                      <Avatar
                        src={contact?.user?.profile?.avatar?.url}
                        srcSet={contact?.user?.profile?.avatar?.url}
                        sx={{
                          borderColor: getColorBasedOnRole(
                            contact?.user?.role?.name
                          ),
                          borderStyle: "solid",
                          borderWidth: { xs: "2px", sm: "5px" },
                        }}
                      >
                        {contact?.profile?.name?.charAt(0) ?? undefined}
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText>
                      <Grid container alignItems="center">
                        <Grid item xs={12}>
                          <Typography>
                            {contact?.user?.restaurant?.length > 0
                              ? `${contact?.user?.restaurant[0].name}`
                              : `${contact?.user?.profile?.name ?? ""} ${
                                  contact?.user?.profile?.middleName ?? ""
                                } ${contact?.user?.profile?.lastName ?? ""}`}
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography>
                            {contact?.user?.restaurant?.length > 0
                              ? `${contact?.user?.restaurant[0].address?.city} ${contact?.user?.restaurant[0].address?.country}`
                              : `${contact?.user?.address?.city ?? ""} ${
                                  contact?.user?.address?.country ?? ""
                                }`}
                          </Typography>
                        </Grid>
                      </Grid>
                    </ListItemText>
                  </ListItem>
                );
              })}
          </List>
          <Typography
            variant="h5"
            sx={{
              borderBottom: 1,
              borderColor: "#D0D0D0",
              color: "rgba(12, 118, 208, 1)",
              pb: 2,
              backgroundColor: "#f2f2f2f2",
              pt: 2,
            }}
          >
            Restaurants
          </Typography>
          <List dense={false}>
            {filteredData
              ?.filter((contact) => contact?.user?.role?.name === "owner")
              ?.map((contact, idx) => {
                return (
                  <ListItem
                    onClick={() => {
                      navigate("/chat/" + contact?.conversationId);
                    }}
                    key={idx}
                    sx={{
                      borderBottom:
                        idx ===
                        filteredData?.filter(
                          (contact) => contact?.user?.role?.name === "owner"
                        )?.length -
                          1
                          ? ""
                          : "1px solid #D0D0D0",
                    }}
                  >
                    <ListItemAvatar>
                      <Avatar
                        src={contact?.user?.profile?.avatar?.url}
                        srcSet={contact?.user?.profile?.avatar?.url}
                        sx={{
                          borderColor: getColorBasedOnRole(
                            contact?.user?.role?.name
                          ),
                          borderStyle: "solid",
                          borderWidth: { xs: "2px", sm: "5px" },
                        }}
                      >
                        {contact?.profile?.name?.charAt(0) ?? undefined}
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText>
                      <Grid container alignItems="center">
                        <Grid item xs={12}>
                          <Typography>
                            {contact?.user?.restaurant?.length > 0
                              ? `${contact?.user?.restaurant[0].name}`
                              : `${contact?.user?.profile?.name ?? ""} ${
                                  contact?.user?.profile?.middleName ?? ""
                                } ${contact?.user?.profile?.lastName ?? ""}`}
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography>
                            {contact?.user?.restaurant?.length > 0
                              ? `${contact?.user?.restaurant[0].address?.city} ${contact?.user?.restaurant[0].address?.country}`
                              : `${contact?.user?.address?.city ?? ""} ${
                                  contact?.user?.address?.country ?? ""
                                }`}
                          </Typography>
                        </Grid>
                      </Grid>
                    </ListItemText>
                  </ListItem>
                );
              })}
          </List>
          <Typography
            variant="h5"
            sx={{
              borderBottom: 1,
              borderColor: "#D0D0D0",
              color: "rgba(12, 118, 208, 1)",
              pb: 2,
              backgroundColor: "#f2f2f2f2",
              pt: 2,
            }}
          >
            Chef
          </Typography>
          <List dense={false}>
            {filteredData
              ?.filter((contact) => contact?.user?.role?.name === "chef")
              ?.map((contact, idx) => {
                return (
                  <ListItem
                    onClick={() => {
                      navigate("/chat/" + contact?.conversationId);
                    }}
                    key={idx}
                    sx={{
                      borderBottom:
                        idx ===
                        filteredData?.filter(
                          (contact) => contact?.user?.role?.name === "chef"
                        )?.length -
                          1
                          ? ""
                          : "1px solid #D0D0D0",
                    }}
                  >
                    <ListItemAvatar>
                      <Avatar
                        src={contact?.user?.profile?.avatar?.url}
                        srcSet={contact?.user?.profile?.avatar?.url}
                        sx={{
                          borderColor: getColorBasedOnRole(
                            contact?.user?.role?.name
                          ),
                          borderStyle: "solid",
                          borderWidth: { xs: "2px", sm: "5px" },
                        }}
                      >
                        {contact?.profile?.name?.charAt(0) ?? undefined}
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText>
                      <Grid container alignItems="center">
                        <Grid item xs={12}>
                          <Typography>
                            {contact?.user?.restaurant?.length > 0
                              ? `${contact?.user?.restaurant[0].name}`
                              : `${contact?.user?.profile?.name ?? ""} ${
                                  contact?.user?.profile?.middleName ?? ""
                                } ${contact?.user?.profile?.lastName ?? ""}`}
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography>
                            {contact?.user?.restaurant?.length > 0
                              ? `${contact?.user?.restaurant[0].address?.city} ${contact?.user?.restaurant[0].address?.country}`
                              : `${contact?.user?.address?.city ?? ""} ${
                                  contact?.user?.address?.country ?? ""
                                }`}
                          </Typography>
                        </Grid>
                      </Grid>
                    </ListItemText>
                  </ListItem>
                );
              })}
          </List>
          <Typography
            variant="h5"
            sx={{
              borderBottom: 1,
              borderColor: "#D0D0D0",
              color: "rgba(12, 118, 208, 1)",
              pb: 2,
              backgroundColor: "#f2f2f2f2",
              pt: 2,
            }}
          >
            Business
          </Typography>
          <List dense={false}>
            {filteredData
              ?.filter((contact) => contact?.user?.role?.name === "business")
              ?.map((contact, idx) => {
                return (
                  <ListItem
                    onClick={() => {
                      navigate("/chat/" + contact?.conversationId);
                    }}
                    key={idx}
                    sx={{
                      borderBottom:
                        idx ===
                        filteredData?.filter(
                          (contact) => contact?.user?.role?.name === "business"
                        )?.length -
                          1
                          ? ""
                          : "1px solid #D0D0D0",
                    }}
                  >
                    <ListItemAvatar>
                      <Avatar
                        src={contact?.user?.profile?.avatar?.url}
                        srcSet={contact?.user?.profile?.avatar?.url}
                        sx={{
                          borderColor: getColorBasedOnRole(
                            contact?.user?.role?.name
                          ),
                          borderStyle: "solid",
                          borderWidth: { xs: "2px", sm: "5px" },
                        }}
                      >
                        {contact?.profile?.name?.charAt(0) ?? undefined}
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText>
                      <Grid container alignItems="center">
                        <Grid item xs={12}>
                          <Typography>
                            {contact?.user?.restaurant?.length > 0
                              ? `${contact?.user?.restaurant[0].name}`
                              : `${contact?.user?.profile?.name ?? ""} ${
                                  contact?.user?.profile?.middleName ?? ""
                                } ${contact?.user?.profile?.lastName ?? ""}`}
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography>
                            {contact?.user?.restaurant?.length > 0
                              ? `${contact?.user?.restaurant[0].address?.city} ${contact?.user?.restaurant[0].address?.country}`
                              : `${contact?.user?.address?.city ?? ""} ${
                                  contact?.user?.address?.country ?? ""
                                }`}
                          </Typography>
                        </Grid>
                      </Grid>
                    </ListItemText>
                  </ListItem>
                );
              })}
          </List>
        </Grid>
      </Grid>
    </Grid>
  );
};

const CssTextField = styled(TextField)({
  "& label.Mui-focused": {
    color: "white",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "white",
  },
  "& .MuiOutlinedInput-root": {
    backgroundColor: "#fff",
    "& fieldset": {
      borderRadius: 8,
    },
    "&:hover fieldset": {
      borderColor: "white",
    },
    "&.Mui-focused fieldset": {
      borderColor: "white",
    },
  },
});
