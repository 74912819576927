import { Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import wolrMapImage from "../../assets/worl-map.png";
import TravelerProfessional from "../../assets/card-travelling-professional-header.png";
import Chef from "../../assets/card-restaurant-chefs-header.png";
import RestaurantAffiliate from "../../assets/card-restaurant-affiliate-header.png";
import Winnery from "../../assets/card-winery-header.png";

export const ProfessionalCard = ({ role, name, id }) => {
  const [backgroundColor, setBackgroundColor] = useState();
  const [image, setImage] = useState();

  useEffect(() => {
    if (role === "customer" || role === 10) {
      setBackgroundColor("rgba(255, 0, 0, 1)");
      setImage(TravelerProfessional);
    }
    if (role === "restaurant" || role === 20 || role === "owner") {
      setBackgroundColor("rgba(12, 118, 208, 1)");
      setImage(RestaurantAffiliate);
    }
    if (role === "chef" || role === 30) {
      setBackgroundColor("rgba(1, 175, 14, 1)");
      setImage(Chef);
    }
    if (role === "business" || role === 40) {
      setBackgroundColor("#E6E077");
      setImage(Winnery);
    }
  }, [role]);

  const buildCardNumberString = (id) => {
    if (!id) return "0000 0000 0000 0000";
    let cardNumber = "";
    for (let i = 0; i < id.length; i = i + 4) {
      cardNumber += id.slice(i, i + 4) + " ";
    }
    return cardNumber;
  };

  return (
    <Box
      sx={{
        backgroundColor: backgroundColor,
        borderRadius: 8,
        position: "relative",
      }}
    >
      <img
        src={wolrMapImage}
        alt="Card"
        style={{
          width: "100%",
        }}
      />
      <img
        src={image}
        alt=""
        style={{
          position: "absolute",
          top: "10%",
          left: "10%",
          maxWidth: "80%",
        }}
      />
      <Typography
        color={
          role === "restaurant" ||
          role === 20 ||
          role === "business" ||
          role === 40
            ? "white"
            : "black"
        }
        sx={{
          position: "absolute",
          bottom: "22%",
          left: "10%",
          fontSize: "1.8vh",
        }}
      >
        {buildCardNumberString(id)}
      </Typography>
      <Typography
        color={
          role === "restaurant" ||
          role === 20 ||
          role === "business" ||
          role === 40
            ? "white"
            : "black"
        }
        sx={{
          position: "absolute",
          bottom: "10%",
          left: "10%",
          fontSize: "1.5vh",
        }}
      >
        {name ?? "Name LastName"}
      </Typography>
    </Box>
  );
};
