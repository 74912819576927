import React, { useEffect } from "react";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { useGetAllBookingsQuery } from "services/bookingsApi";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@mui/material";
import { ExpandMore } from "@mui/icons-material";
import { BookingCard } from "./BookingCard";
import { useTranslation } from "react-i18next";
import { useAuthUser } from "react-auth-kit";
import { useNavigate } from "react-router-dom";

export const Bookings = () => {
  const auth = useAuthUser();
  const navigate = useNavigate();
  useEffect(() => {
    if (!auth().active) navigate("/card");
  }, []);
  const { data, refetch } = useGetAllBookingsQuery();
  const { t } = useTranslation();
  return (
    <Grid
      container
      sx={{
        width: "100vw",
        mt: { xs: "100px" },
        pb: 15,
        height: "calc(100vh - 100px)",
        position: "fixed",
        overflowY: "scroll",
        overflowX: "hidden",
      }}
    >
      <Grid container justifyContent="center" alignItems="center">
        <Grid xs={12}>
          <Typography variant="h4" sx={{ color: "#27bdff", fontWeight: 700 }}>
            {t("mybookings")}
          </Typography>
          <Typography
            variant="caption"
            sx={{ color: "#27bdff", fontWeight: 700 }}
          >
            BID*: Booking Unique Identifier
          </Typography>
        </Grid>
        <Grid xs={11} container>
          <Accordion sx={{ width: "100%" }}>
            <AccordionSummary
              expandIcon={<ExpandMore />}
              aria-controls="accpeted-bookings"
            >
              <Typography>
                {t("accepted")}
                {
                  data?.filter((booking) => booking?.state === "accepted")
                    ?.length
                }
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                spacing={2}
              >
                {data?.map((booking, idx) => {
                  if (booking?.state === "accepted")
                    return (
                      <Grid xs={12} key={idx} md={4}>
                        <BookingCard
                          booking={booking}
                          edit={true}
                          refetch={refetch}
                          restaurant={false}
                        />
                      </Grid>
                    );
                  return null;
                })}
              </Grid>
            </AccordionDetails>
          </Accordion>
        </Grid>
        <Grid xs={11} container>
          <Accordion sx={{ width: "100%" }}>
            <AccordionSummary
              expandIcon={<ExpandMore />}
              aria-controls="pending-bookings"
            >
              <Typography>
                {t("pending")}
                {
                  data?.filter((booking) => booking?.state === "pending")
                    ?.length
                }
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                spacing={2}
              >
                {data?.map((booking, idx) => {
                  if (booking?.state === "pending")
                    return (
                      <Grid xs={12} key={idx} md={4}>
                        <BookingCard
                          booking={booking}
                          edit={true}
                          refetch={refetch}
                          restaurant={false}
                        />
                      </Grid>
                    );
                  return null;
                })}
              </Grid>
            </AccordionDetails>
          </Accordion>
        </Grid>
        <Grid xs={11} container>
          <Accordion sx={{ width: "100%" }}>
            <AccordionSummary
              expandIcon={<ExpandMore />}
              aria-controls="rejected-bookings"
            >
              <Typography>
                {t("rejected")}
                {
                  data?.filter((booking) => booking?.state === "rejected")
                    ?.length
                }
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                spacing={2}
              >
                {data?.map((booking, idx) => {
                  if (booking?.state === "rejected")
                    return (
                      <Grid xs={12} key={idx} md={4}>
                        <BookingCard
                          booking={booking}
                          edit={false}
                          refetch={refetch}
                          restaurant={false}
                        />
                      </Grid>
                    );
                  return null;
                })}
              </Grid>
            </AccordionDetails>
          </Accordion>
        </Grid>
      </Grid>
    </Grid>
  );
};
