import React, { useEffect, useState } from "react";
import {
  AppBar,
  IconButton,
  Toolbar,
  Typography,
  Grid,
  Stack,
  Box,
} from "@mui/material";
import logo from "assets/logo.svg";
import { Sidebar } from "app/Navbar/Sidebar";
import { NotificationIcon } from "app/Navbar/NotificationIcon";
import { MessageIcon } from "app/Navbar/MessageIcon";
import { ProfileIcon } from "./ProfileIcon";
import { useAuthUser } from "react-auth-kit";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectNotifications } from "services/authSlice";
import { NotificationsProvider } from "./NotificationsProvider";
import { DoubleNavbar } from "./DoubleNavbar";

export function Navbar() {
  const notifications = useSelector(selectNotifications);
  const navigate = useNavigate();
  const [isAtuth, setIsAuth] = useState(false);
  const authUser = useAuthUser();
  useEffect(() => {
    setIsAuth(authUser().active);
  }, []);
  return (
    <NotificationsProvider auth={isAtuth}>
      <AppBar
        draggable={false}
        position="fixed"
        sx={{
          backgroundColor: "red",
          paddingRight: 0,
          top: 0,
          display: { xs: "flex", md: "none" },
        }}
      >
        <Toolbar disableGutters sx={{ paddingLeft: 3 }}>
          <Typography
            align="left"
            component="div"
            sx={{ flexGrow: 1, maxWidth: 70, minWidth: 60 }}
            mr={2}
            mt={1}
          >
            <img
              src={logo}
              alt="MyTravelDinner logo"
              style={{ cursor: "pointer" }}
              onClick={() => navigate("/")}
            />
          </Typography>
          <Typography
            align="left"
            component="h1"
            fontSize="2rem"
            fontWeight="bold"
            fontFamily="MTD !important"
            letterSpacing="0.0625rem"
            style={{ cursor: "pointer" }}
            onClick={() => navigate("/")}
            display={{ xs: "none", sm: "block" }}
          >
            My
          </Typography>
          <Typography
            align="left"
            component="h1"
            color="#ffd800"
            fontSize="2rem"
            fontWeight="bold"
            fontFamily="MTD !important"
            letterSpacing="0.0625rem"
            style={{ cursor: "pointer" }}
            onClick={() => navigate("/")}
            display={{ xs: "none", sm: "block" }}
          >
            Travel
          </Typography>
          <Typography
            align="left"
            component="h1"
            fontSize="2rem"
            fontWeight="bold"
            fontFamily="MTD !important"
            letterSpacing="0.0625rem"
            style={{ cursor: "pointer" }}
            onClick={() => navigate("/")}
            display={{ xs: "none", sm: "block" }}
          >
            Dinner
          </Typography>

          <Stack
            direction="row"
            sx={{
              backgroundColor: {
                xs: "red",
                md: `${isAtuth ? "#27bdff" : "#d0d0d0"}`,
              },
              height: 80,
            }}
          >
            <Grid
              container
              justifyContent="space-between"
              alignItems="center"
              direction="column"
              sx={{
                width: { xs: 100, md: 100 },
                "&:hover": {
                  backgroundColor: { xs: "red", md: "#007bff" },
                },
                borderRight: { xs: 0, md: 1 },
              }}
            >
              <IconButton>
                <NotificationIcon
                  auth={isAtuth}
                  notifications={notifications}
                />
              </IconButton>
            </Grid>
            {/* <Grid
              container
              justifyContent="center"
              alignItems="center"
              direction="column"
              sx={{
                width: { xs: 80, md: 100 },
                "&:hover": {
                  backgroundColor: { xs: "red", md: "#007bff" },
                },
                borderRight: { xs: 0, md: 1 },
              }}
            >
              <IconButton>
                <MessageIcon auth={isAtuth} />
              </IconButton>
            </Grid> */}
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              direction="column"
              sx={{
                width: { xs: 100, md: 100 },
                "&:hover": {
                  backgroundColor: { xs: "red", md: "#007bff" },
                },
                borderRight: { xs: 0, md: 1 },
              }}
            >
              <ProfileIcon />
            </Grid>
          </Stack>
          <Sidebar auth={isAtuth} mobile={{ xs: true, md: false }} />
        </Toolbar>
      </AppBar>
      <Box
        component="div"
        m={0}
        p={0}
        sx={{ display: { xs: "none", md: "flex" }, width: "100%" }}
      >
        <DoubleNavbar />
      </Box>
    </NotificationsProvider>
  );
}
