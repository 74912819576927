import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Unstable_Grid2";
import { Information } from "./Information";
import { Images } from "./Images";
import { useGetAllMyRestaurantsQuery } from "services/usersApi";
import PhotoLibraryIcon from "@mui/icons-material/PhotoLibrary";
import FeedIcon from "@mui/icons-material/Feed";
import { Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import RestaurantMenuOutlinedIcon from "@mui/icons-material/RestaurantMenuOutlined";
import { Chefs } from "./Chefs";
export const Restaurant = () => {
  const [activeTab, setActiveTab] = useState("information");
  const { data, refetch } = useGetAllMyRestaurantsQuery();
  const { t } = useTranslation();
  const [id, setId] = useState(null);
  const [formData, setFormData] = useState({});

  useEffect(() => {
    setFormData(data?.restaurants[0]);
  }, [data]);

  const handleContent = () => {
    switch (activeTab) {
      case "information":
        return (
          <Information
            formData={formData}
            setId={setId}
            setFormData={setFormData}
          />
        );
      case "images":
        return <Images id={formData?.id} />;
      case "chefs":
        return (
          <Chefs
            id={formData?.id}
            formData={formData}
            setId={setId}
            setFormData={setFormData}
            refetch={refetch}
          />
        );
      default:
        return <></>;
    }
  };

  return (
    <Grid container xs={12}>
      <Grid xs={4}>
        <Button
          variant="contained"
          startIcon={<FeedIcon />}
          sx={{
            width: "100%",
            borderRadius: 0,
            marginTop: 0,
            color: "white",
            display: { xs: "none", sm: "flex" },
          }}
          onClick={() => setActiveTab("information")}
          color={activeTab === "information" ? "blueStrong" : "blueLight"}
        >
          {t("information")}
        </Button>
        <Button
          variant="contained"
          startIcon={<FeedIcon />}
          sx={{
            width: "100%",
            borderRadius: 0,
            marginTop: 0,
            color: "white",
            display: { xs: "flex", sm: "none" },
          }}
          onClick={() => setActiveTab("information")}
          color={activeTab === "information" ? "blueStrong" : "blueLight"}
        ></Button>
      </Grid>
      <Grid xs={4}>
        <Button
          variant="contained"
          startIcon={<PhotoLibraryIcon />}
          sx={{
            width: "100%",
            borderRadius: 0,
            marginTop: 0,
            color: "white",
            display: { xs: "none", sm: "flex" },
          }}
          onClick={() => setActiveTab("images")}
          color={activeTab === "images" ? "blueStrong" : "blueLight"}
        >
          {t("images")}
        </Button>
        <Button
          variant="contained"
          startIcon={<PhotoLibraryIcon />}
          sx={{
            width: "100%",
            borderRadius: 0,
            marginTop: 0,
            color: "white",
            display: { xs: "flex", sm: "none" },
          }}
          onClick={() => setActiveTab("images")}
          color={activeTab === "images" ? "blueStrong" : "blueLight"}
        ></Button>
      </Grid>
      <Grid xs={4}>
        <Button
          variant="contained"
          startIcon={<RestaurantMenuOutlinedIcon />}
          sx={{
            width: "100%",
            borderRadius: 0,
            marginTop: 0,
            color: "white",
            display: { xs: "none", sm: "flex" },
          }}
          onClick={() => setActiveTab("chefs")}
          color={activeTab === "chefs" ? "blueStrong" : "blueLight"}
        >
          {t("chefs")}
        </Button>
        <Button
          variant="contained"
          startIcon={<RestaurantMenuOutlinedIcon />}
          sx={{
            width: "100%",
            borderRadius: 0,
            marginTop: 0,
            color: "white",
            display: { xs: "flex", sm: "none" },
          }}
          onClick={() => setActiveTab("chefs")}
          color={activeTab === "chefs" ? "blueStrong" : "blueLight"}
        ></Button>
      </Grid>
      <Grid item xs={12} container>
        {handleContent(activeTab)}
      </Grid>
    </Grid>
  );
};
