import { Avatar, Button, Grid, IconButton, Typography } from "@mui/material";
import { blue } from "@mui/material/colors";
import { useSnackbar } from "notistack";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
  useListAllChefRequestsRestaurantMutation,
  useQuitRestaurantMutation,
  useWorkInRestaurantMutation,
} from "services/usersApi";
import ClearIcon from "@mui/icons-material/Clear";

export const Chefs = ({ setId, setFormData, formData, id, refetch }) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [getChaRequests, chaRequestsResults] =
    useListAllChefRequestsRestaurantMutation();
  const [workInRestaurant, workInRestaurantResults] =
    useWorkInRestaurantMutation();
  const [quitChef, quitChefResults] = useQuitRestaurantMutation();

  useEffect(() => {
    if (quitChefResults.isSuccess) {
      enqueueSnackbar(`Successfully updated`, {
        variant: "success",
        autoHideDuration: 2000,
      });
      refetch();
      getChaRequests();
    }
    if (quitChefResults.isError) {
      enqueueSnackbar(`There was an error trying to update it`, {
        variant: "error",
        autoHideDuration: 2000,
      });
      refetch();
    }
  }, [quitChefResults]);
  useEffect(() => {
    if (workInRestaurantResults.isSuccess) {
      enqueueSnackbar(`Successfully updated`, {
        variant: "success",
        autoHideDuration: 2000,
      });
      refetch();
      getChaRequests();
    }
    if (workInRestaurantResults.isError) {
      enqueueSnackbar(`There was an error trying to update it`, {
        variant: "error",
        autoHideDuration: 2000,
      });
      refetch();
      getChaRequests();
    }
  }, [workInRestaurantResults]);

  useEffect(() => {
    getChaRequests();
  }, []);

  const statusText = (status) => {
    if (status === "accepted") return t("chefRequestAccepted");
    else return t("chefRequestRejected");
  };

  return (
    <Grid
      container
      direction="column"
      justfyContent="center"
      sx={{ width: "100%", height: "100vh" }}
    >
      <Grid
        item
        xs={4}
        justifyContent="center"
        container
        alignItems="center"
        sx={{
          backgroundColor: "#F2F2F2",
          borderBottom: "1px solid #D0D0D0",
          maxHeight: "80px",
        }}
      >
        <Typography
          noWrap
          variant="h5"
          sx={{
            color: blue[700],
            display: "flex",
            fontWeight: "700",
          }}
        >
          {t("incomingChefRequests")}
        </Typography>
      </Grid>
      <Grid item xs={4} container justifyContent="center" alignItems="center">
        {chaRequestsResults?.data?.requests
          ?.filter((notification) => notification?.status === "pending")
          ?.map((notification, index) => (
            <Grid
              container
              item
              xs={10}
              key={index}
              sx={{
                borderRadius: "10px",
                p: 1,
                backgroundColor: `${
                  notification?.status === "pending" ? "#1396FF" : "transparent"
                }`,
                borderBottom: "1px solid #D0D0D0",
              }}
            >
              <Grid item xs={2}>
                <Avatar
                  sx={{
                    ml: 1,
                    borderColor: "rgba(1, 175, 14, 1)",
                    borderStyle: "solid",
                    borderWidth: { xs: "1px", sm: "5px" },
                  }}
                  src={notification?.userOne?.profile?.avatar?.url}
                />
              </Grid>
              <Grid item xs={8} justifyContent="center" alignItems="center">
                <Typography
                  variant="h6"
                  noWrap
                  sx={{
                    color: `${"#F7B500"}`,
                  }}
                >
                  {notification?.status === "pending"
                    ? t("newChefRequest")
                    : statusText(notification?.status)}
                </Typography>
                <Typography
                  noWrap
                  sx={{
                    color: `${
                      notification?.status === "pending" ? "#fff" : "#000"
                    }`,
                  }}
                >
                  {`${notification?.userOne?.profile?.name ?? ""} ${
                    notification?.userOne?.profile?.middleName ?? ""
                  } ${notification?.userOne?.profile?.lastName ?? ""}`}
                </Typography>
              </Grid>

              <Grid
                item
                xs={12}
                sm={2}
                container
                justifyContent="space-around"
                alignItems="center"
              >
                {notification?.status === "pending" && (
                  <>
                    <Button
                      variant="outlined"
                      sx={{
                        backgroundColor: "rgba(1, 175, 14, 1)",
                        color: "#fff",
                        maxHeight: "40px",
                        ":hover": {
                          backgroundColor: "rgba(1, 175, 14, 1)",
                          transform: "scale(1.1)",
                        },
                      }}
                      onClick={() => {
                        workInRestaurant({
                          id: notification?.id,
                          status: "accepted",
                        });
                      }}
                    >
                      {t("accept")}
                    </Button>
                    <Button
                      variant="outlined"
                      sx={{
                        backgroundColor: "#FD0000",
                        color: "#fff",
                        maxHeight: "40px",
                        ":hover": {
                          backgroundColor: "#FD0000",
                          transform: "scale(1.1)",
                        },
                      }}
                      onClick={() => {
                        workInRestaurant({
                          id: notification?.id,
                          status: "declined",
                        });
                      }}
                    >
                      {t("decline")}
                    </Button>
                  </>
                )}
              </Grid>
            </Grid>
          ))}
      </Grid>
      <Grid
        item
        xs={4}
        justifyContent="center"
        container
        alignItems="center"
        sx={{
          backgroundColor: "#F2F2F2",
          borderBottom: "1px solid #D0D0D0",
          maxHeight: "80px",
        }}
      >
        <Typography
          noWrap
          variant="h5"
          sx={{
            color: blue[700],
            display: "flex",
            fontWeight: "700",
          }}
        >
          {t("chefs")}
        </Typography>
      </Grid>
      <Grid item xs={4} container justifyContent="center" alignItems="center">
        {formData?.chefs?.map((chef, idx) => (
          <Grid item xs={10} key={idx} container>
            <Grid item xs={2}>
              <Avatar
                sx={{
                  ml: 1,
                  borderColor: "rgba(1, 175, 14, 1)",
                  borderStyle: "solid",
                  borderWidth: { xs: "1px", sm: "5px" },
                }}
                src={chef?.user?.profile?.avatar?.url}
                alt="avatar"
              >
                {chef?.user?.profile?.name ?? ""}
              </Avatar>
            </Grid>
            <Grid
              item
              xs={8}
              container
              justifyContent="center"
              alignItems="center"
            >
              <Typography variant="body1">
                {`${chef?.user?.profile?.name ?? ""} ${
                  chef?.user?.profile?.middleName ?? ""
                } ${chef?.user?.profile?.lastName ?? ""}`}
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <IconButton
                onClick={() =>
                  quitChef({
                    id: formData?.id,
                    chefId: chef?.user?.id,
                  })
                }
              >
                <ClearIcon
                  color="red"
                  sx={{ border: "1px solid red", borderRadius: 4 }}
                />
              </IconButton>
            </Grid>
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
};
