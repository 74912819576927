import { Box, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2"; // Grid version 2
import { ProfessionalCard } from "app/Cards/Card";
import React, { useEffect, useState } from "react";
import { useAuthUser } from "react-auth-kit";
import { StripeMobile } from "features/Stripe/StripeMobile";
import moment from "moment";
import { useTranslation } from "react-i18next";

export const MyCard = () => {
  const { t } = useTranslation();
  const [price, setPrice] = useState({ currency: "usd", amount: "0000" });
  const auth = useAuthUser();
  useEffect(() => {
    paymentDate();
  }, [auth]);
  const paymentDate = () => {
    if (!auth()?.paymentDate) return "";
    var currentDate = moment(auth()?.paymentDate);
    var nextPaymentDate = moment(currentDate).add(1, "Y");
    return nextPaymentDate.format("MMMM Do YYYY");
  };

  const subscriptionType = () => {
    if (auth().role?.name === "customer" || auth().role?.id === 10)
      return t("Customer");
    if (
      auth().role?.name === "restaurant" ||
      auth().role?.id === 20 ||
      auth().role?.name === "owner"
    )
      return t("Restaurant");
    if (auth().role?.name === "chef" || auth().role?.id === 30) return "Chef";
    if (auth().role?.name === "business" || auth().role?.id === 40)
      return t("Business");
  };

  const transformPrice = (price) =>
    `${price?.substr(0, price?.length - 2)}.${price?.substr(
      price?.length - 2,
      price?.length
    )}`;

  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      sx={{ mt: { xs: 4, md: 0 } }}
      spacing={1}
    >
      <Grid
        xs={11}
        container
        sx={{ display: { xs: "flex", md: "none" } }}
        justifyContent="center"
      >
        <Grid xs={11}>
          <Typography variant="subtitle2">{t("cardText")}</Typography>
        </Grid>
        <Grid xs={6} sx={{ my: 1 }}>
          <Typography variant="h6">{t("subscriptionType")}</Typography>
        </Grid>
        <Grid
          container
          alignItems="center"
          justifyContent="center"
          xs={5}
          sx={{ backgroundColor: "#f0f0f0", my: 1, borderRadius: 1 }}
        >
          <Typography variant="h6">{subscriptionType()}</Typography>
        </Grid>
        <Grid xs={6} sx={{ my: 1 }}>
          <Typography variant="h6">{t("status")}</Typography>
        </Grid>
        <Grid
          container
          alignItems="center"
          justifyContent="center"
          xs={5}
          sx={{ backgroundColor: "#f0f0f0", my: 1, borderRadius: 1 }}
        >
          {auth()?.active ? (
            <Typography variant="h6" sx={{ color: "#11A80D" }}>
              {t("active")}
            </Typography>
          ) : (
            <Typography variant="h6" sx={{ color: "#FD0000" }}>
              {t("inactive")}
            </Typography>
          )}
        </Grid>
        {auth().active && (
          <>
            <Grid xs={12}>
              <Typography>{`${paymentDate()}`}</Typography>
            </Grid>
            <Grid xs={11} md={4}>
              <ProfessionalCard
                role={auth().role?.name}
                name={
                  auth().profile?.name && auth().profile?.lastName
                    ? auth().profile?.name + " " + auth().profile?.lastName
                    : null
                }
                id={auth()?.cardNumber}
              />
            </Grid>
          </>
        )}
        {!auth().active && (
          <>
            <Grid xs={11} sm={4}>
              <ProfessionalCard
                role={auth().role?.name}
                name={
                  auth().profile?.name && auth().profile?.lastName
                    ? auth().profile?.name + " " + auth().profile?.lastName
                    : null
                }
                id={auth()?.cardNumber}
              />
              <Typography variant="h4">{`${"$"} ${transformPrice(
                price.amount
              )}`}</Typography>
            </Grid>
            <Grid xs={12} sm={6}>
              <StripeMobile setPrice={setPrice} />
            </Grid>
            <Grid xs={12} mt={2}>
              <Typography>{t("paymentDue")}</Typography>
            </Grid>
          </>
        )}
      </Grid>
      <Grid
        xs={12}
        container
        sx={{
          display: { xs: "none", md: "flex" },
          mt: 4,
        }}
        spacing={4}
      >
        <Grid xs={11}>
          <Typography mx={4} variant="subtitle2">
            {t("cardText")}
          </Typography>
        </Grid>
        <Grid xs={6} container justifyContent="center">
          <Grid xs={8} sx={{ mt: 4 }} container justifyContent="flex-start">
            <Typography variant="h6">{t("subscriptionType")}</Typography>
          </Grid>
          <Grid xs={8}>
            <Box sx={{ backgroundColor: "#f0f0f0", borderRadius: 1, p: 2 }}>
              <Typography variant="subtitle1">{subscriptionType()}</Typography>
            </Box>
          </Grid>
          <Grid xs={8} sx={{ mt: 2 }} container justifyContent="flex-start">
            <Typography variant="h6">{t("status")}</Typography>
          </Grid>
          <Grid xs={8}>
            <Box
              sx={{
                backgroundColor: "#f0f0f0",
                borderRadius: 1,
                p: 2,
              }}
            >
              {auth()?.active ? (
                <Typography variant="subtitle1" sx={{ color: "#11A80D" }}>
                  {t("active")}
                </Typography>
              ) : (
                <Typography variant="subtitle1" sx={{ color: "#FD0000" }}>
                  {t("inactive")}
                </Typography>
              )}
            </Box>
            <Grid xs={12}>
              <Typography>{`${paymentDate()}`}</Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid xs={6} container justifyContent="center">
          {auth().active && (
            <>
              <Grid xs={11} md={8}>
                <ProfessionalCard
                  role={auth().role?.name}
                  name={
                    auth().profile?.name && auth().profile?.lastName
                      ? auth().profile?.name + " " + auth().profile?.lastName
                      : null
                  }
                  id={auth()?.cardNumber}
                />
              </Grid>
            </>
          )}
          {!auth().active && (
            <>
              <Grid xs={11} sm={8}>
                <ProfessionalCard
                  role={auth().role?.name}
                  name={
                    auth().profile?.name && auth().profile?.lastName
                      ? auth().profile?.name + " " + auth().profile?.lastName
                      : null
                  }
                  id={auth()?.cardNumber}
                />
                <Typography variant="h4">{`${"$"} ${transformPrice(
                  price.amount
                )}`}</Typography>
              </Grid>
              <Grid xs={12} sm={6}>
                <StripeMobile setPrice={setPrice} />
              </Grid>
              <Grid xs={12} mt={2}>
                <Typography>{t("paymentDue")}</Typography>
              </Grid>
            </>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};
