import React, { useEffect, useState } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Unstable_Grid2";
import { useGetAllPostsQuery } from "services/postsApi";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useAuthUser } from "react-auth-kit";

export function CardGallery() {
  const [filter, setFilter] = useState("");
  const auth = useAuthUser();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const { data } = useGetAllPostsQuery({ locale: i18n.language });

  useEffect(() => {
    if (window.location.pathname.includes("partners")) setFilter("partners");
    return () => setFilter("");
  }, [window.location.pathname]);

  const renderCardMedia = (post) => {
    return post?.images?.length > 0 ? (
      <CardMedia
        component="img"
        image={post?.images[0]?.image?.url}
        alt="image"
        style={{ height: "250px", width: "100%", objectFit: "cover" }}
      />
    ) : (
      <></>
    );
  };

  return (
    <Grid
      container
      sx={{
        mt: { xs: "100px" },
        pb: 15,
        flexFlow: "column",
        height: "calc(100vh - 100px)",
        position: "fixed",
        overflowY: "scroll",
        overflowX: "hidden",
        width: "100vw",
      }}
    >
      <Grid
        container
        justifyContent="center"
        gap={2}
        xs={12}
        sx={{ position: "relative" }}
      >
        <Grid xs={12}>
          <Typography variant="h4" sx={{ color: "#27bdff", fontWeight: 700 }}>
            {filter?.length > 0 ? t("myPartners") : t("mynews")}
          </Typography>
        </Grid>
        <Grid item xs={11} container spacing={4}>
          {window.location.pathname.includes("news") &&
            data?.posts?.map((post, idx) => {
              return (
                <Grid key={idx} xs={12} sm={3} sx={{ cursor: "pointer" }}>
                  <Card
                    sx={{ height: "100%" }}
                    onClick={() => {
                      if (auth().active) navigate(`/news/${post?.id}`);
                    }}
                  >
                    {renderCardMedia(post)}
                    <CardContent>
                      <Typography
                        variant="h5"
                        sx={{
                          color: "#27bdff",
                          fontWeight: 700,
                        }}
                      >
                        {post?.translations[0]?.title}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
              );
            })}
          {window.location.pathname.includes("partners") &&
            data?.posts
              ?.filter((item) =>
                item?.category?.name?.toLowerCase()?.includes("partners")
              )
              ?.map((post, idx) => {
                return (
                  <Grid key={idx} xs={12} sm={3} sx={{ cursor: "pointer" }}>
                    <Card
                      sx={{ height: "100%" }}
                      onClick={() => {
                        if (auth().active) navigate(`/news/${post?.id}`);
                      }}
                    >
                      {renderCardMedia(post)}
                      <CardContent>
                        <Typography
                          variant="h5"
                          sx={{
                            color: "#27bdff",
                            fontWeight: 700,
                          }}
                        >
                          {post?.translations[0]?.title}
                        </Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                );
              })}
        </Grid>
      </Grid>
    </Grid>
  );
}
