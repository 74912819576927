import React, { useState } from "react";
import Box from "@mui/material/Box";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import RestaurantDialog from "features/Panel/PanelData/RestaurantDialog";
import { useGetRestaurantQuery } from "services/restaurantsApi";
import { Grid, IconButton, Typography } from "@mui/material";
import { ArrowBackIos } from "@mui/icons-material";

export default function RestaurantGallery(props) {
  const { restaurant, setPanelContent } = props;
  const { data: restaurantExtra } = useGetRestaurantQuery(restaurant?.id);
  const [open, setOpen] = useState(false);
  const [imageData, setImage] = useState(null);
  const [index, setIndex] = useState({ index: 0, total: 0 });

  return (
    <>
      <Grid container sx={{ minWidth: { xs: "100vw", md: 500 } }}>
        <Grid
          item
          xs={12}
          container
          justifyContent="center"
          alignItems="center"
          py={2}
          sx={{ backgroundColor: "#1396FF" }}
        >
          <IconButton
            sx={{ position: "absolute", left: "4%" }}
            onClick={() => setPanelContent("info")}
          >
            <ArrowBackIos sx={{ color: "#fff" }} />
          </IconButton>
          <Grid
            item
            xs={8}
            container
            justifyContent="center"
            alignItems="center"
          >
            <Typography variant="h5" sx={{ color: "#fff" }}>
              {restaurant?.name}
            </Typography>
          </Grid>
        </Grid>

        <Box sx={{ width: "100%", maxHeight: "93vh", overflowY: "scroll" }}>
          <ImageList
            variant="masonry"
            cols={2}
            gap={2}
            scroll="body"
            sx={{ mt: 0.2 }}
          >
            {restaurantExtra?.restaurant?.images.map((item, idx) => {
              if (!item.main) {
                return (
                  <ImageListItem
                    key={item.file.id}
                    onClick={() => {
                      setIndex({
                        index: idx,
                        total: restaurantExtra?.restaurant?.images?.length,
                      });
                      setOpen(true);
                      setImage({
                        restaurant: restaurantExtra?.restaurant,
                        images: restaurantExtra?.restaurant?.images,
                        index: idx,
                      });
                    }}
                  >
                    <img
                      src={`${item.file.url}?w=248&fit=crop&auto=format`}
                      srcSet={`${item.file.url}?w=248&fit=crop&auto=format&dpr=2 2x`}
                      alt={item.file.name}
                      loading="lazy"
                    />
                  </ImageListItem>
                );
              }
              return <></>;
            })}
          </ImageList>
        </Box>
        <RestaurantDialog
          open={open}
          setOpen={setOpen}
          data={imageData}
          index={index}
          id={restaurant?.id}
        />
      </Grid>
    </>
  );
}
