import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

i18n
  .use(LanguageDetector)
  .use(initReactI18next)

  .init({
    detection: {
      convertDetectedLanguage: (lng) => {
        if (lng?.includes("-")) {
          return lng.split("-")[0];
        }
        if (lng?.includes("_")) {
          return lng.split("_")[0];
        }
        return lng;
      },
    },
    fallbackLng: "en",
    load: "languageOnly",
    nonExplicitSupportedLngs: false,
    resources: {
      en: {
        translation: {
          youWillReceiveConfirmation:
            "You will receive a confirmation on your MyBookings",
          thanksReservation: "Thanks for your reservation",
          lunch: "Lunch",
          dinner: "Dinner",
          noTimeSlotsAvailable: "No time slots available for lunch",
          share: "Share",
          confirmDeleteAccount: "Confirm account delete",
          deleteAccountConfirm: "Are you sure you want to delete your account?",
          totalRatings: "Total ratings",
          howWouldYouRate: "How would you rate your experience?",
          cleanliness: "Cleanliness",
          food: "Food",
          service: "Service",
          atmosphere: "Atmosphere",
          submit: "Submit",
          country: "Where are you based?",
          countryRestaurant: "Restaurant Address Country",
          buttonQuit: "Quit restaurant",
          noPendingRequests: "No pending requests",
          noChefs: "No chef is working in your restaurant.",
          chefs: "Chefs",
          chefRequestAccepted: "Chef request accepted!",
          chefRequestRejected: "Chef request rejected!",
          newChefRequest: "New Chef Request!",
          incomingChefRequests: "Chef Requests",
          chefRequestsList: "Pending Requests",
          chefRequests: "Work in Requests",
          buttonSend: "Send Request",
          ratings: "Ratings",
          backToNews: "Back to my news",
          paymentDue: "Your payment is due to date",
          back: "Back",
          newMemberMessage: "You are now a member of MyTravelDinner!",
          popUpMessage:
            "Begin session to access all benefits offered by the MyTravelDinner platform.",
          whereAreyouGoing: "Where are you going?",
          cancel: "Cancel",
          topSearches: "Top Searches",
          markAsRead: "Mark as read",
          markAsUnread: "Mark as unread",
          bookings: "Bookings",
          stories: "Stories",
          delete: "Delete",
          posts: "Posts",
          messages: "Messages",
          newPost: "New post",
          markAllAsRead: "Mark all as read",
          markAllAsUnread: "Mark all as unread",
          cardText:
            "In order to gain access to any promotions related to MyTravelDinner Club, please make sure the name on your credit card matches the one that appears on our membership card.",
          countryCode: "Country Calling Code (ISD)",
          countryCodeRestaurant: "Restaurant Country Calling Code (ISD)",
          removeContact: "Remove Contact",
          notificationsPending: "Notifications pending!",
          storyTitle: "New Story!",
          storyBody: "",
          postTitle: "New Post!",
          postBody: "",
          bookingTitle: "New Booking!",
          bookingBody: "",
          messageTitle: "New Message!",
          messageBody: "",
          restaurantWebsite: "Restaurant Website",
          newStory: "New Story",
          subscriptionType: "Subscription Type:",
          makePayment: "Make Payment",
          status: "Status:",
          active: "Active",
          inactive: "Inactive",
          save: "Save",
          copyFrom: "Copy from profile address",
          email: "Email",
          phone: "Cell Phone",
          homePopUpTitle: "You are now a member of MyTravelDinner!",
          homePopUpDescription:
            "Begin session to access all benefits offered by the MyTravelDinner platform.",
          businessName: "Business Name",
          businessType: "Business Type",
          sommelier: "Sommelier",
          wineMakingCompany: "Wine Making Company",
          wineDistributor: "Wine Distributor",
          beerCompany: "Beer Company",
          friends: "Friends",
          blocked: "Blocked",
          Customer: "Traveller",
          Restaurant: "Restaurant",
          Business: "Winery/Sommelier",
          Chef: "Chef",
          notFound: "Page Not Found",
          restaurantSearch: "My Restaurant Search",
          ourChefClub: "Our Chefs' Club",
          mynews: "My News",
          myChat: "My Chat",
          howitworks: "How It Works",
          contactus: "Contact Us",
          mybookings: "My Bookings",
          myaccount: "My Account",
          mycard: "My Card",
          logout: "Log Out",
          login: "Log In",
          account: "Account",
          notamember: "Not a member? Become one!",
          buttonSave: "Save",
          restaurant: "Restaurant",
          chef: "Chef",
          billing: "Billing",
          favourite: "Favourite",
          settings: "Settings",
          address: "Address",
          city: "City",
          cityRestaurant: "Restaurant City",
          postalCode: "Postal Code",
          stateRegion: "State/Province",
          mainImage: "Main Image",
          otherImages: "Other Images",
          view: "View",
          coverImage: "Cover Image",
          italian: "italian",
          spanish: "spanish",
          greek: "greek",
          french: "french",
          portuguese: "portuguese",
          israeli: "israeli",
          moroccan: "moroccan",
          turkish: "turkish",
          argentinian: "argentinian",
          mexico: "mexico",
          uruguayan: "uruguayan",
          mediterranean: "mediterranean",
          seafood: "seafood",
          steakHouse: "steak house",
          localCuisine: "local cuisine",
          monday: "Monday",
          tuesday: "Tuesday",
          wednesday: "Wednesday",
          thursday: "Thursday",
          friday: "Friday",
          saturday: "Saturday",
          sunday: "Sunday",
          solicitationState: "Solicitation State:",
          restaurantCuisine: "Restaurant Cuisine Type",
          restaurantName: "Restaurant Name",
          addressName: "Address Name",
          addressNameRestaurant: "Restaurant Address",
          areaCode: "Area Code",
          areaCodeRestaurant: "Restaurant Phone Area Code",
          region: "Region",
          regionRestaurant: "Restaurant Address Region",
          restaurantPhoneNumber: "Restaurant Phone Number",
          postalCodeDot: "Address Postal Code",
          postalCodeDotRestaurant: "Restaurant Address Postal Code",
          restaurantEmail: "Restaurant Email",
          restarauntWebsite: "Restaurant Website",
          videoUrl: "Video URL",
          videoUrlRestaurant: "Restaurant Video URL",
          description: "Description",
          descriptionRestaurant: "Restaurant Description",
          openingClosingTime: "Select Opening/Closing Time",
          information: "Information",
          images: "Images",
          deactiveAccount: "Deactive Account",
          firstName: "First Name",
          middleName: "Middle name",
          lastName: "Last Name",
          cancelBooking: "Cancel Booking",
          updateBooking: "Update Booking",
          update: "Update",
          accept: "Accept",
          accepted: "Accepted: ",
          pending: "Pending: ",
          rejected: "Rejected: ",
          restaurantBookings: "Restaurant Bookings",
          requests: "Requests",
          mychats: "My Chats",
          contacts: "Contacts",
          block: "Block",
          unBlock: "Unblock",
          remove: "Remove",
          signin: "Sign In",
          free: "free",
          executiveChef: "Executive Chef",
          image: "See More",
          unlike: "Unlike",
          message: "Message",
          browser: "Browser",
          guests: "guests",
          makeReservation: "Make reservation",
          mondayLowerCase: "Monday",
          tuesdayLowerCase: "Tuesday",
          wednesdayLowerCase: "Wednesday",
          thursdayLowerCase: "Thursday",
          fridayLowerCase: "Friday",
          saturdayLowerCase: "Saturday",
          sundayLowerCase: "Sunday",
          phoneNumber: "Cell Phone:",
          cuisine: "Cuisine Type:",
          chefType: "Chef:",
          about: "About",
          openClose: "Hours of operation",
          open: "Open:",
          closed: "Closed",
          myPartners: "My Partners",
          viewOnMap: "View on map",
          previousArticle: "Previous",
          nextArticle: "Next",
          views: "Views",
          likes: "Likes",
          date: "Publish Date",
          recoverPassword: "recover password",
          reset: "reset",
          signUp: "sign up",
          paymentSucceeded: "Payment succeeded!",
          paymentProcessing: "Your payment is processing.",
          paymentRequiresMethod:
            "Your payment was not successful, please try again.",
          paymentDefaultError: "Something went wrong.",
          payNow: "Pay now",
          paymentUnexpectedError: "An unexpected error occurred.",
          chooseLanguage: "Choose Language",
          archived: "Archive",
          unArchived: "Unarchive",
          addToContacts: "Add to contacts",
          mediaHistory: "Media history",
          viewProfile: "View Profile",
          blockUser: "Block user",
          deleteChat: "Delete chat",
          unlikeButton: "Unlike",
          unlikeCancelButton: "Cancel",
          unlikeText: "Unlike?",
          addContact: "Add contact",
          contactAlready: "Contacts already on MyTravelDinner",
          close: "Close",
          like: "Like",
        },
      },
      es: {
        translation: {
          businessName: "Nombre del Negocio",
          businessType: "Tipo de negocio",
          sommelier: "sumiller",
          wineMakingCompany: "Empresa de elaboración de vino",
          wineDistributor: "Distribuidor de vinos",
          beerCompany: "empresa cervecera",
          friends: "Amigos",
          blocked: "Obstruido",
          Customer: "Viajero",
          Restaurant: "Restaurante",
          Business: "Bodega/Sommelier",
          Chef: "Cocinero",
          notFound: "Página no encontrada",
          restaurantSearch: "Mi búsqueda de restaurante",
          ourChefClub: "Nuestro Club de Chefs",
          mynews: "Mis noticias",
          myChat: "mi chat",
          howitworks: "Cómo funciona",
          contactus: "Contáctenos",
          mybookings: "Mis reservas",
          myaccount: "Mi cuenta",
          mycard: "Mi tarjeta",
          logout: "Cerrar sesión",
          login: "Acceso",
          account: "Cuenta",
          notamember: "¿No es un miembro? ",
          buttonSave: "Ahorrar",
          restaurant: "Restaurante",
          chef: "Cocinero",
          billing: "Facturación",
          favourite: "Favorito",
          settings: "Ajustes",
          address: "DIRECCIÓN",
          city: "Ciudad",
          postalCode: "Código Postal",
          stateRegion: "Estado/Región",
          mainImage: "Imagen principal",
          otherImages: "Otras imágenes",
          view: "Vista",
          coverImage: "Imagen de portada",
          italian: "italiano",
          spanish: "español",
          greek: "griego",
          french: "francés",
          portuguese: "portugués",
          israeli: "israelí",
          moroccan: "marroquí",
          turkish: "turco",
          argentinian: "argentino",
          mexico: "México",
          uruguayan: "uruguayo",
          mediterranean: "Mediterráneo",
          seafood: "mariscos",
          steakHouse: "asador",
          localCuisine: "cocina local",
          monday: "Lunes",
          tuesday: "Martes",
          wednesday: "Miércoles",
          thursday: "Jueves",
          friday: "Viernes",
          saturday: "Sábado",
          sunday: "Domingo",
          solicitationState: "Estado de solicitud:",
          restaurantCuisine: "Tipo de cocina del restaurante.",
          restaurantName: "Nombre del restaurante.",
          addressName: "Nombre de la dirección.",
          areaCode: "Codigo de AREA.",
          region: "Región.",
          restaurantPhoneNumber: "Número de teléfono del restaurante.",
          postalCodeDot: "Código Postal.",
          restaurantEmail: "Correo electrónico del restaurante.",
          restarauntWebsite: "Sitio web del restaurante.",
          videoUrl: "URL del vídeo.",
          description: "Descripción",
          openingClosingTime: "Seleccionar hora de apertura/cierre",
          information: "Información",
          images: "Imágenes",
          deactiveAccount: "cuenta desactivada",
          firstName: "Nombre de pila",
          middleName: "Segundo nombre",
          lastName: "Apellido",
          cancelBooking: "Cancelar reserva",
          updateBooking: "Actualizar reserva",
          cancel: "Cancelar",
          update: "Actualizar",
          accept: "Aceptar",
          accepted: "Aceptado: ",
          pending: "Pendiente: ",
          rejected: "Rechazado: ",
          restaurantBookings: "Reservas de restaurante",
          requests: "Peticiones",
          mychats: "Mis chats",
          contacts: "Contactos",
          block: "Bloquear",
          unBlock: "Desatascar",
          remove: "Eliminar",
          signin: "Iniciar sesión",
          free: "gratis",
          executiveChef: "Chef ejecutivo",
          image: "Ver más",
          unlike: "A diferencia de",
          message: "Mensaje",
          browser: "Navegador",
          guests: "huéspedes",
          makeReservation: "Hacer reservacion",
          mondayLowerCase: "Lunes",
          tuesdayLowerCase: "Martes",
          wednesdayLowerCase: "Miércoles",
          thursdayLowerCase: "Jueves",
          fridayLowerCase: "Viernes",
          saturdayLowerCase: "Sábado",
          sundayLowerCase: "Domingo",
          phoneNumber: "Número de teléfono:",
          cuisine: "Tipo de cocina:",
          chefType: "Cocinero:",
          about: "Acerca de",
          openClose: "Horas de operación",
          open: "Abierto:",
          closed: "Cerrado",
          myPartners: "Mis compañeros",
          viewOnMap: "Ver en el mapa",
          previousArticle: "Anterior",
          nextArticle: "Próximo",
          views: "Puntos de vista",
          likes: "Gustos",
          date: "Fecha de publicación",
          recoverPassword: "recuperar contraseña",
          reset: "reiniciar",
          signUp: "inscribirse",
          paymentSucceeded: "¡Pago exitoso!",
          paymentProcessing: "Su pago se está procesando.",
          paymentRequiresMethod: "Su pago no fue exitoso, inténtelo de nuevo.",
          paymentDefaultError: "Algo salió mal.",
          payNow: "Pagar ahora",
          paymentUnexpectedError: "Ocurrió un error inesperado.",
          chooseLanguage: "Elige lengua",
          archived: "Archivo",
          unArchived: "Desarchivar",
          addToContacts: "Añadir contactos",
          mediaHistory: "historial de medios",
          viewProfile: "Ver perfil",
          blockUser: "Bloquear usuario",
          deleteChat: "Eliminar chat",
          unlikeButton: "A diferencia de",
          unlikeCancelButton: "Cancelar",
          unlikeText: "¿A diferencia de?",
          addContact: "Agregar contacto",
          contactAlready: "Contactos que ya están en MyTravelDinner",
        },
      },
      it: {
        translation: {
          businessName: "Nome commerciale",
          businessType: "tipo di affari",
          sommelier: "Sommelier",
          wineMakingCompany: "Azienda vinicola",
          wineDistributor: "Distributore di vini",
          beerCompany: "Compagnia della birra",
          friends: "Amici",
          blocked: "Bloccato",
          Customer: "Viaggiatore",
          Restaurant: "Ristorante",
          Business: "Cantina/Sommerlier",
          Chef: "Cuoco",
          notFound: "Pagina non trovata",
          restaurantSearch: "La mia ricerca del ristorante",
          ourChefClub: "Il nostro club dei cuochi",
          mynews: "Le mie notizie",
          myChat: "La mia chat",
          howitworks: "Come funziona",
          contactus: "Contattaci",
          mybookings: "Le mie prenotazioni",
          myaccount: "Il mio conto",
          mycard: "La mia carta",
          logout: "Disconnettersi",
          login: "Login",
          account: "Account",
          notamember: "Non un membro? ",
          buttonSave: "Salva",
          restaurant: "Ristorante",
          chef: "Cuoco",
          billing: "Fatturazione",
          favourite: "Preferito",
          settings: "Impostazioni",
          address: "Indirizzo",
          city: "Città",
          postalCode: "Codice Postale",
          stateRegion: "Stato/Regione",
          mainImage: "Immagine principale",
          otherImages: "Altre immagini",
          view: "Visualizzazione",
          coverImage: "Immagine di copertina",
          italian: "italiano",
          spanish: "spagnolo",
          greek: "greco",
          french: "francese",
          portuguese: "portoghese",
          israeli: "israeliano",
          moroccan: "marocchino",
          turkish: "Turco",
          argentinian: "argentino",
          mexico: "Messico",
          uruguayan: "uruguaiano",
          mediterranean: "mediterraneo",
          seafood: "frutti di mare",
          steakHouse: "steak house",
          localCuisine: "cucina locale",
          monday: "Lunedi",
          tuesday: "Martedì",
          wednesday: "Mercoledì",
          thursday: "Giovedì",
          friday: "Venerdì",
          saturday: "Sabato",
          sunday: "Domenica",
          solicitationState: "Stato della sollecitazione:",
          restaurantCuisine: "Tipo di cucina del ristorante.",
          restaurantName: "Nome del ristorante.",
          addressName: "Nome dell'indirizzo.",
          areaCode: "Prefisso.",
          region: "Regione.",
          restaurantPhoneNumber: "Numero di telefono del ristorante.",
          postalCodeDot: "Codice Postale.",
          restaurantEmail: "E-mail del ristorante.",
          restarauntWebsite: "Sito web del ristorante.",
          videoUrl: "URL video.",
          description: "Descrizione",
          openingClosingTime: "Seleziona l'orario di apertura/chiusura",
          information: "Informazione",
          images: "immagini",
          deactiveAccount: "Conto disattivato",
          firstName: "Nome di battesimo",
          middleName: "Secondo nome",
          lastName: "Cognome",
          cancelBooking: "Annulla prenotazione",
          updateBooking: "Aggiorna prenotazione",
          cancel: "Annulla",
          update: "Aggiornamento",
          accept: "Accettare",
          accepted: "Accettato: ",
          pending: "In attesa di: ",
          rejected: "Respinto: ",
          restaurantBookings: "Prenotazioni al ristorante",
          requests: "Richieste",
          mychats: "Le mie chat",
          contacts: "Contatti",
          block: "Bloccare",
          unBlock: "Sbloccare",
          remove: "Rimuovere",
          signin: "Registrazione",
          free: "gratuito",
          executiveChef: "Direttore esecutivo",
          image: "Vedi altro",
          unlike: "A differenza di",
          message: "Messaggio",
          browser: "Navigatore",
          guests: "ospiti",
          makeReservation: "Prenotare",
          mondayLowerCase: "Lunedi",
          tuesdayLowerCase: "Martedì",
          wednesdayLowerCase: "Mercoledì",
          thursdayLowerCase: "Giovedì",
          fridayLowerCase: "Venerdì",
          saturdayLowerCase: "Sabato",
          sundayLowerCase: "Domenica",
          phoneNumber: "Numero di telefono:",
          cuisine: "Tipo di cucina:",
          chefType: "Cuoco:",
          about: "Di",
          openClose: "Ore di operazione",
          open: "Aprire:",
          closed: "Chiuso",
          myPartners: "I miei partner",
          viewOnMap: "Visualizza sulla mappa",
          previousArticle: "Precedente",
          nextArticle: "Prossimo",
          views: "Visualizzazioni",
          likes: "Piace",
          date: "Data di pubblicazione",
          recoverPassword: "recupera la password",
          reset: "Ripristina",
          signUp: "iscrizione",
          paymentSucceeded: "Pagamento riuscito!",
          paymentProcessing: "Il tuo pagamento è in fase di elaborazione.",
          paymentRequiresMethod:
            "Il pagamento non è andato a buon fine, riprova.",
          paymentDefaultError: "Qualcosa è andato storto.",
          payNow: "Paga ora",
          paymentUnexpectedError: "Si è verificato un errore imprevisto.",
          chooseLanguage: "Scegli la lingua",
          archived: "Archivio",
          unArchived: "Annulla archiviazione",
          addToContacts: "Aggiungi ai contatti",
          mediaHistory: "Storia dei media",
          viewProfile: "Vedi profilo",
          blockUser: "Blocca utente",
          deleteChat: "Elimina chat",
          unlikeButton: "A differenza di",
          unlikeCancelButton: "Annulla",
          unlikeText: "A differenza di?",
          addContact: "Aggiungi contatto",
          contactAlready: "Contatti già su MyTravelDinner",
        },
      },
      "zh-CN": {
        translation: {
          businessName: "公司名称",
          businessType: "业务类型",
          sommelier: "侍酒师",
          wineMakingCompany: "酿酒公司",
          wineDistributor: "葡萄酒经销商",
          beerCompany: "啤酒公司",
          friends: "朋友们",
          blocked: "封锁",
          Customer: "游客",
          Restaurant: "餐厅",
          Business: "酒庄/侍酒师",
          Chef: "厨师",
          notFound: "找不到网页",
          restaurantSearch: "我的餐厅搜索",
          ourChefClub: "我们的厨师俱乐部",
          mynews: "我的消息",
          myChat: "我的聊天",
          howitworks: "怎么运行的",
          contactus: "联系我们",
          mybookings: "我的预订",
          myaccount: "我的账户",
          mycard: "我的卡",
          logout: "登出",
          login: "登录",
          account: "帐户",
          notamember: "非会员？",
          buttonSave: "节省",
          restaurant: "餐厅",
          chef: "厨师",
          billing: "计费",
          favourite: "最喜欢的",
          settings: "设置",
          address: "地址",
          city: "城市",
          postalCode: "邮政编码",
          stateRegion: "州/地区",
          mainImage: "主图",
          otherImages: "其他图片",
          view: "看法",
          coverImage: "封面图片",
          italian: "意大利语",
          spanish: "西班牙语",
          greek: "希腊语",
          french: "法语",
          portuguese: "葡萄牙语",
          israeli: "以色列",
          moroccan: "摩洛哥人",
          turkish: "土耳其",
          argentinian: "阿根廷人",
          mexico: "墨西哥",
          uruguayan: "乌拉圭人",
          mediterranean: "地中海",
          seafood: "海鲜",
          steakHouse: "牛排屋",
          localCuisine: "当地美食",
          monday: "周一",
          tuesday: "周二",
          wednesday: "周三",
          thursday: "周四",
          friday: "星期五",
          saturday: "周六",
          sunday: "星期日",
          solicitationState: "征集状态：",
          restaurantCuisine: "餐厅美食类型。",
          restaurantName: "餐厅名称。",
          addressName: "地址名称。",
          areaCode: "区号。",
          region: "地区。",
          restaurantPhoneNumber: "餐厅电话号码。",
          postalCodeDot: "邮政编码。",
          restaurantEmail: "餐厅邮箱。",
          restarauntWebsite: "餐厅网站。",
          videoUrl: "视频网址。",
          description: "描述",
          openingClosingTime: "选择开/关时间",
          information: "信息",
          images: "图片",
          deactiveAccount: "停用帐户",
          firstName: "名",
          middleName: "中间名字",
          lastName: "姓",
          cancelBooking: "取消预订",
          updateBooking: "更新预订",
          cancel: "取消",
          update: "更新",
          accept: "接受",
          accepted: "公认： ",
          pending: "待办的： ",
          rejected: "拒绝了： ",
          restaurantBookings: "餐厅预订",
          requests: "要求",
          mychats: "我的聊天记录",
          contacts: "联系人",
          block: "堵塞",
          unBlock: "解锁",
          remove: "消除",
          signin: "登入",
          free: "自由的",
          executiveChef: "行政总厨",
          image: "查看更多",
          unlike: "不像",
          message: "信息",
          browser: "浏览器",
          guests: "客人",
          makeReservation: "进行预订",
          mondayLowerCase: "周一",
          tuesdayLowerCase: "周二",
          wednesdayLowerCase: "周三",
          thursdayLowerCase: "周四",
          fridayLowerCase: "星期五",
          saturdayLowerCase: "周六",
          sundayLowerCase: "星期日",
          phoneNumber: "电话号码：",
          cuisine: "菜式：",
          chefType: "厨师：",
          about: "关于",
          openClose: "营业时间",
          open: "打开：",
          closed: "关闭",
          myPartners: "我的伙伴",
          viewOnMap: "在地图上查看",
          previousArticle: "以前的",
          nextArticle: "下一个",
          views: "观点",
          likes: "喜欢",
          date: "发布日期",
          recoverPassword: "恢复密码",
          reset: "重置",
          signUp: "报名",
          paymentSucceeded: "支付成功！",
          paymentProcessing: "您的付款正在处理中。",
          paymentRequiresMethod: "您的支付失败，请重试。",
          paymentDefaultError: "出了些问题。",
          payNow: "现在付款",
          paymentUnexpectedError: "一个意料之外的问题发生了。",
          chooseLanguage: "选择语言",
          archived: "档案",
          unArchived: "取消归档",
          addToContacts: "添加到通讯录",
          mediaHistory: "媒体历史",
          viewProfile: "查看资料",
          blockUser: "阻止用户",
          deleteChat: "删除聊天",
          unlikeButton: "不像",
          unlikeCancelButton: "取消",
          unlikeText: "不像？",
          addContact: "增加联系人",
          contactAlready: "已在 MyTravelDinner 上的联系人",
        },
      },
      ru: {
        translation: {
          businessName: "Наименование фирмы",
          businessType: "Вид бизнеса",
          sommelier: "Сомелье",
          wineMakingCompany: "Винодельческая Компания",
          wineDistributor: "Дистрибьютор вина",
          beerCompany: "Пивная компания",
          friends: "Друзья",
          blocked: "Заблокировано",
          Customer: "Путешественник",
          Restaurant: "Ресторан",
          Business: "Винодельня/Соммерлье",
          Chef: "шеф-повар",
          notFound: "Страница не найдена",
          restaurantSearch: "Поиск моего ресторана",
          ourChefClub: "Наш клуб поваров",
          mynews: "Мои новости",
          myChat: "Мой чат",
          howitworks: "Как это работает",
          contactus: "Связаться с нами",
          mybookings: "Мои заказы",
          myaccount: "Мой счет",
          mycard: "Моя карта",
          logout: "Выйти",
          login: "Авторизоваться",
          account: "Счет",
          notamember: "Не член? ",
          buttonSave: "Сохранять",
          restaurant: "Ресторан",
          chef: "шеф-повар",
          billing: "Выставление счетов",
          favourite: "Любимый",
          settings: "Настройки",
          address: "Адрес",
          city: "Город",
          postalCode: "Почтовый индекс",
          stateRegion: "Штат/регион",
          mainImage: "Основное изображение",
          otherImages: "Другие изображения",
          view: "Вид",
          coverImage: "Изображение обложки",
          italian: "итальянский",
          spanish: "испанский",
          greek: "греческий",
          french: "французский",
          portuguese: "португальский",
          israeli: "израильтянин",
          moroccan: "марокканский",
          turkish: "турецкий",
          argentinian: "аргентинец",
          mexico: "Мексика",
          uruguayan: "уругвайский",
          mediterranean: "средиземноморский",
          seafood: "морепродукты",
          steakHouse: "стейк-хаус",
          localCuisine: "местная кухня",
          monday: "Понедельник",
          tuesday: "Вторник",
          wednesday: "Среда",
          thursday: "Четверг",
          friday: "Пятница",
          saturday: "Суббота",
          sunday: "Воскресенье",
          solicitationState: "Состояние заявки:",
          restaurantCuisine: "Ресторанный тип кухни.",
          restaurantName: "Название ресторана.",
          addressName: "Адрес.",
          areaCode: "Код города.",
          region: "Область, край.",
          restaurantPhoneNumber: "Телефон ресторана.",
          postalCodeDot: "Почтовый индекс.",
          restaurantEmail: "Электронная почта ресторана.",
          restarauntWebsite: "Сайт ресторана.",
          videoUrl: "URL-адрес видео.",
          description: "Описание",
          openingClosingTime: "Выберите время открытия/закрытия",
          information: "Информация",
          images: "Изображений",
          deactiveAccount: "Деактивированный аккаунт",
          firstName: "Имя",
          middleName: "Второе имя",
          lastName: "Фамилия",
          cancelBooking: "Отменить бронирование",
          updateBooking: "Обновить бронирование",
          cancel: "Отмена",
          update: "Обновлять",
          accept: "Принимать",
          accepted: "Принял: ",
          pending: "В ожидании: ",
          rejected: "Отклоненный: ",
          restaurantBookings: "Заказ столиков в ресторанах",
          requests: "Запросы",
          mychats: "Мои чаты",
          contacts: "Контакты",
          block: "Блокировать",
          unBlock: "Разблокировать",
          remove: "Удалять",
          signin: "Войти",
          free: "бесплатно",
          executiveChef: "Шеф-повар",
          image: "Узнать больше",
          unlike: "В отличие от",
          message: "Сообщение",
          browser: "Браузер",
          guests: "гости",
          makeReservation: "Забронировать",
          mondayLowerCase: "Понедельник",
          tuesdayLowerCase: "Вторник",
          wednesdayLowerCase: "Среда",
          thursdayLowerCase: "Четверг",
          fridayLowerCase: "Пятница",
          saturdayLowerCase: "Суббота",
          sundayLowerCase: "Воскресенье",
          phoneNumber: "Номер телефона:",
          cuisine: "Тип кухни:",
          chefType: "Шеф-повар:",
          about: "О",
          openClose: "Часы работы",
          open: "Открыть:",
          closed: "Закрыто",
          myPartners: "Мои партнеры",
          viewOnMap: "Посмотреть на карте",
          previousArticle: "Предыдущий",
          nextArticle: "Следующий",
          views: "Взгляды",
          likes: "Нравится",
          date: "Дата публикации",
          recoverPassword: "восстановить пароль",
          reset: "перезагрузить",
          signUp: "зарегистрироваться",
          paymentSucceeded: "Оплата прошла успешно!",
          paymentProcessing: "Ваш платеж обрабатывается.",
          paymentRequiresMethod:
            "Ваш платеж не прошел успешно, попробуйте еще раз.",
          paymentDefaultError: "Что-то пошло не так.",
          payNow: "Заплатить сейчас",
          paymentUnexpectedError: "Произошла непредвиденная ошибка.",
          chooseLanguage: "Выберите язык",
          archived: "Архив",
          unArchived: "Разархивировать",
          addToContacts: "Добавить в контакты",
          mediaHistory: "История СМИ",
          viewProfile: "Просмотреть профиль",
          blockUser: "Блокировать пользователя",
          deleteChat: "Удалить чат",
          unlikeButton: "В отличие от",
          unlikeCancelButton: "Отмена",
          unlikeText: "В отличие от?",
          addContact: "Добавить контакт",
          contactAlready: "Контакты уже есть на MyTravelDinner",
        },
      },
      de: {
        translation: {
          businessName: "Firmenname",
          businessType: "Unternehmensart",
          sommelier: "Sommelier",
          wineMakingCompany: "Weinbauunternehmen",
          wineDistributor: "Weinhändler",
          beerCompany: "Bierunternehmen",
          friends: "Freunde",
          blocked: "verstopft",
          Customer: "Reisender",
          Restaurant: "Restaurant",
          Business: "Weingut/Sommerlier",
          Chef: "Koch",
          notFound: "Seite nicht gefunden",
          restaurantSearch: "Meine Restaurantsuche",
          ourChefClub: "Unser Chefs' Club",
          mynews: "Meine Neuigkeiten",
          myChat: "Mein Chat",
          howitworks: "Wie es funktioniert",
          contactus: "Kontaktiere uns",
          mybookings: "Meine Buchungen",
          myaccount: "Mein Konto",
          mycard: "Meine Karte",
          logout: "Ausloggen",
          login: "Anmeldung",
          account: "Konto",
          notamember: "Kein Mitglied? ",
          buttonSave: "Speichern",
          restaurant: "Restaurant",
          chef: "Koch",
          billing: "Abrechnung",
          favourite: "Favorit",
          settings: "Einstellungen",
          address: "Adresse",
          city: "Stadt",
          postalCode: "Postleitzahl",
          stateRegion: "Bundesstaat/Region",
          mainImage: "Hauptbild",
          otherImages: "Andere Bilder",
          view: "Sicht",
          coverImage: "Titelbild",
          italian: "Italienisch",
          spanish: "Spanisch",
          greek: "griechisch",
          french: "französisch",
          portuguese: "Portugiesisch",
          israeli: "israelisch",
          moroccan: "marokkanisch",
          turkish: "Türkisch",
          argentinian: "argentinisch",
          mexico: "Mexiko",
          uruguayan: "uruguayisch",
          mediterranean: "Mittelmeer",
          seafood: "Meeresfrüchte",
          steakHouse: "Steak-House",
          localCuisine: "lokale Küche",
          monday: "Montag",
          tuesday: "Dienstag",
          wednesday: "Mittwoch",
          thursday: "Donnerstag",
          friday: "Freitag",
          saturday: "Samstag",
          sunday: "Sonntag",
          solicitationState: "Ausschreibungsstatus:",
          restaurantCuisine: "Art der Restaurantküche.",
          restaurantName: "Restaurantname.",
          addressName: "Adressname.",
          areaCode: "Vorwahl.",
          region: "Region.",
          restaurantPhoneNumber: "Telefonnummer des Restaurants.",
          postalCodeDot: "Postleitzahl.",
          restaurantEmail: "Restaurant-E-Mail.",
          restarauntWebsite: "Restaurant-Website.",
          videoUrl: "Video-URL.",
          description: "Beschreibung",
          openingClosingTime: "Wählen Sie die Öffnungs-/Schließzeit aus",
          information: "Information",
          images: "Bilder",
          deactiveAccount: "Konto deaktivieren",
          firstName: "Vorname",
          middleName: "Zweiter Vorname",
          lastName: "Familienname, Nachname",
          cancelBooking: "Buchung stornieren",
          updateBooking: "Buchung aktualisieren",
          cancel: "Stornieren",
          update: "Aktualisieren",
          accept: "Akzeptieren",
          accepted: "Akzeptiert: ",
          pending: "Ausstehend: ",
          rejected: "Abgelehnt: ",
          restaurantBookings: "Restaurantbuchungen",
          requests: "Anfragen",
          mychats: "Meine Chats",
          contacts: "Kontakte",
          block: "Block",
          unBlock: "Entsperren",
          remove: "Entfernen",
          signin: "Anmelden",
          free: "frei",
          executiveChef: "Geschäftsführer",
          image: "Mehr sehen",
          unlike: "nicht wie",
          message: "Nachricht",
          browser: "Browser",
          guests: "Gäste",
          makeReservation: "Reservieren",
          mondayLowerCase: "Montag",
          tuesdayLowerCase: "Dienstag",
          wednesdayLowerCase: "Mittwoch",
          thursdayLowerCase: "Donnerstag",
          fridayLowerCase: "Freitag",
          saturdayLowerCase: "Samstag",
          sundayLowerCase: "Sonntag",
          phoneNumber: "Telefonnummer:",
          cuisine: "Küchentyp:",
          chefType: "Koch:",
          about: "Um",
          openClose: "Öffnungszeiten",
          open: "Offen:",
          closed: "Geschlossen",
          myPartners: "Meine Partner",
          viewOnMap: "Auf der Karte anzeigen",
          previousArticle: "Vorherige",
          nextArticle: "Nächste",
          views: "Ansichten",
          likes: "Likes",
          date: "Datum der Veröffentlichung",
          recoverPassword: "Passwort wiederherstellen",
          reset: "zurücksetzen",
          signUp: "Anmeldung",
          paymentSucceeded: "Zahlung erfolgreich abgeschlossen!",
          paymentProcessing: "Ihre Zahlung wird bearbeitet.",
          paymentRequiresMethod:
            "Ihre Zahlung war nicht erfolgreich. Bitte versuchen Sie es erneut.",
          paymentDefaultError: "Etwas ist schief gelaufen.",
          payNow: "Zahlen Sie jetzt",
          paymentUnexpectedError: "Ein unerwarteter Fehler ist aufgetreten.",
          chooseLanguage: "Sprache wählen",
          archived: "Archiv",
          unArchived: "Dearchivieren",
          addToContacts: "Zu den Kontakten hinzufügen",
          mediaHistory: "Mediengeschichte",
          viewProfile: "Profil anzeigen",
          blockUser: "Benutzer blockieren",
          deleteChat: "Chat löschen",
          unlikeButton: "nicht wie",
          unlikeCancelButton: "Stornieren",
          unlikeText: "Nicht wie?",
          addContact: "Kontakt hinzufügen",
          contactAlready: "Kontakte bereits auf MyTravelDinner",
        },
      },
      pt: {
        translation: {
          businessName: "Nome da empresa",
          businessType: "Tipo de Negócio",
          sommelier: "Sommelier",
          wineMakingCompany: "Empresa de Vinificação",
          wineDistributor: "Distribuidor de vinhos",
          beerCompany: "Cervejaria",
          friends: "Amigos",
          blocked: "Bloqueado",
          Customer: "Viajante",
          Restaurant: "Restaurante",
          Business: "Vinícola/Sommerlier",
          Chef: "Chefe de cozinha",
          notFound: "Página não encontrada",
          restaurantSearch: "Minha pesquisa de restaurante",
          ourChefClub: "O nosso Clube de Chefs",
          mynews: "Minhas notícias",
          myChat: "meu chat",
          howitworks: "Como funciona",
          contactus: "Contate-nos",
          mybookings: "Minhas reservas",
          myaccount: "Minha conta",
          mycard: "Meu cartão",
          logout: "Sair",
          login: "Conecte-se",
          account: "Conta",
          notamember: "Não é um membro? ",
          buttonSave: "Salvar",
          restaurant: "Restaurante",
          chef: "Chefe de cozinha",
          billing: "Cobrança",
          favourite: "Favorito",
          settings: "Configurações",
          address: "Endereço",
          city: "Cidade",
          postalCode: "Código postal",
          stateRegion: "Estado/Região",
          mainImage: "Imagem Principal",
          otherImages: "Outras imagens",
          view: "Visualizar",
          coverImage: "Imagem de capa",
          italian: "italiano",
          spanish: "espanhol",
          greek: "grego",
          french: "francês",
          portuguese: "português",
          israeli: "israelense",
          moroccan: "marroquino",
          turkish: "turco",
          argentinian: "argentino",
          mexico: "México",
          uruguayan: "uruguaio",
          mediterranean: "Mediterrâneo",
          seafood: "frutos do mar",
          steakHouse: "Churrascaria",
          localCuisine: "cozinha local",
          monday: "Segunda-feira",
          tuesday: "Terça-feira",
          wednesday: "Quarta-feira",
          thursday: "Quinta-feira",
          friday: "Sexta-feira",
          saturday: "Sábado",
          sunday: "Domingo",
          solicitationState: "Estado da solicitação:",
          restaurantCuisine: "Tipo de cozinha do restaurante.",
          restaurantName: "Nome do restaurante.",
          addressName: "Nome do endereço.",
          areaCode: "Código de área.",
          region: "Região.",
          restaurantPhoneNumber: "Número de telefone do restaurante.",
          postalCodeDot: "Código postal.",
          restaurantEmail: "E-mail do restaurante.",
          restarauntWebsite: "Site do restaurante.",
          videoUrl: "URL do vídeo.",
          description: "Descrição",
          openingClosingTime: "Selecione o horário de abertura/fechamento",
          information: "Informação",
          images: "Imagens",
          deactiveAccount: "conta desativada",
          firstName: "Primeiro nome",
          middleName: "Nome do meio",
          lastName: "Sobrenome",
          cancelBooking: "Cancelar reserva",
          updateBooking: "Atualizar reserva",
          cancel: "Cancelar",
          update: "Atualizar",
          accept: "Aceitar",
          accepted: "Aceitaram: ",
          pending: "Pendente: ",
          rejected: "Rejeitado: ",
          restaurantBookings: "Reservas em restaurantes",
          requests: "solicitações de",
          mychats: "Meus chats",
          contacts: "Contatos",
          block: "Bloquear",
          unBlock: "Desbloquear",
          remove: "Remover",
          signin: "Entrar",
          free: "livre",
          executiveChef: "Chefe executivo",
          image: "Ver mais",
          unlike: "Diferente",
          message: "Mensagem",
          browser: "Navegador",
          guests: "convidados",
          makeReservation: "Fazer reserva",
          mondayLowerCase: "Segunda-feira",
          tuesdayLowerCase: "Terça-feira",
          wednesdayLowerCase: "Quarta-feira",
          thursdayLowerCase: "Quinta-feira",
          fridayLowerCase: "Sexta-feira",
          saturdayLowerCase: "Sábado",
          sundayLowerCase: "Domingo",
          phoneNumber: "Número de telefone:",
          cuisine: "Tipo de cozinha:",
          chefType: "Chefe de cozinha:",
          about: "Sobre",
          openClose: "Horarios de funcionamento",
          open: "Abrir:",
          closed: "Fechado",
          myPartners: "Meus parceiros",
          viewOnMap: "Ver no mapa",
          previousArticle: "Anterior",
          nextArticle: "Próximo",
          views: "Visualizações",
          likes: "curtidas",
          date: "Data de publicação",
          recoverPassword: "recuperar senha",
          reset: "reiniciar",
          signUp: "inscrever-se",
          paymentSucceeded: "Pagamento efetuado com sucesso!",
          paymentProcessing: "Seu pagamento está sendo processado.",
          paymentRequiresMethod:
            "Seu pagamento não foi bem-sucedido, tente novamente.",
          paymentDefaultError: "Algo deu errado.",
          payNow: "Pague agora",
          paymentUnexpectedError: "Um erro inesperado ocorreu.",
          chooseLanguage: "Escolha o seu idioma",
          archived: "Arquivo",
          unArchived: "Desarquivar",
          addToContacts: "Adicionar aos contatos",
          mediaHistory: "história da mídia",
          viewProfile: "Ver perfil",
          blockUser: "Bloquear usuário",
          deleteChat: "Excluir bate-papo",
          unlikeButton: "Diferente",
          unlikeCancelButton: "Cancelar",
          unlikeText: "Diferente?",
          addContact: "Adicionar contato",
          contactAlready: "Contatos já no MyTravelDinner",
        },
      },
      fr: {
        translation: {
          businessName: "Nom de l'entreprise",
          businessType: "Type d'entreprise",
          sommelier: "Sommelier",
          wineMakingCompany: "Entreprise de vinification",
          wineDistributor: "Distributeur de vin",
          beerCompany: "Entreprise de bière",
          friends: "Amis",
          blocked: "Bloqué",
          Customer: "Voyageur",
          Restaurant: "Restaurant",
          Business: "Cave/Sommelier",
          Chef: "Chef",
          notFound: "Page non trouvée",
          restaurantSearch: "Ma recherche de restaurant",
          ourChefClub: "Notre Club des Chefs",
          mynews: "Mes nouvelles",
          myChat: "Ma conversation",
          howitworks: "Comment ça fonctionne",
          contactus: "Contactez-nous",
          mybookings: "Mes réservations",
          myaccount: "Mon compte",
          mycard: "Ma carte",
          logout: "Se déconnecter",
          login: "Connexion",
          account: "Compte",
          notamember: "Pas un membre? ",
          buttonSave: "Sauvegarder",
          restaurant: "Restaurant",
          chef: "Chef",
          billing: "Facturation",
          favourite: "Préféré",
          settings: "Paramètres",
          address: "Adresse",
          city: "Ville",
          postalCode: "Code Postal",
          stateRegion: "État/Région",
          mainImage: "Image principale",
          otherImages: "Autres photos",
          view: "Voir",
          coverImage: "Image de couverture",
          italian: "italien",
          spanish: "Espagnol",
          greek: "grec",
          french: "Français",
          portuguese: "Portugais",
          israeli: "israélien",
          moroccan: "marocain",
          turkish: "turc",
          argentinian: "argentin",
          mexico: "Mexique",
          uruguayan: "uruguayen",
          mediterranean: "méditerranéen",
          seafood: "fruit de mer",
          steakHouse: "steak House",
          localCuisine: "cuisine locale",
          monday: "Lundi",
          tuesday: "Mardi",
          wednesday: "Mercredi",
          thursday: "Jeudi",
          friday: "Vendredi",
          saturday: "Samedi",
          sunday: "Dimanche",
          solicitationState: "État de la sollicitation :",
          restaurantCuisine: "Type de restauration.",
          restaurantName: "Nom du restaurant.",
          addressName: "Nom de l'adresse.",
          areaCode: "Indicatif régional.",
          region: "Région.",
          restaurantPhoneNumber: "Numéro de téléphone du restaurant.",
          postalCodeDot: "Code Postal.",
          restaurantEmail: "E-mail du restaurant.",
          restarauntWebsite: "Site Internet des restaurants.",
          videoUrl: "URL de la vidéo.",
          description: "Description",
          openingClosingTime: "Sélectionnez l'heure d'ouverture/fermeture",
          information: "Information",
          images: "Images",
          deactiveAccount: "Désactiver le compte",
          firstName: "Prénom",
          middleName: "Deuxième nom",
          lastName: "Nom de famille",
          cancelBooking: "Annuler la réservation",
          updateBooking: "Mettre à jour la réservation",
          cancel: "Annuler",
          update: "Mise à jour",
          accept: "Accepter",
          accepted: "Accepté: ",
          pending: "En attente: ",
          rejected: "Rejeté: ",
          restaurantBookings: "Réservations de restaurants",
          requests: "Demandes",
          mychats: "Mes chats",
          contacts: "Contacts",
          block: "Bloc",
          unBlock: "Débloquer",
          remove: "Retirer",
          signin: "S'identifier",
          free: "gratuit",
          executiveChef: "Chef executif",
          image: "Voir plus",
          unlike: "Contrairement à",
          message: "Message",
          browser: "Navigateur",
          guests: "invités",
          makeReservation: "Faire une réservation",
          mondayLowerCase: "Lundi",
          tuesdayLowerCase: "Mardi",
          wednesdayLowerCase: "Mercredi",
          thursdayLowerCase: "Jeudi",
          fridayLowerCase: "Vendredi",
          saturdayLowerCase: "Samedi",
          sundayLowerCase: "Dimanche",
          phoneNumber: "Numéro de téléphone:",
          cuisine: "Type de cuisine :",
          chefType: "Chef:",
          about: "À propos",
          openClose: "Heures d'ouverture",
          open: "Ouvrir:",
          closed: "Fermé",
          myPartners: "Mes partenaires",
          viewOnMap: "Voir sur la carte",
          previousArticle: "Précédent",
          nextArticle: "Suivant",
          views: "Vues",
          likes: "Aime",
          date: "Date de publication",
          recoverPassword: "récupérer mot de passe",
          reset: "réinitialiser",
          signUp: "s'inscrire",
          paymentSucceeded: "Paiement réussi !",
          paymentProcessing: "Votre paiement est en cours de traitement.",
          paymentRequiresMethod:
            "Votre paiement n'a pas abouti, veuillez réessayer.",
          paymentDefaultError: "Quelque chose s'est mal passé.",
          payNow: "Payez maintenant",
          paymentUnexpectedError: "Une erreur inattendue est apparue.",
          chooseLanguage: "Choisissez la langue",
          archived: "Archive",
          unArchived: "Désarchiver",
          addToContacts: "Ajouter aux contacts",
          mediaHistory: "Histoire des médias",
          viewProfile: "Voir le profil",
          blockUser: "Bloquer un utilisateur",
          deleteChat: "Supprimer le chat",
          unlikeButton: "Contrairement à",
          unlikeCancelButton: "Annuler",
          unlikeText: "Contrairement à?",
          addContact: "Ajouter le contact",
          contactAlready: "Contacts déjà sur MyTravelDinner",
        },
      },
    },
  });

export default i18n;
