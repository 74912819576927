import React, { useEffect } from "react";
import { Button, FormControl, FormHelperText, TextField } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import CountrySelect from "app/Navbar/CountrySelect";
import { useTranslation } from "react-i18next";
import { Controller, useForm } from "react-hook-form";

export const Billing = ({ formData, setFormData, updateUser }) => {
  const { t } = useTranslation();
  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
  } = useForm({
    billingAddress: "",
    billingAddressCity: "",
    billingAddressRegion: "",
    billingAddressCountry: "",
    billingAddressPostalCode: "",
  });

  const onSubmit = (data) => {
    updateUser({ data: formData });
  };
  const copyFrom = () => {
    setValue("billingAddress", formData?.address?.address ?? "");
    setValue("billingAddressCity", formData?.address?.city ?? "");
    setValue("billingAddressRegion", formData?.address?.region ?? "");
    setValue("billingAddressCountry", formData?.address?.country ?? "");
    setValue("billingAddressPostalCode", formData?.address?.postalCode ?? "");
    setFormData({
      ...formData,
      billingAddress: {
        address: formData?.address?.address ?? "",
        city: formData?.address?.city ?? "",
        region: formData?.address?.region ?? "",
        country: formData?.address?.country ?? "",
        postalCode: formData?.address?.postalCode ?? "",
      },
    });
  };

  useEffect(() => {
    setValue("billingAddress", formData?.billingAddress?.address ?? "");
    setValue("billingAddressCity", formData?.billingAddress?.city ?? "");
    setValue("billingAddressRegion", formData?.billingAddress?.region ?? "");
    setValue("billingAddressCountry", formData?.billingAddress?.country ?? "");
    setValue(
      "billingAddressPostalCode",
      formData?.billingAddress?.postalCode ?? ""
    );
  }, [formData]);

  return (
    <Grid
      sx={{ width: "100%", pt: { xs: 4 } }}
      container
      direction="column"
      alignItems="center"
      component="form"
      onSubmit={handleSubmit(onSubmit)}
      spacing={{ xs: 0, sm: 4 }}
    >
      <Grid container xs={12} sx={{ width: "100%", ml: { xs: "0.2%" } }}>
        <Grid xs={12}>
          <FormControl sx={{ width: "87.5%" }}>
            <FormHelperText id="billing-address-name-input">
              {t("address")}
            </FormHelperText>
            <Controller
              name="billingAddress"
              control={control}
              rules={{ required: true }}
              render={({ field: { ref, ...field } }) => (
                <TextField
                  id="billing-address-name-input"
                  aria-describedby="billing-address-name-input-helper-text"
                  value={formData?.billingAddress?.address ?? ""}
                  onChange={(e) => {
                    field.onChange(e);
                    setFormData({
                      ...formData,
                      billingAddress: {
                        ...formData?.billingAddress,
                        address: e.target.value,
                      },
                    });
                  }}
                  error={!!errors.billingAddress}
                  sx={{ backgroundColor: "white" }}
                />
              )}
            />
          </FormControl>
        </Grid>
        <Grid xs={6}>
          <FormControl sx={{ width: "75%" }}>
            <FormHelperText id="billing-city-name-input">
              {t("city")}
            </FormHelperText>
            <Controller
              name="billingAddressCity"
              control={control}
              rules={{ required: true }}
              render={({ field: { ref, ...field } }) => (
                <TextField
                  id="billing-city-name-input"
                  aria-describedby="billing-city-input-helper-text"
                  value={formData?.billingAddress?.city ?? ""}
                  onChange={(e) => {
                    field.onChange(e);
                    setFormData({
                      ...formData,
                      billingAddress: {
                        ...formData?.billingAddress,
                        city: e.target.value,
                      },
                    });
                  }}
                  sx={{ backgroundColor: "white" }}
                  error={!!errors.billingAddressCity}
                />
              )}
            />
          </FormControl>
        </Grid>
        <Grid xs={6}>
          <FormControl sx={{ width: "75%" }}>
            <FormHelperText id="postal-code-name-input">
              {t("postalCodeDot")}
            </FormHelperText>
            <Controller
              name="billingAddressPostalCode"
              control={control}
              rules={{ required: true }}
              render={({ field: { ref, ...field } }) => (
                <TextField
                  id="postal-code-name-input"
                  aria-describedby="postal-code-name-input-helper-text"
                  value={formData?.billingAddress?.postalCode ?? ""}
                  onChange={(e) => {
                    field.onChange(e);
                    setFormData({
                      ...formData,
                      billingAddress: {
                        ...formData?.billingAddress,
                        postalCode: e.target.value,
                      },
                    });
                  }}
                  error={!!errors.billingAddressPostalCode}
                  sx={{ backgroundColor: "white" }}
                />
              )}
            />
          </FormControl>
        </Grid>
        <Grid xs={6}>
          <FormControl sx={{ width: "75%", mt: 3 }}>
            <FormHelperText id="area-code-input-help">
              {t("countryCode")}*
            </FormHelperText>
            <Controller
              name="billingAddressCountry"
              control={control}
              rules={{ required: true }}
              render={({ field: { ref, ...field } }) => (
                <CountrySelect
                  phone={false}
                  required={true}
                  onChange={field.onChange}
                  error={!!errors.billingAddressCountry}
                  id="billing-country-name-input"
                  aria-describedby="billing-country-name-input-helper-text"
                  value={formData?.billingAddress?.country ?? ""}
                  setValue={(value) =>
                    setFormData({
                      ...formData,
                      billingAddress: {
                        ...formData?.billingAddress,
                        country: value,
                      },
                    })
                  }
                  sx={{ backgroundColor: "white" }}
                />
              )}
            />
          </FormControl>
        </Grid>
        <Grid xs={6}>
          <FormControl sx={{ width: "75%" }}>
            <FormHelperText id="billing-state-name-input">
              {t("stateRegion")}
            </FormHelperText>
            <Controller
              name="billingAddressRegion"
              control={control}
              rules={{ required: true }}
              render={({ field: { ref, ...field } }) => (
                <TextField
                  id="billing-state-name-input"
                  aria-describedby="billing-state-name-input-helper-text"
                  value={formData?.billingAddress?.region ?? ""}
                  onChange={(e) => {
                    field.onChange(e);
                    setFormData({
                      ...formData,
                      billingAddress: {
                        ...formData?.billingAddress,
                        region: e.target.value,
                      },
                    });
                  }}
                  error={!!errors.billingAddressRegion}
                  sx={{ backgroundColor: "white" }}
                />
              )}
            />
          </FormControl>
        </Grid>
      </Grid>
      <Grid xs={12} mt={2}>
        <Button variant="contained" size="large" onClick={() => copyFrom()}>
          {t("copyFrom")}
        </Button>
      </Grid>
      <Grid xs={12} mt={2}>
        <Button
          variant="contained"
          size="large"
          type="submit"
          sx={{ backgroundColor: "#FD0000" }}
        >
          {t("save")}
        </Button>
      </Grid>
    </Grid>
  );
};
