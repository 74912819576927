import { Button, ThemeProvider } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useAuthLoginMutation } from "services/authApi";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { useSnackbar } from "notistack";
import { setToken, setUser } from "services/authSlice";
import map from "../../assets/global-map.svg";
import logo from "../../assets/logo-login.svg";
import { LoginTheme } from "services/themes";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Grid from "@mui/material/Unstable_Grid2";
import { useSignIn } from "react-auth-kit";
function Copyright(props) {
  return (
    <Typography variant="body2" color="white" align="center" {...props}>
      {"Copyright © "}
      <Link color="inherit" href="https://www.mytraveldinner.com/">
        MyTravelDinner
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

export const Login = () => {
  const signIn = useSignIn();
  const { enqueueSnackbar } = useSnackbar();
  const [login, results] = useAuthLoginMutation();
  const [loginForm, setLoginForm] = useState({ email: "", password: "" });
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleLogin = (e) => {
    e.preventDefault();
    login({
      data: {
        email: loginForm.email,
        password: loginForm.password,
      },
    });
  };

  useEffect(() => {
    if (results.isSuccess) {
      dispatch(setToken(results.data?.token));
      dispatch(setUser(results.data?.user));
      enqueueSnackbar(`Successfully logged in`, {
        variant: "success",
        autoHideDuration: 2000,
      });
      signIn({
        token: results.data?.token,
        expiresIn: results.data.expiresIn,
        tokenType: "Bearer",
        authState: results.data.user,
      });
      navigate("/");
    } else if (results.isError) {
      setToken(null);
      enqueueSnackbar(`${results?.error?.data?.error?.message}`, {
        variant: "error",
        autoHideDuration: 2000,
      });
    }
    //eslint-disable-next-line
  }, [results]);

  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
      sx={{
        height: "100vh",
        width: "100vw",
        backgroundColor: "#0171bb",
        backgroundImage: "url(" + map + ")",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
      }}
    >
      <Grid
        xs
        sx={{
          backgroundColor: "rgba(0,0,0,0.45)",
          borderRadius: 5,
          opacity: "0.9",
          zIndex: 1,
          maxWidth: { xs: 300, md: "30vw" },
          maxHeight: { xs: 600, md: "80vh" },
        }}
      >
        <Container component="main" maxWidth="xs" sx={{ opacity: 1 }}>
          <CssBaseline />
          <ThemeProvider theme={LoginTheme}>
            <Grid
              container
              direction="column"
              justifyContent="space-around"
              alignItems="center"
            >
              <Grid xs sx={{ maxWidth: { xs: 200, sm: 250 }, marginTop: 5 }}>
                <img src={logo} alt="Login logo" style={{ width: "80%" }} />
              </Grid>
              <Grid xs>
                <Typography
                  component="h1"
                  variant="h4"
                  color={"white"}
                  sx={{ fontWeight: 600, textTransform: "capitalize" }}
                >
                  {t("login")}
                </Typography>
                <Box component="form" sx={{ mt: 1 }}>
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="email"
                    label="Email Address"
                    name="email"
                    autoComplete="email"
                    color="primary"
                    onChange={(e) =>
                      setLoginForm({ ...loginForm, email: e.target.value })
                    }
                    value={loginForm.email}
                    variant="filled"
                    sx={{ borderRadius: 2, backgroundColor: "#fcfcfb" }}
                  />
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    name="password"
                    label="Password"
                    type="password"
                    id="password"
                    autoComplete="current-password"
                    variant="filled"
                    color="primary"
                    onChange={(e) =>
                      setLoginForm({ ...loginForm, password: e.target.value })
                    }
                    value={loginForm.password}
                    sx={{ borderRadius: 2, backgroundColor: "#fcfcfb" }}
                  />

                  <Button
                    fullWidth
                    variant="contained"
                    sx={{ mt: 3, mb: 2 }}
                    onClick={(e) => handleLogin(e)}
                    type="submit"
                  >
                    {t("signin")}
                  </Button>
                  <Grid container justifyContent="center">
                    <Grid item xs={12}>
                      <Typography color="white">
                        Don t remember de password?{" "}
                        <Button onClick={() => navigate("/auth/recover")}>
                          Recover it
                        </Button>
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography color="white">
                        Not a member?{" "}
                        <Button onClick={() => navigate("/auth/signup")}>
                          Register
                        </Button>
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
                <Copyright />
              </Grid>
            </Grid>
          </ThemeProvider>
        </Container>
      </Grid>
    </Grid>
  );
};
