import * as React from "react";
import TableBarIcon from "@mui/icons-material/TableBar";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { Button } from "@mui/material";
import { useTranslation } from "react-i18next";

const freeStyle = {
  backgroundColor: "success.light",
  color: "white",
};
const primaryStyle = {
  backgroundColor: "primary",
  color: "white",
};

export default function TablePicker(props) {
  const { value, setValue, makeBooking } = props;
  const { t } = useTranslation();
  const tables = [
    { number: 1, state: t("free") },
    { number: 2, state: t("free") },
    { number: 3, state: t("free") },
    { number: 4, state: t("free") },
    { number: 5, state: t("free") },
    { number: 6, state: t("free") },
    { number: 7, state: t("free") },
    { number: 8, state: t("free") },
  ];

  const handleTableState = (tableState) => {
    switch (tableState) {
      case t("free"):
        return freeStyle;
      default:
        return primaryStyle;
    }
  };

  React.useEffect(() => {
    console.log(value);
  }, [value]);

  return (
    <Grid
      container
      justifyContent="space-around"
      alignItems="center"
      direction="row"
      mt={3}
      spacing={2}
    >
      {tables.map((table, index) => {
        return (
          <Grid xs={6} key={index}>
            <Grid container justifyContent="center" alignItems="center">
              <Button
                variant="contained"
                startIcon={<TableBarIcon />}
                sx={handleTableState(table.state)}
                key={table.number}
                onClick={() => {
                  setValue(table.number);
                }}
              >
                {table.number} Guests
              </Button>
            </Grid>
          </Grid>
        );
      })}
      <Button
        sx={{ mt: 2 }}
        variant="contained"
        disabled={value === null}
        onClick={() => {
          makeBooking();
        }}
      >
        {t("makeReservation")}
      </Button>
    </Grid>
  );
}
