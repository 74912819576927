import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { API_URL } from "./env";
import { getValue } from "./storage";

export const bookingsApi = createApi({
  reducerPath: "bookingsApi",
  keepUnusedDataFor: 0,
  baseQuery: fetchBaseQuery({
    baseUrl: API_URL,
    prepareHeaders: (headers) => {
      const token = getValue("_auth");
      headers.set("Authorization", `Bearer ${token}`);
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getAllBookings: builder.query({
      query: () => {
        return { url: `bookings` };
      },
      transformResponse: (response) => {
        return response?.booking?.sort((a, b) => a?.id - b?.id);
      },
    }),
    getBooking: builder.query({
      query: (id) => {
        return { url: `bookings/${id}` };
      },
    }),
    getRestaurantBookings: builder.mutation({
      query: (id) => {
        return { url: `bookings/restaurant/${id}` };
      },
      transformResponse: (response) => {
        return response?.bookings?.sort((a, b) => a?.id - b?.id);
      },
    }),
    getRestaurantBookingsQuery: builder.query({
      query: (id) => {
        return { url: `bookings/restaurant/${id}` };
      },
      transformResponse: (response) => {
        return response?.bookings?.sort((a, b) => b?.id - a?.id);
      },
    }),
    createBooking: builder.mutation({
      query: ({ id, booking }) => ({
        url: `bookings/restaurant/${id}`,
        method: "POST",
        body: { booking: booking },
      }),
    }),
    updateBooking: builder.mutation({
      query: ({ id, booking }) => ({
        url: `bookings/${id}`,
        method: "PUT",
        body: { booking: booking },
      }),
    }),
  }),
});

export const {
  useGetAllBookingsQuery,
  useCreateBookingMutation,
  useGetBookingQuery,
  useGetRestaurantBookingsMutation,
  useUpdateBookingMutation,
  useGetRestaurantBookingsQueryQuery,
} = bookingsApi;
