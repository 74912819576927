import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import AspectRatio from "@mui/joy/AspectRatio";
import ReactPlayer from "react-player";
import "./index.css";
import { ArrowBackIos, ArrowForwardIos } from "@mui/icons-material";
import { IconButton } from "@mui/material";

export const YoutubeSlide = ({ url, isSelected }) => (
  <ReactPlayer width="100%" height="100%" url={url} playing={isSelected} />
);

export function PostCarousel(props) {
  const { data, video } = props;
  const arrowStyles = {
    position: "absolute",
    zIndex: 2,
    top: "calc(50% - 15px)",
    width: 30,
    height: 30,
    cursor: "pointer",
  };
  return (
    <>
      {video && (
        <Carousel
          showThumbs={false}
          showStatus={false}
          renderArrowPrev={(onClickHandler, hasPrev, label) =>
            hasPrev && (
              <IconButton
                onClick={onClickHandler}
                title={label}
                sx={{
                  ...arrowStyles,
                  left: 15,
                  color: "red !important",
                  padding: "30px",
                  backgroundColor: 'rgba(255,255,255,0.3) !important'
                }}
                elevation={4}
              >
                <ArrowBackIos />
              </IconButton>
            )
          }
          renderArrowNext={(onClickHandler, hasNext, label) =>
            hasNext && (
              <IconButton
                onClick={onClickHandler}
                title={label}
                sx={{
                  ...arrowStyles,
                  right: 15,
                  color: "red !important",
                  padding: "30px",
                  backgroundColor: 'rgba(255,255,255,0.3) !important'
                }}
                elevation={4}
              >
                <ArrowForwardIos />
              </IconButton>
            )
          }
        >
          {data?.map((image, idx) => {
            return (
              <AspectRatio minHeight={120} maxHeight={"50vh"} key={idx}>
                <img src={image?.image?.url} alt={image?.image?.name} />
              </AspectRatio>
            );
          })}
          <YoutubeSlide url={video} />
        </Carousel>
      )}
      {video === null && (
        <Carousel
          showThumbs={false}
          showStatus={false}
          renderArrowPrev={(onClickHandler, hasPrev, label) =>
            hasPrev && (
              <IconButton
                onClick={onClickHandler}
                title={label}
                sx={{
                  ...arrowStyles,
                  left: 15,
                  color: "red !important",
                  padding: "30px",
                  backgroundColor: 'rgba(255,255,255,0.3) !important'
                }}
                elevation={4}
              >
                <ArrowBackIos />
              </IconButton>
            )
          }
          renderArrowNext={(onClickHandler, hasNext, label) =>
            hasNext && (
              <IconButton
                onClick={onClickHandler}
                title={label}
                sx={{
                  ...arrowStyles,
                  right: 15,
                  color: "red !important",
                  padding: "30px",
                  backgroundColor: 'rgba(255,255,255,0.3) !important'
                }}
                elevation={4}
              >
                <ArrowForwardIos />
              </IconButton>
            )
          }
        >
          {data?.map((image, idx) => {
            return (
              <AspectRatio minHeight={120} maxHeight={"50vh"} key={idx}>
                <img src={image?.image?.url} alt={image?.image?.name} />
              </AspectRatio>
            );
          })}
        </Carousel>
      )}
    </>
  );
}
