import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { API_URL } from "./env";
import { getValue } from "./storage";
export const uploadApi = createApi({
  reducerPath: "uploadApi",
  keepUnusedDataFor: 0,
  baseQuery: fetchBaseQuery({
    baseUrl: API_URL,
    prepareHeaders: (headers) => {
      const token = getValue("_auth");
      headers.set("Authorization", `Bearer ${token}`);
      return headers;
    },
  }),
  endpoints: (builder) => ({
    uploadAvatarUser: builder.mutation({
      query: ({ data, id }) => ({
        url: `users/avatar`,
        method: "POST",
        body: data,
      }),
    }),
    uploadRestaurantPhotos: builder.mutation({
      query: ({ data, id }) => ({
        url: `restaurants/${id}/images`,
        method: "POST",
        body: data,
      }),
    }),
    uploadChefImages: builder.mutation({
      query: ({ data }) => ({
        url: `users/images`,
        method: "POST",
        body: data,
      }),
    }),
    deleteChefImage: builder.mutation({
      query: ({ id }) => ({
        url: `users/images/${id}`,
        method: "DELETE",
      }),
    }),
    uploadChatFile: builder.mutation({
      query: ({ data }) => ({
        url: `contacts/files`,
        method: "POST",
        body: data,
      }),
    }),
  }),
});

export const {
  useUploadAvatarUserMutation,
  useUploadRestaurantPhotosMutation,
  useUploadChefImagesMutation,
  useDeleteChefImageMutation,
  useUploadChatFileMutation,
} = uploadApi;
