import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { API_URL } from "./env";
import { getValue } from "./storage";
export const countriesApi = createApi({
  reducerPath: "countriesApi",
  baseQuery: fetchBaseQuery({
    baseUrl: API_URL,
    prepareHeaders: (headers) => {
      const token = getValue("_auth");
      headers.set("Authorization", `Bearer ${token}`);
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getAllCountries: builder.query({
      query: () => {
        return { url: `countries` };
      },
    }),
    getCountry: builder.query({
      query: (id) => {
        return { url: `countries/${id}` };
      },
    }),
  }),
});

export const { useGetAllCountriesQuery, useGetCountryQuery } = countriesApi;
