import React, { useEffect, useState } from "react";
import {
  Box,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Typography,
} from "@mui/material";

import { useResolvedPath, Link } from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";
import { useSelector } from "react-redux";
import { selectUserToken } from "services/authSlice";
import { useGetSelfMMutation } from "services/usersApi";
import { useTranslation } from "react-i18next";
import { LanguageIcon } from "./LanguageIcon";

const publicRouteStyle = {
  backgroundColor: "#fff",
  "&:hover": {
    backgroundColor: "#fff",
  },
  border: 1,
  borderColor: "#D0D0D0",
};

// const privateRouteStyle = {
//   color: "ffffff",
//   backgroundColor: "#27BDFF",
//   "&:hover": {
//     backgroundColor: "#1594Fd",
//   },
//   marginTop: "1px",
//   marginBottom: "1px",
// };

export function Sidebar({ auth, mobile }) {
  const [sidebarIsOpened, setSidebarOpen] = useState(false);
  const token = useSelector(selectUserToken);
  const url = useResolvedPath().pathname;
  const [data, setData] = useState({});
  const [getSelf, getSelfResults] = useGetSelfMMutation();

  useEffect(() => {
    if (token && token.length > 0) {
      getSelf();
    }
  }, [token]);

  useEffect(() => {
    if (getSelfResults.isSuccess) {
      setData(getSelfResults.data);
    }
    if (getSelfResults.isError) getSelf();
  }, [getSelfResults]);
  const { t } = useTranslation();

  const pages = token
    ? [
        { text: t("restaurantSearch"), link: "/", type: "private" },
        { text: t("ourChefClub"), link: "/chef_club", type: "public" },
        { text: t("mynews"), link: "/news", type: "public" },
        { text: t("myPartners"), link: "/partners", type: "public" },
        {
          text: t("mybookings"),
          link: "/mybookings",
          type: "private",
        },
        {
          text: "My Restaurant Bookings",
          link: "/bookings",
          type: "private",
        },
        { text: t("howitworks"), link: "/how_it_works", type: "public" },
        { text: t("contactus"), link: "/contact_us", type: "public" },
        {
          text: t("logout"),
          link: "/auth/logout",
          type: "private",
        },
      ]
    : [
        { text: t("restaurantSearch"), link: "/", type: "public" },
        { text: t("ourChefClub"), link: "/chef_club", type: "public" },
        { text: t("mynews"), link: "/news", type: "public" },
        { text: t("howitworks"), link: "/how_it_works", type: "public" },
        { text: t("contactus"), link: "/contact_us", type: "public" },
        { text: t("login"), link: "/auth/login", type: "public" },
        {
          text: t("notamember"),
          link: "/register",
          type: "public",
        },
      ];

  const toggleDrawer = (open) => (event) => {
    // Control for blind people
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setSidebarOpen(open);
  };

  return (
    <>
      <IconButton
        onClick={toggleDrawer(true)}
        sx={{
          borderRadius: 0,
          height: 80,
          width: 70,
          marginLeft: 0,
          color: "white",
          display: { xs: !mobile ? "none" : "flex", md: "flex" },

          backgroundColor: { xs: "red", md: "#27bdff" },

          "&:hover": {
            backgroundColor: "#007bff",
          },
        }}
      >
        <MenuIcon />
      </IconButton>
      <Drawer
        anchor={"right"}
        open={sidebarIsOpened}
        onClose={toggleDrawer(false)}
      >
        <Box sx={{ minWidth: { sm: 240 }, maxWidth: { xs: 300, md: 500 } }}>
          <List sx={{ paddingTop: 0 }}>
            <ListItem
              key="mtd-title"
              sx={{ backgroundColor: "red" }}
              onClick={toggleDrawer(false)}
              onKeyDown={toggleDrawer(false)}
            >
              <ListItemButton>
                <Typography
                  align="left"
                  component="h1"
                  fontSize="2rem"
                  fontWeight="bold"
                  letterSpacing="0.0625rem"
                  fontFamily="MTD !important"
                  color="white"
                  sx={{ fontWeight: "bold" }}
                >
                  My
                </Typography>
                <Typography
                  align="left"
                  component="h1"
                  color="#ffd800"
                  fontSize="2rem"
                  fontWeight="bold"
                  fontFamily="MTD !important"
                  letterSpacing="0.0625rem"
                  sx={{ fontWeight: "bold" }}
                >
                  Travel
                </Typography>
                <Typography
                  align="left"
                  component="h1"
                  fontSize="2rem"
                  fontWeight="bold"
                  letterSpacing="0.0625rem"
                  fontFamily="MTD !important"
                  color="white"
                >
                  Dinner
                </Typography>
                <Typography
                  align="left"
                  component="div"
                  sx={{ flexGrow: 1 }}
                ></Typography>
              </ListItemButton>
            </ListItem>
            <ListItem sx={{ display: { xs: "block", md: "none" } }}>
              <LanguageIcon />
            </ListItem>
            {pages.map((page, idx) => {
              if (
                !auth &&
                page.link !== "/card" &&
                page.link !== "/auth/logout"
              )
                return (
                  <ListItem
                    key={idx}
                    disablePadding
                    onMouseOver={{}}
                    sx={publicRouteStyle}
                    onClick={toggleDrawer(false)}
                    onKeyDown={toggleDrawer(false)}
                  >
                    <ListItemButton disabled={!auth && page.link !== "/card"}>
                      <ListItemText
                        primary={page.text}
                        primaryTypographyProps={{
                          fontWeight: page.link === url ? "bold" : "normal",
                        }}
                      />
                    </ListItemButton>
                  </ListItem>
                );
              else {
                if (page.link === "/bookings") {
                  if (data?.user?.role?.name === "owner")
                    return (
                      <Link
                        to={page.link}
                        key={idx}
                        style={{
                          color: "inherit",
                          textDecoration: "inherit",
                          paddingTop: 0,
                          paddingBottom: 0,
                        }}
                        onClick={toggleDrawer(false)}
                        onKeyDown={toggleDrawer(false)}
                      >
                        <ListItem
                          key={page.text}
                          disablePadding
                          sx={publicRouteStyle}
                        >
                          <ListItemButton>
                            <ListItemText
                              primary={page.text}
                              primaryTypographyProps={{
                                fontWeight:
                                  page.link === url ? "bold" : "normal",
                              }}
                              sx={{
                                color: "black",
                              }}
                            />
                          </ListItemButton>
                        </ListItem>
                      </Link>
                    );
                } else if (page.link === "/how_it_works") {
                  return (
                    <a
                      href="https://www.mytraveldinner.com/"
                      target={"_blank"}
                      style={{ textDecoration: "none" }}
                      rel="noreferrer"
                    >
                      <ListItem key={idx} disablePadding sx={publicRouteStyle}>
                        <ListItemButton>
                          <ListItemText
                            primary={page.text}
                            primaryTypographyProps={{
                              fontWeight: page.link === url ? "bold" : "normal",
                            }}
                            sx={{ color: "black" }}
                          />
                        </ListItemButton>
                      </ListItem>
                    </a>
                  );
                } else if (page.link === "/contact_us") {
                  return (
                    <a
                      href="https://www.mytraveldinner.com/contact"
                      target={"_blank"}
                      style={{ textDecoration: "none" }}
                      rel="noreferrer"
                    >
                      <ListItem key={idx} disablePadding sx={publicRouteStyle}>
                        <ListItemButton>
                          <ListItemText
                            primary={page.text}
                            primaryTypographyProps={{
                              fontWeight: page.link === url ? "bold" : "normal",
                            }}
                            sx={{ color: "black" }}
                          />
                        </ListItemButton>
                      </ListItem>
                    </a>
                  );
                } else
                  return (
                    <Link
                      to={page.link}
                      key={idx}
                      style={{
                        color: "inherit",
                        textDecoration: "inherit",
                        paddingTop: 0,
                        paddingBottom: 0,
                      }}
                      onClick={toggleDrawer(false)}
                      onKeyDown={toggleDrawer(false)}
                    >
                      <ListItem
                        key={page.text}
                        disablePadding
                        sx={publicRouteStyle}
                      >
                        <ListItemButton>
                          <ListItemText
                            primary={page.text}
                            primaryTypographyProps={{
                              fontWeight: page.link === url ? "bold" : "normal",
                            }}
                            sx={{ color: "black" }}
                          />
                        </ListItemButton>
                      </ListItem>
                    </Link>
                  );
                return <></>;
              }
            })}
          </List>
        </Box>
      </Drawer>
    </>
  );
}
