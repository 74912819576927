import React, { useEffect, useState } from "react";
import {
  AppBar,
  Box,
  IconButton,
  Toolbar,
  Typography,
  Grid,
  Stack,
} from "@mui/material";
import logo from "assets/logo.svg";
import { Sidebar } from "app/Navbar/DoubleNavbarSidebar";
import { NotificationIcon } from "app/Navbar/NotificationIcon";
import { MessageIcon } from "app/Navbar/MessageIcon";
import { LanguageIcon } from "app/Navbar/LanguageIcon";
import { ProfileIcon } from "./ProfileIcon";
import { useAuthUser } from "react-auth-kit";
import { useNavigate } from "react-router-dom";
import SearchCustomIcon from "assets/icono_search_blanco.svg";
import { useSelector } from "react-redux";
import { selectNotifications, selectUserToken } from "services/authSlice";
import { NotificationsProvider } from "./NotificationsProvider";

export function DoubleNavbar() {
  const notifications = useSelector(selectNotifications);
  const navigate = useNavigate();
  const [isAtuth, setIsAuth] = useState(false);
  const token = useSelector(selectUserToken);
  const authUser = useAuthUser();

  useEffect(() => {
    if (authUser()) {
      setIsAuth(authUser().active);
      if (!authUser().active) navigate("/account");
    }
    if (token === null || token?.length === 0) setIsAuth(false);
  }, [token]);

  return (
    <NotificationsProvider auth={isAtuth}>
      <Box
        sx={{
          flexGrow: 1,
        }}
      >
        <AppBar
          position="fixed"
          sx={{
            backgroundColor: "red",
            paddingRight: 0,
            height: { xs: "15vh", sm: "80px" },
            minHeight: '70px'
          }}
        >
          <Toolbar disableGutters sx={{ paddingLeft: { xs: 1, sm: 3 }, minHeight: 70 }}>
            <Typography
              align="left"
              component="div"
              sx={{ flexGrow: 1, maxWidth: 70 }}
              mr={2}
              mt={1}
            >
              <img
                src={logo}
                alt="MyTravelDinner logo"
                style={{ cursor: "pointer" }}
                onClick={() => navigate("/")}
              />
            </Typography>
            <Typography
              align="left"
              component="h1"
              fontSize="1.8rem"
              fontWeight="bold"
              fontFamily="MTD !important"
              letterSpacing="0.0625rem"
              style={{ cursor: "pointer", marginLeft: "3px" }}
              onClick={() => navigate("/")}
            >
              My
            </Typography>
            <Typography
              align="left"
              component="h1"
              color="#ffd800"
              fontSize="1.8rem"
              fontWeight="bold"
              fontFamily="MTD !important"
              letterSpacing="0.0625rem"
              style={{ cursor: "pointer" }}
              onClick={() => navigate("/")}
            >
              Travel
            </Typography>
            <Typography
              align="left"
              component="h1"
              fontSize="1.8rem"
              fontWeight="bold"
              fontFamily="MTD !important"
              letterSpacing="0.0625rem"
              style={{ cursor: "pointer" }}
              onClick={() => navigate("/")}
            >
              Dinner
            </Typography>

            {/* DESKTOP */}
            <Stack
              direction="row"
              mt={1}
              sx={{
                flexGrow: 1,
                display: { xs: "none", sm: "flex" },
                marginTop: 0,
                height: 80,
              }}
            ></Stack>
            <Stack
              direction="row"
              sx={{
                flexGrow: 1,
                display: { xs: "none", sm: "flex" },
                backgroundColor: {
                  xs: "red",
                  md: `${isAtuth ? "#27bdff" : "#d0d0d0"}`,
                },
                height: 80,
              }}
            >
              <Grid
                container
                justifyContent="center"
                alignItems="center"
                direction="column"
                sx={{
                  "&:hover": {
                    backgroundColor: { xs: "red", md: "#007bff" },
                  },
                  borderRight: 1,
                  borderRightColor: "white",
                }}
              >
                <IconButton
                  onClick={() => {
                    if (isAtuth) navigate("/search");
                  }}
                  sx={{ color: "white" }}
                >
                  <img
                    src={SearchCustomIcon}
                    style={{ width: 40, height: 40 }}
                    alt=""
                  ></img>
                </IconButton>
              </Grid>
              <Grid
                container
                justifyContent="center"
                alignItems="center"
                direction="column"
                sx={{
                  "&:hover": {
                    backgroundColor: { xs: "red", md: "#007bff" },
                  },
                  borderRight: 1,
                  borderRightColor: "white",
                }}
              >
                <NotificationIcon
                  notifications={notifications}
                  auth={isAtuth}
                />
              </Grid>
              {/* <Grid
                container
                justifyContent="center"
                alignItems="center"
                direction="column"
                sx={{
                  "&:hover": {
                    backgroundColor: { xs: "red", md: "#007bff" },
                  },
                  borderRight: 1,
                  borderRightColor: "white",
                }}
              >
                <MessageIcon auth={isAtuth} />
              </Grid> */}
              <Grid
                container
                justifyContent="center"
                alignItems="center"
                direction="column"
                sx={{
                  "&:hover": {
                    backgroundColor: { xs: "red", md: "#007bff" },
                  },
                  borderRight: 1,
                  borderRightColor: "white",
                }}
              >
                <ProfileIcon />
              </Grid>
              <Grid
                container
                justifyContent="center"
                alignItems="center"
                direction="column"
                sx={{
                  display: { xs: "none", md: "block" },
                  "&:hover": {
                    backgroundColor: { xs: "red", md: "#007bff" },
                  },
                  borderRight: 1,
                  borderRightColor: "white",
                }}
              >
                <LanguageIcon auth={isAtuth} />
              </Grid>
            </Stack>
            <Sidebar auth={isAtuth} mobile={false} />
          </Toolbar>
          {/* Mobile */}
          <Toolbar disableGutters sx={{ display: { xs: "flex", sm: "none" } }}>
            <Stack
              direction="row"
              sx={{
                flexGrow: 1,
                backgroundColor: `${isAtuth ? "#27bdff" : "#d0d0d0"}`,
                height: 63,
                "@media (max-height: 830px)": {
                  height: 60
                },
              }}
            >
              <Grid
                container
                justifyContent="center"
                alignItems="center"
                direction="column"
                sx={{
                  "&:hover": {
                    backgroundColor: `${isAtuth ? "#007bff" : "#d0d0d0"}`,
                  },
                  borderRight: 1,
                  borderRightColor: "white",
                }}
              >
                <IconButton
                  onClick={() => {
                    if (isAtuth) navigate("/search");
                  }}
                  sx={{ color: "white" }}
                >
                  <img
                    src={SearchCustomIcon}
                    style={{ width: 40, height: 40 }}
                    alt=""
                  ></img>
                </IconButton>
              </Grid>
              <Grid
                container
                justifyContent="center"
                alignItems="center"
                direction="column"
                sx={{
                  "&:hover": {
                    backgroundColor: `${isAtuth ? "#007bff" : "#d0d0d0"}`,
                  },
                  borderRight: 1,
                  borderRightColor: "white",
                }}
              >
                <NotificationIcon
                  auth={isAtuth}
                  notifications={notifications}
                />
              </Grid>
              {/* <Grid
                container
                justifyContent="center"
                alignItems="center"
                direction="column"
                sx={{
                  "&:hover": {
                    backgroundColor: `${isAtuth ? "#007bff" : "#d0d0d0"}`,
                  },
                  borderRight: 1,
                  borderRightColor: "white",
                }}
              >
                <MessageIcon auth={isAtuth} />
              </Grid> */}
              <Grid
                container
                justifyContent="center"
                alignItems="center"
                direction="column"
                sx={{
                  "&:hover": {
                    backgroundColor: `${isAtuth ? "#007bff" : "#d0d0d0"}`,
                  },
                  borderRight: 1,
                  borderRightColor: "white",
                }}
              >
                <ProfileIcon />
              </Grid>
              <Grid
                container
                justifyContent="center"
                alignItems="center"
                direction="column"
                sx={{
                  display: { xs: "none", md: "block" },
                  "&:hover": {
                    backgroundColor: `${isAtuth ? "#007bff" : "#d0d0d0"}`,
                  },
                }}
              >
                <LanguageIcon auth={isAtuth} />
              </Grid>
            </Stack>

            <Sidebar auth={isAtuth} mobile={true} />
          </Toolbar>
        </AppBar>
      </Box>
    </NotificationsProvider>
  );
}
