import { ArrowBackIos } from "@mui/icons-material";
import {
  Avatar,
  Button,
  Grid,
  IconButton,
  Modal,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  useDeleteMutation,
  useGetAllNotificationsMutation,
  useMarkAllMutation,
  useSeenNotificationMutation,
} from "services/notificationsApi";
import { Box } from "@mui/system";
import MarkEmailUnreadIcon from "@mui/icons-material/MarkEmailUnread";
import MarkAsUnreadIcon from "@mui/icons-material/MarkAsUnread";
import { blue } from "@mui/material/colors";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import DeleteIcon from "@mui/icons-material/Delete";
import { useTranslation } from "react-i18next";
import { useAuthUser } from "react-auth-kit";
// import {
//   useListAllChefRequestsRestaurantMutation,
//   useWorkInRestaurantMutation,
// } from "services/usersApi";

export const Notifications = () => {
  const { t } = useTranslation();
  const [selected, setSelected] = useState(null);
  const navigate = useNavigate();
  const [openMenu, setOpenMenu] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [markAll, markAllResults] = useMarkAllMutation();
  const [markAs, markAsResults] = useSeenNotificationMutation();
  const [deleteNot, deleteNotResults] = useDeleteMutation();
  const [getNotifications, notificationsResults] =
    useGetAllNotificationsMutation();

  // const [getChaRequests, chaRequestsResults] =
  //   useListAllChefRequestsRestaurantMutation();
  // const [workInRestaurant, workInRestaurantResults] =
  //   useWorkInRestaurantMutation();

  const user = useAuthUser();

  useEffect(() => {
    // if (user()?.role?.name === "owner") getChaRequests();
    getNotifications();
  }, []);

  // useEffect(() => {
  //   if (workInRestaurantResults.isSuccess) {
  //     getChaRequests();
  //   }
  //   if (workInRestaurantResults.isError) {
  //     getChaRequests();
  //   }
  // }, [workInRestaurantResults]);

  useEffect(() => {
    if (notificationsResults.isSuccess) {
      setNotifications(notificationsResults?.data?.notifications);
      setOpenMenu(false);
    }
  }, [notificationsResults]);

  useEffect(() => {
    if (markAllResults.isSuccess) {
      getNotifications();
    }
  }, [markAllResults]);
  useEffect(() => {
    if (markAsResults.isSuccess) getNotifications();
  }, [markAsResults]);
  useEffect(() => {
    if (deleteNotResults.isSuccess) getNotifications();
  }, [deleteNotResults]);

  // const statusText = (status) => {
  //   if (status === "accepted") return t("chefRequestAccepted");
  //   else return t("chefRequestRejected");
  // };

  return (
    <Box maxHeight={"100vh"} maxWidth={"100vw"}>
      {/* HEADER */}
      <Grid
        item
        xs={12}
        container
        justifyContent="space-between"
        alignItems="center"
        sx={{
          backgroundColor: "#1396FF",
          minHeight: "8vh",
          maxHeight: "100vh",
        }}
      >
        <Grid item xs={2} container alignItems="center" justifyContent="center">
          <IconButton
            sx={{ color: "#fff", display: { xs: "flex", md: "none" } }}
            onClick={() => navigate("/")}
          >
            <ArrowBackIos />
          </IconButton>
        </Grid>
        <Grid item xs>
          <Button sx={{ color: "#fff" }} onClick={() => markAll("read")}>
            {t("markAllAsRead")}
          </Button>
        </Grid>
        <Grid item xs>
          <Button sx={{ color: "#fff" }} onClick={() => markAll("unread")}>
            {t("markAllAsUnread")}
          </Button>
        </Grid>
      </Grid>
      {/* POST, Messages, Bookings */}
      <Grid
        container
        direction="row"
        justifyContent="space-around"
        alignItems="center"
        sx={{
          opacity: openMenu ? 0.5 : 1,
          maxHeight: { xs: "92vh", md: "80vh" },
          overflow: "scroll",
          maxWidth: "100vw",
        }}
      >
        {/* {user()?.role?.name === "owner" && (
          <Grid item xs={12} container justifyContent="center">
            <Grid
              item
              xs={12}
              justifyContent="center"
              container
              sx={{
                backgroundColor: "#F2F2F2",
                borderBottom: "1px solid #D0D0D0",
              }}
            >
              <Typography
                noWrap
                variant="h5"
                sx={{
                  color: blue[700],
                  display: "flex",
                  marginLeft: "5px",
                  fontWeight: "700",
                  py: 1.5,
                }}
              >
                {t("incomingChefRequests")}
              </Typography>
            </Grid>
            {chaRequestsResults?.data?.requests?.map((notification, index) => (
              <Grid
                container
                item
                xs={12}
                key={index}
                sx={{
                  backgroundColor: `${
                    notification?.status === "pending"
                      ? "#1396FF"
                      : "transparent"
                  }`,
                  py: 1.2,
                  my: 0.2,
                  borderBottom: "1px solid #D0D0D0",
                }}
              >
                <Grid item xs={2}>
                  <Avatar
                    sx={{
                      ml: 1,
                      borderColor: "rgba(1, 175, 14, 1)",
                      borderStyle: "solid",
                      borderWidth: { xs: "1px", sm: "5px" },
                    }}
                    src={notification?.userOne?.profile?.avatar?.url}
                  />
                </Grid>
                <Grid item xs={10}>
                  <Box p={1}>
                    <Typography
                      variant="h6"
                      noWrap
                      sx={{
                        color: `${
                          notification?.status === "pending"
                            ? "#1396FF"
                            : "#F7B500"
                        }`,
                      }}
                    >
                      {notification?.status === "pending"
                        ? t("newChefRequest")
                        : statusText(notification?.status)}
                    </Typography>
                    <Typography
                      noWrap
                      sx={{
                        color: `${
                          notification?.status === "pending" ? "#fff" : "#000"
                        }`,
                      }}
                    >
                      {`${notification?.userOne?.profile?.name ?? ""} ${
                        notification?.userOne?.profile?.middleName ?? ""
                      } ${notification?.userOne?.profile?.lastName ?? ""}`}
                    </Typography>
                  </Box>
                </Grid>
                {notification?.status === "pending" && (
                  <Grid item={12} container justifyContent="space-around">
                    <Button
                      variant="outlined"
                      sx={{
                        backgroundColor: "rgba(1, 175, 14, 1)",
                        ":hover": {
                          backgroundColor: "rgba(1, 175, 14, 1)",
                          transform: "scale(1.1)",
                        },
                      }}
                      onClick={() => {
                        workInRestaurant({
                          id: notification?.id,
                          status: "accepted",
                        });
                      }}
                    >
                      {t("accept")}
                    </Button>
                    <Button
                      variant="outlined"
                      sx={{
                        backgroundColor: "#FD0000",
                        ":hover": {
                          backgroundColor: "#FD0000",
                          transform: "scale(1.1)",
                        },
                      }}
                      onClick={() => {
                        workInRestaurant({
                          id: notification?.id,
                          status: "declined",
                        });
                      }}
                    >
                      {t("decline")}
                    </Button>
                  </Grid>
                )}
              </Grid>
            ))}
          </Grid>
        )} */}
        <Grid item xs={12} container justifyContent="center">
          <Grid
            item
            xs={12}
            justifyContent="center"
            container
            sx={{
              backgroundColor: "#F2F2F2",
              borderBottom: "1px solid #D0D0D0",
            }}
          >
            <Typography
              noWrap
              variant="h5"
              sx={{
                color: blue[700],
                display: "flex",
                marginLeft: "5px",
                fontWeight: "700",
                py: 1.5,
              }}
            >
              {t("posts")}
            </Typography>
          </Grid>
          {notifications
            ?.filter((notification) => notification.type === "post")
            ?.sort((a, b) => a?.id > b?.id)
            ?.map((notification, index) => (
              <Grid
                container
                item
                xs={12}
                key={index}
                sx={{
                  backgroundColor: `${
                    notification?.seen ? "transparent" : "#1396FF"
                  }`,
                  py: 1.2,
                  my: 0.2,
                  borderBottom: "1px solid #D0D0D0",
                }}
              >
                <Grid item xs={2}>
                  <Avatar
                    sx={{
                      ml: 1,
                      borderColor: "white",
                      borderStyle: "solid",
                      borderWidth: { xs: "1px", sm: "5px" },
                    }}
                    src={
                      notification?.post?.images?.filter(
                        (image) => image?.main === true
                      )?.[0]?.image?.url
                    }
                  />
                </Grid>
                <Grid
                  item
                  xs={8}
                  onClick={() => {
                    markAs({ id: selected, status: "read" });
                    navigate("/news/" + notification?.post?.id);
                  }}
                >
                  <Box>
                    <Typography
                      variant="h6"
                      noWrap
                      sx={{
                        color: `${notification?.seen ? "#1396FF" : "#F7B500"}`,
                      }}
                    >
                      {t("newPost")},{" "}
                      {
                        notification?.post?.translations?.filter(
                          (t) => t?.language === "en"
                        )?.[0]?.title
                      }
                    </Typography>
                    <Typography
                      noWrap
                      sx={{
                        color: `${notification?.seen ? "#000" : "#fff"}`,
                      }}
                    >
                      {notification?.content}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={2}>
                  <IconButton
                    onClick={() => {
                      setSelected(notification?.id);
                      setOpenMenu(true);
                    }}
                  >
                    <MoreVertIcon
                      sx={{
                        color: `${notification?.seen ? "#000" : "#fff"}`,
                      }}
                    />
                  </IconButton>
                </Grid>
              </Grid>
            ))}
        </Grid>
        {/* <Grid container justifyContent="flex-start" item xs={12}>
          <Grid
            item
            xs={12}
            justifyContent="center"
            container
            sx={{
              backgroundColor: "#F2F2F2",
              borderBottom: "1px solid #D0D0D0",
            }}
          >
            <Typography
              variant="h5"
              noWrap
              sx={{
                color: blue[700],
                display: "flex",
                marginLeft: "5px",
                fontWeight: "700",
                py: 1.5,
              }}
            >
              {t("messages")}
            </Typography>
          </Grid>
          {notifications
            ?.filter((notification) => notification.type === "message")
            ?.sort((a, b) => a?.id > b?.id)
            ?.map((notification, index) => (
              <Grid
                container
                item
                xs={12}
                key={index}
                sx={{
                  backgroundColor: `${
                    notification?.seen ? "transparent" : "#1396FF"
                  }`,
                  py: 1.2,
                  my: 0.2,
                  borderBottom: "1px solid #D0D0D0",
                }}
              >
                <Grid item xs={2}>
                  <Avatar
                    sx={{
                      ml: 1,
                      borderColor: "white",
                      borderStyle: "solid",
                      borderWidth: { xs: "1px", sm: "5px" },
                    }}
                    src={notification?.relatedUser?.profile?.avatar?.url}
                  />
                </Grid>
                <Grid
                  item
                  xs={8}
                  onClick={() => {
                    markAs({ id: selected, status: "read" });
                    navigate("/chat");
                  }}
                >
                  <Typography
                    noWrap
                    variant="h6"
                    sx={{
                      color: `${notification?.seen ? "#1396FF" : "#F7B500"}`,
                    }}
                  >
                    {notification?.relatedUser?.profile?.name +
                      " " +
                      notification?.relatedUser?.profile?.middleName +
                      " " +
                      notification?.relatedUser?.profile?.lastName}
                  </Typography>
                  <Typography
                    sx={{
                      color: `${notification?.seen ? "#000" : "#fff"}`,
                    }}
                  >
                    {notification?.content}
                  </Typography>
                </Grid>
                <Grid item xs={2}>
                  <IconButton
                    onClick={() => {
                      setSelected(notification?.id);
                      setOpenMenu(true);
                    }}
                  >
                    <MoreVertIcon
                      sx={{
                        color: `${notification?.seen ? "#000" : "#fff"}`,
                      }}
                    />
                  </IconButton>
                </Grid>
              </Grid>
            ))}
        </Grid> */}

        <Grid container justifyContent="flex-start">
          <Grid
            item
            xs={12}
            justifyContent="center"
            container
            sx={{
              backgroundColor: "#F2F2F2",
              borderBottom: "1px solid #D0D0D0",
            }}
          >
            <Typography
              variant="h5"
              noWrap
              sx={{
                color: blue[700],
                display: "flex",
                marginLeft: "5px",
                fontWeight: "700",
                py: 1.5,
              }}
            >
              {t("bookings")}
            </Typography>
          </Grid>
          {notifications
            ?.filter((notification) => notification.type === "booking")
            ?.sort((a, b) => a?.id > b?.id)
            ?.map((notification, index) => (
              <Grid
                container
                item
                xs={12}
                key={index}
                sx={{
                  backgroundColor: `${
                    notification?.seen ? "transparent" : "#1396FF"
                  }`,
                  py: 1.2,
                  my: 0.2,
                  borderBottom: "1px solid #D0D0D0",
                }}
              >
                <Grid item xs={2}>
                  <Avatar
                    sx={{
                      ml: 1,
                      borderColor: "white",
                      borderStyle: "solid",
                      borderWidth: { xs: "1px", sm: "5px" },
                    }}
                    src={
                      notification?.booking?.restaurant?.images?.filter(
                        (image) => image?.main === true
                      )?.[0]?.file?.url
                    }
                  />
                </Grid>
                <Grid
                  item
                  xs={8}
                  onClick={() => {
                    markAs({ id: selected, status: "read" });
                    user()?.role?.name === "owner"
                      ? navigate("/bookings")
                      : navigate("/mybookings");
                  }}
                >
                  <Typography
                    noWrap
                    variant="h6"
                    sx={{
                      color: `${notification?.seen ? "#1396FF" : "#F7B500"}`,
                    }}
                  >
                    {notification?.booking?.restaurant?.name}
                  </Typography>
                  <Typography
                    noWrap
                    sx={{
                      color: `${notification?.seen ? "#000" : "#fff"}`,
                    }}
                  >
                    {notification?.content}
                  </Typography>
                </Grid>
                <Grid item xs={2}>
                  <IconButton
                    onClick={() => {
                      setSelected(notification?.id);
                      setOpenMenu(true);
                    }}
                  >
                    <MoreVertIcon
                      sx={{
                        color: `${notification?.seen ? "#000" : "#fff"}`,
                      }}
                    />
                  </IconButton>
                </Grid>
              </Grid>
            ))}
        </Grid>
        <Grid container justifyContent="flex-start">
          <Grid
            item
            xs={12}
            justifyContent="center"
            container
            sx={{
              backgroundColor: "#F2F2F2",
              borderBottom: "1px solid #D0D0D0",
            }}
          >
            <Typography
              variant="h5"
              noWrap
              sx={{
                color: blue[700],
                display: "flex",
                marginLeft: "5px",
                fontWeight: "700",
                py: 1.5,
              }}
            >
              {t("stories")}
            </Typography>
          </Grid>
          {notifications
            ?.filter((notification) => notification.type === "story")
            ?.sort((a, b) => a?.id > b?.id)
            ?.map((notification, index) => (
              <Grid
                container
                item
                xs={12}
                key={index}
                sx={{
                  backgroundColor: `${
                    notification?.seen ? "transparent" : "#1396FF"
                  }`,
                  py: 1.2,
                  my: 0.2,
                  borderBottom: "1px solid #D0D0D0",
                }}
              >
                <Grid item xs={2}>
                  <Avatar
                    sx={{
                      ml: 1,
                      borderColor: "white",
                      borderStyle: "solid",
                      borderWidth: { xs: "1px", sm: "5px" },
                    }}
                    src={notification?.story?.user?.profile?.avatar?.url}
                  />
                </Grid>
                <Grid
                  item
                  xs={8}
                  onClick={() => {
                    markAs({ id: selected, status: "read" });
                    navigate("/stories/" + notification?.story?.id);
                  }}
                >
                  <Typography
                    noWrap
                    variant="h6"
                    sx={{
                      color: `${notification?.seen ? "#1396FF" : "#F7B500"}`,
                    }}
                  >
                    {`${notification?.story?.user?.profile?.name ?? ""} ${
                      notification?.story?.user?.profile?.middleName ?? ""
                    } ${notification?.story?.user?.profile?.lastName ?? ""}`}
                  </Typography>
                  <Typography
                    noWrap
                    sx={{
                      color: `${notification?.seen ? "#000" : "#fff"}`,
                    }}
                  >
                    {t("newStory")}
                  </Typography>
                </Grid>
                <Grid item xs={2}>
                  <IconButton
                    onClick={() => {
                      setSelected(notification?.id);
                      setOpenMenu(true);
                    }}
                  >
                    <MoreVertIcon
                      sx={{
                        color: `${notification?.seen ? "#000" : "#fff"}`,
                      }}
                    />
                  </IconButton>
                </Grid>
              </Grid>
            ))}
        </Grid>
      </Grid>
      {/* Modal Actions */}
      <Modal keepMounted open={openMenu} onClose={() => setOpenMenu(false)}>
        <Box>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={2}
            sx={{ minWidth: "100%" }}
          >
            <Grid item xs={12}>
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                sx={{
                  width: "100vw",
                  height: "20vh",
                  position: "fixed",
                  backgroundColor: "#323638",
                  bottom: 0,
                }}
              >
                <Grid
                  item
                  xs={12}
                  container
                  alignItems="center"
                  justifyContent="space-around"
                  sx={{ border: 1, borderColor: "gray" }}
                >
                  <Grid item xs={1}>
                    <DeleteIcon sx={{ color: "#ff0000" }} />
                  </Grid>
                  <Grid item xs={8}>
                    <Button
                      onClick={() => {
                        deleteNot(selected);
                      }}
                      sx={{ textTransform: "capitalize" }}
                    >
                      <Typography variant="h5" color="white">
                        {t("delete")}
                      </Typography>
                    </Button>
                  </Grid>
                </Grid>
                <Grid
                  item
                  xs={12}
                  container
                  alignItems="center"
                  justifyContent="space-around"
                  sx={{ border: 1, borderColor: "gray" }}
                >
                  <Grid item xs={1}>
                    <MarkAsUnreadIcon sx={{ color: "#59addf" }} />
                  </Grid>
                  <Grid item xs={8}>
                    <Button
                      onClick={() => {
                        markAs({ id: selected, status: "read" });
                      }}
                      sx={{ textTransform: "capitalize" }}
                    >
                      <Typography variant="h5" color="white">
                        {t("markAsRead")}
                      </Typography>
                    </Button>
                  </Grid>
                </Grid>
                <Grid
                  item
                  xs={12}
                  container
                  alignItems="center"
                  justifyContent="space-around"
                  sx={{ border: 1, borderColor: "gray" }}
                >
                  <Grid item xs={1}>
                    <MarkEmailUnreadIcon sx={{ color: "#59addf" }} />
                  </Grid>
                  <Grid item xs={8}>
                    <Button
                      onClick={() => {
                        markAs({ id: selected, status: "unread" });
                      }}
                      sx={{ textTransform: "capitalize" }}
                    >
                      <Typography variant="h5" color="white">
                        {t("markAsUnread")}
                      </Typography>
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </Box>
  );
};
