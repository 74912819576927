import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { API_URL } from "./env";
import { getValue } from "./storage";
export const regionsApi = createApi({
  reducerPath: "regionsApi",
  baseQuery: fetchBaseQuery({
    baseUrl: API_URL,
    prepareHeaders: (headers) => {
      const token = getValue("_auth");
      headers.set("Authorization", `Bearer ${token}`);
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getAllRegions: builder.query({
      query: () => {
        return { url: `regions` };
      },
    }),
    getRegion: builder.query({
      query: (id) => {
        return { url: `regions/${id}` };
      },
    }),
  }),
});

export const selectAllRegions = (regions) => regions;

export const { useGetAllRegionsQuery, useGetRegionQuery } = regionsApi;
