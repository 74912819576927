import * as React from "react";
import CloseIcon from "@mui/icons-material/Close";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import ShareIcon from "@mui/icons-material/Share";
import { useState } from "react";
import { ArrowBackIos, ArrowForwardIos } from "@mui/icons-material";
import { useEffect } from "react";
import { Box, Grid, Modal } from "@mui/material";
import { Share } from "@capacitor/share";
import { useSwipeable } from "react-swipeable";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useSnackbar } from "notistack";

export default function RestaurantDialog(props) {
  const { open, setOpen, data, index, id } = props;
  const handleClose = () => {
    setOpen(false);
  };
  const { enqueueSnackbar } = useSnackbar();
  const [currentIndex, setCurrentIndex] = useState(0);
  const [image, setImage] = useState({ url: "", alt: "" });

  useEffect(() => {
    setCurrentIndex(data?.index);
    setImage({
      url: data?.images[data?.index]?.file?.url,
      alt: data?.images[data?.index]?.file?.name,
    });
    return () => setImage({ url: "", alt: "" });
  }, [props]);

  useEffect(() => {
    setImage({
      url: data?.images[currentIndex]?.file?.url,
      alt: data?.images[currentIndex]?.file?.name,
    });
  }, [currentIndex]);

  const shareTo = () => {
    Share.canShare().then(() =>
      Share.share({
        title: "Amazing food picture",
        text: "Restaurant food picture",
        url: image?.url,
        dialogTitle: "Share with",
      })
    );
  };

  const handlers = useSwipeable({
    onSwipedLeft: () => {
      if (currentIndex + 1 < data?.images?.length)
        setCurrentIndex(currentIndex + 1);
    },
    onSwipedRight: () => {
      if (currentIndex - 1 > 0) setCurrentIndex(currentIndex - 1);
    },
  });

  return (
    <Modal open={open} onClose={handleClose} keepMounted>
      <Box
        {...handlers}
        sx={{
          width: "100vw",
          height: "100vh",
          backgroundColor: "#000",
          backgroundImage: `url(${image?.url})`,
          backgroundSize: "contain",
          backgroundPositionX: "center",
          backgroundPositionY: "center",
          backgroundRepeat: "no-repeat",
        }}
      >
        <Grid container justifyContent="center" alignItems="center">
          <Grid
            item
            xs={12}
            container
            justifyContent="center"
            alignItems="center"
            sx={{
              my: 8,
              backgroundColor: "rgba(0,0,0,0.1)",
              border: "1px solid rgba(255,255,255,0.2)",
            }}
          >
            <Grid item xs={4} container justifyContent="center">
              <Avatar
                sx={{ width: 72, height: 72 }}
                src={data?.restaurant?.images.map((image) =>
                  image.main === true ? image.file.url : null
                )}
                srcSet={data?.restaurant?.images.map((image) =>
                  image.main === true ? image.file.url : null
                )}
              >
                {data?.restaurant?.name}
              </Avatar>
            </Grid>
            <Grid item xs={8}>
              <Typography noWrap sx={{ color: "#fff" }} variant="h5">
                {data?.restaurant?.name}
              </Typography>
              <Typography sx={{ color: "#fff" }}>{`${currentIndex}/${
                index.total - 1
              }`}</Typography>
            </Grid>
          </Grid>
          <IconButton
            sx={{
              position: "absolute",
              right: "4%",
              top: "1%",
              backgroundColor: "rgba(0,0,0,0.1)",
              border: "1px solid rgba(255,255,255,0.2)",
            }}
            onClick={handleClose}
          >
            <CloseIcon sx={{ color: "#fff" }} />
          </IconButton>
          <IconButton
            sx={{
              position: "absolute",
              left: "4%",
              top: "50%",
              backgroundColor: "rgba(0,0,0,0.1)",
              border: "1px solid rgba(255,255,255,0.2)",
              borderRadius: 5,
            }}
            onClick={() => {
              if (currentIndex - 1 > 0) setCurrentIndex(currentIndex - 1);
            }}
          >
            <ArrowBackIos
              sx={{
                color: "#ff0000",
              }}
            />
          </IconButton>

          <IconButton
            sx={{
              position: "absolute",
              right: "4%",
              top: "50%",
              backgroundColor: "rgba(0,0,0,0.1)",
              border: "1px solid rgba(255,255,255,0.2)",
            }}
            onClick={() => {
              if (currentIndex + 1 < data?.images?.length)
                setCurrentIndex(currentIndex + 1);
            }}
          >
            <ArrowForwardIos sx={{ color: "#ff0000" }} />
          </IconButton>

          <Grid item xs={12} mt={2} container justifyContent="center">
            <Grid item xs={12} sx={{ position: "absolute", bottom: 50 }}>
              {window.cordova ? (
                <IconButton onClick={shareTo}>
                  <ShareIcon sx={{ color: "#ff0000", width: 42, height: 42 }} />
                </IconButton>
              ) : (
                <CopyToClipboard
                  text={`https://mytraveldinner.com/${id}`}
                  onCopy={() =>
                    enqueueSnackbar(`Successfully copied`, {
                      variant: "success",
                      autoHideDuration: 2000,
                    })
                  }
                >
                  <IconButton>
                    <ShareIcon
                      sx={{ color: "#ff0000", width: 42, height: 42 }}
                    />
                  </IconButton>
                </CopyToClipboard>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
}
