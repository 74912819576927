import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { API_URL } from "./env";
import { getValue } from "./storage";
export const contactsApi = createApi({
  reducerPath: "contactsApi",
  keepUnusedDataFor: 0,
  baseQuery: fetchBaseQuery({
    baseUrl: API_URL,
    prepareHeaders: (headers) => {
      const token = getValue("_auth");
      headers.set("Authorization", `Bearer ${token}`);
      return headers;
    },
  }),
  endpoints: (builder) => ({
    requestFriendship: builder.mutation({
      query: (id) => ({
        url: `contacts/request/${id}/`,
        method: "GET",
      }),
    }),
    getContacts: builder.mutation({
      query: () => {
        return { url: `contacts/contacts` };
      },
    }),
    getRequests: builder.mutation({
      query: () => {
        return { url: `contacts/requests` };
      },
    }),
    getConversation: builder.mutation({
      query: (id) => {
        return { url: `contacts/conversation/${id}` };
      },
    }),
    acceptRequest: builder.mutation({
      query: (id) => {
        return { url: `contacts/accept/${id}` };
      },
    }),
    declineRequest: builder.mutation({
      query: (id) => {
        return { url: `contacts/decline/${id}` };
      },
    }),
    blockContact: builder.mutation({
      query: (id) => {
        return { url: `contacts/block/${id}` };
      },
    }),
    unBlockContact: builder.mutation({
      query: (id) => {
        return { url: `contacts/unblock/${id}` };
      },
    }),
    removeContact: builder.mutation({
      query: (id) => {
        return { url: `contacts/remove/${id}` };
      },
    }),
    searchContact: builder.mutation({
      query: (search) => {
        return { url: `contacts/search?value=${search}`, method: "GET" };
      },
    }),
    getConversations: builder.mutation({
      query: () => {
        return { url: `contacts/conversations`, method: "GET" };
      },
    }),
    compareContacts: builder.mutation({
      query: ({ emails, phones }) => {
        return {
          url: `contacts/compare`,
          method: "POST",
          body: { phones: phones, emails: [] },
        };
      },
    }),
    createConversation: builder.mutation({
      query: ({ id, restaurant }) => {
        return {
          url: `contacts/create/${id}${restaurant ? "?restaurant=true" : ""}`,
          method: "POST",
          body: {},
        };
      },
    }),
    deleteConversation: builder.mutation({
      query: ({ id }) => {
        return {
          url: `contacts/conversations/${id}`,
          method: "DELETE",
        };
      },
    }),
    setStatus: builder.mutation({
      query: ({ id, status }) => {
        return {
          url: `contacts/conversations/${id}/${status}`,
          method: "POST",
          body: {},
        };
      },
    }),
  }),
});

export const {
  useRequestFriendshipMutation,
  useGetContactsMutation,
  useGetConversationMutation,
  useAcceptRequestMutation,
  useDeclineRequestMutation,
  useBlockContactMutation,
  useRemoveContactMutation,
  useSearchContactMutation,
  useGetRequestsMutation,
  useGetConversationsMutation,
  useCompareContactsMutation,
  useCreateConversationMutation,
  useUnBlockContactMutation,
  useDeleteConversationMutation,
  useSetStatusMutation,
} = contactsApi;
