import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { API_URL } from "./env";

import { getValue } from "./storage";
export const notificationsApi = createApi({
  reducerPath: "notificationsApi",
  keepUnusedDataFor: 0,
  baseQuery: fetchBaseQuery({
    baseUrl: API_URL,
    prepareHeaders: (headers) => {
      const token = getValue("_auth");
      headers.set("Authorization", `Bearer ${token}`);
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getAllNotifications: builder.mutation({
      query: () => {
        return { method: "GET", url: `notifications` };
      },
    }),
    getAllUnreadNotifications: builder.mutation({
      query: () => {
        return { method: "GET", url: `notifications/unread` };
      },
    }),
    getAllUnreadNotificationsQuery: builder.query({
      query: () => {
        return { method: "GET", url: `notifications/unread` };
      },
    }),
    seenNotification: builder.mutation({
      query: ({ id, status }) => {
        return { method: "PUT", url: `notifications/${id}/${status}` };
      },
    }),
    markAll: builder.mutation({
      query: (status) => {
        return { method: "GET", url: `notifications/status/${status}` };
      },
    }),
    delete: builder.mutation({
      query: (id) => {
        return { method: "DELETE", url: `notifications/${id}` };
      },
    }),
  }),
});

export const {
  useGetAllNotificationsMutation,
  useSeenNotificationMutation,
  useMarkAllMutation,
  useDeleteMutation,
  useGetAllUnreadNotificationsMutation,
  useGetAllUnreadNotificationsQueryQuery
} = notificationsApi;
