import "react-chat-elements/dist/main.css";
import React, { useEffect, useState } from "react";
import ArchiveIcon from "@mui/icons-material/Archive";
import AnnouncementIcon from "@mui/icons-material/Announcement";
import ForumIcon from "@mui/icons-material/Forum";
import {
  Autocomplete,
  Box,
  Button,
  Grid,
  IconButton,
  Modal,
  TextField,
  Typography,
  Avatar,
  List,
  ListItemText,
  ListItem,
  ListItemAvatar,
  Menu,
  MenuItem,
  CircularProgress,
} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import PersonAddAltIcon from "@mui/icons-material/PersonAddAlt";
import { createTheme } from "@mui/material/styles";
import { ThemeProvider } from "@mui/material";
import { MoreHoriz } from "@mui/icons-material";
import SearchIcon from "@mui/icons-material/Search";
import GroupsIcon from "@mui/icons-material/Groups";
import {
  useAcceptRequestMutation,
  useCompareContactsMutation,
  useDeclineRequestMutation,
  useGetContactsMutation,
  useGetConversationMutation,
  useGetConversationsMutation,
  useGetRequestsMutation,
  useRequestFriendshipMutation,
  useSearchContactMutation,
  useBlockContactMutation,
  useRemoveContactMutation,
  useUnBlockContactMutation,
} from "services/contact";
import { useSnackbar } from "notistack";
import ChatIcon from "@mui/icons-material/Chat";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { io } from "socket.io-client";
import SendIcon from "@mui/icons-material/Send";
import { useUploadChatFileMutation } from "services/uploadApi";
import { ChatConversation } from "./ChatConversation";
import { useTranslation } from "react-i18next";
import { Contacts } from "@capacitor-community/contacts";
import { useDispatch, useSelector } from "react-redux";
import { selectConversation, setConversationId } from "services/authSlice";
import { useNavigate, useParams } from "react-router-dom";
import { useAuthUser } from "react-auth-kit";
import { ArrowBackIos } from "@mui/icons-material";
import { Chefcard } from "features/ChefCard/Chefcard";
import { red } from "@mui/material/colors";

const style = {
  position: "absolute",
  top: { xs: 0, md: "50%" },
  left: { xs: 0, md: "50%" },
  transform: { xs: "translate(0%, 0%)", md: "translate(-50%, -50%)" },
  width: { xs: "100%", md: "55%" },
  bgcolor: "#e1f5fe",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  overflowY: "scroll",
  maxHeight: { xs: "100%", md: "85%" },
  height: { xs: "100vh", md: "auto" },
};

export const theme = createTheme({
  palette: {
    red: {
      main: "#ff0000",
      light: "#ff3333",
      dark: "#b20000",
    },
    blueStrong: {
      main: "#007bff",
      light: "#3395ff",
      dark: "#0056b2",
    },
    blueLight: {
      main: "#27bdff",
      light: "#52caff",
      dark: "#1b84b2",
    },
  },
});

export const Chat = () => {
  const [details, setDetails] = useState();
  const [chatRequestsViewOnly, setChatRequestViewOnly] = useState(false);
  const [archivedViewOnly, setArchivedViewOnly] = useState(false);
  const [chatsViewOnly, setChatsViewOnly] = useState(false);
  const [openCard, setOpenCard] = useState(false);
  const auth = useAuthUser();
  const [openMenuHandle, setOpenMenuHandle] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    if (!auth().active) navigate("/card");
  }, []);
  const { t } = useTranslation();
  const { id } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState(null);
  const [inputValue, setInputValue] = useState("");
  const dispatch = useDispatch();
  const conversation = useSelector(selectConversation);
  const setConversation = (id) => {
    dispatch(setConversationId(id));
  };
  const [conversationDetails, setConversationDetails] = useState({});
  const [options, setOptions] = useState([]);
  const [requests, setRequests] = useState([]);
  const [contacts, setContacts] = useState([]);
  const [conversations, setConversations] = useState([]);
  const [blockContact, blockContactResults] = useBlockContactMutation();
  const [unBlockContact, unBlockContactResults] = useUnBlockContactMutation();
  const [removeContact, removeContactResults] = useRemoveContactMutation();
  const [searchUser, searchUserResults] = useSearchContactMutation();
  const [addContact, addContactResults] = useRequestFriendshipMutation();
  const [getRequests, requestsResults] = useGetRequestsMutation();
  const [acceptRequest, acceptRequestResults] = useAcceptRequestMutation();
  const [declineRequest, declineRequestResults] = useDeclineRequestMutation();
  const [getContacts, getContactsResults] = useGetContactsMutation();
  const [getConversations, getConversationsResults] =
    useGetConversationsMutation();
  const [getConversation, getConversationResults] =
    useGetConversationMutation();
  const [uploadFile, uploadFileResults] = useUploadChatFileMutation();
  const [activeTab, setActiveTab] = useState("mychats");
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  useEffect(() => {
    if (id !== undefined) setConversation(id);
  }, [id]);

  const handleAutoCompleteInputChange = (event, newInputValue) => {
    setInputValue(newInputValue);
    searchUser(newInputValue);
  };
  const handleAutoCompleteChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    if (searchUserResults?.isSuccess) setOptions(searchUserResults.data?.users);
  }, [searchUserResults]);
  useEffect(() => {
    if (requestsResults?.isSuccess) {
      setRequests(requestsResults.data?.requests);
    }
  }, [requestsResults]);

  useEffect(() => {
    if (addContactResults?.isSuccess) {
      enqueueSnackbar(`Successfully requested`, {
        variant: "success",
        autoHideDuration: 2000,
      });
    }
    if (addContactResults?.isError) {
      enqueueSnackbar(`Could not perform the operation at the moment`, {
        variant: "error",
        autoHideDuration: 2000,
      });
    }
  }, [addContactResults]);
  useEffect(() => {
    if (blockContactResults?.isSuccess) {
      getContacts();
      enqueueSnackbar(`Successfully blocked`, {
        variant: "success",
        autoHideDuration: 2000,
      });
    }
    if (blockContactResults?.isError) {
      enqueueSnackbar(`Could not perform the operation at the moment`, {
        variant: "error",
        autoHideDuration: 2000,
      });
    }
  }, [blockContactResults]);
  useEffect(() => {
    if (unBlockContactResults?.isSuccess) {
      getContacts();
      enqueueSnackbar(`Successfully unblocked`, {
        variant: "success",
        autoHideDuration: 2000,
      });
    }
    if (unBlockContactResults?.isError) {
      enqueueSnackbar(`Could not perform the operation at the moment`, {
        variant: "error",
        autoHideDuration: 2000,
      });
    }
  }, [unBlockContactResults]);
  useEffect(() => {
    if (removeContactResults?.isSuccess) {
      getContacts();
      enqueueSnackbar(`Successfully blocked`, {
        variant: "success",
        autoHideDuration: 2000,
      });
    }
    if (removeContactResults?.isError) {
      enqueueSnackbar(`Could not perform the operation at the moment`, {
        variant: "error",
        autoHideDuration: 2000,
      });
    }
  }, [removeContactResults]);

  useEffect(() => {
    if (acceptRequestResults?.isError || declineRequestResults?.isError) {
      enqueueSnackbar(`Could not perform the operation at the moment`, {
        variant: "error",
        autoHideDuration: 2000,
      });
      getConversations();
      getRequests();
      getContacts();
    }
    if (acceptRequestResults?.isSuccess) {
      enqueueSnackbar(`Successfully accepted`, {
        variant: "success",
        autoHideDuration: 2000,
      });
      getConversations();
      getRequests();
      getContacts();
    }
  }, [acceptRequestResults, declineRequestResults]);

  useEffect(() => {
    if (declineRequestResults?.isSuccess) {
      enqueueSnackbar(`Successfully declined`, {
        variant: "success",
        autoHideDuration: 2000,
      });
      getConversations();
      getRequests();
      getContacts();
    }
  }, [declineRequestResults]);

  useEffect(() => {
    if (getContactsResults?.isSuccess) {
      setContacts(getContactsResults?.data?.contacts);
    }
  }, [getContactsResults]);

  useEffect(() => {
    if (getContactsResults?.isSuccess) {
      setContacts(getContactsResults?.data?.contacts);
    }
  }, [getContactsResults]);

  useEffect(() => {
    if (getConversationsResults?.isSuccess) {
      setConversations(getConversationsResults?.data?.conversations);
    }
  }, [getConversationsResults]);

  useEffect(() => {
    if (getConversationResults?.isSuccess) {
      setConversationDetails(getConversationResults.data);
    }
  }, [getConversationResults]);

  const handleAddContact = () => {
    addContact(value?.id);
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const openM = Boolean(anchorEl);
  const openMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const closeMenu = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    const socket = io(process.env.REACT_APP_SOCKET_URL);
    socket.on("message", (msg) => {
      if (
        msg?.conversationId === conversation &&
        conversationDetails?.conversation !== undefined &&
        getConversationResults.isLoading === false
      ) {
        const messages = conversationDetails.conversation.messages.slice();
        messages.push({
          id: msg?.id,
          content: msg?.content,
          file: msg?.file,
          seenAt: msg?.seenAt,
          sentAt: msg?.sentAt,
          senderId: msg?.senderId,
        });

        setConversationDetails({
          ...conversationDetails,
          conversation: {
            ...conversationDetails?.conversation,
            messages: messages,
          },
        });
      }
    });
    getConversations();
  }, []);

  useEffect(() => {
    if (conversation !== null) {
      getConversation(conversation);
    }
  }, [conversation]);

  const [message, setMessage] = useState("");
  const sendMessage = () => {
    const socket = io(process.env.REACT_APP_SOCKET_URL);
    const obj = {
      conversationId: conversation,
      senderId: conversationDetails?.myid,
      content: message,
      fileId: null,
      receiverId: conversationDetails?.contact?.id,
    };
    socket.emit("message", obj);

    setMessage("");
  };

  const [previewModalOpen, setPreviewModalOpen] = useState(false);
  const [fileToUpload, setFileToUpload] = useState(null);
  const handleOpenPreview = () => setPreviewModalOpen(true);
  const handleClosePreview = () => setPreviewModalOpen(false);

  const upload = () => {
    const newFile = new FormData();
    newFile.append("file", fileToUpload);
    uploadFile({ data: newFile });
  };

  useEffect(() => {
    if (uploadFileResults?.isSuccess) {
      const obj = {
        conversationId: conversation,
        senderId: conversationDetails?.myid,
        content: null,
        fileId: uploadFileResults?.data?.file?.id,
        receiverId: conversationDetails?.contact?.id,
      };
      const socket = io(process.env.REACT_APP_SOCKET_URL);
      socket.emit("message", obj);
      handleClosePreview();
    }
    if (uploadFileResults?.isError) {
      handleClosePreview();
      enqueueSnackbar(`Could not perform the operation at the moment`, {
        variant: "error",
        autoHideDuration: 2000,
      });
    }
  }, [uploadFileResults]);

  useEffect(() => {
    if (conversationDetails !== null) {
      const messsages = conversationDetails?.conversation?.messages?.filter(
        (message) =>
          message?.seenAt === undefined &&
          message?.senderId !== conversationDetails?.myid
      );
      if (messsages?.length > 0) {
        const socket = io(process.env.REACT_APP_SOCKET_URL);
        messsages?.forEach((message) => {
          socket.emit("seen", { id: message?.id });
        });
      }
    }
  }, [conversationDetails]);
  useEffect(() => {
    if (conversation === null) getConversations();
  }, [conversation]);

  Contacts.getPermissions();

  const [phoneContacts, setPhoneContacts] = useState([]);

  useEffect(() => {
    Contacts.getContacts().then((contacts) => {
      setPhoneContacts(contacts.contacts);
    });
    getRequests();
    getContacts();
  }, []);

  const [compare, compareResults] = useCompareContactsMutation();
  const [contactsInMtd, setContactsInMtd] = useState([]);
  useEffect(() => {
    let phones = [];
    let emails = [];
    phoneContacts?.forEach((contact) => {
      contact?.emails?.forEach(
        (email) => (emails = [...emails, email?.address])
      );
      contact?.phoneNumbers?.forEach(
        (phone) => (phones = [...phones, phone?.number?.replace(/\D/g, "")])
      );
    });
    compare({ emails: emails, phones: phones });
  }, [phoneContacts]);

  useEffect(() => {
    if (compareResults?.isSuccess) {
      console.log(compareResults.data?.users);
      setContactsInMtd(compareResults.data?.users);
    }
  }, [compareResults]);

  const svg = `<svg xmlns="http://www.w3.org/2000/svg"
                              class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium MuiAvatar-fallback css-10mi8st-MuiSvgIcon-root-MuiAvatar-fallback"
                              focusable="false"
                              aria-hidden="true"
                              viewBox="0 0 24 24"
                              data-testid="PersonIcon"
                              fill="white"
                            >
                              <path d="M12 12c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm0 2c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4z"></path>
                            </svg>`;
  const blob = new Blob([svg], { type: "image/svg+xml" });
  const url = URL.createObjectURL(blob);

  const getColorBasedOnRole = (role) => {
    if (role === "customer" || role === 10) {
      return "rgba(255, 0, 0, 1)";
    }
    if (role === "restaurant" || role === 20 || role === "owner") {
      return "rgba(12, 118, 208, 1)";
    }
    if (role === "chef" || role === 30) {
      return "rgba(1, 175, 14, 1)";
    }
    if (role === "business" || role === 40) {
      return "#E6E077";
    }
  };
  return (
    <>
      {/* DESKTOP */}
      <ThemeProvider theme={theme}>
        <Grid
          container
          sx={{
            display: { xs: "none", md: "flex", overflowY: "hidden" },
            mt: { sm: "80px" },
          }}
        >
          {/* Float Icon add contact */}
          {!conversation && (
            <Box style={{ position: "fixed", right: 16, bottom: 16 }}>
              <IconButton
                sx={{ color: "rgba(255, 0, 0, 1)" }}
                onClick={() => handleOpen()}
              >
                <PersonAddAltIcon
                  fontSize="large"
                  sx={{
                    width: { xs: 28, sm: 56 },
                    height: { xs: 28, sm: 56 },
                  }}
                />
              </IconButton>
            </Box>
          )}

          <Grid item xs={4} sx={{ opacity: openMenuHandle ? 0.5 : 1 }}>
            {/* Menu */}
            <Grid
              xs={12}
              container
              sx={{ backgroundColor: "#1396FF", py: 2, marginTop: "" }}
              alignItems="center"
            >
              <Grid item xs={2}>
                <IconButton
                  onClick={() => {
                    navigate("/");
                  }}
                >
                  <ArrowBackIos
                    sx={{
                      color: "#fff",
                      height: { xs: 32, md: 24 },
                      width: { xs: 32, md: 24 },
                    }}
                  />
                </IconButton>
              </Grid>
              <Grid item xs={4}>
                <Typography
                  fontSize="1.3rem"
                  fontWeight="bold"
                  fontFamily="Cooper"
                  letterSpacing="0.0625rem"
                  sx={{ color: "#fff" }}
                >
                  My Travel Chat
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <IconButton
                  onClick={() => {
                    setOpenMenuHandle(true);
                  }}
                >
                  <MoreHoriz
                    sx={{
                      color: "#fff",
                      height: { xs: 32, md: 24 },
                      width: { xs: 32, md: 24 },
                    }}
                  />
                </IconButton>
              </Grid>
              <Grid item xs={2}>
                <IconButton
                  onClick={() => {
                    navigate("/contacts");
                  }}
                >
                  <GroupsIcon
                    sx={{
                      color: "#fff",
                      height: { xs: 32, md: 24 },
                      width: { xs: 32, md: 24 },
                    }}
                  />
                </IconButton>
              </Grid>
              <Grid item xs={2}>
                <IconButton
                  onClick={() => {
                    navigate("/contacts/search");
                  }}
                >
                  <SearchIcon
                    sx={{
                      color: "#fff",
                      height: { xs: 32, md: 24 },
                      width: { xs: 32, md: 24 },
                    }}
                  />
                </IconButton>
              </Grid>
            </Grid>
            <Grid item xs={12} sx={{ overflowY: "scroll", maxHeight: "90vh" }}>
              <List sx={{ p: 0 }}>
                {!chatsViewOnly &&
                  !archivedViewOnly &&
                  requests?.map((request, idx) => {
                    return (
                      <ListItem
                        key={idx}
                        sx={{ backgroundColor: "#303E37", py: 2 }}
                      >
                        <ListItemAvatar>
                          <Avatar
                            sx={{ width: 52, height: 52 }}
                            src={request?.user?.profile?.avatar?.url}
                            srcSet={request?.user?.profile?.avatar?.url}
                          >
                            {request?.user?.profile?.name?.charAt(0) ??
                              undefined}
                          </Avatar>
                        </ListItemAvatar>
                        <ListItemText sx={{ color: "#fff" }}>
                          <Grid
                            container
                            justifyContent="space-between"
                            alignItems="center"
                          >
                            <Grid item>
                              <Typography variant="h5">
                                {`${request?.user?.profile?.name ?? ""} ${
                                  request?.user?.profile?.middleName ?? ""
                                } ${request?.user?.profile?.lastName ?? ""}`}
                              </Typography>
                            </Grid>
                            <Grid item>
                              <Typography variant="body2" noWrap>{`${
                                request?.createdAt
                                  ? new Date(
                                      request?.createdAt
                                    )?.toLocaleString()
                                  : new Date().toLocaleString()
                              }`}</Typography>
                            </Grid>
                          </Grid>
                          {request?.user?.address?.city && (
                            <Typography variant="h6">
                              {`${request?.user?.address?.city ?? ""}, ${
                                request?.user?.address?.country ?? ""
                              }`}
                            </Typography>
                          )}
                          <Grid
                            container
                            justifyContent="center"
                            alignItems="center"
                            sx={{ mt: 1 }}
                          >
                            <Grid item xs={4}>
                              <Typography>Request Chat</Typography>
                            </Grid>
                            <Grid item xs={4}>
                              <Button
                                sx={{
                                  backgroundColor: "#fff",
                                  color: "#1396FF",
                                }}
                                variant="contained"
                                onClick={() => {
                                  acceptRequest(request?.id);
                                }}
                              >
                                Accept
                              </Button>
                            </Grid>
                            <Grid item xs={4}>
                              <Button
                                sx={{
                                  backgroundColor: "#fff",
                                  color: "#1396FF",
                                }}
                                variant="contained"
                                onClick={() => {
                                  declineRequest(request?.id);
                                }}
                              >
                                Decline
                              </Button>
                            </Grid>
                          </Grid>
                        </ListItemText>
                      </ListItem>
                    );
                  })}
              </List>
              {(chatsViewOnly || !archivedViewOnly) &&
                conversations
                  ?.filter((item) => item?.status === "active")
                  ?.map((conversation, idx) => {
                    if (!chatRequestsViewOnly)
                      return (
                        <Grid
                          item
                          xs={12}
                          key={idx}
                          sx={{
                            borderBottom: "1px solid #D0D0D0",
                            py: 2,
                            px: 2,
                          }}
                          container
                        >
                          <Grid
                            item
                            xs={12}
                            container
                            justifyContent="flex-end"
                          >
                            <Typography variant="caption">
                              {conversation?.messages?.[0]?.sentAt
                                ? new Date(
                                    conversation?.messages?.[0]?.sentAt
                                  ).toLocaleDateString()
                                : ""}
                            </Typography>
                          </Grid>
                          <Grid item xs={2}>
                            <Avatar
                              src={conversation?.contact?.profile?.avatar?.url}
                              sx={{
                                borderColor: getColorBasedOnRole(
                                  conversation?.contact?.role?.name
                                ),
                                borderStyle: "solid",
                                borderWidth: { xs: "5px", sm: "5px" },
                                width: 64,
                                height: 64,
                              }}
                              onClick={() => {
                                setDetails({
                                  contactId: conversation?.contact?.id,
                                  restaurantId:
                                    conversation?.contact?.restaurant?.[0]?.id,
                                  image:
                                    conversation?.contact?.profile?.avatar?.url,
                                  profile: conversation?.contact?.profile,
                                  address: conversation?.contact?.address,
                                  status: conversation?.status,
                                  id: conversation?.id,
                                  role: conversation?.contact?.role,
                                  restaurant:
                                    conversation?.contact?.restaurant?.[0]
                                      ?.name,
                                });
                                setOpenCard(true);
                              }}
                            />
                          </Grid>
                          <Grid
                            item
                            xs={9}
                            onClick={() => {
                              setConversation(conversation?.id);
                            }}
                          >
                            <Typography variant="h6" noWrap>
                              {conversation?.contact?.restaurant?.length > 0
                                ? `${conversation?.contact?.restaurant[0].name}`
                                : `${
                                    conversation?.contact?.profile?.name ?? ""
                                  } ${
                                    conversation?.contact?.profile
                                      ?.middleName ?? ""
                                  } ${
                                    conversation?.contact?.profile?.lastName ??
                                    ""
                                  }`}
                            </Typography>
                            <Typography variant="body2">
                              {conversation?.contact?.restaurant?.length > 0
                                ? `${conversation?.contact?.restaurant[0].address?.city}, ${conversation?.contact?.restaurant[0].address?.country}`
                                : `${
                                    conversation?.contact?.address?.city
                                      ? conversation?.contact?.address?.city +
                                        ","
                                      : ""
                                  } ${
                                    conversation?.contact?.address?.country ??
                                    ""
                                  }`}
                            </Typography>
                          </Grid>
                          <Grid item xs={1} container alignItems="center">
                            {conversation.unread !== 0 && (
                              <Grid item xs>
                                <Box
                                  sx={{
                                    borderRadius: "50%",
                                    backgroundColor: "#ff0000",
                                    width: 32,
                                    height: 32,
                                    position: "relative",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                  }}
                                >
                                  <Typography
                                    sx={{
                                      color: "#fff",
                                    }}
                                  >
                                    {conversation?.unread}
                                  </Typography>
                                </Box>
                              </Grid>
                            )}
                          </Grid>
                        </Grid>
                      );
                  })}
              {archivedViewOnly &&
                conversations
                  ?.filter((item) => item?.status === "archived")
                  ?.map((conversation, idx) => {
                    return (
                      <Grid
                        item
                        xs={12}
                        key={idx}
                        sx={{
                          borderBottom: "1px solid #525252",
                          py: 2,
                          px: 2,
                        }}
                        container
                      >
                        <Grid item xs={2}>
                          <Avatar
                            src={conversation?.contact?.profile?.avatar?.url}
                            sx={{
                              borderColor: getColorBasedOnRole(
                                conversation?.contact?.role?.name
                              ),
                              borderStyle: "solid",
                              borderWidth: { xs: "5px", sm: "5px" },
                              width: 64,
                              height: 64,
                            }}
                            onClick={() => {
                              setDetails({
                                image:
                                  conversation?.contact?.profile?.avatar?.url,
                                profile: conversation?.contact?.profile,
                                address: conversation?.contact?.address,
                                status: conversation?.status,
                                id: conversation?.id,
                              });
                              setOpenCard(true);
                            }}
                          />
                        </Grid>
                        <Grid
                          item
                          xs={8}
                          onClick={() => {
                            setConversation(conversation?.id);
                          }}
                        >
                          <Typography>
                            {conversation?.contact?.restaurant?.length > 0
                              ? `${conversation?.contact?.restaurant[0].name}`
                              : `${
                                  conversation?.contact?.profile?.name ?? ""
                                } ${
                                  conversation?.contact?.profile?.middleName ??
                                  ""
                                } ${
                                  conversation?.contact?.profile?.lastName ?? ""
                                }`}
                          </Typography>
                          <Typography>
                            {conversation?.contact?.restaurant?.length > 0
                              ? `${conversation?.contact?.restaurant[0].address?.city}, ${conversation?.contact?.restaurant[0].address?.country}`
                              : `${
                                  conversation?.contact?.address?.city ?? ""
                                } ${
                                  conversation?.contact?.address?.country ?? ""
                                }`}
                          </Typography>
                        </Grid>
                        <Grid item xs={2} container alignItems="center">
                          <Grid item xs>
                            <Typography>
                              {conversation?.messages?.[0]?.sentAt
                                ? new Date(
                                    conversation?.messages?.[0]?.sentAt
                                  ).toLocaleString()
                                : ""}
                            </Typography>
                          </Grid>
                          {conversation.unread !== 0 && (
                            <Grid item xs>
                              <Box
                                sx={{
                                  borderRadius: "50%",
                                  backgroundColor: "#ff0000",
                                  width: 32,
                                  height: 32,
                                  position: "relative",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                              >
                                <Typography
                                  sx={{
                                    color: "#fff",
                                  }}
                                >
                                  {conversation?.unread}
                                </Typography>
                              </Box>
                            </Grid>
                          )}
                        </Grid>
                      </Grid>
                    );
                  })}
            </Grid>
          </Grid>

          {conversation && (
            <Grid
              item
              xs={8}
              sx={{ maxWidth: "66vw", maxHeight: "90vh", overflowY: "scroll" }}
            >
              <ChatConversation
                conversationDetails={conversationDetails}
                setConversationDetails={setConversationDetails}
                message={message}
                setMessage={setMessage}
                handleOpenPreview={handleOpenPreview}
                setFileToUpload={setFileToUpload}
                sendMessage={sendMessage}
                setConversation={setConversation}
                conversation={conversation}
              />
            </Grid>
          )}

          {activeTab === "contacts" && (
            <Grid item xs={12}>
              <List dense={false}>
                {contacts?.map((contact, idx) => {
                  return (
                    <ListItem
                      key={idx}
                      secondaryAction={
                        <Box>
                          {contact?.status === "friends" && (
                            <IconButton
                              edge="end"
                              onClick={() => {
                                setConversation(contact?.conversationId);
                                setActiveTab("mychats");
                              }}
                              sx={{ marginRight: "5px" }}
                            >
                              <ChatIcon />
                            </IconButton>
                          )}
                          <IconButton
                            aria-label="more"
                            aria-controls={openM ? "long-menu" : undefined}
                            aria-expanded={openM ? "true" : undefined}
                            aria-haspopup="true"
                            onClick={openMenu}
                          >
                            <MoreVertIcon />
                          </IconButton>
                          <Menu
                            MenuListProps={{
                              "aria-labelledby": "long-button",
                            }}
                            anchorEl={anchorEl}
                            open={openM}
                            onClose={closeMenu}
                          >
                            <MenuItem
                              onClick={() =>
                                contact?.status === "friends"
                                  ? blockContact(contact?.user?.id)
                                  : unBlockContact(contact?.user?.id)
                              }
                            >
                              {contact?.status === "friends"
                                ? t("block")
                                : t("unBlock")}
                            </MenuItem>
                            <MenuItem
                              onClick={() => removeContact(contact?.user?.id)}
                            >
                              {t("remove")}
                            </MenuItem>
                          </Menu>
                        </Box>
                      }
                    >
                      <ListItemAvatar>
                        <Avatar
                          src={contact?.user?.profile?.avatar?.url}
                          srcSet={contact?.user?.profile?.avatar?.url}
                        >
                          {contact?.profile?.name?.charAt(0) ?? undefined}
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText>
                        <Grid container direction="row" alignItems="center">
                          <Typography>
                            {contact?.user?.restaurant?.length > 0
                              ? `${contact?.user?.restaurant[0].name}`
                              : `${contact?.user?.profile?.name ?? ""} ${
                                  contact?.user?.profile?.middleName ?? ""
                                } ${contact?.user?.profile?.lastName ?? ""}`}
                          </Typography>
                          <Typography
                            color={
                              contact?.status === "friends"
                                ? "green"
                                : "#ff0000"
                            }
                            sx={{ textTransform: "capitalize", pl: 4 }}
                          >
                            {contact?.status === "friends"
                              ? t("friends")
                              : t("blocked")}
                          </Typography>
                        </Grid>
                      </ListItemText>
                    </ListItem>
                  );
                })}
              </List>
            </Grid>
          )}

          <Modal
            keepMounted
            open={openMenuHandle}
            onClose={() => setOpenMenuHandle(false)}
          >
            <Box>
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                spacing={2}
                sx={{ minWidth: "100%" }}
              >
                <Grid item xs={12}>
                  <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    sx={{
                      width: "100vw",
                      height: "40vh",
                      position: "fixed",
                      backgroundColor: "#26282f",
                      bottom: 0,
                    }}
                  >
                    <Grid
                      item
                      xs={12}
                      container
                      justifyContent="center"
                      alignItems="center"
                      sx={{ border: 1, borderColor: "gray" }}
                    >
                      <Typography variant="h5" sx={{ color: "#fff" }}>
                        All Chats
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      container
                      alignItems="center"
                      justifyContent="center"
                      sx={{ border: 1, borderColor: "gray" }}
                    >
                      <Grid item xs={4} container justifyContent="center">
                        <ArchiveIcon sx={{ color: "#59addf" }} />
                      </Grid>
                      <Grid item xs={8}>
                        <Button
                          onClick={() => setArchivedViewOnly(!archivedViewOnly)}
                          sx={{ textTransform: "capitalize" }}
                        >
                          <Typography variant="h5" color="white">
                            Archived
                          </Typography>
                        </Button>
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      container
                      alignItems="center"
                      justifyContent="space-around"
                      sx={{ border: 1, borderColor: "gray" }}
                    >
                      <Grid item xs={4} container justifyContent="center">
                        <AnnouncementIcon sx={{ color: "#59addf" }} />
                      </Grid>
                      <Grid item xs={8}>
                        <Button
                          onClick={() => {
                            setChatRequestViewOnly(!chatRequestsViewOnly);
                          }}
                          sx={{ textTransform: "capitalize" }}
                        >
                          <Typography variant="h5" color="white">
                            View Chat Requests
                          </Typography>
                        </Button>
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      container
                      alignItems="center"
                      justifyContent="space-around"
                      sx={{ border: 1, borderColor: "gray" }}
                    >
                      <Grid item xs={4} container justifyContent="center">
                        <ForumIcon sx={{ color: "#59addf" }} />
                      </Grid>
                      <Grid item xs={8}>
                        <Button
                          onClick={() => {
                            setChatsViewOnly(!chatsViewOnly);
                          }}
                          sx={{ textTransform: "capitalize" }}
                        >
                          <Typography variant="h5" color="white">
                            View Chats
                          </Typography>
                        </Button>
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      container
                      alignItems="center"
                      justifyContent="space-around"
                      sx={{ border: 1, borderColor: "gray" }}
                    >
                      <Grid item xs={4} container justifyContent="center">
                        <ForumIcon sx={{ color: "#59addf" }} />
                      </Grid>
                      <Grid item xs={8}>
                        <Button
                          onClick={() => {
                            setChatsViewOnly(false);
                            setArchivedViewOnly(false);
                            setChatRequestViewOnly(false);
                          }}
                          sx={{ textTransform: "capitalize" }}
                        >
                          <Typography variant="h5" color="white">
                            View Reset
                          </Typography>
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </Modal>

          <Modal keepMounted open={open} onClose={handleClose}>
            <Box sx={style}>
              <Grid
                container
                justifyContent="flex-end"
                mb={1}
                sx={{ width: "100%" }}
              >
                <IconButton
                  sx={{ color: "rgba(255, 0, 0, 1)" }}
                  onClick={() => handleClose()}
                >
                  <ClearIcon
                    sx={{
                      width: { xs: 14, sm: 28 },
                      height: { xs: 14, sm: 28 },
                    }}
                  />
                </IconButton>
              </Grid>
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                spacing={2}
                sx={{ minWidth: "100%" }}
              >
                <Grid item xs={12}>
                  <Autocomplete
                    value={value}
                    onChange={handleAutoCompleteChange}
                    inputValue={inputValue}
                    onInputChange={handleAutoCompleteInputChange}
                    options={options}
                    fullWidth
                    getOptionLabel={(option) =>
                      `${option?.profile?.name ?? ""} ${
                        option?.profile?.middleName ?? ""
                      } ${option?.profile?.lastName ?? ""}`
                    }
                    renderOption={(props, option) => {
                      return (
                        <Box
                          component="li"
                          sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                          {...props}
                        >
                          <Avatar
                            src={option?.profile?.avatar?.url}
                            srcSet={option?.profile?.avatar?.url}
                          >
                            {option?.profile?.name
                              ? option?.profile?.name?.charAt(0)
                              : undefined}
                          </Avatar>

                          <Typography sx={{ ml: 2 }}>
                            {`${option?.profile?.name ?? ""} ${
                              option?.profile?.middleName ?? ""
                            } ${option?.profile?.lastName ?? ""}`}
                          </Typography>
                        </Box>
                      );
                    }}
                    renderInput={(params) => (
                      <TextField {...params} label="Search user" />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Button onClick={handleAddContact}>Add contact</Button>
                </Grid>
                {contactsInMtd?.length > 0 && (
                  <>
                    <Grid item xs={12}>
                      <Typography>
                        Contacts already on MyTravelDinner
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <List dense={false}>
                        {contactsInMtd.map((contact, idx) => {
                          return (
                            <ListItem
                              key={idx}
                              secondaryAction={
                                <Box>
                                  <IconButton
                                    edge="end"
                                    onClick={() => {
                                      addContact(contact?.id);
                                    }}
                                  >
                                    <PersonAddAltIcon
                                      color="red"
                                      fontSize="large"
                                    />
                                  </IconButton>
                                </Box>
                              }
                            >
                              <ListItemAvatar>
                                <Avatar
                                  src={contact?.profile?.avatar?.url}
                                  srcSet={contact?.profile?.avatar?.url}
                                >
                                  {contact?.profile?.name?.charAt(0) ??
                                    undefined}
                                </Avatar>
                              </ListItemAvatar>
                              <ListItemText>
                                {`${contact?.profile?.name ?? ""} ${
                                  contact?.profile?.middleName ?? ""
                                } ${contact?.profile?.lastName ?? ""}`}
                              </ListItemText>
                            </ListItem>
                          );
                        })}
                      </List>
                    </Grid>
                  </>
                )}
              </Grid>
            </Box>
          </Modal>
          <Modal
            open={previewModalOpen}
            onClose={handleClosePreview}
            sx={{ zIndex: 4000 }}
          >
            <Box
              sx={{ width: "100vw", height: "100vh", backgroundColor: "black" }}
            >
              <Grid
                container
                justifyContent="center"
                alignItems="center"
                py={4}
              >
                <Grid
                  item
                  xs={12}
                  container
                  justifyContent="flex-end"
                  alignItems="center"
                >
                  <Grid item>
                    <IconButton
                      sx={{ color: "white" }}
                      onClick={() => handleClosePreview()}
                    >
                      <ClearIcon />
                    </IconButton>
                  </Grid>
                </Grid>
                <Grid
                  item
                  xs={12}
                  container
                  justifyContent="center"
                  alignItems="center"
                >
                  <Grid item>
                    {fileToUpload?.type?.includes("image") && (
                      <img
                        src={URL.createObjectURL(fileToUpload)}
                        alt="a"
                        style={{ maxHeight: "80vh" }}
                      />
                    )}
                    {fileToUpload?.type?.includes("video") && (
                      <video
                        controls
                        autoPlay
                        src={URL.createObjectURL(fileToUpload)}
                        alt="a"
                      />
                    )}
                  </Grid>
                </Grid>
                <Grid
                  item
                  xs={6}
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                >
                  <IconButton sx={{ color: "white" }} onClick={() => upload()}>
                    <SendIcon />
                  </IconButton>
                </Grid>
              </Grid>
              {uploadFileResults.isLoading && (
                <Box
                  sx={{
                    display: "flex",
                    position: "fixed",
                    top: "50%",
                    left: "47%",
                  }}
                >
                  <CircularProgress size={100} />
                </Box>
              )}
            </Box>
          </Modal>
          <Modal
            open={openCard}
            onClose={() => {
              setOpenCard(false);
              getConversations();
            }}
          >
            <Chefcard
              contactId={details?.contactId}
              restaurantId={details?.restaurantId}
              image={details?.image}
              profile={details?.profile}
              address={details?.address}
              status={details?.status}
              id={details?.id}
              role={details?.role}
              setOpen={setOpenCard}
              restaurant={details?.restaurant}
            />
          </Modal>
        </Grid>
      </ThemeProvider>

      {/* Mobile */}
      <ThemeProvider theme={theme}>
        <Grid container sx={{ display: { md: "none" } }}>
          {!conversation && (
            <Grid
              xs={12}
              container
              sx={{ backgroundColor: "#1396FF", height: { xs: "10vh" } }}
              alignItems="center"
            >
              <Grid item xs={2}>
                <IconButton
                  onClick={() => {
                    navigate("/");
                  }}
                >
                  <ArrowBackIos
                    sx={{
                      color: "#fff",
                      height: { xs: 32, md: 24 },
                      width: { xs: 32, md: 24 },
                    }}
                  />
                </IconButton>
              </Grid>
              <Grid item xs={5}>
                <Typography
                  sx={{ color: "#fff" }}
                  fontSize="1.3rem"
                  fontWeight="bold"
                  fontFamily="Cooper"
                  letterSpacing="0.0625rem"
                >
                  My Travel Chat
                </Typography>
              </Grid>
              <Grid item xs={1.5}>
                <IconButton
                  onClick={() => {
                    setOpenMenuHandle(true);
                  }}
                >
                  <MoreHoriz
                    sx={{
                      color: "#fff",
                      height: { xs: 32, md: 24 },
                      width: { xs: 32, md: 24 },
                    }}
                  />
                </IconButton>
              </Grid>
              <Grid item xs={1.5}>
                <IconButton
                  onClick={() => {
                    navigate("/contacts");
                  }}
                >
                  <GroupsIcon
                    sx={{
                      color: "#fff",
                      height: { xs: 32, md: 24 },
                      width: { xs: 32, md: 24 },
                    }}
                  />
                </IconButton>
              </Grid>
              <Grid item xs={1.5}>
                <IconButton
                  onClick={() => {
                    navigate("/contacts/search");
                  }}
                >
                  <SearchIcon
                    sx={{
                      color: "#fff",
                      height: { xs: 32, md: 24 },
                      width: { xs: 32, md: 24 },
                    }}
                  />
                </IconButton>
              </Grid>
            </Grid>
          )}
          {!conversation && (
            <Box style={{ position: "fixed", right: 16, bottom: 16 }}>
              <IconButton
                sx={{ color: "rgba(255, 0, 0, 1)" }}
                onClick={() => handleOpen()}
              >
                <PersonAddAltIcon
                  fontSize="large"
                  sx={{
                    width: { xs: 28, sm: 56 },
                    height: { xs: 28, sm: 56 },
                  }}
                />
              </IconButton>
            </Box>
          )}

          {conversation && (
            <ChatConversation
              conversationDetails={conversationDetails}
              message={message}
              setMessage={setMessage}
              handleOpenPreview={handleOpenPreview}
              setFileToUpload={setFileToUpload}
              sendMessage={sendMessage}
              setConversation={setConversation}
            />
          )}

          {!conversation && (
            <Grid item xs={12} sx={{ opacity: openMenuHandle ? 0.5 : 1 }}>
              <List sx={{ p: 0 }}>
                {!chatsViewOnly &&
                  !archivedViewOnly &&
                  requests?.map((request, idx) => {
                    return (
                      <ListItem
                        key={idx}
                        sx={{ backgroundColor: "#303E37", py: 2 }}
                      >
                        <ListItemAvatar>
                          <Avatar
                            sx={{ width: 52, height: 52 }}
                            src={request?.user?.profile?.avatar?.url}
                            srcSet={request?.user?.profile?.avatar?.url}
                          >
                            {request?.user?.profile?.name?.charAt(0) ??
                              undefined}
                          </Avatar>
                        </ListItemAvatar>
                        <ListItemText sx={{ color: "#fff" }}>
                          <Grid
                            container
                            justifyContent="space-between"
                            alignItems="center"
                          >
                            <Grid item>
                              <Typography variant="h5">
                                {`${request?.user?.profile?.name ?? ""} ${
                                  request?.user?.profile?.middleName ?? ""
                                } ${request?.user?.profile?.lastName ?? ""}`}
                              </Typography>
                            </Grid>
                            <Grid item>
                              <Typography variant="body2" noWrap>{`${
                                request?.createdAt
                                  ? new Date(
                                      request?.createdAt
                                    )?.toLocaleString()
                                  : new Date().toLocaleString()
                              }`}</Typography>
                            </Grid>
                          </Grid>
                          {request?.user?.address?.city && (
                            <Typography variant="h6">
                              {`${request?.user?.address?.city ?? ""}, ${
                                request?.user?.address?.country ?? ""
                              }`}
                            </Typography>
                          )}
                          <Grid
                            container
                            justifyContent="center"
                            alignItems="center"
                            sx={{ mt: 1 }}
                          >
                            <Grid item xs={4}>
                              <Typography>Request Chat</Typography>
                            </Grid>
                            <Grid item xs={4}>
                              <Button
                                sx={{
                                  backgroundColor: "#fff",
                                  color: "#1396FF",
                                }}
                                variant="contained"
                                onClick={() => {
                                  acceptRequest(request?.id);
                                }}
                              >
                                Accept
                              </Button>
                            </Grid>
                            <Grid item xs={4}>
                              <Button
                                sx={{
                                  backgroundColor: "#fff",
                                  color: "#1396FF",
                                }}
                                variant="contained"
                                onClick={() => {
                                  declineRequest(request?.id);
                                }}
                              >
                                Decline
                              </Button>
                            </Grid>
                          </Grid>
                        </ListItemText>
                      </ListItem>
                    );
                  })}
              </List>
              {(chatsViewOnly || !archivedViewOnly) &&
                conversations
                  ?.filter((item) => item?.status === "active")
                  ?.map((conversation, idx) => {
                    if (!chatRequestsViewOnly)
                      return (
                        <Grid
                          item
                          xs={12}
                          key={idx}
                          sx={{
                            borderBottom: "1px solid #D0D0D0",
                            py: 2,
                            px: 2,
                          }}
                          container
                        >
                          <Grid
                            item
                            xs={12}
                            container
                            justifyContent="flex-end"
                          >
                            <Typography variant="caption">
                              {conversation?.messages?.[0]?.sentAt
                                ? new Date(
                                    conversation?.messages?.[0]?.sentAt
                                  ).toLocaleDateString()
                                : ""}
                            </Typography>
                          </Grid>
                          <Grid item xs={2}>
                            <Avatar
                              src={conversation?.contact?.profile?.avatar?.url}
                              sx={{
                                borderColor: getColorBasedOnRole(
                                  conversation?.contact?.role?.name
                                ),
                                borderStyle: "solid",
                                borderWidth: { xs: "5px", sm: "5px" },
                                width: 64,
                                height: 64,
                              }}
                              onClick={() => {
                                setDetails({
                                  contactId: conversation?.contact?.id,
                                  restaurantId:
                                    conversation?.contact?.restaurant?.[0]?.id,
                                  image:
                                    conversation?.contact?.profile?.avatar?.url,
                                  profile: conversation?.contact?.profile,
                                  address: conversation?.contact?.address,
                                  status: conversation?.status,
                                  id: conversation?.id,
                                  role: conversation?.contact?.role,
                                  restaurant:
                                    conversation?.contact?.restaurant?.[0]
                                      ?.name,
                                });
                                setOpenCard(true);
                              }}
                            />
                          </Grid>
                          <Grid
                            item
                            xs={9}
                            onClick={() => {
                              setConversation(conversation?.id);
                            }}
                          >
                            <Typography variant="h6" noWrap>
                              {conversation?.contact?.restaurant?.length > 0
                                ? `${conversation?.contact?.restaurant[0].name}`
                                : `${
                                    conversation?.contact?.profile?.name ?? ""
                                  } ${
                                    conversation?.contact?.profile
                                      ?.middleName ?? ""
                                  } ${
                                    conversation?.contact?.profile?.lastName ??
                                    ""
                                  }`}
                            </Typography>
                            <Typography variant="body2">
                              {conversation?.contact?.restaurant?.length > 0
                                ? `${conversation?.contact?.restaurant[0].address?.city}, ${conversation?.contact?.restaurant[0].address?.country}`
                                : `${
                                    conversation?.contact?.address?.city ?? ""
                                  } ${
                                    conversation?.contact?.address?.country ??
                                    ""
                                  }`}
                            </Typography>
                          </Grid>
                          <Grid item xs={1} container alignItems="center">
                            {conversation.unread !== 0 && (
                              <Grid item xs>
                                <Box
                                  sx={{
                                    borderRadius: "50%",
                                    backgroundColor: "#ff0000",
                                    width: 32,
                                    height: 32,
                                    position: "relative",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                  }}
                                >
                                  <Typography
                                    sx={{
                                      color: "#fff",
                                    }}
                                  >
                                    {conversation?.unread}
                                  </Typography>
                                </Box>
                              </Grid>
                            )}
                          </Grid>
                        </Grid>
                      );
                  })}
              {archivedViewOnly &&
                conversations
                  ?.filter((item) => item?.status === "archived")
                  ?.map((conversation, idx) => {
                    return (
                      <Grid
                        item
                        xs={12}
                        key={idx}
                        sx={{
                          borderBottom: "1px solid #525252",
                          py: 2,
                          px: 2,
                        }}
                        container
                      >
                        <Grid item xs={2}>
                          <Avatar
                            src={conversation?.contact?.profile?.avatar?.url}
                            sx={{
                              borderColor: getColorBasedOnRole(
                                conversation?.contact?.role?.name
                              ),
                              borderStyle: "solid",
                              borderWidth: { xs: "5px", sm: "5px" },
                              width: 64,
                              height: 64,
                            }}
                            onClick={() => {
                              setDetails({
                                image:
                                  conversation?.contact?.profile?.avatar?.url,
                                profile: conversation?.contact?.profile,
                                address: conversation?.contact?.address,
                                status: conversation?.status,
                                id: conversation?.id,
                              });
                              setOpenCard(true);
                            }}
                          />
                        </Grid>
                        <Grid
                          item
                          xs={8}
                          onClick={() => {
                            setConversation(conversation?.id);
                          }}
                        >
                          <Typography>
                            {conversation?.contact?.restaurant?.length > 0
                              ? `${conversation?.contact?.restaurant[0].name}`
                              : `${
                                  conversation?.contact?.profile?.name ?? ""
                                } ${
                                  conversation?.contact?.profile?.middleName ??
                                  ""
                                } ${
                                  conversation?.contact?.profile?.lastName ?? ""
                                }`}
                          </Typography>
                          <Typography>
                            {conversation?.contact?.restaurant?.length > 0
                              ? `${conversation?.contact?.restaurant[0].address?.city}, ${conversation?.contact?.restaurant[0].address?.country}`
                              : `${
                                  conversation?.contact?.address?.city ?? ""
                                } ${
                                  conversation?.contact?.address?.country ?? ""
                                }`}
                          </Typography>
                        </Grid>
                        <Grid item xs={2} container alignItems="center">
                          <Grid item xs>
                            {/**
                             * Falta formatear la fecha
                             */}
                            <Typography>
                              {conversation?.messages?.[0]?.sentAt
                                ? new Date(
                                    conversation?.messages?.[0]?.sentAt
                                  ).toLocaleString()
                                : ""}
                            </Typography>
                          </Grid>
                          {conversation.unread !== 0 && (
                            <Grid item xs>
                              <Box
                                sx={{
                                  borderRadius: "50%",
                                  backgroundColor: "#ff0000",
                                  width: 32,
                                  height: 32,
                                  position: "relative",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                              >
                                <Typography
                                  sx={{
                                    color: "#fff",
                                  }}
                                >
                                  {conversation?.unread}
                                </Typography>
                              </Box>
                            </Grid>
                          )}
                        </Grid>
                      </Grid>
                    );
                  })}
            </Grid>
          )}

          {activeTab === "contacts" && (
            <Grid item xs={12}>
              <List dense={false}>
                {contacts?.map((contact, idx) => {
                  return (
                    <ListItem
                      key={idx}
                      secondaryAction={
                        <Box>
                          {contact?.status === "friends" && (
                            <IconButton
                              edge="end"
                              onClick={() => {
                                setConversation(contact?.conversationId);
                                setActiveTab("mychats");
                              }}
                              sx={{ marginRight: "5px" }}
                            >
                              <ChatIcon />
                            </IconButton>
                          )}
                          <IconButton
                            aria-label="more"
                            aria-controls={openM ? "long-menu" : undefined}
                            aria-expanded={openM ? "true" : undefined}
                            aria-haspopup="true"
                            onClick={openMenu}
                          >
                            <MoreVertIcon />
                          </IconButton>
                          <Menu
                            MenuListProps={{
                              "aria-labelledby": "long-button",
                            }}
                            anchorEl={anchorEl}
                            open={openM}
                            onClose={closeMenu}
                          >
                            <MenuItem
                              onClick={() =>
                                contact?.status === "friends"
                                  ? blockContact(contact?.user?.id)
                                  : unBlockContact(contact?.user?.id)
                              }
                            >
                              {contact?.status === "friends"
                                ? t("block")
                                : t("unBlock")}
                            </MenuItem>
                            <MenuItem
                              onClick={() => removeContact(contact?.user?.id)}
                            >
                              {t("remove")}
                            </MenuItem>
                          </Menu>
                        </Box>
                      }
                    >
                      <ListItemAvatar>
                        <Avatar
                          src={contact?.user?.profile?.avatar?.url}
                          srcSet={contact?.user?.profile?.avatar?.url}
                        >
                          {contact?.profile?.name?.charAt(0) ?? undefined}
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText>
                        <Grid container direction="row" alignItems="center">
                          <Typography>
                            {contact?.user?.restaurant?.length > 0
                              ? `${contact?.user?.restaurant[0].name}`
                              : `${contact?.user?.profile?.name ?? ""} ${
                                  contact?.user?.profile?.middleName ?? ""
                                } ${contact?.user?.profile?.lastName ?? ""}`}
                          </Typography>
                          <Typography
                            color={
                              contact?.status === "friends"
                                ? "green"
                                : "#ff0000"
                            }
                            sx={{ textTransform: "capitalize", pl: 4 }}
                          >
                            {contact?.status === "friends"
                              ? t("friends")
                              : t("blocked")}
                          </Typography>
                        </Grid>
                      </ListItemText>
                    </ListItem>
                  );
                })}
              </List>
            </Grid>
          )}

          <Modal
            keepMounted
            open={openMenuHandle}
            onClose={() => setOpenMenuHandle(false)}
          >
            <Box>
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                spacing={2}
                sx={{ minWidth: "100%" }}
              >
                <Grid item xs={12}>
                  <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    sx={{
                      width: "100vw",
                      height: "40vh",
                      position: "fixed",
                      backgroundColor: "#26282f",
                      bottom: 0,
                    }}
                  >
                    <Grid
                      item
                      xs={12}
                      container
                      justifyContent="center"
                      alignItems="center"
                      sx={{ border: 1, borderColor: "gray" }}
                    >
                      <Typography variant="h5" sx={{ color: "#fff" }}>
                        All Chats
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      container
                      alignItems="center"
                      justifyContent="center"
                      sx={{ border: 1, borderColor: "gray" }}
                    >
                      <Grid item xs={4} container justifyContent="center">
                        <ArchiveIcon sx={{ color: "#59addf" }} />
                      </Grid>
                      <Grid item xs={8}>
                        <Button
                          onClick={() => setArchivedViewOnly(!archivedViewOnly)}
                          sx={{ textTransform: "capitalize" }}
                        >
                          <Typography variant="h5" color="white">
                            Archived
                          </Typography>
                        </Button>
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      container
                      alignItems="center"
                      justifyContent="space-around"
                      sx={{ border: 1, borderColor: "gray" }}
                    >
                      <Grid item xs={4} container justifyContent="center">
                        <AnnouncementIcon sx={{ color: "#59addf" }} />
                      </Grid>
                      <Grid item xs={8}>
                        <Button
                          onClick={() => {
                            setChatRequestViewOnly(!chatRequestsViewOnly);
                          }}
                          sx={{ textTransform: "capitalize" }}
                        >
                          <Typography variant="h5" color="white">
                            View Chat Requests
                          </Typography>
                        </Button>
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      container
                      alignItems="center"
                      justifyContent="space-around"
                      sx={{ border: 1, borderColor: "gray" }}
                    >
                      <Grid item xs={4} container justifyContent="center">
                        <ForumIcon sx={{ color: "#59addf" }} />
                      </Grid>
                      <Grid item xs={8}>
                        <Button
                          onClick={() => {
                            setChatsViewOnly(!chatsViewOnly);
                          }}
                          sx={{ textTransform: "capitalize" }}
                        >
                          <Typography variant="h5" color="white">
                            View Chats
                          </Typography>
                        </Button>
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      container
                      alignItems="center"
                      justifyContent="space-around"
                      sx={{ border: 1, borderColor: "gray" }}
                    >
                      <Grid item xs={4} container justifyContent="center">
                        <ForumIcon sx={{ color: "#59addf" }} />
                      </Grid>
                      <Grid item xs={8}>
                        <Button
                          onClick={() => {
                            setChatsViewOnly(false);
                            setArchivedViewOnly(false);
                            setChatRequestViewOnly(false);
                          }}
                          sx={{ textTransform: "capitalize" }}
                        >
                          <Typography variant="h5" color="white">
                            View Reset
                          </Typography>
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </Modal>
          {/* Ventana modal */}
          <Modal keepMounted open={open} onClose={handleClose}>
            <Grid
              container
              direction="column"
              justifyContent={"space-between"}
              sx={style}
            >
              <Grid
                container
                item
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
                spacing={2}
                sx={{ minWidth: "100%" }}
              >
                <Grid
                  container
                  item
                  xs={1}
                  justifyContent="flex-end"
                  mb={1}
                  sx={{ width: "100%" }}
                >
                  <IconButton
                    sx={{ color: "rgba(255, 0, 0, 1)" }}
                    onClick={() => handleClose()}
                  >
                    <ClearIcon
                      sx={{
                        width: { xs: 28, sm: 28 },
                        height: { xs: 28, sm: 28 },
                      }}
                    />
                  </IconButton>
                </Grid>
                <Grid item xs={12}>
                  <Autocomplete
                    value={value}
                    onChange={handleAutoCompleteChange}
                    inputValue={inputValue}
                    onInputChange={handleAutoCompleteInputChange}
                    options={options}
                    fullWidth
                    getOptionLabel={(option) =>
                      `${option?.profile?.name ?? ""} ${
                        option?.profile?.middleName ?? ""
                      } ${option?.profile?.lastName ?? ""}`
                    }
                    renderOption={(props, option) => {
                      return (
                        <Box
                          component="li"
                          sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                          {...props}
                        >
                          <Avatar
                            src={option?.profile?.avatar?.url}
                            srcSet={option?.profile?.avatar?.url}
                          >
                            {option?.profile?.name
                              ? option?.profile?.name?.charAt(0)
                              : undefined}
                          </Avatar>

                          <Typography sx={{ ml: 2 }}>
                            {`${option?.profile?.name ?? ""} ${
                              option?.profile?.middleName ?? ""
                            } ${option?.profile?.lastName ?? ""}`}
                          </Typography>
                        </Box>
                      );
                    }}
                    renderInput={(params) => (
                      <TextField {...params} label="Search user" />
                    )}
                  />
                </Grid>

                {contactsInMtd?.length > 0 && (
                  <>
                    <Grid item xs={12}>
                      <Typography>{t("contactAlready")}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <List dense={false}>
                        {contactsInMtd.map((contact, idx) => {
                          return (
                            <ListItem
                              key={idx}
                              secondaryAction={
                                <Box>
                                  <IconButton
                                    edge="end"
                                    onClick={() => {
                                      addContact(contact?.id);
                                    }}
                                  >
                                    <PersonAddAltIcon
                                      color="red"
                                      fontSize="large"
                                    />
                                  </IconButton>
                                </Box>
                              }
                            >
                              <ListItemAvatar>
                                <Avatar
                                  src={contact?.profile?.avatar?.url}
                                  srcSet={contact?.profile?.avatar?.url}
                                >
                                  {contact?.profile?.name?.charAt(0) ??
                                    undefined}
                                </Avatar>
                              </ListItemAvatar>
                              <ListItemText>
                                {`${contact?.profile?.name ?? ""} ${
                                  contact?.profile?.middleName ?? ""
                                } ${contact?.profile?.lastName ?? ""}`}
                              </ListItemText>
                            </ListItem>
                          );
                        })}
                      </List>
                    </Grid>
                  </>
                )}
              </Grid>
              <Grid container item xs={1} justifyContent="center" mt={2}>
                <Button
                  variant="contained"
                  style={{
                    backgroundColor: red[700],
                    "&:hover": { backgroundColor: red[900] },
                  }}
                  onClick={handleAddContact}
                >
                  {t("addContact")}
                </Button>
              </Grid>
            </Grid>
          </Modal>
          <Modal
            open={previewModalOpen}
            onClose={handleClosePreview}
            sx={{ zIndex: 4000 }}
          >
            <Box
              sx={{ width: "100vw", height: "100vh", backgroundColor: "black" }}
            >
              <Grid
                container
                justifyContent="center"
                alignItems="center"
                py={4}
              >
                <Grid
                  item
                  xs={12}
                  container
                  justifyContent="flex-end"
                  alignItems="center"
                >
                  <Grid item>
                    <IconButton
                      sx={{ color: "white" }}
                      onClick={() => handleClosePreview()}
                    >
                      <ClearIcon />
                    </IconButton>
                  </Grid>
                </Grid>
                <Grid
                  item
                  xs={12}
                  container
                  justifyContent="center"
                  alignItems="center"
                >
                  <Grid item>
                    {fileToUpload?.type?.includes("image") && (
                      <img
                        src={URL.createObjectURL(fileToUpload)}
                        alt="a"
                        style={{ maxHeight: "80vh" }}
                      />
                    )}
                    {fileToUpload?.type?.includes("video") && (
                      <video
                        controls
                        autoPlay
                        src={URL.createObjectURL(fileToUpload)}
                        alt="a"
                      />
                    )}
                  </Grid>
                </Grid>
                <Grid
                  item
                  xs={6}
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                >
                  <IconButton sx={{ color: "white" }} onClick={() => upload()}>
                    <SendIcon />
                  </IconButton>
                </Grid>
              </Grid>
              {uploadFileResults.isLoading && (
                <Box
                  sx={{
                    display: "flex",
                    position: "fixed",
                    top: "50%",
                    left: "47%",
                  }}
                >
                  <CircularProgress size={100} />
                </Box>
              )}
            </Box>
          </Modal>
          <Modal
            open={openCard}
            onClose={() => {
              setOpenCard(false);
              getConversations();
            }}
          >
            <Chefcard
              contactId={details?.contactId}
              restaurantId={details?.restaurantId}
              image={details?.image}
              profile={details?.profile}
              address={details?.address}
              status={details?.status}
              id={details?.id}
              role={details?.role}
              setOpen={setOpenCard}
              restaurant={details?.restaurant}
            />
          </Modal>
        </Grid>
      </ThemeProvider>
    </>
  );
};
