import React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Unstable_Grid2";
import { useGetAllLikesQuery } from "services/usersApi";
import { Button, CardActions } from "@mui/material";
import FavoriteIcon from "@mui/icons-material/Favorite";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

export function Favourite() {
  const { data } = useGetAllLikesQuery({ locale: "en" });

  const renderCardMediaRestaurant = (restaurant) => {
    return restaurant?.images?.length > 0 ? (
      <CardMedia
        component="img"
        image={restaurant?.images[0]?.file?.url}
        alt="image"
      />
    ) : (
      <></>
    );
  };
  const renderCardMediaPost = (post) => {
    return post?.images?.length > 0 ? (
      <CardMedia
        component="img"
        image={post?.images[0]?.image?.url}
        alt="image"
        style={{ height: "200px", width: "100%", objectFit: "cover" }}
      />
    ) : (
      <></>
    );
  };

  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <Grid
      container
      spacing={2}
      justifyContent="flex-start"
      mt={4}
      mx={2}
      mb={2}
    >
      <Grid xs={12} item>
        <Typography variant="h5">Restaurants</Typography>
      </Grid>
      {data?.likes
        ?.filter((item) => item?.restaurant !== null)
        ?.map((like, idx) => {
          return (
            <Grid
              xs={12}
              sm={4}
              md={3}
              ml={1}
              mr={1}
              sx={{ cursor: "pointer" }}
              key={idx}
            >
              <Card
                sx={{ height: "100%" }}
                onClick={() =>
                  navigate(
                    like?.restaurant
                      ? "/" + like?.restaurant?.id
                      : "/news/" + like?.post?.id
                  )
                }
              >
                {like?.restaurant
                  ? renderCardMediaRestaurant(like?.restaurant)
                  : renderCardMediaPost(like?.post)}
                <CardContent>
                  <Typography gutterBottom variant="h5" component="div">
                    {like?.restaurant
                      ? like?.restaurant?.name
                      : like?.post?.translations[0]?.title}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    {like?.restaurant ? like?.restaurant?.address?.address : ""}
                  </Typography>
                </CardContent>
                <CardActions>
                  <Button size="small" variant="contained">
                    {t("view")}
                  </Button>
                  <FavoriteIcon sx={{ ml: "25%" }} color="primary" />
                </CardActions>
              </Card>
            </Grid>
          );
        })}
      <Grid xs={12} item>
        <Typography variant="h5">Posts</Typography>
      </Grid>
      {data?.likes
        ?.filter((item) => item?.post !== null)
        ?.map((like, idx) => {
          return (
            <Grid
              xs={12}
              sm={4}
              md={3}
              ml={1}
              mr={1}
              sx={{ cursor: "pointer" }}
              key={idx}
            >
              <Card
                sx={{ height: "100%" }}
                onClick={() =>
                  navigate(
                    like?.restaurant
                      ? "/" + like?.restaurant?.id
                      : "/news/" + like?.post?.id
                  )
                }
              >
                {like?.restaurant
                  ? renderCardMediaRestaurant(like?.restaurant)
                  : renderCardMediaPost(like?.post)}
                <CardContent>
                  <Typography gutterBottom variant="h5" component="div">
                    {like?.restaurant
                      ? like?.restaurant?.name
                      : like?.post?.translations[0]?.title}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    {like?.restaurant ? like?.restaurant?.address?.address : ""}
                  </Typography>
                </CardContent>
                <CardActions>
                  <Button size="small" variant="contained">
                    {t("view")}
                  </Button>
                  <FavoriteIcon sx={{ ml: "25%" }} color="primary" />
                </CardActions>
              </Card>
            </Grid>
          );
        })}
    </Grid>
  );
}
