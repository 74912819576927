import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { API_URL } from "./env";
import { getValue } from "./storage";
export const usersApi = createApi({
  reducerPath: "usersApi",
  keepUnusedDataFor: 0,
  baseQuery: fetchBaseQuery({
    baseUrl: API_URL,
    prepareHeaders: (headers) => {
      const token = getValue("_auth");
      headers.set("Authorization", `Bearer ${token}`);
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getUser: builder.query({
      query: (id) => {
        return { url: `users` };
      },
    }),
    getSelf: builder.query({
      query: () => {
        return { url: `users` };
      },
    }),
    getSelfM: builder.mutation({
      query: () => {
        return { method: "GET", url: `users` };
      },
    }),
    updateUser: builder.mutation({
      query: ({ data, id }) => ({
        url: `users`,
        method: "PUT",
        body: { user: data },
      }),
    }),
    deleteUser: builder.mutation({
      query: () => ({
        url: `users`,
        method: "DELETE",
      }),
    }),
    getAllChefs: builder.mutation({
      query: () => {
        return { url: `users/chefs` };
      },
    }),
    getChef: builder.query({
      query: ({ id }) => {
        return { url: `users/chefs/${id}` };
      },
    }),
    getAllLikes: builder.query({
      query: ({ locale }) => {
        return { url: `users/likes/${locale}` };
      },
    }),
    getAllMyRestaurants: builder.query({
      query: () => {
        return { url: `users/restaurants` };
      },
    }),
    workInRestaurantRequest: builder.mutation({
      query: ({ id }) => ({
        url: `users/workinrestaurant/${id}`,
        method: "POST",
      }),
    }),
    workInRestaurant: builder.mutation({
      query: ({ id, status }) => ({
        url: `users/workinrestaurant/${id}/${status}`,
        method: "PUT",
      }),
    }),
    quitRestaurant: builder.mutation({
      query: ({ id, chefId }) => ({
        url: `users/workinrestaurant/${id}/${chefId}`,
        method: "DELETE",
      }),
    }),
    listAllChefRequests: builder.mutation({
      query: () => ({
        url: `users/workinrestaurant`,
        method: "GET",
      }),
    }),
    listAllChefRequestsRestaurant: builder.mutation({
      query: () => ({
        url: `users/workinrestaurant/restaurant`,
        method: "GET",
      }),
    }),
    updatePay: builder.mutation({
      query: () => ({
        url: `users/pay`,
        method: "GET",
      }),
    }),
    createStory: builder.mutation({
      query: ({ data }) => ({
        url: `users/story`,
        method: "POST",
        body: data,
      }),
    }),
    getStories: builder.mutation({
      query: () => ({
        url: `users/story`,
        method: "GET",
      }),
    }),
  }),
});

export const {
  useGetUserQuery,
  useUpdateUserMutation,
  useDeleteUserMutation,
  useGetAllChefsMutation,
  useGetSelfQuery,
  useGetAllLikesQuery,
  useGetAllMyRestaurantsQuery,
  useGetChefQuery,
  useWorkInRestaurantMutation,
  useQuitRestaurantMutation,
  useGetSelfMMutation,
  useUpdatePayMutation,
  useCreateStoryMutation,
  useGetStoriesMutation,
  useWorkInRestaurantRequestMutation,
  useListAllChefRequestsMutation,
  useListAllChefRequestsRestaurantMutation,
} = usersApi;
