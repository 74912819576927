import * as React from "react";
import Box from "@mui/joy/Box";
import {
  Card,
  CardContent,
  CardMedia,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import { useGetAllRestaurantsQuery } from "services/restaurantsApi";
import { cities } from "./Cities";
import { ArrowBackIos, ArrowForwardIos } from "@mui/icons-material";

export function Carousel(props) {
  const restaurants = useGetAllRestaurantsQuery().data?.restaurants;
  const { setSidebarOpen, setItem, setMapLocation, zoom, mapLocation } = props;
  const [carousel, setCarousel] = React.useState([]);
  const ref = React.useRef();

  const scrollLeft = () => (ref.current.scrollLeft -= 40);
  const scrollRight = () => (ref.current.scrollLeft += 40);

  React.useEffect(() => {
    setCarousel(cities);
  }, []);

  React.useEffect(() => {
    if ((zoom >= 12) & (closestRestaurants().length > 0)) {
      setCarousel(closestRestaurants());
    } else {
      setCarousel(cities);
    }
  }, [zoom]);

  const floatToStringWithOneDecimal = (value) => {
    const indexOfFloatDot = value?.toString()?.indexOf(".");
    return value?.toString()?.substring(0, indexOfFloatDot + 1);
  };

  const closestRestaurants = () => {
    const closeRestaurants = [];
    const mapLocationLat = floatToStringWithOneDecimal(mapLocation[0]);
    const mapLocationLng = floatToStringWithOneDecimal(mapLocation[1]);
    restaurants?.forEach((restaurant) => {
      if (
        mapLocationLat ===
          floatToStringWithOneDecimal(restaurant?.geolocation?.latitude) &&
        mapLocationLng ===
          floatToStringWithOneDecimal(restaurant?.geolocation?.longitude)
      )
        closeRestaurants.push(restaurant);
    });
    return closeRestaurants;
  };

  const heightCarousel = {
    xs: "22vh",
    sm: "20vh",
    lg: "15.5vh",
    xl: "16.5vh",
  };

  const heightButton = { xs: "87vh", sm: "88vh", md: "91vh" };

  return (
    <Box
      sx={{
        display: "flex",
        overflowY: "hidden",
        height: heightCarousel,
      }}
      my={{ xs: "0.5", sm: "1" }}
    >
      <IconButton
        sx={{
          position: { xs: "absolute" },
          top: heightButton,
          left: 4,
          color: { xs: "#ff0000", sm: "#ff0000" },
          backgroundColor: "rgb(255,255,255,0.5)",
        }}
        onClick={() => scrollLeft()}
        onTouchStart={() => scrollLeft()}
      >
        <ArrowBackIos sx={{ width: { md: 35 }, height: { md: 35 } }} />
      </IconButton>
      <Box
        ref={ref}
        sx={{
          display: "flex",
          overflowY: "hidden",
          width: "100vw",
          maxWidth: "100vw",
          height: heightCarousel,
        }}
      >
        {carousel?.map((item, idx) => (
          <Box
            key={idx}
            sx={{
              height: "100%",
              width: "100%",
              justifySelf: "flex-end",
              minWidth: { sm: 160 },
            }}
          >
            <Card
              variant="outlined"
              sx={{
                marginLeft: "1px",
                marginRight: "1px",
                width: "auto",
                maxWidth: "42vw",
                minWidth: { xs: "42vw", sm: "10vw" },
                height: "100%",
              }}
              onClick={() => {
                if (item?.city === true) {
                  setMapLocation([item?.lat, item?.lng]);
                } else {
                  setItem(item);
                  setSidebarOpen(item);
                }
              }}
            >
              <CardMedia
                component="img"
                image={item?.images.map((image) => {
                  if (image.main === true) {
                    return image.file.url;
                  }
                  return null;
                })}
                alt={item.title}
                sx={{
                  width: "100%",
                  height: { xs: "70%", sm: "70%" },
                  // "@media screen and (min-height: 300px)": {
                  //   height: 75,
                  // },
                  // "@media screen and (min-height: 450px)": {
                  //   height: 75,
                  // },
                  // "@media screen and (min-height: 500px)": {
                  //   height: 75,
                  // },
                  // "@media screen and (min-height: 550px)": {
                  //   height: 75,
                  // },
                  // "@media screen and (min-height: 600px)": {
                  //   height: 75,
                  // },
                  // "@media screen and (min-height: 650px)": {
                  //   height: 75,
                  // },
                  // "@media screen and (min-height: 700px)": {
                  //   height: 75,
                  // },
                  // "@media screen and (min-height: 750px)": {
                  //   height: 75,
                  // },
                  // "@media screen and (min-height: 800px)": {
                  //   height: 75,
                  // },
                  // "@media screen and (min-height: 850px)": {
                  //   height: 75,
                  // },
                  // "@media screen and (min-height: 900px)": {
                  //   height: 75,
                  // },
                  // "@media screen and (min-height: 950px)": {
                  //   height: 75,
                  // },
                  // "@media screen and (min-height: 1000px)": {
                  //   height: 175,
                  // },
                }}
              />
              <CardContent
                sx={{
                  backgroundColor: "red",
                  "@media screen and (min-width: 0px)": {
                    paddingTop: "3px",
                  },
                  "@media screen and (min-width: 1020px)": {
                    paddingTop: "10px",
                  },
                }}
              >
                <Grid
                  style={{
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    width: "100%",
                  }}
                  container
                  justifyContent="center"
                  alignItems="center"
                >
                  <Grid item xs>
                    <Typography
                      noWrap
                      color="white"
                      textTransform={"capitalize"}
                    >
                      {item.name}
                    </Typography>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Box>
        ))}
      </Box>
      <IconButton
        sx={{
          position: { xs: "absolute" },
          right: 4,
          top: heightButton,
          color: { xs: "#ff0000", sm: "#ff0000" },
          backgroundColor: "rgb(255,255,255,0.5)",
        }}
        onClick={() => scrollRight()}
        onTouchStart={() => scrollRight()}
      >
        <ArrowForwardIos sx={{ width: { md: 35 }, height: { md: 35 } }} />
      </IconButton>
    </Box>
  );
}
