import React, { useEffect, useState } from "react";
import { Grid, Avatar, IconButton, Typography, Modal } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import AspectRatio from "@mui/joy/AspectRatio";
import { ChefBar } from "features/Panel/PanelData/ChefBar";
import ChefGallery from "features/Panel/PanelData/ChefGallery";
import { useGetChefQuery } from "services/usersApi";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import { useRequestFriendshipMutation } from "services/contact";
import ChefHatLogo from "assets/2023/chef.svg";
import { Box } from "@mui/system";
import returnIcon from "assets/new_svg_icons/Post Iconos/Iconos Retorno.svg";

export function ChefPanelData(props) {
  const { chef, toggleDrawer } = props;
  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState("");
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { data } = useGetChefQuery({ id: chef?.id });
  const [createConversation, createConversationResults] =
    useRequestFriendshipMutation();
  const { t } = useTranslation();

  useEffect(() => {
    if (createConversationResults.isSuccess) {
      navigate("/chat/" + createConversationResults.data?.id);
    }
    if (createConversationResults.isError) {
      enqueueSnackbar(`No user associated to this restaurant`, {
        variant: "error",
        autoHideDuration: 2000,
      });
    }
  }, [createConversationResults]);

  const handleCreateConversation = () => {
    createConversation({ id: chef?.id });
  };

  const [addContact, addContactResults] = useRequestFriendshipMutation();

  useEffect(() => {
    if (addContactResults?.isSuccess) {
      enqueueSnackbar(`Successfully requested`, {
        variant: "success",
        autoHideDuration: 2000,
      });
    }
    if (addContactResults?.isError) {
      enqueueSnackbar(`Could not perform the operation at the moment`, {
        variant: "error",
        autoHideDuration: 2000,
      });
    }
  }, [addContactResults]);

  const handleAddContact = (id) => {
    addContact(chef?.id);
  };

  return (
    <div style={{}}>
      <div style={{ position: "relative" }}>
        <IconButton
          sx={{
            position: "absolute",
            top: "2%",
            left: "4%",
            zIndex: 3000,
            with: "100%",
          }}
          onClick={toggleDrawer(false)}
          disableRipple={true}
        >
          <img src={returnIcon} alt="" />
        </IconButton>
        <Box>
          <AspectRatio maxWidth={500}>
            <img
              onClick={() => {
                setSelected(
                  data?.user?.profile?.chef?.images?.filter(
                    (image) => image.main === true
                  )?.length > 0
                    ? data?.user?.profile?.chef?.images?.filter(
                        (image) => image.main === true
                      )[0]?.image?.url
                    : ""
                );
                setOpen(true);
              }}
              src={
                data?.user?.profile?.chef?.images?.filter(
                  (image) => image.main === true
                )?.length > 0
                  ? data?.user?.profile?.chef?.images?.filter(
                      (image) => image.main === true
                    )[0]?.image?.url
                  : ""
              }
              srcSet={
                data?.user?.profile?.chef?.images?.filter(
                  (image) => image.main === true
                )?.length > 0
                  ? data?.user?.profile?.chef?.images?.filter(
                      (image) => image.main === true
                    )[0]?.image?.url
                  : ""
              }
              alt=""
            />
          </AspectRatio>
        </Box>
      </div>
      <Grid
        container
        mt={0}
        direction="row"
        sx={{ backgroundColor: "#eee", py: 2 }}
      >
        <Grid item mt={-6} xs={4} ml={2}>
          <Avatar
            alt="Nombre del chef"
            src={data?.user?.profile?.avatar?.url}
            srcSet={data?.user?.profile?.avatar?.url}
            sx={{
              width: { xs: 120, sm: 140 },
              height: { xs: 120, sm: 140 },
              position: "relative",
              borderColor: "#fff",
              borderStyle: "solid",
              borderWidth: { xs: "2px", sm: "5px" },
            }}
            onClick={() => {
              setSelected(
                data?.user?.profile?.chef?.images?.filter(
                  (image) => image.main === true
                )?.length > 0
                  ? data?.user?.profile?.chef?.images?.filter(
                      (image) => image.main === true
                    )[0]?.image?.url
                  : ""
              );
              setOpen(true);
            }}
          />
        </Grid>

        <Grid container item xs direction="column" spacing={1}>
          <Grid item xs container directoin="row" alignItems="center">
            <Grid item xs>
              <Typography
                mt={2}
                textTransform={"capitalize"}
                fontWeight={600}
                variant="h6"
              >
                Chef {chef?.profile?.name + " " + chef?.profile?.lastName}
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs container directoin="row" alignItems="center">
            <Grid item xs container spacing={1} gap={1}>
              <Typography sx={{ fontWeight: 500 }}>Works at</Typography>
              <Typography sx={{ fontWeight: 500, color: "#1396FF" }}>
                {chef?.chefs[0]?.restaurant?.name}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item container xs={12}>
          <Grid
            item
            xs={6}
            container
            justifyContent="center"
            alignItems="center"
          >
            <Avatar src={ChefHatLogo}></Avatar>
            <Typography>{t("executiveChef")}</Typography>
          </Grid>
          <Grid
            item
            xs={6}
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <Grid item xs={2} md={1} mr={1}>
              <LocationOnIcon sx={{ color: "#ff0000" }} />
            </Grid>
            <Grid item xs>
              <Typography>
                {chef?.chefs[0]?.restaurant?.address?.city +
                  ", " +
                  chef?.chefs[0]?.restaurant?.address?.country}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <ChefBar
          handleCreateConversation={handleCreateConversation}
          handleAddContact={handleAddContact}
          status={data?.status}
          setOpen={toggleDrawer}
        />
      </Grid>

      <ChefGallery
        images={data?.user?.profile?.chef?.images}
        name={`${chef?.profile?.name ?? ""} ${
          chef?.profile?.middleName ?? ""
        } ${chef?.profile?.lastName ?? ""}
        }`}
      />
      <Modal
        keepMounted
        open={open}
        onClose={() => {
          setOpen(false);
          setSelected(null);
        }}
      >
        <Box
          sx={{
            width: "100vw",
            height: "100vh",
            backgroundColor: "#000",
            backgroundImage: `url(${selected})`,
            backgroundSize: "contain",
            backgroundPositionX: "center",
            backgroundPositionY: "center",
            backgroundRepeat: "no-repeat",
          }}
        >
          <IconButton
            sx={{
              position: "absolute",
              right: "4%",
              top: "1%",
              backgroundColor: "rgba(0,0,0,0.1)",
              border: "1px solid rgba(255,255,255,0.2)",
            }}
            onClick={() => setOpen(false)}
          >
            <ClearIcon sx={{ color: "#fff" }} />
          </IconButton>
        </Box>
      </Modal>
    </div>
  );
}
