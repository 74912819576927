import { Button, Grid, ThemeProvider } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useResetPasswordMutation } from "services/authApi";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { useSnackbar } from "notistack";
import map from "assets/global-map.svg";
import logo from "assets/logo-login.svg";
import { LoginTheme } from "services/themes";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";

function Copyright(props) {
  return (
    <Typography variant="body2" color="white" align="center" {...props}>
      {"Copyright © "}
      <Link color="inherit" href="https://www.mytraveldinner.com/">
        MyTravelDinner
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

export const ResetPassword = () => {
  const { token } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [loginForm, setLoginForm] = useState({
    password: "",
    confirmPassword: "",
    role: "",
  });
  const [reset, resetResults] = useResetPasswordMutation();

  const handleSubmit = (e) => {
    let isValid = true;
    if (!checkPasswords()) {
      enqueueSnackbar(`Passwords don 't match`, {
        variant: "error",
        autoHideDuration: 2000,
      });
      isValid = false;
    }
    if (!checkPasswordLength()) {
      enqueueSnackbar(`Password is too short 6 characters at least required`, {
        variant: "error",
        autoHideDuration: 2000,
      });
      isValid = false;
    }
    if (isValid)
      reset({
        data: {
          password: loginForm.password,
          confirmPassword: loginForm.confirmPassword,
        },
        token,
      });
  };

  const checkPasswords = () => {
    if (loginForm.confirmPassword !== loginForm.password) {
      return false;
    }
    return true;
  };
  const checkPasswordLength = () => {
    if (loginForm.password.length < 6) {
      return false;
    }
    return true;
  };
  useEffect(() => {
    if (resetResults.isError) {
      enqueueSnackbar(`${resetResults.error?.data?.error?.message}`, {
        variant: "error",
        autoHideDuration: 2000,
      });
    }
    if (resetResults.isSuccess) {
      enqueueSnackbar(`Successfully updated`, {
        variant: "success",
        autoHideDuration: 2000,
      });
      navigate("/");
    }
  }, [resetResults]);

  return (
    <div
      style={{ width: "100vw", height: "100vh", backgroundColor: "#0171bb" }}
    >
      <Helmet bodyAttributes={{ style: "background-color: #0171bb" }}></Helmet>
      <img
        src={map}
        alt="Global Map"
        style={{ height: "100%", position: "absolute", top: 0, left: "8%" }}
      />
      <Grid
        container
        direction="column"
        alignItems="center"
        justifyContent="center"
        sx={{ height: "100vh" }}
      >
        <Grid
          container
          item
          xs={7}
          sx={{ zIndex: 1 }}
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          <Grid
            item
            sx={{ backgroundColor: "rgba(0,0,0,0.45)", borderRadius: 5 }}
          >
            <Container component="main" maxWidth="md" sx={{ opacity: 1 }}>
              <CssBaseline />
              <Box
                sx={{
                  marginTop: 8,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <ThemeProvider theme={LoginTheme}>
                  <img src={logo} style={{ maxWidth: 250 }} alt="Login logo" />
                  <Typography
                    component="h1"
                    variant="h4"
                    color={"white"}
                    sx={{ mt: 4, fontWeight: 600, textTransform: "capitalize" }}
                  >
                    {t("recoverPassword")}
                  </Typography>

                  <Box sx={{ mt: 1, width: "100%" }}>
                    <TextField
                      margin="normal"
                      required
                      fullWidth
                      name="password"
                      label="Password"
                      type="password"
                      id="password"
                      autoComplete="current-password"
                      variant="filled"
                      color="primary"
                      onChange={(e) =>
                        setLoginForm({ ...loginForm, password: e.target.value })
                      }
                      value={loginForm.password}
                      sx={{ borderRadius: 2, backgroundColor: "#fcfcfb" }}
                    />
                    <TextField
                      margin="normal"
                      required
                      fullWidth
                      name="confirmpassword"
                      label="Confirm Password"
                      type="password"
                      id="confirmpassword"
                      autoComplete="confirm-password"
                      variant="filled"
                      color="primary"
                      onChange={(e) =>
                        setLoginForm({
                          ...loginForm,
                          confirmPassword: e.target.value,
                        })
                      }
                      value={loginForm.confirmPassword}
                      sx={{ borderRadius: 2, backgroundColor: "#fcfcfb" }}
                    />

                    <Button
                      fullWidth
                      variant="contained"
                      sx={{
                        mt: 3,
                        mb: 2,
                        "&.Mui-disabled": {
                          background: "rgba(255, 0, 0, 0.5)",
                          color: "#c0c0c0",
                        },
                      }}
                      onClick={(e) => handleSubmit(e)}
                      type="button"
                    >
                      {t("reset")}
                    </Button>
                    <Grid container></Grid>
                  </Box>
                </ThemeProvider>
              </Box>
              <Copyright sx={{ mt: 8, mb: 4 }} />
            </Container>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};
