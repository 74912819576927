import React, { useEffect, useState } from "react";
import {
  IconButton,
  Box,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Typography,
  Modal,
} from "@mui/material";
import "./LanguageIcon.css";
import { useTranslation } from "react-i18next";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 250,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

export function LanguageIcon() {
  const [open, setOpen] = useState(false);
  const [languageSelected, setLanguage] = useState({
    id: 0,
    name: "English",
    iconUrl:
      "https://mytraveldinner.s3.amazonaws.com/statics/assets/images/flags/en-us-2c5db0c0da3792e95631d63678c1912b.png",
    languageSelector: "en",
  });
  const flagIcons = [
    {
      id: 0,
      name: "English",
      iconUrl:
        "https://mytraveldinner.s3.amazonaws.com/statics/assets/images/flags/en-us-2c5db0c0da3792e95631d63678c1912b.png",
      languageSelector: "en",
    },
    {
      id: 1,
      name: "Spanish",
      iconUrl:
        "https://mytraveldinner.s3.amazonaws.com/statics/assets/images/flags/es-es-d46b3ea733bbbc680278c613c40791f8.png",
      languageSelector: "es",
    },
    {
      id: 2,
      name: "Italian",
      iconUrl:
        "https://mytraveldinner.s3.amazonaws.com/statics/assets/images/flags/it-it-1f06f8c90e549f654b2c125a422ac721.png",
      languageSelector: "it",
    },
    {
      id: 3,
      name: "Chinese",
      iconUrl:
        "https://mytraveldinner.s3.amazonaws.com/statics/assets/images/flags/zh-zh-e206c2f9aa8a76ca87fe5d1f85abf8bb.png",
      languageSelector: "zh-CN",
    },
    {
      id: 4,
      name: "Russian",
      iconUrl:
        "https://mytraveldinner.s3.amazonaws.com/statics/assets/images/flags/ru-ru-3fc5fc00bf91a64e510dae5cdd517176.png",
      languageSelector: "ru",
    },
    {
      id: 5,
      name: "German",
      iconUrl:
        "https://mytraveldinner.s3.amazonaws.com/statics/assets/images/flags/de-de-9125e9009b8a320bbd16da052dcaf33e.png",
      languageSelector: "de",
    },
    {
      id: 6,
      name: "Portuguese",
      iconUrl:
        "https://mytraveldinner.s3.amazonaws.com/statics/assets/images/flags/pt-pt-bdd7ccc46592a37719adad3e4eaa7352.png",
      languageSelector: "pt",
    },
    {
      id: 7,
      name: "French",
      iconUrl:
        "https://mytraveldinner.s3.amazonaws.com/statics/assets/images/flags/fr-fr-916a8e3cb9beaea78d6c008762f1cd49.png",
      languageSelector: "fr",
    },
  ];

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const { i18n, t } = useTranslation();
  const handleSelect = (language) => {
    setOpen(false);
    setLanguage(language);
    i18n.changeLanguage(language.languageSelector);
  };

  useEffect(() => {
    let languageInit = flagIcons.filter(
      (language) => language.languageSelector === i18n.language
    );
    if (languageInit) {
      setLanguage(languageInit[0]);
    } else {
      setLanguage(flagIcons[0]);
    }
  }, []);

  return (
    <>
      <IconButton
        onClick={handleOpen}
        sx={{
          borderRadius: 0,
        }}
      >
        <div className="flagContainer">
          <img
            src={
              languageSelected?.iconUrl ??
              "https://mytraveldinner.s3.amazonaws.com/statics/assets/images/flags/en-us-2c5db0c0da3792e95631d63678c1912b.png"
            }
            alt={languageSelected?.name + " flag"}
            className="flagImage"
          />
        </div>
        <Typography ml={1} sx={{ display: { xs: "block", md: "none" } }}>
          {" "}
          {t("chooseLanguage")}
        </Typography>
      </IconButton>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h1">
            {t("chooseLanguage")}
          </Typography>
          <List>
            {flagIcons.map((language) => (
              <ListItem key={language.id} disablePadding>
                <ListItemButton onClick={() => handleSelect(language)}>
                  <ListItemText>
                    <div className="flagContainer">
                      <img
                        src={
                          language?.iconUrl ??
                          "https://mytraveldinner.s3.amazonaws.com/statics/assets/images/flags/en-us-2c5db0c0da3792e95631d63678c1912b.png"
                        }
                        alt={(language?.name ?? "English") + " flag"}
                        className="flagImage"
                      />
                    </div>
                  </ListItemText>
                  <ListItemText primary={language.name} />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </Box>
      </Modal>
    </>
  );
}
