import { Avatar, Box, Grid, IconButton, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import ClearIcon from "@mui/icons-material/Clear";
import { useSwipeable } from "react-swipeable";
import dayjs from "dayjs";
import { ArrowBackIos, ArrowForwardIos } from "@mui/icons-material";
import { useGetStoriesMutation } from "services/usersApi";
import { useSnackbar } from "notistack";
import { useNavigate, useParams } from "react-router-dom";
export const StoriesViewerPage = () => {
  const { id } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [getStories, getStoriesResults] = useGetStoriesMutation();
  const [user, setUser] = useState(id);
  const [seen, setSeen] = useState([]);
  const [users, setUsers] = useState([]);
  const [active, setActive] = useState();
  const [counter, setCounter] = useState(0);
  const [index, setIndex] = useState(0);
  const [image, setImage] = useState("");
  const [stories, setStories] = useState([]);

  useEffect(() => {
    getStories();
  }, []);

  useEffect(() => {
    if (getStoriesResults.isSuccess) {
      setStories(getStoriesResults.data?.stories);
      const userMap = new Map();
      for (const story of getStoriesResults.data?.stories) {
        userMap.set(story?.userId, story?.user);
      }
      const arr = Array.from(userMap, ([key, value]) => ({
        key,
        value,
      }));
      setUsers(arr);
    }
    if (getStoriesResults.isError) {
      enqueueSnackbar(`Could not retrieves the stories in this moment`, {
        variant: "error",
        autoHideDuration: 2000,
      });
    }
  }, [getStoriesResults]);

  useEffect(() => {
    if (counter < 100) {
      setTimeout(() => setCounter(counter + 1), 100);
    } else {
      setCounter(0);
      setIndex(index + 1);
      setSeen([...seen, active]);
    }
  }, [counter]);

  useEffect(() => {
    if (index >= stories?.length) {
      enqueueSnackbar(`Story not available`, {
        variant: "error",
        autoHideDuration: 2000,
      });
    } else {
      const story = stories
        ?.filter((story) => story?.userId === user)
        ?.sort((a, b) => a?.id < b?.id);
      setActive(story?.[index]?.id);
      setImage(story?.[index]?.file?.url);
    }
  }, [stories, user, index]);

  const handlers = useSwipeable({
    onSwipedLeft: () => {
      setIndex(index + 1);
      setSeen([...seen, active]);
    },
    onSwipedRight: () => {
      setIndex(index + -1);
      setSeen([...seen, active]);
    },
  });

  const storyStatus = (id) => {
    if (id === active)
      return `linear-gradient(to right,  rgba(255,255,255,1) ${counter}%,transparent 10%)`;
    if (seen.includes(id)) return `rgba(255,255,255,1)`;
    else return `rgba(255,255,255,0.2)`;
  };

  const getName = () => {
    const user = stories?.filter((item) => item?.id === active)?.[0];
    return `${user?.user?.profile?.name ?? ""} ${
      user?.user?.profile?.lastName ?? ""
    }`;
  };
  const getTime = () => {
    const user = stories?.filter((item) => item?.id === active)?.[0];
    const now = dayjs();
    const date = dayjs(user?.createdAt);

    return now.diff(date, "hours");
  };

  return (
    <Box
      sx={{
        width: "100vw",
        height: "100vh",
        backgroundColor: "#26282f",
        backgroundImage: `url(${image})`,
        backgroundPositionX: "center",
        backgroundPositionY: "center",
        backgroundSize: "contain",
        backgroundRepeat: "no-repeat",
      }}
      {...handlers}
    >
      <IconButton
        sx={{
          position: "absolute",
          left: "4%",
          top: "50%",
          backgroundColor: "rgba(0,0,0,0.1)",
          border: "1px solid rgba(255,255,255,0.2)",
          borderRadius: 5,
        }}
        onClick={() => {
          setIndex(index + -1);
          setSeen([...seen, active]);
        }}
      >
        <ArrowBackIos
          sx={{
            color: "#ff0000",
          }}
        />
      </IconButton>

      <IconButton
        sx={{
          position: "absolute",
          right: "4%",
          top: "50%",
          backgroundColor: "rgba(0,0,0,0.1)",
          border: "1px solid rgba(255,255,255,0.2)",
        }}
        onClick={() => {
          setIndex(index + 1);
          setSeen([...seen, active]);
        }}
      >
        <ArrowForwardIos sx={{ color: "#ff0000" }} />
      </IconButton>
      <Grid container sx={{ position: "fixed", top: "0" }}>
        {stories?.map((item) => {
          return (
            <Grid item xs sx={{ mx: 0.1 }}>
              <Box
                sx={{
                  height: 5,

                  width: "100%",
                  background: `${storyStatus(item?.id)}`,
                }}
              ></Box>
            </Grid>
          );
        })}
      </Grid>
      <Grid container sx={{ position: "fixed", top: "2%" }}>
        <Grid
          item
          xs={8}
          container
          justifyContent="center"
          alignItems={"center"}
        >
          <Grid item xs={4} container justifyContent="center">
            <Avatar
              src={
                stories?.filter((item) => item?.id === active)?.[0]?.user
                  ?.profile?.avatar?.url
              }
            />
          </Grid>
          <Grid item xs={8} container>
            <Typography sx={{ color: "#fff" }} noWrap>
              {getName()}
            </Typography>
          </Grid>
        </Grid>
        <Grid
          item
          xs={2}
          container
          justifyContent="center"
          alignItems={"center"}
        >
          <Typography sx={{ color: "#fff" }}>{getTime()}h</Typography>
        </Grid>
        <Grid
          item
          xs={2}
          container
          justifyContent="center"
          alignItems={"center"}
        >
          <IconButton onClick={() => navigate(-1)}>
            <ClearIcon sx={{ color: "#fff" }} />
          </IconButton>
        </Grid>
      </Grid>
      <Box
        sx={{
          display: "flex",
          overflowY: "hidden",
          width: "100vw",
          minHeight: "10vh",
          backgroundColor: "rgba(0,0,0,0.5)",
          overflowX: "scroll",
          position: "fixed",
          bottom: "0",
          pt: 1,
        }}
      >
        <Grid
          container
          sx={{ position: "fixed", bottom: "2%" }}
          alignContent="flex-start"
          direction={"row"}
          alignItems={"center"}
        >
          {users?.map((item, idx) => (
            <Grid
              item
              xs
              key={idx}
              container
              justifyContent="center"
              alignItems="center"
              sx={{ minWidth: "25%" }}
              onClick={() => setUser(item?.key)}
            >
              <Grid
                item
                xs={12}
                container
                justifyContent="center"
                alignItems="center"
              >
                <Avatar
                  src={item?.value?.profile?.avatar?.url}
                  srcSet={item?.value?.profile?.avatar?.url}
                  sx={{ border: "2px solid rgba(1, 175, 14, 1)" }}
                />
              </Grid>
              <Grid
                item
                xs={12}
                container
                justifyContent="center"
                alignItems="center"
              >
                <Typography
                  sx={{ fontWeight: 900, color: "#fff" }}
                  variant="caption"
                  noWrap
                >
                  {`${item?.value?.profile?.name ?? ""} ${
                    item?.value?.profile?.lastName ?? ""
                  }`}
                </Typography>
              </Grid>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Box>
  );
};
