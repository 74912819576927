import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { API_URL } from "./env";
import { getValue } from "./storage";

export const paymentsApi = createApi({
  reducerPath: "paymentsApi",
  baseQuery: fetchBaseQuery({
    baseUrl: API_URL,
    prepareHeaders: (headers) => {
      const token = getValue("_auth");
      headers.set("Authorization", `Bearer ${token}`);
      return headers;
    },
  }),
  endpoints: (builder) => ({
    createPaymentIntent: builder.mutation({
      query: () => {
        return { method: "POST", url: `payments/create-payment-intent` };
      },
    }),
  }),
});

export const { useCreatePaymentIntentMutation } = paymentsApi;
