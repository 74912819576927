import React, { useState } from "react";
import Box from "@mui/material/Box";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import ChefDialog from "features/Panel/PanelData/ChefDialog";

export default function ChefGallery({ images, name }) {
  const [open, setOpen] = useState(false);
  const [data, setData] = useState(null);
  const [index, setIndex] = useState({ index: 0, total: 0 });
  return (
    <>
      <ChefDialog
        open={open}
        setOpen={setOpen}
        data={data}
        avatar={images?.filter((image) => image?.main === true)}
        name={name}
        index={index}
      />
      <Box sx={{ width: "100%", maxHeight: "65vh", overflowY: "scroll" }}>
        <ImageList variant="masonry" cols={2} gap={8} scroll="body">
          {images
            ?.filter((image) => image?.main === false)
            ?.map((item, idx) => (
              <ImageListItem
                key={item.img}
                onClick={() => {
                  setOpen(true);
                  setData({
                    images: images?.filter((image) => image?.main === false),
                    index: idx,
                  });
                  setIndex({
                    index: idx,
                    total: images?.filter((image) => image?.main === false)
                      ?.length,
                  });
                }}
              >
                <img
                  src={item?.image?.url}
                  srcSet={item?.image?.url}
                  alt={item.title}
                  loading="lazy"
                />
              </ImageListItem>
            ))}
        </ImageList>
      </Box>
    </>
  );
}
