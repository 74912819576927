import React, { useState } from "react";
import Geocode from "react-geocode";
import Grid from "@mui/material/Unstable_Grid2";
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  MenuItem,
  Modal,
  Select,
  TextField,
  Typography,
  IconButton,
  Avatar,
} from "@mui/material";
import { useEffect } from "react";
import CountrySelect from "app/Navbar/CountrySelect";
import { useSnackbar } from "notistack";
import { useUpdateRestaurantMutation } from "services/restaurantsApi";
import { LocalizationProvider, TimePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import ClearIcon from "@mui/icons-material/Clear";
import { useTranslation } from "react-i18next";
import { useQuitRestaurantMutation } from "services/usersApi";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "55%",
  bgcolor: "#e1f5fe",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  overflowY: "scroll",
  maxHeight: "85%",
};

export const Information = ({ data, setId, formData, setFormData }) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [updateRestaurant, updateRestaurantResults] =
    useUpdateRestaurantMutation();
  const [open, setOpen] = useState(false);

  const cuisineTypes = [
    { id: 1, name: t("italian") },
    { id: 2, name: t("spanish") },
    { id: 3, name: t("greek") },
    { id: 4, name: t("french") },
    { id: 5, name: t("portuguese") },
    { id: 6, name: t("israeli") },
    { id: 7, name: t("moroccan") },
    { id: 8, name: t("turkish") },
    { id: 13, name: t("argentinian") },
    { id: 14, name: t("mexico") },
    { id: 9, name: t("uruguayan") },
    { id: 10, name: t("mediterranean") },
    { id: 11, name: t("seafood") },
    { id: 12, name: t("steakHouse") },
    { id: 15, name: t("localCuisine") },
  ];

  useEffect(() => {
    if (updateRestaurantResults.isSuccess) {
      enqueueSnackbar(`Successfully updated`, {
        variant: "success",
        autoHideDuration: 2000,
      });
      setFormData({
        ...formData,
        id: updateRestaurantResults.data?.restaurant?.id,
        schedule: updateRestaurantResults.data?.restaurant?.schedule,
      });
      setId(updateRestaurantResults.data?.restaurant?.id);
    }
    if (updateRestaurantResults.isError) {
      enqueueSnackbar(`There was an error trying to update it`, {
        variant: "error",
        autoHideDuration: 2000,
      });
    }
  }, [updateRestaurantResults]);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const getDayName = (day) => {
    switch (day) {
      case 1:
        return t("monday");
      case 2:
        return t("tuesday");
      case 3:
        return t("wednesday");
      case 4:
        return t("thursday");
      case 5:
        return t("friday");
      case 6:
        return t("saturday");
      case 7:
        return t("sunday");
      default:
        return "";
    }
  };

  const updateStartTime = (idx, startTime) => {
    const newSchedule = [];
    formData?.schedule
      ?.slice()
      ?.sort((a, b) => a?.day - b?.day)
      ?.forEach((schedule, idx_) => {
        if (idx === idx_) {
          newSchedule.push({
            ...schedule,
            startTime: startTime,
          });
        } else newSchedule.push(schedule);
      });
    setFormData({ ...formData, schedule: newSchedule });
  };

  const updateEndTime = (idx, endTime) => {
    const newSchedule = [];
    formData?.schedule
      ?.slice()
      ?.sort((a, b) => a?.day - b?.day)
      ?.forEach((schedule, idx_) => {
        if (idx === idx_) {
          newSchedule.push({
            ...schedule,
            endTime: endTime,
          });
        } else newSchedule.push(schedule);
      });
    setFormData({ ...formData, schedule: newSchedule });
  };
  Geocode.setApiKey("AIzaSyCtKjiLmtKQ-R79xiAllwqrv0KK2sjtpF8");
  useEffect(() => {
    if (
      formData?.address?.address &&
      formData?.address?.city &&
      formData?.address?.country
    )
      Geocode.fromAddress(
        `${formData?.address?.address}, ${formData?.address?.city} ${formData?.address?.country}`
      ).then(
        (response) => {
          const { lat, lng } = response.results[0].geometry.location;
          if (
            lat != formData?.geolocation?.latitude ||
            lng != formData?.geolocation?.longitude
          )
            setFormData({
              ...formData,
              geolocation: { latitude: lat + "", longitude: lng + "" },
            });
        },
        (error) => {
          console.error(error);
        }
      );
  }, [formData]);

  return (
    <Grid
      container
      direction="column"
      justfyContent="center"
      alignItems="center"
      sx={{ width: "100vw", mt: 4 }}
      spacing={2}
    >
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        xs={12}
        sx={{ width: "100vw" }}
      >
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          xs={12}
          sm={12}
        >
          <Grid>
            <Typography variant="h6" ml={4} mr={1}>
              {t("solicitationState")}
            </Typography>
            <Typography
              variant="body1"
              sx={{
                fontWeight: 700,
                color: formData?.solicitationState ? "success.main" : "grey",
              }}
              textTransform={"capitalize"}
            >
              {formData?.solicitationState}
            </Typography>
          </Grid>
        </Grid>
        <Grid xs={12} sm={6}>
          <FormControl>
            <FormControlLabel
              control={
                <Checkbox
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      mediterraneanFood: !formData?.mediterraneanFood,
                    });
                  }}
                  checked={formData?.mediterraneanFood ?? false}
                />
              }
              sx={{ textTransform: "capitalize" }}
              label="Serves mediterranean food"
            />
          </FormControl>
        </Grid>
        <Grid xs={12} sm={6}></Grid>

        <Grid xs={12} sm={6}>
          <FormControl sx={{ width: { xs: "85%", sm: "75%" } }}>
            <FormHelperText id="name-input-help">
              <Typography color="#000" variant="caption" fontWeight={700}>
                {t("restaurantName")}
              </Typography>
              <Typography color="#ff0000" variant="caption">
                *
              </Typography>
            </FormHelperText>
            <TextField
              id="name-input"
              aria-describedby="name-input-text"
              value={formData?.name ?? ""}
              onChange={(e) => {
                setFormData({ ...formData, name: e.target.value });
              }}
              sx={{ backgroundColor: "white" }}
            />
          </FormControl>
        </Grid>
        <Grid xs={12} sm={6}>
          <FormControl sx={{ width: { xs: "85%", sm: "75%" } }}>
            <FormHelperText id="cuisine-type-text">
              <Typography color="#000" variant="caption" fontWeight={700}>
                {t("restaurantCuisine")}
              </Typography>
              <Typography color="#ff0000" variant="caption">
                *
              </Typography>
            </FormHelperText>
            <Select
              id="cuisine-type-select"
              onChange={(e) => {
                setFormData({
                  ...formData,
                  cuisine: { id: e.target.value },
                });
              }}
              value={formData?.cuisine?.id ?? ""}
              sx={{ textTransform: "capitalize", backgroundColor: "white" }}
            >
              {cuisineTypes.map((cuisine, idx) => (
                <MenuItem
                  key={idx}
                  value={cuisine.id}
                  sx={{ textTransform: "capitalize" }}
                >
                  {cuisine.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid xs={12} sm={6}>
          <FormControl sx={{ width: { xs: "85%", sm: "75%" } }}>
            <FormHelperText id="address--input-help">
              <Typography color="#000" variant="caption" fontWeight={700}>
                {t("countryCodeRestaurant")}
              </Typography>
              <Typography color="#ff0000" variant="caption">
                *
              </Typography>
            </FormHelperText>
            <CountrySelect
              phone={true}
              value={
                (formData?.contact?.phone?.countryCode?.startsWith("+")
                  ? formData?.contact?.phone?.countryCode?.substring(
                      1,
                      formData?.contact?.phone?.countryCode?.length
                    )
                  : formData?.contact?.phone?.countryCode) ?? ""
              }
              setValue={(value) =>
                setFormData({
                  ...formData,
                  contact: {
                    ...formData?.contact,
                    phone: {
                      ...formData?.contact?.phone,
                      countryCode: value,
                    },
                  },
                })
              }
              sx={{ backgroundColor: "white" }}
            />
          </FormControl>
        </Grid>
        <Grid xs={12} sm={6}>
          <FormControl sx={{ width: { xs: "85%", sm: "75%" } }}>
            <FormHelperText id="phone-number-input-help">
              <Typography color="#000" variant="caption" fontWeight={700}>
                {t("restaurantPhoneNumber")}
              </Typography>
              <Typography color="#ff0000" variant="caption">
                *
              </Typography>
            </FormHelperText>
            <TextField
              variant="outlined"
              id="phone-number-input"
              aria-describedby="phone-number-input-text"
              value={formData?.contact?.phone?.number ?? ""}
              onChange={(e) => {
                setFormData({
                  ...formData,
                  contact: {
                    ...formData?.contact,
                    phone: {
                      ...formData?.contact?.phone,
                      number: e.target.value,
                    },
                  },
                });
              }}
              sx={{ backgroundColor: "white" }}
            />
          </FormControl>
        </Grid>
        <Grid xs={12} sm={6}>
          <FormControl sx={{ width: { xs: "85%", sm: "75%" } }}>
            <FormHelperText id="address--input-help">
              <Typography color="#000" variant="caption" fontWeight={700}>
                {t("addressNameRestaurant")}
              </Typography>
              <Typography color="#ff0000" variant="caption">
                *
              </Typography>
            </FormHelperText>
            <TextField
              id="address--input"
              aria-describedby="address--input-text"
              value={formData?.address?.address ?? ""}
              onChange={(e) => {
                setFormData({
                  ...formData,
                  address: { ...formData?.address, address: e.target.value },
                });
              }}
              sx={{ backgroundColor: "white" }}
            />
          </FormControl>
        </Grid>
        <Grid xs={12} sm={6}>
          <FormControl sx={{ width: { xs: "85%", sm: "75%" } }}>
            <FormHelperText id="city-name--input-help">
              <Typography color="#000" variant="caption" fontWeight={700}>
                {t("cityRestaurant")}
              </Typography>
              <Typography color="#ff0000" variant="caption">
                *
              </Typography>
            </FormHelperText>
            <TextField
              id="city-name--input"
              aria-describedby="city-name--input-text"
              value={formData?.address?.city ?? ""}
              onChange={(e) => {
                setFormData({
                  ...formData,
                  address: { ...formData?.address, city: e.target.value },
                });
              }}
              sx={{ backgroundColor: "white" }}
            />
          </FormControl>
        </Grid>
        <Grid xs={12} sm={6}>
          <FormControl sx={{ width: { xs: "85%", sm: "75%" } }}>
            <FormHelperText id="address--input-help">
              <Typography color="#000" variant="caption" fontWeight={700}>
                {t("countryRestaurant")}
              </Typography>
              <Typography color="#ff0000" variant="caption">
                *
              </Typography>
            </FormHelperText>
            <CountrySelect
              phone={false}
              value={formData?.address?.country ?? ""}
              setValue={(value) =>
                setFormData({
                  ...formData,
                  address: { ...formData?.address, country: value },
                })
              }
              sx={{ backgroundColor: "white" }}
            />
          </FormControl>
        </Grid>
        <Grid xs={12} sm={6}>
          <FormControl sx={{ width: { xs: "85%", sm: "75%" } }}>
            <FormHelperText id="region-name--input-help">
              <Typography color="#000" variant="caption" fontWeight={700}>
                {t("regionRestaurant")}
              </Typography>
              <Typography color="#ff0000" variant="caption">
                *
              </Typography>
            </FormHelperText>
            <TextField
              id="region-name--input"
              aria-describedby="region-name--input-text"
              value={formData?.address?.region ?? ""}
              onChange={(e) => {
                setFormData({
                  ...formData,
                  address: { ...formData?.address, region: e.target.value },
                });
              }}
              sx={{ backgroundColor: "white" }}
            />
          </FormControl>
        </Grid>
        {/* <Grid xs={12} sm={6}>
          <FormControl sx={{ width: { xs: "85%", sm: "75%" } }}>
            <FormHelperText id="postal-code-name--input-help">
              <Typography color="#000" variant="caption" fontWeight={700}>
                {t("postalCodeDotRestaurant")}
              </Typography>
              <Typography color="#ff0000" variant="caption">
                *
              </Typography>
            </FormHelperText>
            <TextField
              id="postal-code-name--input"
              aria-describedby="postal-code-name--input-text"
              value={formData?.address?.postalCode ?? ""}
              onChange={(e) => {
                setFormData({
                  ...formData,
                  address: {
                    ...formData?.address,
                    postalCode: e.target.value,
                  },
                });
              }}
              sx={{ backgroundColor: "white" }}
            />
          </FormControl>
        </Grid> */}

        {/* <Grid xs={12} sm={6}>
          <FormControl sx={{ width: { xs: "85%", sm: "75%" } }}>
            <FormHelperText id="area-code-input-help">
              <Typography color="#000" variant="caption" fontWeight={700}>
                {t("areaCodeRestaurant")}
              </Typography>
              <Typography color="#ff0000" variant="caption">
                *
              </Typography>
            </FormHelperText>
            <TextField
              id="area-code-input"
              aria-describedby="area-code-input-text"
              value={formData?.contact?.phone?.areaCode ?? ""}
              onChange={(e) => {
                setFormData({
                  ...formData,
                  contact: {
                    ...formData?.contact,
                    phone: {
                      ...formData?.contact?.phone,
                      areaCode: e.target.value,
                    },
                  },
                });
              }}
              sx={{ backgroundColor: "white" }}
            />
          </FormControl>
        </Grid> */}

        <Grid xs={12} sm={6}>
          <FormControl sx={{ width: { xs: "85%", sm: "75%" } }}>
            <FormHelperText id="email-input-help">
              <Typography color="#000" variant="caption" fontWeight={700}>
                {t("restaurantEmail")}
              </Typography>
              <Typography color="#ff0000" variant="caption">
                *
              </Typography>
            </FormHelperText>
            <TextField
              id="email-input"
              aria-describedby="email-input-text"
              value={formData?.contact?.email ?? ""}
              onChange={(e) => {
                setFormData({
                  ...formData,
                  contact: {
                    ...formData?.contact,
                    email: e.target.value,
                  },
                });
              }}
              sx={{ backgroundColor: "white" }}
            />
          </FormControl>
        </Grid>

        <Grid xs={12} sm={6}>
          <FormControl sx={{ width: { xs: "85%", sm: "75%" } }}>
            <FormHelperText id="website-input-help">
              <Typography color="#000" variant="caption" fontWeight={700}>
                {t("restaurantWebsite")}
              </Typography>
              <Typography color="#ff0000" variant="caption">
                *
              </Typography>
            </FormHelperText>
            <TextField
              id="website-input"
              aria-describedby="website-input-text"
              value={formData?.contact?.website ?? ""}
              onChange={(e) => {
                setFormData({
                  ...formData,
                  contact: {
                    ...formData?.contact,
                    website: e.target.value,
                  },
                });
              }}
              sx={{ backgroundColor: "white" }}
            />
          </FormControl>
        </Grid>

        <Grid xs={12} sm={6} alignSelf={"flex-start"}>
          <FormControl sx={{ width: { xs: "85%", sm: "75%" } }}>
            <FormHelperText id="video-url-input-help">
              <Typography color="#000" variant="caption" fontWeight={700}>
                {t("videoUrlRestaurant")}
              </Typography>
              <Typography color="#ff0000" variant="caption">
                *
              </Typography>
            </FormHelperText>
            <TextField
              id="video-url-input"
              aria-describedby="video-url-input-text"
              value={formData?.videoUrl ?? ""}
              onChange={(e) => {
                setFormData({ ...formData, videoUrl: e.target.value });
              }}
              sx={{ backgroundColor: "white" }}
            />
          </FormControl>
        </Grid>
        <Grid xs={12} sm={6} sx={{ display: { xs: "none", sm: "flex" } }} />
        <Grid xs={12} sm={12}>
          <FormControl sx={{ width: { xs: "85%", sm: "88%" } }}>
            <FormHelperText id="phone-number-input-help">
              <Typography color="#000" variant="caption" fontWeight={700}>
                {t("descriptionRestaurant")}
              </Typography>
            </FormHelperText>
            <TextField
              id="description-input"
              aria-describedby="description-input-text"
              value={formData?.description ?? ""}
              onChange={(e) => {
                setFormData({ ...formData, description: e.target.value });
              }}
              multiline
              rows={3}
              sx={{ backgroundColor: "white" }}
              inputProps={{ maxLength: 400 }}
            />
            <FormHelperText>
              <Grid item xs={12} container>
                <Typography
                  variant="caption"
                  component={"span"}
                  my={1}
                  fontWeight={500}
                >
                  {formData?.description?.length ?? 0}/400
                </Typography>
              </Grid>
            </FormHelperText>
          </FormControl>
        </Grid>
      </Grid>

      <Grid
        container
        justifyContent="center"
        alignItems="center"
        xs={12}
        sx={{ width: "100vw" }}
        mb={2}
      >
        <Grid xs={4}>
          <Button
            variant="contained"
            size="large"
            onClick={() =>
              updateRestaurant({ id: formData?.id ?? 0, data: formData })
            }
            sx={{ width: "100%" }}
          >
            {t("buttonSave")}
          </Button>
        </Grid>
        <Grid xs={4}>
          <Grid xs={6}>
            <Button onClick={() => handleOpen()}>
              {t("openingClosingTime")}
            </Button>
          </Grid>
          <Modal
            keepMounted
            open={open}
            onClose={handleClose}
            aria-labelledby="keep-mounted-modal-title"
            aria-describedby="keep-mounted-modal-description"
          >
            <Box sx={style}>
              <Grid
                container
                justifyContent="flex-end"
                mb={1}
                sx={{ width: "100%" }}
              >
                <IconButton
                  sx={{ color: "rgba(255, 0, 0, 1)" }}
                  onClick={() => handleClose()}
                >
                  <ClearIcon
                    sx={{
                      width: { xs: 14, sm: 28 },
                      height: { xs: 14, sm: 28 },
                    }}
                  />
                </IconButton>
              </Grid>
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                spacing={2}
                sx={{ minWidth: "100%" }}
              >
                {/* <List> */}
                {formData?.schedule
                  ?.slice()
                  ?.sort((a, b) => a?.day - b?.day)
                  ?.map((timeline, idx) => {
                    return (
                      // <ListItem
                      //   key={idx + "0"}
                      // sx={{
                      //   backgroundColor: "white",
                      //   "&:hover": {
                      //     backgroundColor: "whitesmoke",
                      //   },
                      // }}
                      //   divider
                      // >
                      <React.Fragment key={idx}>
                        <Grid
                          xs={12}
                          sx={{
                            backgroundColor: "white",
                            "&:hover": {
                              backgroundColor: "whitesmoke",
                            },
                          }}
                        >
                          <Typography variant="h6" textTransform={"capitalize"}>
                            {getDayName(timeline?.day) + " " + timeline?.meal}
                          </Typography>
                        </Grid>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <Grid
                            xs={12}
                            key={idx + "2"}
                            sx={{
                              backgroundColor: "white",
                              "&:hover": {
                                backgroundColor: "whitesmoke",
                              },
                            }}
                          >
                            <TimePicker
                              displayStaticWrapperAs="mobile"
                              value={timeline?.startTime}
                              onChange={(newValue) => {
                                if (!newValue) {
                                  updateStartTime(idx, null);
                                }
                                updateStartTime(idx, newValue);
                              }}
                              onAccept={() => {}}
                              renderInput={(params) => (
                                <TextField {...params} />
                              )}
                            />
                          </Grid>
                          <Grid
                            xs={12}
                            key={idx + "3"}
                            sx={{
                              backgroundColor: "white",
                              "&:hover": {
                                backgroundColor: "whitesmoke",
                              },
                              pb: 1,
                            }}
                          >
                            <TimePicker
                              displayStaticWrapperAs="mobile"
                              value={timeline?.endTime}
                              onChange={(newValue) => {
                                if (!newValue) {
                                  updateEndTime(idx, null);
                                }
                                updateEndTime(idx, newValue);
                              }}
                              onAccept={() => {}}
                              renderInput={(params) => (
                                <TextField {...params} />
                              )}
                            />
                          </Grid>
                        </LocalizationProvider>
                        {/* </ListItem> */}
                      </React.Fragment>
                    );
                  })}
                {/* </List> */}
              </Grid>
              <Grid
                container
                justifyContent="center"
                alignItems="center"
                xs
                mb={1}
                m={2}
              >
                <Grid xs={6}>
                  <Button
                    variant="contained"
                    onClick={() => {
                      handleClose();
                      updateRestaurant({
                        id: formData?.id ?? 0,
                        data: formData,
                      });
                    }}
                    sx={{ width: "100%" }}
                  >
                    {t("buttonSave")}
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Modal>
        </Grid>
      </Grid>
    </Grid>
  );
};
