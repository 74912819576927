import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { API_URL } from "./env";

export const authApi = createApi({
  reducerPath: "authApi",
  keepUnusedDataFor: 0,
  baseQuery: fetchBaseQuery({
    baseUrl: API_URL,
  }),
  endpoints: (builder) => ({
    authLogin: builder.mutation({
      query: ({ data }) => ({
        url: `auth/login`,
        method: "POST",
        body: { user: data },
      }),
    }),
    authRegister: builder.mutation({
      query: ({ data }) => ({
        url: `auth/register`,
        method: "POST",
        body: { user: data },
      }),
    }),
    resendEmail: builder.mutation({
      query: ({ data }) => ({
        url: `auth/resend`,
        method: "POST",
        body: { user: data },
      }),
    }),
    passwordRecover: builder.mutation({
      query: ({ data }) => ({
        url: `auth/recover`,
        method: "POST",
        body: { user: data },
      }),
    }),
    resetPassword: builder.mutation({
      query: ({ data, token }) => ({
        url: `auth/reset/${token}`,
        method: "POST",
        body: { user: data },
      }),
    }),
  }),
});

export const {
  useAuthLoginMutation,
  useAuthRegisterMutation,
  useResendEmailMutation,
  usePasswordRecoverMutation,
  useResetPasswordMutation,
} = authApi;
