import React from "react";
import { Box, Drawer } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { RestaurantPanelData } from "features/Panel/PanelData/RestaurantPanelData";
import { ChefPanelData } from "features/Panel/PanelData/ChefPanelData";

export function Panel(props) {
  const navigate = useNavigate();
  const { sidebarIsOpened, setSidebarOpen, data, map, setMap, setZoom } = props;
  const location = useLocation();

  const toggleDrawer = (open) => (event) => {
    // Control for blind people
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setSidebarOpen(open);
    if (!location.pathname?.includes("chef")) navigate("/");
    else navigate("/chef_club");
  };

  return (
    <>
      <Drawer
        anchor={"left"}
        open={sidebarIsOpened}
        onClose={() => {
          toggleDrawer(false);
          // setZoom();
        }}
        ModalProps={{ onBackdropClick: toggleDrawer(false) }}
      >
        <Box
          sx={{
            width: { xs: "100%", sm: 400, md: 500 },
          }}
        >
          {!location.pathname?.includes("chef") ? (
            <RestaurantPanelData
              restaurant={data}
              toggleDrawer={toggleDrawer}
              map={map}
              setMap={setMap}
            />
          ) : location.pathname?.includes("chef") ? (
            <ChefPanelData chef={data} toggleDrawer={toggleDrawer} />
          ) : null}
        </Box>
      </Drawer>
    </>
  );
}
