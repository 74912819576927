import {
  Avatar,
  Button,
  Grid,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  TextField,
  Typography,
} from "@mui/material";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import { styled } from "@mui/material/styles";
import React, { useEffect, useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import { useNavigate } from "react-router-dom";
import {
  useRequestFriendshipMutation,
  useSearchContactMutation,
} from "services/contact";
import { useSnackbar } from "notistack";

export const SearchContacts = () => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [filteredData, setFilteredData] = useState([]);
  const [input, setInput] = useState("");
  const [searchUser, searchUserResults] = useSearchContactMutation();
  const [addContact, addContactResults] = useRequestFriendshipMutation();

  useEffect(() => {
    if (searchUserResults?.isSuccess) {
      setFilteredData(searchUserResults?.data?.users);
    }
  }, [searchUserResults]);

  useEffect(() => {
    if (input?.length > 0) {
      searchUser(input);
    } else {
      setFilteredData([]);
    }
  }, [input]);

  const handleAddContact = (id) => {
    addContact(id);
  };

  useEffect(() => {
    if (addContactResults?.isSuccess) {
      enqueueSnackbar(`Successfully requested`, {
        variant: "success",
        autoHideDuration: 2000,
      });
    }
    if (addContactResults?.isError) {
      enqueueSnackbar(`Could not perform the operation at the moment`, {
        variant: "error",
        autoHideDuration: 2000,
      });
    }
  }, [addContactResults]);

  const getColorBasedOnRole = (role) => {
    if (role === "customer" || role === 10) {
      return "rgba(255, 0, 0, 1)";
    }
    if (role === "restaurant" || role === 20 || role === "owner") {
      return "rgba(12, 118, 208, 1)";
    }
    if (role === "chef" || role === 30) {
      return "rgba(1, 175, 14, 1)";
    }
    if (role === "business" || role === 40) {
      return "#E6E077";
    }
  };
  return (
    <Grid container direction="row" justifyContent="center">
      <Grid
        container
        item
        xs={12}
        justifyContent="center"
        alignItems="center"
        sx={{ background: "#1291FC" }}
      >
        <Grid item xs={8} sx={{ my: 2, mx: 3 }}>
          <CssTextField
            placeholder="Search Contacts"
            onChange={(e) => setInput(e.target.value)}
            value={input}
            fullWidth
            // sx={{ backgroundColor: "#fff" }}
            inputProps={{
              style: {
                textTransform: "capitalize",
              },
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start" sx={{ ml: 1 }}>
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={2}>
          <Button sx={{ color: "#fff" }} onClick={() => navigate("/chat")}>
            Cancel
          </Button>
        </Grid>
      </Grid>
      <Grid xs={12} item container justifyContent="center" alignItems="center">
        <Grid item xs={12}>
          <Typography
            variant="h5"
            sx={{
              borderBottom: 1,
              borderColor: "#D0D0D0",
              color: "rgba(12, 118, 208, 1)",
              pb: 2,
              backgroundColor: "#f2f2f2f2",
              pt: 2,
            }}
          >
            Travelling
          </Typography>
          <List dense={false}>
            {filteredData
              ?.filter((contact) => contact?.role?.name === "customer")
              ?.map((contact, idx) => {
                return (
                  <ListItem
                    key={idx}
                    sx={{
                      borderBottom:
                        idx ===
                        filteredData?.filter(
                          (contact) => contact?.role?.name === "customer"
                        )?.length -
                          1
                          ? ""
                          : "1px solid #D0D0D0",
                    }}
                  >
                    <ListItemAvatar>
                      <Avatar
                        src={contact?.profile?.avatar?.url}
                        srcSet={contact?.profile?.avatar?.url}
                        sx={{
                          borderColor: getColorBasedOnRole(contact?.role?.name),
                          borderStyle: "solid",
                          borderWidth: { xs: "2px", sm: "5px" },
                        }}
                      >
                        {contact?.profile?.name?.charAt(0) ?? undefined}
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText>
                      <Grid container alignItems="center">
                        <Grid item xs={10}>
                          <Typography fontWeight={"bold"}>
                            {contact?.restaurant?.length > 0
                              ? `${contact?.restaurant[0].name}`
                              : `${contact?.profile?.name ?? ""} ${
                                  contact?.profile?.middleName ?? ""
                                } ${contact?.profile?.lastName ?? ""}`}
                          </Typography>
                        </Grid>
                        <Grid item xs={2}>
                          <IconButton
                            onClick={() => handleAddContact(contact?.id)}
                          >
                            <AddCircleOutlineOutlinedIcon
                              sx={{
                                width: 32,
                                height: 32,
                                color: "rgba(255, 0, 0, 1)",
                              }}
                            />
                          </IconButton>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography>
                            {contact?.restaurant?.length > 0
                              ? `${contact?.restaurant[0].address?.city} ${contact?.restaurant[0].address?.country}`
                              : `${contact?.address?.city ?? ""} ${
                                  contact?.address?.country ?? ""
                                }`}
                          </Typography>
                        </Grid>
                      </Grid>
                    </ListItemText>
                  </ListItem>
                );
              })}
          </List>
          <Typography
            variant="h5"
            sx={{
              borderBottom: 1,
              borderColor: "#D0D0D0",
              color: "rgba(12, 118, 208, 1)",
              pb: 2,
              pt: 2,
              backgroundColor: "#f2f2f2f2",
            }}
          >
            Restaurants
          </Typography>
          <List dense={false}>
            {filteredData
              ?.filter((contact) => contact?.role?.name === "owner")
              ?.map((contact, idx) => {
                return (
                  <ListItem
                    onClick={() => {
                      navigate("/chat/" + contact?.conversationId);
                    }}
                    key={idx}
                    sx={{
                      borderBottom:
                        idx ===
                        filteredData?.filter(
                          (contact) => contact?.role?.name === "owner"
                        )?.length -
                          1
                          ? ""
                          : "1px solid #D0D0D0",
                    }}
                  >
                    <ListItemAvatar>
                      <Avatar
                        src={contact?.profile?.avatar?.url}
                        srcSet={contact?.profile?.avatar?.url}
                        sx={{
                          borderColor: getColorBasedOnRole(contact?.role?.name),
                          borderStyle: "solid",
                          borderWidth: { xs: "2px", sm: "5px" },
                        }}
                      >
                        {contact?.profile?.name?.charAt(0) ?? undefined}
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText>
                      <Grid container alignItems="center">
                        <Grid item xs={10}>
                          <Typography fontWeight={"bold"}>
                            {contact?.restaurant?.length > 0
                              ? `${contact?.restaurant[0].name}`
                              : `${contact?.profile?.name ?? ""} ${
                                  contact?.profile?.middleName ?? ""
                                } ${contact?.profile?.lastName ?? ""}`}
                          </Typography>
                        </Grid>
                        <Grid item xs={2}>
                          <IconButton
                            onClick={() => handleAddContact(contact?.id)}
                          >
                            <AddCircleOutlineOutlinedIcon
                              sx={{
                                width: 32,
                                height: 32,
                                color: "rgba(12, 118, 208, 1)",
                              }}
                            />
                          </IconButton>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography>
                            {contact?.restaurant?.length > 0
                              ? `${contact?.restaurant[0].address?.city} ${contact?.restaurant[0].address?.country}`
                              : `${contact?.address?.city ?? ""} ${
                                  contact?.address?.country ?? ""
                                }`}
                          </Typography>
                        </Grid>
                      </Grid>
                    </ListItemText>
                  </ListItem>
                );
              })}
          </List>
          <Typography
            variant="h5"
            sx={{
              borderBottom: 1,
              borderColor: "#D0D0D0",
              color: "rgba(12, 118, 208, 1)",
              pb: 2,
              pt: 2,
              backgroundColor: "#F2F2F2",
            }}
          >
            Chef
          </Typography>
          <List dense={false}>
            {filteredData
              ?.filter((contact) => contact?.role?.name === "chef")
              ?.map((contact, idx) => {
                return (
                  <ListItem
                    onClick={() => {
                      navigate("/chat/" + contact?.conversationId);
                    }}
                    key={idx}
                    sx={{
                      borderBottom:
                        idx ===
                        filteredData?.filter(
                          (contact) => contact?.role?.name === "chef"
                        )?.length -
                          1
                          ? ""
                          : "1px solid #D0D0D0",
                    }}
                  >
                    <ListItemAvatar>
                      <Avatar
                        src={contact?.profile?.avatar?.url}
                        srcSet={contact?.profile?.avatar?.url}
                        sx={{
                          borderColor: getColorBasedOnRole(contact?.role?.name),
                          borderStyle: "solid",
                          borderWidth: { xs: "2px", sm: "5px" },
                        }}
                      >
                        {contact?.profile?.name?.charAt(0) ?? undefined}
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText>
                      <Grid container alignItems="center">
                        <Grid item xs={10}>
                          <Typography fontWeight={"bold"}>
                            {contact?.restaurant?.length > 0
                              ? `${contact?.restaurant[0].name}`
                              : `${contact?.profile?.name ?? ""} ${
                                  contact?.profile?.middleName ?? ""
                                } ${contact?.profile?.lastName ?? ""}`}
                          </Typography>
                        </Grid>
                        <Grid item xs={2}>
                          <IconButton
                            onClick={() => handleAddContact(contact?.id)}
                          >
                            <AddCircleOutlineOutlinedIcon
                              sx={{
                                width: 32,
                                height: 32,
                                color: "rgba(1, 175, 14, 1)",
                              }}
                            />
                          </IconButton>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography>
                            {contact?.restaurant?.length > 0
                              ? `${contact?.restaurant[0].address?.city} ${contact?.restaurant[0].address?.country}`
                              : `${contact?.address?.city ?? ""} ${
                                  contact?.address?.country ?? ""
                                }`}
                          </Typography>
                        </Grid>
                      </Grid>
                    </ListItemText>
                  </ListItem>
                );
              })}
          </List>
          <Typography
            variant="h5"
            sx={{
              borderBottom: 1,
              borderColor: "#D0D0D0",
              color: "rgba(12, 118, 208, 1)",
              pb: 2,
              pt: 2,
              backgroundColor: "#F2F2F2",
            }}
          >
            Business
          </Typography>
          <List dense={false}>
            {filteredData
              ?.filter((contact) => contact?.role?.name === "business")
              ?.map((contact, idx) => {
                return (
                  <ListItem
                    onClick={() => {
                      navigate("/chat/" + contact?.conversationId);
                    }}
                    key={idx}
                    sx={{
                      borderBottom:
                        idx ===
                        filteredData?.filter(
                          (contact) => contact?.role?.name === "business"
                        )?.length -
                          1
                          ? ""
                          : "1px solid #D0D0D0",
                    }}
                  >
                    <ListItemAvatar>
                      <Avatar
                        src={contact?.profile?.avatar?.url}
                        srcSet={contact?.profile?.avatar?.url}
                        sx={{
                          borderColor: getColorBasedOnRole(contact?.role?.name),
                          borderStyle: "solid",
                          borderWidth: { xs: "2px", sm: "5px" },
                        }}
                      >
                        {contact?.profile?.name?.charAt(0) ?? undefined}
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText>
                      <Grid container alignItems="center">
                        <Grid item xs={10}>
                          <Typography fontWeight={"bold"}>
                            {contact?.restaurant?.length > 0
                              ? `${contact?.restaurant[0].name}`
                              : `${contact?.profile?.name ?? ""} ${
                                  contact?.profile?.middleName ?? ""
                                } ${contact?.profile?.lastName ?? ""}`}
                          </Typography>
                        </Grid>
                        <Grid item xs={2}>
                          <IconButton
                            onClick={() => handleAddContact(contact?.id)}
                          >
                            <AddCircleOutlineOutlinedIcon
                              sx={{
                                width: 32,
                                height: 32,
                                color: "#E6E077",
                              }}
                            />
                          </IconButton>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography>
                            {contact?.restaurant?.length > 0
                              ? `${contact?.restaurant[0].address?.city} ${contact?.restaurant[0].address?.country}`
                              : `${contact?.address?.city ?? ""} ${
                                  contact?.address?.country ?? ""
                                }`}
                          </Typography>
                        </Grid>
                      </Grid>
                    </ListItemText>
                  </ListItem>
                );
              })}
          </List>
        </Grid>
      </Grid>
    </Grid>
  );
};

const CssTextField = styled(TextField)({
  "& label.Mui-focused": {
    color: "white",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "white",
  },
  "& .MuiOutlinedInput-root": {
    backgroundColor: "#fff",
    "& fieldset": {
      borderRadius: 8,
    },
    "&:hover fieldset": {
      borderColor: "white",
    },
    "&.Mui-focused fieldset": {
      borderColor: "white",
    },
  },
});
