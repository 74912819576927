import {
  Button,
  FormControl,
  MenuItem,
  Select,
  ThemeProvider,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  useAuthLoginMutation,
  useAuthRegisterMutation,
  useResendEmailMutation,
} from "services/authApi";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { useSnackbar } from "notistack";
import { setToken, setUser } from "services/authSlice";
import map from "assets/global-map.svg";
import logo from "assets/logo-login.svg";
import { LoginTheme } from "services/themes";

import { ProfessionalCard } from "app/Cards/Card";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Grid from "@mui/material/Unstable_Grid2";

function Copyright(props) {
  return (
    <Typography variant="body2" color="white" align="center" {...props}>
      {"Copyright © "}
      <Link color="inherit" href="https://www.mytraveldinner.com/">
        MyTravelDinner
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

export const SignUp = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [, results] = useAuthLoginMutation();
  const [loginForm, setLoginForm] = useState({
    email: "",
    password: "",
    confirmPassword: "",
    role: "",
  });
  const dispatch = useDispatch();

  const [signUp, signUpResults] = useAuthRegisterMutation();
  const [resend, resendResults] = useResendEmailMutation();
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    if (results.isSuccess) {
      dispatch(setToken(results.data?.token));
      dispatch(setUser(results.data?.user));
      enqueueSnackbar(`Successfully logged in`, {
        variant: "success",
        autoHideDuration: 2000,
      });
    } else if (results.isError) {
      setToken(null);
      enqueueSnackbar(`${results?.error?.data?.error?.message}`, {
        variant: "error",
        autoHideDuration: 2000,
      });
    }
    //eslint-disable-next-line
  }, [results]);

  const handleChange = (event) => {
    setLoginForm({ ...loginForm, role: event.target.value });
  };

  const handleSubmit = () => {
    let isValid = true;
    if (!validateEmail(loginForm.email)) {
      enqueueSnackbar(`Email is not valid`, {
        variant: "error",
        autoHideDuration: 2000,
      });
      isValid = false;
    }
    if (!checkPasswords()) {
      enqueueSnackbar(`Passwords don 't match`, {
        variant: "error",
        autoHideDuration: 2000,
      });
      isValid = false;
    }
    if (!checkPasswordLength()) {
      enqueueSnackbar(`Password is too short 6 characters at least required`, {
        variant: "error",
        autoHideDuration: 2000,
      });
      isValid = false;
    }
    if (![10, 20, 30, 40].includes(loginForm.role)) {
      enqueueSnackbar(`Role is not valid, please select a role`, {
        variant: "error",
        autoHideDuration: 2000,
      });
      isValid = false;
    }
    if (isValid) {
      signUp({
        data: {
          email: loginForm.email,
          password: loginForm.password,
          confirmPassword: loginForm.confirmPassword,
          role: getRoleId(loginForm.role),
        },
      });
    }
  };

  useEffect(() => {
    if (signUpResults.isError) {
      enqueueSnackbar(`${signUpResults.error.data.error.message}`, {
        variant: "error",
        autoHideDuration: 2000,
      });
    }
    if (signUpResults.isSuccess) setSuccess(true);
  }, [signUpResults]);
  useEffect(() => {
    if (resendResults.isError) {
      enqueueSnackbar(`${resendResults.error.data.error.message}`, {
        variant: "error",
        autoHideDuration: 2000,
      });
    }
    if (resendResults.isSuccess) {
      enqueueSnackbar(`Successfully resent it`, {
        variant: "success",
        autoHideDuration: 2000,
      });
    }
  }, [resendResults]);

  const getRoleId = (role) => {
    if (role === 10) return "customer";
    if (role === 20) return "owner";
    if (role === 30) return "chef";
    if (role === 40) return "business";
  };

  const checkPasswords = () => {
    if (loginForm.confirmPassword !== loginForm.password) {
      return false;
    }
    return true;
  };
  const checkPasswordLength = () => {
    if (loginForm.password.length < 6) {
      return false;
    }
    return true;
  };

  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
      sx={{
        height: "100vh",
        width: "100vw",
        backgroundColor: "#0171bb",
        backgroundImage: "url(" + map + ")",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
      }}
    >
      <Grid
        xs
        sx={{
          backgroundColor: "rgba(0,0,0,0.45)",
          borderRadius: 5,
          opacity: "0.9",
          zIndex: 1,
          maxWidth: { xs: 300, md: "30vw" },
          maxHeight: { xs: 700, md: "70vh" },
          height: "100%",
        }}
      >
        <Container component="main" maxWidth="md" sx={{ opacity: 1 }}>
          <CssBaseline />
          <ThemeProvider theme={LoginTheme}>
            <Grid
              container
              direction="column"
              justifyContent="space-around"
              alignItems="center"
              gap={4}
            >
              <Grid
                xs
                sx={{
                  maxWidth: { xs: 200, sm: 250 },
                  marginTop: 5,
                  display: loginForm.role ? "none" : "block",
                }}
              >
                <img src={logo} alt="Login logo" style={{ width: "80%" }} />
              </Grid>

              <Grid
                xs
                sx={{
                  maxWidth: { xs: 200, sm: 250 },
                  display: loginForm.role ? "none" : "block",
                }}
              >
                <Typography
                  component="h1"
                  variant="h4"
                  color={"white"}
                  sx={{ fontWeight: 600, textTransform: "capitalize" }}
                >
                  {t("signUp")}
                </Typography>
              </Grid>

              {loginForm.role && (
                <Grid
                  item
                  xs={12}
                  container
                  justifyContent="center"
                  alignItems={"center"}
                >
                  <Box sx={{ mt: 4 }}>
                    <ProfessionalCard role={loginForm.role} />
                  </Box>
                </Grid>
              )}

              <Grid xs>
                {!success && (
                  <>
                    <Box sx={{ mt: 1, width: "100%" }}>
                      <FormControl fullWidth>
                        <Select
                          required
                          fullWidth
                          value={loginForm.role}
                          placeholder="Role"
                          color="secondary"
                          onChange={handleChange}
                          defaultValue={10}
                          sx={{
                            borderRadius: 2,
                            backgroundColor: "#fcfcfb",
                            textAlign: "left",
                          }}
                        >
                          <MenuItem value={10}>{t("Customer")}</MenuItem>
                          <MenuItem value={20}>{t("Restaurant")}</MenuItem>
                          <MenuItem value={30}>{t("Chef")}</MenuItem>
                          <MenuItem value={40}>{t("Business")}</MenuItem>
                        </Select>
                      </FormControl>
                      <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        label="Email Address"
                        name="email"
                        autoComplete="email"
                        color="primary"
                        onChange={(e) =>
                          setLoginForm({
                            ...loginForm,
                            email: e.target.value,
                          })
                        }
                        value={loginForm.email}
                        variant="filled"
                        sx={{ borderRadius: 2, backgroundColor: "#fcfcfb" }}
                      />
                      <TextField
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label="Password"
                        type="password"
                        id="password"
                        autoComplete="current-password"
                        variant="filled"
                        color="primary"
                        onChange={(e) =>
                          setLoginForm({
                            ...loginForm,
                            password: e.target.value,
                          })
                        }
                        value={loginForm.password}
                        sx={{ borderRadius: 2, backgroundColor: "#fcfcfb" }}
                      />
                      <TextField
                        margin="normal"
                        required
                        fullWidth
                        name="confirmpassword"
                        label="Confirm Password"
                        type="password"
                        id="confirmpassword"
                        autoComplete="confirm-password"
                        variant="filled"
                        color="primary"
                        onChange={(e) =>
                          setLoginForm({
                            ...loginForm,
                            confirmPassword: e.target.value,
                          })
                        }
                        value={loginForm.confirmPassword}
                        sx={{ borderRadius: 2, backgroundColor: "#fcfcfb" }}
                      />

                      <Button
                        fullWidth
                        variant="contained"
                        sx={{
                          mt: 3,
                          mb: 2,
                          "&.Mui-disabled": {
                            background: "rgba(255, 0, 0, 0.5)",
                            color: "#c0c0c0",
                          },
                        }}
                        onClick={(e) => handleSubmit(e)}
                        type="button"
                      >
                        {t("signUp")}
                      </Button>

                      <Grid container justifyContent="center">
                        <Grid item xs={12}>
                          <Typography color="white">
                            Already a member?{" "}
                            <Button onClick={() => navigate("/auth/login")}>
                              Log in
                            </Button>
                          </Typography>
                        </Grid>
                      </Grid>
                    </Box>
                  </>
                )}
                {success && (
                  <>
                    <Typography color="white" mt={2} maxWidth={300}>
                      Please check your email to verify your account. If you don
                      ' t receive it please feel free to resend it clicking
                      <Button
                        onClick={() =>
                          resend({ data: { email: loginForm.email } })
                        }
                      >
                        here
                      </Button>
                      .
                    </Typography>
                    <Grid container justifyContent="center">
                      <Grid item xs={12}>
                        <Typography color="white">
                          Already a member?{" "}
                          <Button onClick={() => navigate("/auth/login")}>
                            Log in
                          </Button>
                        </Typography>
                      </Grid>
                    </Grid>
                  </>
                )}
              </Grid>
            </Grid>
          </ThemeProvider>

          <Copyright sx={{ display: loginForm.role ? "none" : "block" }} />
        </Container>
      </Grid>
    </Grid>
  );
};
