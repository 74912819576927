import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { API_URL } from "./env";
import { getValue } from "./storage";
export const restaurantsApi = createApi({
  reducerPath: "restaurantApi",
  baseQuery: fetchBaseQuery({
    baseUrl: API_URL,
    prepareHeaders: (headers) => {
      const token = getValue("_auth");
      headers.set("Authorization", `Bearer ${token}`);
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getAllRestaurants: builder.query({
      query: () => {
        return { url: `restaurants` };
      },
    }),
    getRestaurant: builder.query({
      query: (id) => {
        return { url: `restaurants/${id}` };
      },
    }),
    likeRestaurant: builder.mutation({
      query: ({ id }) => ({
        url: `restaurants/${id}/like`,
        method: "POST",
      }),
    }),
    unLikeRestaurant: builder.mutation({
      query: ({ id }) => ({
        url: `restaurants/${id}/like`,
        method: "DELETE",
      }),
    }),
    updateRestaurant: builder.mutation({
      query: ({ id, data }) => ({
        url: `restaurants/${id}`,
        method: "PUT",
        body: { restaurant: data },
      }),
    }),
    deleteRestaurantImage: builder.mutation({
      query: ({ id, imageid }) => ({
        url: `restaurants/${id}/images/${imageid}`,
        method: "DELETE",
      }),
    }),
    createRatings: builder.mutation({
      query: ({ id, ratings }) => ({
        url: `restaurants/${id}/rate`,
        method: "POST",
        body: { ratings },
      }),
    }),
    deleteRatings: builder.mutation({
      query: ({ id }) => ({
        url: `restaurants/${id}/rate/`,
        method: "DELETE",
      }),
    }),
    getRatings: builder.mutation({
      query: ({ id }) => ({
        url: `restaurants/${id}/rate/`,
        method: "GET",
      }),
    }),
  }),
});

export const {
  useGetAllRestaurantsQuery,
  useGetRestaurantQuery,
  useLikeRestaurantMutation,
  useUnLikeRestaurantMutation,
  useUpdateRestaurantMutation,
  useDeleteRestaurantImageMutation,
  useCreateRatingsMutation,
  useGetRatingsMutation,
} = restaurantsApi;
