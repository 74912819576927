import React from "react";
import { Grid, Button } from "@mui/material";
import PersonAddAlt1Icon from "@mui/icons-material/PersonAddAlt1";
import PersonIcon from "@mui/icons-material/Person";
import { useSnackbar } from "notistack";
import chatIcon from "assets/2023/chat.svg";
const blueButton = {
  color: "#1291FC",
  "&:hover": {
    color: "#A50707",
  },
  width: "100%",
  height: 40,
};

export function ChefBar(props) {
  const { enqueueSnackbar } = useSnackbar();
  const { handleCreateConversation, handleAddContact, status, setOpen } = props;

  const alreadyFriends = () =>
    enqueueSnackbar(`You are already in contact`, {
      variant: "error",
      autoHideDuration: 2000,
    });
  return (
    <Grid
      container
      direction="row"
      pt={1}
      spacing={1}
      justifyContent="space-around"
    >
      {/* <Grid item container justifyContent="center" xs={5}>
        {status ? (
          <Button
            sx={blueButton}
            variant="outlined"
            startIcon={<PersonIcon />}
            onClick={() => alreadyFriends()}
          ></Button>
        ) : (
          <Button
            sx={blueButton}
            variant="outlined"
            startIcon={<PersonAddAlt1Icon />}
            onClick={() => handleAddContact()}
          >
            Add
          </Button>
        )}
      </Grid>
      <Grid item xs={5} container justifyContent="center">
        <Button
          sx={blueButton}
          variant="outlined"
          startIcon={<img src={chatIcon} alt="" />}
          onClick={() => {
            handleCreateConversation();
            setOpen(false);
          }}
        ></Button>
      </Grid> */}
    </Grid>
  );
}
