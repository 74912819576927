import React from "react";
import { Marker } from "@react-google-maps/api";
import restaurantLogo from "assets/map-marker.svg";
import chefLogo from "assets/chef-marker.svg";
import { useResolvedPath } from "react-router-dom";

export function PositionMarker(props) {
  const {
    lat,
    lng,
    chef,
    restaurant,
    setRestaurant,
    showPanel,
    map,
    setMap,
    clusterer,
    active,
    showSignUpPopUp,
  } = props;
  const url = useResolvedPath().pathname;
  const icon = url === "/chef_club" ? chefLogo : restaurantLogo;

  const position = {
    lat: parseFloat(lat),
    lng: parseFloat(lng),
  };

  return (
    <Marker
      position={position}
      icon={icon}
      clusterer={clusterer}
      onMouseDown={() => {
        if (active) {
          setMap([position.lat, position.lng, map[2] + 3]);
          setRestaurant(chef ?? restaurant);
          showPanel(true);
        } else {
          showSignUpPopUp(true);
        }
      }}
    />
  );
}
