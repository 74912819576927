import React, { useEffect } from "react";
import {
  Button,
  FormControl,
  FormHelperText,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import CountrySelect from "app/Navbar/CountrySelect";
import { useTranslation } from "react-i18next";
import { Controller, useForm } from "react-hook-form";

export const User = ({ formData, setFormData, updateUser }) => {
  const { t } = useTranslation();
  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
  } = useForm({
    name: "",
    middleName: "",
    lastName: "",
    phoneCountryCode: "",
    phoneAreaCode: "",
    phoneNumber: "",
    address: "",
    addressCity: "",
    addressRegion: "",
    addressCountry: "",
    addressPostalCode: "",
    business: "",
    businessName: "",
  });

  const onSubmit = (data) => {
    updateUser({ data: formData });
  };

  useEffect(() => {
    setValue("name", formData?.profile?.name ?? "");
    setValue("middleName", formData?.profile?.middleName ?? "");
    setValue("lastName", formData?.profile?.lastName ?? "");
    setValue(
      "phoneCountryCode",
      formData?.phone?.countryCode?.startsWith("+")
        ? formData?.phone?.countryCode?.substring(
            1,
            formData?.phone?.countryCode?.length
          )
        : formData?.phone?.countryCode ?? ""
    );
    setValue("phoneAreaCode", formData?.phone?.areaCode ?? "");
    setValue("phoneNumber", formData?.phone?.number ?? "");
    setValue("address", formData?.address?.address ?? "");
    setValue("addressCity", formData?.address?.city ?? "");
    setValue("addressRegion", formData?.address?.region ?? "");
    setValue("addressCountry", formData?.address?.country ?? "");
    setValue("addressPostalCode", formData?.address?.postalCode ?? "");
    setValue("business", formData?.business ?? "");
    setValue("businessName", formData?.businessName ?? "");
  }, [formData]);

  return (
    <Grid
      sx={{ width: "100%", pt: { xs: 4 } }}
      container
      direction="column"
      alignItems="center"
      component="form"
      onSubmit={handleSubmit(onSubmit)}
      spacing={{ xs: 0, sm: 4 }}
    >
      <Grid
        container
        justifyContent="space-around"
        alignItems="center"
        sx={{ width: "100%", ml: { xs: "0.2%" } }}
        spacing={4}
      >
        <Grid xs={12} sm={6}>
          <FormControl sx={{ width: { xs: "85%", sm: "75%" } }}>
            <FormHelperText id="first-nameinput-help">
              <Typography color="#000" variant="caption" fontWeight={700}>
                {t("firstName")}
              </Typography>
              <Typography color="#ff0000" variant="caption">
                *
              </Typography>
            </FormHelperText>
            <Controller
              name="name"
              control={control}
              rules={{ required: true }}
              render={({ field: { ref, ...field } }) => (
                <TextField
                  id="user-first-name-input"
                  aria-describedby="user-first-name-input-helper-text"
                  value={formData?.profile?.name ?? ""}
                  onChange={(e) => {
                    field.onChange(e);
                    setFormData({
                      ...formData,
                      profile: { ...formData?.profile, name: e.target.value },
                    });
                  }}
                  error={!!errors.name}
                  sx={{ backgroundColor: "white" }}
                />
              )}
            />
          </FormControl>
        </Grid>
        {/* <Grid xs={12} sm={6}>
          <FormControl sx={{ width: "85%" }}>
            <FormHelperText id="user-middle-input">
              {t("middleName")}*
            </FormHelperText>
            <Controller
              name="middleName"
              control={control}
              rules={{ required: false }}
              render={({ field: { ref, ...field } }) => (
                <TextField
                  id="user-middle-input"
                  aria-describedby="user-middle-input-helper-text"
                  value={formData?.profile?.middleName ?? ""}
                  onChange={(e) => {
                    field.onChange(e);
                    setFormData({
                      ...formData,
                      profile: {
                        ...formData?.profile,
                        middleName: e.target.value,
                      },
                    });
                  }}
                  error={!!errors.middleName}
                  sx={{ backgroundColor: "white" }}
                />
              )}
            />
          </FormControl>
        </Grid> */}
        <Grid xs={12} sm={6}>
          <FormControl sx={{ width: { xs: "85%", sm: "75%" } }}>
            <FormHelperText id="user-last-name-input">
              <Typography color="#000" variant="caption" fontWeight={700}>
                {t("lastName")}
              </Typography>
              <Typography color="#ff0000" variant="caption">
                *
              </Typography>
            </FormHelperText>
            <Controller
              name="lastName"
              control={control}
              rules={{ required: true }}
              render={({ field: { ref, ...field } }) => (
                <TextField
                  id="user-last-name-input"
                  aria-describedby="user-last-name-input-helper-text"
                  value={formData?.profile?.lastName ?? ""}
                  onChange={(e) => {
                    field.onChange(e);
                    setFormData({
                      ...formData,
                      profile: {
                        ...formData?.profile,
                        lastName: e.target.value,
                      },
                    });
                  }}
                  error={!!errors.lastName}
                  sx={{ backgroundColor: "white" }}
                />
              )}
            />
          </FormControl>
        </Grid>
        <Grid xs={12} sm={6}>
          <FormControl sx={{ width: { xs: "85%", sm: "75%" } }}>
            <FormHelperText id="email-input">
              <Typography color="#000" variant="caption" fontWeight={700}>
                {t("email")}
              </Typography>
              <Typography color="#ff0000" variant="caption">
                *
              </Typography>
            </FormHelperText>
            <TextField
              id="email-input"
              aria-describedby="email-input-helper-text"
              value={formData?.email ?? ""}
              disabled
              onChange={(e) => {
                setFormData({
                  ...formData,
                  email: e.target.value,
                });
              }}
              sx={{ backgroundColor: "white" }}
            />
          </FormControl>
        </Grid>
        <Grid xs={12} sm={6} sx={{ display: { sm: "flex", xs: "none" } }} />
        <Grid xs={12} sm={6}>
          <FormControl sx={{ width: { xs: "85%", sm: "75%" } }}>
            <FormHelperText id="area-code-input-help">
              <Typography color="#000" variant="caption" fontWeight={700}>
                {t("countryCode")}
              </Typography>
              <Typography color="#ff0000" variant="caption">
                *
              </Typography>
            </FormHelperText>
            <Controller
              name="phoneCountryCode"
              control={control}
              rules={{ required: true }}
              render={({ field: { ref, ...field } }) => (
                <CountrySelect
                  required={true}
                  error={!!errors.phoneCountryCode}
                  phone={true}
                  value={
                    (formData?.phone?.countryCode?.startsWith("+")
                      ? formData?.phone?.countryCode?.substring(
                          1,
                          formData?.phone?.countryCode?.length
                        )
                      : formData?.phone?.countryCode) ?? ""
                  }
                  onChange={field.onChange}
                  setValue={(value) =>
                    setFormData({
                      ...formData,
                      phone: {
                        ...formData?.phone,
                        countryCode: value,
                      },
                    })
                  }
                  sx={{ backgroundColor: "white" }}
                />
              )}
            />
          </FormControl>
        </Grid>
        <Grid xs={12} sm={6}>
          <FormControl sx={{ width: { xs: "85%", sm: "75%" } }}>
            <FormHelperText id="phone-name-input">
              <Typography color="#000" variant="caption" fontWeight={700}>
                {t("phone")}
              </Typography>
              <Typography color="#ff0000" variant="caption">
                *
              </Typography>
            </FormHelperText>
            <Controller
              name="phoneNumber"
              control={control}
              rules={{ required: true }}
              render={({ field: { ref, ...field } }) => (
                <TextField
                  id="phone-input"
                  aria-describedby="phone-input-helper-text"
                  value={formData?.phone?.number ?? ""}
                  onChange={(e) => {
                    field.onChange(e);
                    setFormData({
                      ...formData,
                      phone: { ...formData?.phone, number: e.target.value },
                    });
                  }}
                  error={!!errors.phoneNumber}
                  sx={{ backgroundColor: "white" }}
                />
              )}
            />
          </FormControl>
        </Grid>
        {formData?.role?.name === "business" && (
          <>
            <Grid xs={12} sm={6}>
              <FormControl sx={{ width: { xs: "85%", sm: "75%" } }}>
                <FormHelperText id="business-type-text">
                  <Typography color="#000" variant="caption" fontWeight={700}>
                    {t("businessType")}
                  </Typography>
                  <Typography color="#ff0000" variant="caption">
                    *
                  </Typography>
                </FormHelperText>
                <Controller
                  name="business"
                  control={control}
                  rules={{ required: false }}
                  render={({ field: { ref, ...field } }) => (
                    <Select
                      id="business-type-select"
                      onChange={(e) => {
                        field.onChange(e);
                        setFormData({
                          ...formData,
                          profile: {
                            ...formData?.profile,
                            businessType: e.target.value,
                          },
                        });
                      }}
                      value={formData?.profile?.businessType ?? ""}
                      sx={{
                        textTransform: "capitalize",
                        backgroundColor: "white",
                      }}
                      error={!!errors.business}
                    >
                      {[
                        "sommelier",
                        "wineMakingCompany",
                        "wineDistributor",
                        "beerCompany",
                      ].map((business) => (
                        <MenuItem
                          key={business}
                          value={business}
                          sx={{ textTransform: "capitalize" }}
                        >
                          {t(business)}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                />
              </FormControl>
            </Grid>
            <Grid xs={12} sm={6}>
              <FormControl sx={{ width: { xs: "85%", sm: "75%" } }}>
                <FormHelperText id="business-name-input">
                  <Typography color="#000" variant="caption" fontWeight={700}>
                    {t("businessName")}
                  </Typography>
                  <Typography color="#ff0000" variant="caption">
                    *
                  </Typography>
                </FormHelperText>
                <Controller
                  name="businessName"
                  control={control}
                  rules={{ required: false }}
                  render={({ field: { ref, ...field } }) => (
                    <TextField
                      id="business-name-input"
                      aria-describedby="business-name-input-helper-text"
                      value={formData?.profile?.businessName ?? ""}
                      onChange={(e) => {
                        field.onChange(e);
                        setFormData({
                          ...formData,
                          profile: {
                            ...formData?.profile,
                            businessName: e.target.value,
                          },
                        });
                      }}
                      error={!!errors.businessName}
                      sx={{ backgroundColor: "white" }}
                    />
                  )}
                />
              </FormControl>
            </Grid>
            <Grid xs={12} sm={4} />
          </>
        )}
        {/* <Grid xs={12} sm={6}>
          <FormControl sx={{ width: { xs: "85%", sm: "75%" } }}>
            <FormHelperText id="area-code-input-help">
              <Typography color="#000" variant="caption" fontWeight={700}>
                {t("areaCode")}
              </Typography>
              <Typography color="#ff0000" variant="caption">
                *
              </Typography>
            </FormHelperText>
            <Controller
              name="phoneAreaCode"
              control={control}
              rules={{ required: true }}
              render={({ field: { ref, ...field } }) => (
                <TextField
                  id="area-code-input"
                  aria-describedby="area-code-input-text"
                  value={formData?.phone?.areaCode ?? ""}
                  onChange={(e) => {
                    field.onChange(e);
                    setFormData({
                      ...formData,
                      phone: {
                        ...formData?.phone,
                        areaCode: e.target.value,
                      },
                    });
                  }}
                  error={!!errors.phoneAreaCode}
                  sx={{ backgroundColor: "white" }}
                />
              )}
            />
          </FormControl>
        </Grid> */}

        <Grid xs={12} sm={6}>
          <FormControl sx={{ width: { xs: "85%", sm: "75%" } }}>
            <FormHelperText id="billing-address-name-input">
              <Typography color="#000" variant="caption" fontWeight={700}>
                {t("address")}
              </Typography>
              <Typography color="#ff0000" variant="caption">
                *
              </Typography>
            </FormHelperText>
            <Controller
              name="address"
              control={control}
              rules={{ required: true }}
              render={({ field: { ref, ...field } }) => (
                <TextField
                  id="billing-address-name-input"
                  aria-describedby="billing-address-name-input-helper-text"
                  value={formData?.address?.address ?? ""}
                  onChange={(e) => {
                    field.onChange(e);
                    setFormData({
                      ...formData,
                      address: {
                        ...formData?.address,
                        address: e.target.value,
                      },
                    });
                  }}
                  error={!!errors.address}
                  sx={{ backgroundColor: "white" }}
                />
              )}
            />
          </FormControl>
        </Grid>
        <Grid
          xs={12}
          sm={6}
          container
          justifyContent="flex-start"
          alignItems="center"
          sx={{ display: { xs: "none", sm: "flex" } }}
        >
          <Grid
            item
            xs={12}
            sm={6}
            smOffset={1}
            justifyContent="center"
            alignItems="center"
          >
            <FormControl sx={{ width: { xs: "85%", sm: "78%" } }}>
              <FormHelperText id="billing-city-name-input">
                <Typography color="#000" variant="caption" fontWeight={700}>
                  {t("city")}
                </Typography>
                <Typography color="#ff0000" variant="caption">
                  *
                </Typography>
              </FormHelperText>
              <Controller
                name="addressCity"
                control={control}
                rules={{ required: true }}
                render={({ field: { ref, ...field } }) => (
                  <TextField
                    id="billing-city-name-input"
                    aria-describedby="billing-city-input-helper-text"
                    value={formData?.address?.city ?? ""}
                    onChange={(e) => {
                      field.onChange(e);
                      setFormData({
                        ...formData,
                        address: {
                          ...formData?.address,
                          city: e.target.value,
                        },
                      });
                    }}
                    error={!!errors.addressCity}
                    sx={{ backgroundColor: "white" }}
                  />
                )}
              />
            </FormControl>
          </Grid>
          <Grid
            item
            xs={12}
            sm={4}
            justifyContent="center"
            alignItems="center"
          >
            <FormControl sx={{ width: { xs: "85%", sm: "66%" } }}>
              <FormHelperText id="postal-code-name-input">
                <Typography color="#000" variant="caption" fontWeight={700}>
                  {t("postalCodeDot")}
                </Typography>
                <Typography color="#ff0000" variant="caption">
                  *
                </Typography>
              </FormHelperText>
              <Controller
                name="addressPostalCode"
                control={control}
                rules={{ required: true }}
                render={({ field: { ref, ...field } }) => (
                  <TextField
                    id="postal-code-name-input"
                    aria-describedby="postal-code-name-input-helper-text"
                    value={formData?.address?.postalCode ?? ""}
                    onChange={(e) => {
                      field.onChange(e);
                      setFormData({
                        ...formData,
                        address: {
                          ...formData?.address,
                          postalCode: e.target.value,
                        },
                      });
                    }}
                    error={!!errors.addressPostalCode}
                    sx={{ backgroundColor: "white" }}
                  />
                )}
              />
            </FormControl>
          </Grid>
        </Grid>
        <Grid xs={12} sm={6} sx={{ display: { xs: "block", sm: "none" } }}>
          <FormControl sx={{ width: { xs: "85%", sm: "75%" } }}>
            <FormHelperText id="billing-city-name-input">
              <Typography color="#000" variant="caption" fontWeight={700}>
                {t("city")}
              </Typography>
              <Typography color="#ff0000" variant="caption">
                *
              </Typography>
            </FormHelperText>
            <Controller
              name="addressCity"
              control={control}
              rules={{ required: true }}
              render={({ field: { ref, ...field } }) => (
                <TextField
                  id="billing-city-name-input"
                  aria-describedby="billing-city-input-helper-text"
                  value={formData?.address?.city ?? ""}
                  onChange={(e) => {
                    field.onChange(e);
                    setFormData({
                      ...formData,
                      address: {
                        ...formData?.address,
                        city: e.target.value,
                      },
                    });
                  }}
                  error={!!errors.addressCity}
                  sx={{ backgroundColor: "white" }}
                />
              )}
            />
          </FormControl>
        </Grid>
        <Grid xs={12} sm={6} sx={{ display: { xs: "block", sm: "none" } }}>
          <FormControl sx={{ width: { xs: "85%", sm: "75%" } }}>
            <FormHelperText id="postal-code-name-input">
              <Typography color="#000" variant="caption" fontWeight={700}>
                {t("postalCodeDot")}
              </Typography>
              <Typography color="#ff0000" variant="caption">
                *
              </Typography>
            </FormHelperText>
            <Controller
              name="addressPostalCode"
              control={control}
              rules={{ required: true }}
              render={({ field: { ref, ...field } }) => (
                <TextField
                  id="postal-code-name-input"
                  aria-describedby="postal-code-name-input-helper-text"
                  value={formData?.address?.postalCode ?? ""}
                  onChange={(e) => {
                    field.onChange(e);
                    setFormData({
                      ...formData,
                      address: {
                        ...formData?.address,
                        postalCode: e.target.value,
                      },
                    });
                  }}
                  error={!!errors.addressPostalCode}
                  sx={{ backgroundColor: "white" }}
                />
              )}
            />
          </FormControl>
        </Grid>
        <Grid xs={12} sm={6}>
          <FormControl sx={{ width: { xs: "85%", sm: "75%" } }}>
            <FormHelperText id="billing-country-name-input-helper">
              <Typography color="#000" variant="caption" fontWeight={700}>
                {t("country")}
              </Typography>
              <Typography color="#ff0000" variant="caption">
                *
              </Typography>
            </FormHelperText>
            <Controller
              name="addressCountry"
              control={control}
              rules={{ required: true }}
              render={({ field: { ref, ...field } }) => (
                <CountrySelect
                  phone={false}
                  id="billing-country-name-input"
                  aria-describedby="billing-country-name-input-helper-text"
                  value={formData?.address?.country ?? ""}
                  onChange={field.onChange}
                  setValue={(value) =>
                    setFormData({
                      ...formData,
                      address: {
                        ...formData?.address,
                        country: value,
                      },
                    })
                  }
                  error={!!errors.addressCountry}
                  sx={{ backgroundColor: "white" }}
                />
              )}
            />
          </FormControl>
        </Grid>
        <Grid xs={12} sm={6}>
          <FormControl sx={{ width: { xs: "85%", sm: "75%" } }}>
            <FormHelperText id="billing-state-name-input">
              <Typography color="#000" variant="caption" fontWeight={700}>
                {t("stateRegion")}
              </Typography>
              <Typography color="#ff0000" variant="caption">
                *
              </Typography>
            </FormHelperText>
            <Controller
              name="addressRegion"
              control={control}
              rules={{ required: true }}
              render={({ field: { ref, ...field } }) => (
                <TextField
                  id="billing-state-name-input"
                  aria-describedby="billing-state-name-input-helper-text"
                  value={formData?.address?.region ?? ""}
                  onChange={(e) => {
                    field.onChange(e);
                    setFormData({
                      ...formData,
                      address: {
                        ...formData?.address,
                        region: e.target.value,
                      },
                    });
                  }}
                  error={!!errors.addressRegion}
                  sx={{ backgroundColor: "white" }}
                />
              )}
            />
          </FormControl>
        </Grid>
        <Grid xs={12} sm={6} />

        <Grid xs={12} mt={6} mb={6}>
          <Button
            variant="contained"
            size="large"
            type="submit"
            sx={{ backgroundColor: "#FD0000" }}
          >
            {t("save")}
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};
