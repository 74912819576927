import React, { useEffect } from "react";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";
import moment from "moment";
import { extendMoment } from "moment-range";
import { Button, Grid, Typography } from "@mui/material";
import objectSupport from "dayjs/plugin/objectSupport";
import { useTranslation } from "react-i18next";

export default function TimePicker(props) {
  const { t } = useTranslation();
  const { value, setValue, setActiveStep, schedule } = props;

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        mt={2}
        spacing={2}
      >
        <Grid
          item
          xs={12}
          container
          justifyContent="center"
          alignItems="center"
        >
          <Typography>{t("lunch")}</Typography>
          <Grid item xs={12} container>
            {schedule
              ?.filter(
                (schedule) =>
                  schedule?.meal === "lunch" &&
                  schedule?.day === value?.get("day")
              )
              ?.map((schedule) => {
                const start = moment(schedule?.startTime);
                const end = moment(schedule?.endTime);
                const range = extendMoment(moment).range(start, end);
                const time_slots = Array.from(
                  range.by("minutes", { step: 15 })
                );
                if (time_slots?.length === 0)
                  return (
                    <Grid item xs container justifyContent="center">
                      <Typography>{t("noTimeSlotsAvailable")}</Typography>
                    </Grid>
                  );
                return time_slots?.map((me, idx) => (
                  <Grid
                    key={idx}
                    item
                    xs={4}
                    container
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Button
                      sx={{ border: "1px solid gray", m: 0.2 }}
                      fullWidth
                      onClick={() => {
                        dayjs.extend(objectSupport);
                        setValue(
                          dayjs({
                            year: value?.get("year"),
                            month: value?.get("month"),
                            day: value?.get("date"),
                            hour: me.hours(),
                            minute: me.minutes(),
                            second: 0,
                            millisecond: 0,
                          })
                        );
                        console.log(
                          dayjs({
                            year: value?.get("year"),
                            month: value?.get("month"),
                            day: value?.get("date"),
                            hour: me.hours(),
                            minute: me.minutes(),
                            second: 0,
                            millisecond: 0,
                          }).format("YYYY-DD-MM hh:mm A")
                        );
                        setActiveStep(2);
                      }}
                    >
                      {me.format("hh:mm A").toLocaleString()}
                    </Button>
                  </Grid>
                ));
              })}
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          container
          justifyContent="center"
          alignItems="center"
        >
          <Typography>{t("dinner")}</Typography>

          <Grid item xs={12} container>
            {schedule
              ?.filter(
                (schedule) =>
                  schedule?.meal === "dinner" &&
                  schedule?.day === value?.get("day")
              )
              ?.map((schedule) => {
                const start = moment(schedule?.startTime);
                const end = moment(schedule?.endTime);
                const range = extendMoment(moment).range(start, end);
                const time_slots = Array.from(
                  range.by("minutes", { step: 15 })
                );
                if (time_slots?.length === 0)
                  return (
                    <Grid item xs container justifyContent="center">
                      <Typography>{t("noTimeSlotsAvailable")}</Typography>
                    </Grid>
                  );
                return time_slots?.map((me, idx) => (
                  <Grid
                    key={idx}
                    item
                    xs={4}
                    container
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Button
                      sx={{ border: "1px solid gray", m: 0.2 }}
                      fullWidth
                      onClick={() => {
                        dayjs.extend(objectSupport);
                        setValue(
                          dayjs({
                            year: value?.get("year"),
                            month: value?.get("month"),
                            day: value?.get("date"),
                            hour: me.hours(),
                            minute: me.minutes(),
                            second: 0,
                            millisecond: 0,
                          })
                        );
                        setActiveStep(2);
                      }}
                    >
                      {me.format("hh:mm A").toLocaleString()}
                    </Button>
                  </Grid>
                ));
              })}
          </Grid>
        </Grid>
      </Grid>
    </LocalizationProvider>
  );
}
