import "react-chat-elements/dist/main.css";
import React, { useEffect, useRef, useState } from "react";
import {
  IconButton,
  TextField,
  Typography,
  Avatar,
  Input,
  InputLabel,
  Modal,
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import ClearIcon from "@mui/icons-material/Clear";
import { MessageBox } from "react-chat-elements";
import SendIcon from "@mui/icons-material/Send";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import { grey } from "@mui/material/colors";
import { Waypoint } from "react-waypoint";
import { Chefcard } from "features/ChefCard/Chefcard";
import { Box } from "@mui/system";
import { ArrowBackIos } from "@mui/icons-material";
import { io } from "socket.io-client";

export const ChatConversation = (props) => {
  const [image, setImage] = useState();
  const [previewModalOpen, setPreviewModalOpen] = useState(false);
  const [open, setOpen] = useState(false);
  const divToBottom = useRef(null);
  const {
    conversationDetails,
    message,
    setMessage,
    handleOpenPreview,
    setFileToUpload,
    sendMessage,
    setConversation,
    setConversationDetails,
    conversation,
  } = props;

  const scroll = () =>
    window.scrollTo(0, document.body.scrollHeight + 99999999999999999999999999);
  useEffect(() => {
    scroll();
  }, []);

  useEffect(() => {
    const socket = io(process.env.REACT_APP_SOCKET_URL);
    socket.on("message", (msg) => {
      if (
        msg?.conversationId === conversation &&
        conversationDetails?.conversation !== undefined
      ) {
        const messages = conversationDetails.conversation.messages.slice();
        messages.push({
          id: msg?.id,
          content: msg?.content,
          file: msg?.file,
          seenAt: msg?.seenAt,
          sentAt: msg?.sentAt,
          senderId: msg?.senderId,
        });

        setConversationDetails({
          ...conversationDetails,
          conversation: {
            ...conversationDetails?.conversation,
            messages: messages,
          },
        });
      }
    });
  }, [conversationDetails]);

  useEffect(() => {
    if (conversationDetails !== null) {
      const messsages = conversationDetails?.conversation?.messages?.filter(
        (message) =>
          message?.seenAt === null &&
          message?.senderId !== conversationDetails?.myid
      );
      if (messsages?.length > 0) {
        const socket = io(process.env.REACT_APP_SOCKET_URL);
        messsages?.forEach((message) => {
          socket.emit("seen", { id: message?.id });
        });
      }
    }
  }, [conversationDetails]);
  const messagesEndRef = useRef(null);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView();
  };

  useEffect(() => {
    scrollToBottom();
  }, [conversationDetails]);
  return (
    <>
      <Grid
        container
        xs={12}
        direction="column"
        sx={{ maxHeight: { xs: "100vh", md: "85vh" }, overflow: "hidden" }}
        mb={1}
      >
        {/* Bloque cabecera */}
        <Grid
          xs={12}
          container
          sx={{
            backgroundColor: "#1396FF",
            py: 2,
            position: { xs: "sticky", md: "absolute" },
            top: { xs: 0, md: "80px" },
            zIndex: 1000,
            width: { xs: "100vw", md: "100%" },
            overflow: "hidden",
            height: { xs: "10vh", md: "72px" }, // MD no sé si es totalmente adaptable, hay que revisarlo bien
          }}
          alignItems="center"
        >
          <Grid item xs={2}>
            <IconButton
              onClick={() => {
                setConversation(null);
              }}
            >
              <ArrowBackIos sx={{ color: "#fff" }} />
            </IconButton>
          </Grid>
          <Grid
            item
            xs={1}
            container
            justifyContent="flex-end"
            onClick={() => setOpen(true)}
          >
            <Avatar
              src={conversationDetails?.contact?.profile?.avatar?.url}
              alt="avatar"
              size="xlarge"
              type="rounded"
            />
          </Grid>
          <Grid
            item
            xs
            container
            justifyContent="flex-start"
            onClick={() => setOpen(true)}
          >
            <Typography
              variant="h6"
              sx={{ marginLeft: "10px", color: "white" }}
              noWrap
            >
              {conversationDetails?.contact?.restaurant?.length > 0
                ? `${conversationDetails?.contact?.restaurant[0].name}`
                : `${conversationDetails?.contact?.profile?.name ?? ""} ${
                    conversationDetails?.contact?.profile?.middleName ?? ""
                  } ${conversationDetails?.contact?.profile?.lastName ?? ""}`}
            </Typography>
          </Grid>
        </Grid>

        {/* Bloque mensajes */}
        <Grid
          container
          xs={12}
          alignItems="center"
          sx={{ backgroundColor: grey[200] }}
        >
          <Grid
            item
            xs={12}
            sx={{
              pt: { xs: 0, md: "80px" },
              height: { xs: "75vh", md: "82vh" },
              backgroundColor: grey[200],
              overflowY: "scroll",
            }}
          >
            {conversationDetails?.conversation?.messages?.length > 0 &&
              conversationDetails?.conversation?.messages?.map(
                (message, idx) => {
                  if (
                    conversationDetails?.conversation?.deletedUserOneId !== null
                  ) {
                    if (
                      conversationDetails?.conversation?.contacts?.userOneId ===
                        conversationDetails?.myid &&
                      message?.id <=
                        conversationDetails?.conversation?.deletedUserOneId
                    ) {
                      return <></>;
                    }
                  } else if (
                    conversationDetails?.conversation?.deletedUserTwoId !== null
                  ) {
                    if (
                      conversationDetails?.conversation?.contacts?.userTwoId ===
                        conversationDetails?.myid &&
                      message?.id <=
                        conversationDetails?.conversation?.deletedUserTwoId
                    ) {
                      return <></>;
                    }
                  }
                  const position =
                    message?.senderId === conversationDetails?.myid
                      ? "right"
                      : "left";
                  if (message?.file?.type === undefined)
                    return (
                      <Box key={idx}>
                        <MessageBox
                          position={position}
                          type="text"
                          text={message?.content}
                          date={message?.sentAt}
                          title=""
                          ref={
                            idx ===
                            conversationDetails?.conversation?.messages
                              ?.length -
                              1
                              ? divToBottom
                              : null
                          }
                        />
                        <Waypoint
                          onEnter={
                            idx ===
                            conversationDetails?.conversation?.messages
                              ?.length -
                              1
                              ? scroll()
                              : () => {}
                          }
                        />
                      </Box>
                    );
                  else if (message?.file?.type?.includes("image"))
                    return (
                      <Box key={idx}>
                        <MessageBox
                          position={position}
                          type={"photo"}
                          date={message?.sentAt}
                          data={{ uri: message?.file?.url }}
                          title=""
                          ref={
                            idx ===
                            conversationDetails?.conversation?.messages
                              ?.length -
                              1
                              ? divToBottom
                              : null
                          }
                          onOpen={() => {
                            setImage(message?.file?.url);
                            setPreviewModalOpen(true);
                          }}
                        />
                        <Waypoint
                          onEnter={
                            idx ===
                            conversationDetails?.conversation?.messages
                              ?.length -
                              1
                              ? scroll()
                              : () => {}
                          }
                        />
                      </Box>
                    );
                  else if (message?.file?.type?.includes("video"))
                    return (
                      <Box key={idx}>
                        <MessageBox
                          position={position}
                          type="video"
                          date={message?.sentAt}
                          data={{
                            videoURL: message?.file?.url,
                            status: {
                              click: true,
                              loading: 0.5,
                              download: true,
                            },
                          }}
                          ref={
                            idx ===
                            conversationDetails?.conversation?.messages
                              ?.length -
                              1
                              ? divToBottom
                              : null
                          }
                          onOpen={() => {
                            setImage(message?.file?.url);
                            setPreviewModalOpen(true);
                          }}
                        />
                        <Waypoint
                          onEnter={
                            idx ===
                            conversationDetails?.conversation?.messages
                              ?.length -
                              1
                              ? scroll()
                              : () => {}
                          }
                        />
                      </Box>
                    );

                  return (
                    <MessageBox
                      position={position}
                      type={"file"}
                      text={message?.file?.name}
                      date={message?.sentAt}
                      data={{
                        uri: message?.file?.url,
                        status: {
                          click: true,
                          loading: 0,
                        },
                      }}
                      ref={
                        idx === conversationDetails?.conversation?.messages - 1
                          ? divToBottom
                          : null
                      }
                    />
                  );
                }
              )}
            <div ref={messagesEndRef} />
          </Grid>
        </Grid>

        {/* Bloque controles */}
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          sx={{
            width: { xs: "100vw", md: "66vw" },
            height: { xs: "10vh", md: "5vh" },
            position: { xs: "fixed", md: "absolute" },
            bottom: { xs: 15, md: "5%" },
            right: 0,
            zIndex: 1000,
            backgroundColor: "white",
          }}
        >
          <Grid xs xsOffset={1}>
            <TextField
              disabled={conversationDetails?.status !== "friends"}
              fullWidth
              value={message}
              onChange={(e) => setMessage(e.target.value)}
            ></TextField>
          </Grid>
          <Grid container xs={1} direction="column" mr={1}>
            <InputLabel htmlFor="file-button-file">
              <Input
                disabled={conversationDetails?.status !== "friends"}
                inputProps={{ accept: "image/*" }}
                style={{ display: "none" }}
                id="file-button-file"
                name="file-button-file"
                type="file"
                onChange={(e) => {
                  handleOpenPreview();
                  setFileToUpload(e.target.files[0]);
                }}
              />

              <IconButton
                component="span"
                disabled={conversationDetails?.status !== "friends"}
              >
                <AttachFileIcon sx={{ color: "#007bff" }} />
              </IconButton>
            </InputLabel>
            <IconButton onClick={sendMessage} disabled={message?.length === 0}>
              <SendIcon
                sx={{
                  color: "white",
                  backgroundColor: "#007bff",
                  borderRadius: "50%",
                  padding: "5px",
                }}
              />
            </IconButton>
          </Grid>
        </Grid>
      </Grid>
      <Modal
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        sx={{ zIndex: 5000 }}
      >
        <Chefcard
          contactId={conversationDetails?.contact?.id}
          restaurantId={conversationDetails?.contact?.restaurant?.[0]?.id}
          image={conversationDetails?.contact?.profile?.avatar?.url}
          profile={conversationDetails?.contact?.profile}
          address={conversationDetails?.contact?.address}
          status={conversationDetails?.conversation?.status}
          id={conversationDetails?.conversation?.id}
          role={conversationDetails?.contact?.role}
          setOpen={setOpen}
          restaurant={conversationDetails?.contact?.restaurant?.[0]?.name}
        />
      </Modal>
      <Modal
        open={previewModalOpen}
        onClose={() => setPreviewModalOpen(false)}
        sx={{ zIndex: 4000 }}
      >
        <Box
          sx={{
            width: "100vw",
            height: "100vh",
            backgroundColor: "#000",
            backgroundImage: `url(${image})`,
            backgroundSize: "contain",
            backgroundPositionX: "center",
            backgroundPositionY: "center",
            backgroundRepeat: "no-repeat",
          }}
        >
          <IconButton
            sx={{
              color: "white",
              position: "absolute",
              right: "4%",
              top: "2%",
              border: "1px solid #fff",
            }}
            onClick={() => setPreviewModalOpen(false)}
          >
            <ClearIcon />
          </IconButton>
        </Box>
      </Modal>
    </>
  );
};
