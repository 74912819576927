import { Close } from "@mui/icons-material";
import {
  Avatar,
  Box,
  CircularProgress,
  Grid,
  IconButton,
  Modal,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Chefs from "../../assets/chefs.jpg";
import AddIcon from "@mui/icons-material/Add";
import {
  useCreateStoryMutation,
  useGetSelfMMutation,
  useGetStoriesMutation,
} from "services/usersApi";
import { Camera, CameraResultType } from "@capacitor/camera";
import SendIcon from "@mui/icons-material/Send";
import ClearIcon from "@mui/icons-material/Clear";
import { AspectRatio } from "@mui/joy";
import { useSnackbar } from "notistack";
import { StoriesViewer } from "./StoriesViewer";
import { useSelector } from "react-redux";
import { selectUserToken } from "services/authSlice";
import { useAuthUser } from "react-auth-kit";

export const Stories = () => {
  const { enqueueSnackbar } = useSnackbar();
  const token = useSelector(selectUserToken);
  const [getSelf, getSelfResults] = useGetSelfMMutation();
  const [open, setOpen] = useState(false);
  const [openPreview, setOpenPreview] = useState(false);
  const [openStoryViewer, setOpenStoryViewer] = useState(false);
  const [image, setImage] = useState();
  const [uploadPhoto, uploadPhotoResults] = useCreateStoryMutation();
  const [getStories, getStoriesResults] = useGetStoriesMutation();
  const [stories, setStories] = useState([]);
  const [users, setUsers] = useState([]);
  const [viewFirst, setViewFirst] = useState();
  const authUser = useAuthUser();

  useEffect(() => {
    if (token && token.length > 0) {
      getSelf();
    }
  }, [token]);

  useEffect(() => {
    Camera.requestPermissions();
  }, []);

  useEffect(() => {
    if (getSelfResults.isSuccess) getStories();
    if (getSelfResults.isError && authUser() && authUser().active) getSelf();
  }, [getSelfResults]);

  const startCamera = async () => {
    const image = await Camera.getPhoto({
      quality: 90,
      allowEditing: true,
      resultType: CameraResultType.Uri,
    });

    let blob = await fetch(image.webPath)
      .then((r) => r.blob())
      .catch((err) => console.log(err));

    // image.webPath will contain a path that can be set as an image src.
    // You can access the original file using image.path, which can be
    // passed to the Filesystem API to read the raw data of the image,
    // if desired (or pass resultType: CameraResultType.Base64 to getPhoto)
    // const imageUrl = window.URL.createObjectURL(image.dataUrl);
    setImage(blob);
    setOpenPreview(true);
  };

  const upload = () => {
    const newPhoto = new FormData();
    newPhoto.append("main", image, "main");
    uploadPhoto({ data: newPhoto });
  };

  useEffect(() => {
    if (uploadPhotoResults.isSuccess) {
      setOpenPreview(false);
      enqueueSnackbar(`Successfully created`, {
        variant: "success",
        autoHideDuration: 2000,
      });
      getStories();
    }
    if (uploadPhotoResults.isError) {
      setOpenPreview(false);
      enqueueSnackbar(`Could not perform the operation at the moment`, {
        variant: "error",
        autoHideDuration: 2000,
      });
    }
  }, [uploadPhotoResults]);

  useEffect(() => {
    if (getStoriesResults.isSuccess) {
      setStories(getStoriesResults.data?.stories);
      const userMap = new Map();
      for (const story of getStoriesResults.data?.stories) {
        userMap.set(story?.userId, story?.user);
      }
      const arr = Array.from(userMap, ([key, value]) => ({
        key,
        value,
      }));
      setUsers(arr);
    }
    if (getStoriesResults.isError) {
      setOpenPreview(false);
      enqueueSnackbar(`Could not retrieves the stories in this moment`, {
        variant: "error",
        autoHideDuration: 2000,
      });
    }
  }, [getStoriesResults]);

  return (
    <Grid
      container
      sx={{
        height: "8vh",
        position: "absolute",
        top: { xs: "68vh", md: "68vh" },
        width: { xs: open ? "100%" : "85%", md: open ? "100%" : "25%" },
      }}
      direction="row"
      alignItems="center"
    >
      {!open && (
        <Grid item xs={3} container>
          <Grid item xs={12}>
            <Typography sx={{ fontWeight: 900 }}>Stories</Typography>
          </Grid>
          <Grid
            item
            xs={12}
            container
            justifyContent="flex-end"
            sx={{
              backgroundColor: "rgba(1, 175, 14, 1)",
              minHeight: "100%",
              borderBottomRightRadius: 25,
              borderTopRightRadius: 25,
            }}
            onClick={() => setOpen(true)}
          >
            <Avatar
              src={Chefs}
              srcSet={Chefs}
              sx={{ border: "1px solid #fff" }}
            />
          </Grid>
        </Grid>
      )}
      {open && (
        <Box
          sx={{
            display: "flex",
            overflowY: "hidden",
            minWidth: "100vw",
            maxWidth: "100vw",
            maxHeight: "100%",
            backgroundColor: "rgba(0,0,0,0.8)",
            overflowX: "scroll",
            pt: 1,
          }}
        >
          {getSelfResults.data?.user?.role?.name === "chef" && (
            <Grid
              container
              onClick={() => setOpen(true)}
              justifyContent="center"
              alignItems="center"
              sx={{ minWidth: "15%" }}
            >
              <Grid
                item
                xs={12}
                container
                justifyContent="center"
                alignItems="center"
                onClick={() => startCamera()}
              >
                <Avatar
                  sx={{
                    border: "2px solid rgba(1, 175, 14, 1)",
                    backgroundColor: "transparent",
                  }}
                >
                  <AddIcon
                    sx={{
                      width: "100%",
                      height: "100%",
                      color: "rgba(1, 175, 14, 1)",
                    }}
                  />
                </Avatar>
              </Grid>
              <Grid
                item
                xs={12}
                container
                justifyContent="center"
                alignItems="center"
              >
                <Typography
                  sx={{ fontWeight: 900, color: "#fff" }}
                  variant="caption"
                >
                  New
                </Typography>
              </Grid>
            </Grid>
          )}
          {users?.map((item, idx) => (
            <Grid
              key={idx}
              container
              onClick={() => {
                setOpenStoryViewer(true);
                setViewFirst(item?.key);
              }}
              justifyContent="center"
              alignItems="center"
              sx={{ minWidth: "25%" }}
            >
              <Grid
                item
                xs={12}
                container
                justifyContent="center"
                alignItems="center"
              >
                <Avatar
                  src={item?.value?.profile?.avatar?.url}
                  srcSet={item?.value?.profile?.avatar?.url}
                  sx={{ border: "2px solid rgba(1, 175, 14, 1)" }}
                />
              </Grid>
              <Grid
                item
                xs={12}
                container
                justifyContent="center"
                alignItems="center"
              >
                <Typography
                  sx={{ fontWeight: 900, color: "#fff" }}
                  variant="caption"
                  noWrap
                >
                  {item?.value?.profile?.name +
                    " " +
                    item?.value?.profile?.lastName}
                </Typography>
              </Grid>
            </Grid>
          ))}
          <Grid container justifyContent="flex-start" alignItems="flex-start">
            <IconButton onClick={() => setOpen(false)}>
              <Close sx={{ color: "#fff", width: 36, height: 36 }} />
            </IconButton>
          </Grid>
        </Box>
      )}
      <Modal
        open={openPreview}
        onClose={() => {
          setOpenPreview(false);
          getStories();
        }}
      >
        <Box sx={{ width: "100vw", height: "100vh", backgroundColor: "black" }}>
          <Grid container justifyContent="center" alignItems="center" py={4}>
            <Grid
              item
              xs={12}
              container
              justifyContent="flex-end"
              alignItems="center"
            >
              <Grid item>
                <IconButton
                  sx={{ color: "white" }}
                  onClick={() => setOpenPreview(false)}
                >
                  <ClearIcon />
                </IconButton>
              </Grid>
            </Grid>
            <Grid
              item
              xs={12}
              container
              justifyContent="center"
              alignItems="center"
              sx={{ minHeight: "70vh" }}
            >
              <Grid item xs={12}>
                <AspectRatio objectFit="contain">
                  <img
                    src={image ? window.URL.createObjectURL(image) : ""}
                    alt="a"
                  />
                </AspectRatio>
              </Grid>
            </Grid>
            <IconButton
              sx={{ color: "white", position: "absolute", bottom: "20vh" }}
              onClick={() => upload()}
            >
              <SendIcon />
            </IconButton>
          </Grid>
          {uploadPhotoResults.isLoading && (
            <Box
              sx={{
                display: "flex",
                position: "fixed",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
              }}
            >
              <CircularProgress size={100} />
            </Box>
          )}
        </Box>
      </Modal>
      <Modal open={openStoryViewer} onClose={() => setOpenStoryViewer(false)}>
        <StoriesViewer
          stories={stories}
          onClose={setOpenStoryViewer}
          users={users}
          viewFirst={viewFirst}
        />
      </Modal>
    </Grid>
  );
};
