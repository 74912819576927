/**
 * If you use typescript@4.5, you can write:
 * import { useCapacitorStripe } from '@capacitor-community/stripe/react';
 */
import { useCapacitorStripe } from "@capacitor-community/stripe/dist/esm/react/provider";
import React, { useEffect, useState } from "react";
import { Button, CircularProgress, Typography } from "@mui/material";
import { PaymentFlowEventsEnum } from "@capacitor-community/stripe";
import { useGetSelfMMutation, useUpdatePayMutation } from "services/usersApi";
import { useCreatePaymentIntentMutation } from "services/paymentsApi";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";

export const StripeMobile = ({ setPrice }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const [message, setMessage] = useState();
  const { stripe } = useCapacitorStripe();
  const [updatePay, updatePayResults] = useUpdatePayMutation();
  const [getSelf, getSelfResults] = useGetSelfMMutation();
  const [createPaymentIntent, createPaymentIntentResults] =
    useCreatePaymentIntentMutation();
  const [clientSecret, setClientSecret] = useState("");
  const [showSpinner, setShowSpinner] = useState(false);

  useEffect(() => {
    if (createPaymentIntentResults.isError) {
      enqueueSnackbar(`Error trying to pay`, {
        variant: "error",
        autoHideDuration: 2000,
      });
    }
  }, [createPaymentIntentResults]);

  useEffect(() => {
    createPaymentIntent().then((res) => {
      setClientSecret(res?.data?.clientSecret);
      setPrice({ currency: res?.data?.currency, amount: res?.data?.price });
    });
  }, []);

  useEffect(() => {
    if (updatePayResults.error) {
      setMessage("Please contact with the administrator");
    }
    if (updatePayResults.isSuccess) {
      getSelf();
    }
  }, [updatePayResults]);

  useEffect(() => {
    if (getSelfResults.isSuccess) {
      setShowSpinner(true);
      localStorage.setItem(
        "_auth_state",
        JSON.stringify(getSelfResults.data?.user)
      );
      setTimeout(() => {
        setShowSpinner(false);
        window.location.reload();
      }, 3000);
    }
  }, [getSelfResults]);

  return (
    <>
      {showSpinner && (
        <CircularProgress
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            color: "#007bff",
          }}
        />
      )}
      <Button
        sx={{ backgroundColor: "#FD0000", color: "#fff" }}
        onClick={async (e) => {
          e.preventDefault();

          try {
            await stripe.createPaymentFlow({
              paymentIntentClientSecret: clientSecret,
            });
            await stripe.presentPaymentFlow();
            const confirmResult = await stripe.confirmPaymentFlow();
            if (
              confirmResult.paymentResult === PaymentFlowEventsEnum.Completed
            ) {
              updatePay();
              setMessage("Successfully paid");
            } else {
              setMessage(confirmResult.paymentResult);
            }
          } catch (e) {
            console.log(e);
          }
        }}
      >
        {t("makePayment")}
      </Button>
      {message && <Typography>{message}</Typography>}
    </>
  );
};
