import {
  Avatar,
  Button,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import React, { useEffect, useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import { useNavigate } from "react-router-dom";
import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward";
import { cities } from "features/Carousel/Cities";
import { LoadScript, StandaloneSearchBox } from "@react-google-maps/api";
import { useTranslation } from "react-i18next";
const capitalize = (s) => {
  if (typeof s !== "string") return "";
  return s.charAt(0).toUpperCase() + s.slice(1);
};

export const Search = ({ setMapLocation, setZoom }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [filteredData, setFilteredData] = useState(cities);

  const [input, setInput] = useState("");

  useEffect(() => {
    if (input?.length > 0) {
      const citiesFiltered = cities.filter((city) =>
        city?.name?.toLowerCase()?.includes(input?.toLowerCase())
      );
      setFilteredData(citiesFiltered);
    } else {
      setFilteredData(cities);
    }
  }, [input]);

  const [searchBox, setSearchBox] = useState(null);

  const onPlacesChanged = () => {
    const location = searchBox.getPlaces()[0].geometry.location;
    setMapLocation([location.lat(), location.lng()]);
    let zoom = 12;
    if (searchBox.getPlaces()[0].types.includes("country")) {
      zoom = 10;
    } else if (searchBox.getPlaces()[0].types.includes("city")) {
      zoom = 16;
    }
    setZoom(zoom);
    navigate("/");
  };
  const onSBLoad = (ref) => {
    setSearchBox(ref);
  };

  return (
    <>
      <Grid container direction="row" justifyContent="center">
        <Grid
          container
          item
          xs={12}
          justifyContent="center"
          alignItems="center"
          sx={{ background: "#ff0000", position: "fixed", zIndex: 20 }}
        >
          <Grid item xs={8} sx={{ py: 2, px: 3 }}>
            <LoadScript
              googleMapsApiKey="AIzaSyCtKjiLmtKQ-R79xiAllwqrv0KK2sjtpF8"
              libraries={["places"]}
            >
              <StandaloneSearchBox
                onPlacesChanged={onPlacesChanged}
                onLoad={onSBLoad}
              >
                <CssTextField
                  placeholder={t("whereAreyouGoing")}
                  onChange={(e) => setInput(e.target.value)}
                  value={input}
                  fullWidth
                  sx={{ backgroundColor: "#fff" }}
                  inputProps={{
                    style: {
                      textTransform: "capitalize",
                    },
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start" sx={{ ml: 1 }}>
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </StandaloneSearchBox>
            </LoadScript>
          </Grid>

          <Grid item xs={2}>
            <Button sx={{ color: "#fff" }} onClick={() => navigate(-1)}>
              {t("cancel")}
            </Button>
          </Grid>
        </Grid>
        <Grid
          xs={10}
          item
          container
          spacing={2}
          justifyContent="center"
          alignItems="center"
          sx={{ mt: { xs: "80px" }, pb: 10, pt: { xs: 0, md: 5 } }}
        >
          <Grid item xs={12}>
            <Typography variant="h6">{t("topSearches")}</Typography>
          </Grid>
          {filteredData?.map((city, index) => (
            <React.Fragment key={index}>
              <Grid item xs={2}>
                <Avatar src={city?.images?.[0]?.file?.url} />
              </Grid>
              <Grid item xs={8}>
                <Typography>{`${capitalize(city.name)}`}</Typography>
              </Grid>
              <Grid item xs={2}>
                <IconButton
                  onClick={() => {
                    setMapLocation([
                      parseFloat(city?.lat),
                      parseFloat(city?.lng),
                    ]);
                    setZoom(16);
                    navigate("/");
                  }}
                >
                  <ArrowOutwardIcon />
                </IconButton>
              </Grid>
            </React.Fragment>
          ))}
        </Grid>
      </Grid>
    </>
  );
};

const CssTextField = styled(TextField)({
  "& label.Mui-focused": {
    color: "white",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "white",
  },
  "& .MuiOutlinedInput-root": {
    backgroundColor: "#fff",
    "& fieldset": {
      borderRadius: 8,
    },
    "&:hover fieldset": {
      borderColor: "white",
    },
    "&.Mui-focused fieldset": {
      borderColor: "white",
    },
  },
});
