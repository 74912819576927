export const setKeyValue = async (key, value) => {
  localStorage.setItem(key, value);
};

export const getValue = (key) => {
  return localStorage.getItem(key);
};

export const removeKeyValue = async (key) => {
  localStorage.removeItem(key);
};
