import React from "react";
import { Grid, Typography, Stack, IconButton } from "@mui/material";
import { useTranslation } from "react-i18next";
import StarIcon from "@mui/icons-material/Star";
import imageIcon from "assets/new_svg_icons/Restaurant Profile Icones/Iconos SVG Picture.svg";
import chatIcon from "assets/2023/chat.svg";
import heartIcon from "assets/2023/like1.svg";
import heartFilledIcon from "assets/2023/like2.svg";

const blueButton = {
  color: "#1291FC",
  "&:hover": {
    color: "#A50707",
  },
  height: "48px",
};

export function IconBar(props) {
  const {
    like,
    handleLike,
    panelContent,
    setPanelContent,
    handleChat,
    handleOpenModal,
  } = props;
  const { t } = useTranslation();
  const handlePanelContent = () => {
    if (panelContent === "gallery") {
      setPanelContent("info");
    } else {
      setPanelContent("gallery");
    }
  };

  const handlePanelContentRatings = () => {
    if (panelContent === "ratings") {
      setPanelContent("info");
    } else {
      setPanelContent("ratings");
    }
  };

  return (
    <Stack direction="row" sx={{ backgroundColor: "#eee" }}>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        direction="column"
      >
        <IconButton sx={blueButton} onClick={() => handlePanelContent()}>
          <img src={imageIcon} alt="more pictures icon" />
        </IconButton>
        <Typography>{t("image")}</Typography>
      </Grid>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        direction="column"
      >
        <IconButton
          sx={{ color: "red", display: like ? "block" : "none" }}
          onClick={() => handleOpenModal()}
        >
          <img src={heartFilledIcon} alt="user suscribed" />
        </IconButton>
        <Typography sx={{ display: like ? "block" : "none" }}>
          {t("unlike")}
        </Typography>
        <IconButton
          sx={{ color: "red", display: like ? "none" : "block" }}
          onClick={() => handleLike()}
        >
          <img src={heartIcon} alt="user can suscribe" />
        </IconButton>
        <Typography sx={{ display: like ? "none" : "block" }}>
          {t("like")}
        </Typography>
      </Grid>
      {/* <Grid
        container
        justifyContent="center"
        alignItems="center"
        direction="column"
      >
        <IconButton sx={blueButton} onClick={() => handleChat()}>
          <img src={chatIcon} alt="chat icon" />
        </IconButton>
        <Typography>{t("message")}</Typography>
      </Grid> */}
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        direction="column"
      >
        <IconButton sx={blueButton} onClick={() => handlePanelContentRatings()}>
          <StarIcon />
        </IconButton>
        <Typography>{t("ratings")}</Typography>
      </Grid>
    </Stack>
  );
}
