import React, { useEffect, useState } from "react";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import ImageListItemBar from "@mui/material/ImageListItemBar";
import ClearIcon from "@mui/icons-material/Clear";
import {
  Autocomplete,
  Button,
  CircularProgress,
  FormControl,
  Grid,
  Input,
  TextField,
  Typography,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import PhotoCamera from "@mui/icons-material/PhotoCamera";
import {
  useDeleteChefImageMutation,
  useUploadChefImagesMutation,
} from "services/uploadApi";
import { useSnackbar } from "notistack";
import { Box } from "@mui/system";
import {
  useGetChefQuery,
  useGetSelfQuery,
  useListAllChefRequestsMutation,
  useQuitRestaurantMutation,
  useWorkInRestaurantMutation,
  useWorkInRestaurantRequestMutation,
} from "services/usersApi";
import { useGetAllRestaurantsQuery } from "services/restaurantsApi";
import { useTranslation } from "react-i18next";
import { useAuthUser } from "react-auth-kit";

export const Chef = () => {
  const { data: userData } = useGetSelfQuery();
  const [showSpinner, setShowSpinner] = useState(false);
  const [deletePhoto, deleteResults] = useDeleteChefImageMutation();
  const [uploadPhoto, results] = useUploadChefImagesMutation();
  const [isNew, setIsNew] = useState(true);
  const { data, refetch } = useGetChefQuery({ id: userData?.user?.id });
  const { data: restaurantData } = useGetAllRestaurantsQuery();
  const { enqueueSnackbar } = useSnackbar();
  const [superImage, setSuperImage] = useState();
  const [chefImages, setChefImages] = useState([]);
  const [value, setValue] = useState(null);
  const [inputValue, setInputValue] = useState("");
  const [workInRestaurant, workInRestaurantResults] =
    useWorkInRestaurantRequestMutation();
  const [quitRestaurant, quitRestaurantResults] = useQuitRestaurantMutation();
  const [listChefRequests, listChefRequestsResults] =
    useListAllChefRequestsMutation();
  const { t } = useTranslation();
  const auth = useAuthUser();

  useEffect(() => {
    listChefRequests();
  }, []);

  const upload = (e, type) => {
    const newPhoto = new FormData();
    newPhoto.append(type, e.target.files[0]);
    uploadPhoto({ id: userData?.user?.id, data: newPhoto });
  };

  useEffect(() => {
    setSuperImage(
      data?.user?.profile?.chef?.images?.filter((image) => image.main === true)
        ?.length > 0
        ? data?.user?.profile?.chef?.images?.filter(
            (image) => image.main === true
          )[0]
        : {}
    );
    setChefImages(
      data?.user?.profile?.chef?.images?.filter((image) => image.main === false)
    );
    if (
      restaurantData?.restaurants?.filter(
        (restaurant) => restaurant?.id === data?.user?.chefs[0]?.restaurant?.id
      ).length > 0
    ) {
      setValue(
        restaurantData?.restaurants?.filter(
          (restaurant) =>
            restaurant?.id === data?.user?.chefs[0]?.restaurant?.id
        )[0]
      );
      setIsNew(false);
    } else setValue(null);
  }, [data]);

  useEffect(() => {
    if (results.isLoading || deleteResults.isLoading) {
      setShowSpinner(true);
    }
    if (results.isSuccess || deleteResults.isSuccess) {
      setShowSpinner(false);
      enqueueSnackbar(`Successfully uploaded`, {
        variant: "success",
        autoHideDuration: 2000,
      });
      refetch();
    }

    if (results.isError || deleteResults.isError) {
      setShowSpinner(false);
      enqueueSnackbar("There was an error", {
        variant: "error",
        autoHideDuration: 2000,
      });
    }
    // eslint-disable-next-line
  }, [results, deleteResults, enqueueSnackbar]);

  useEffect(() => {
    if (workInRestaurantResults?.isSuccess) {
      setShowSpinner(false);
      enqueueSnackbar(`Successfully updated`, {
        variant: "success",
        autoHideDuration: 2000,
      });
      refetch();
      listChefRequests();
      setIsNew(false);
    }
    if (quitRestaurantResults?.isSuccess) {
      setShowSpinner(false);
      enqueueSnackbar(`Successfully updated`, {
        variant: "success",
        autoHideDuration: 2000,
      });
      refetch();
      listChefRequests();
      setIsNew(true);
    }
  }, [workInRestaurantResults, quitRestaurantResults]);

  return (
    <Grid
      container
      direction="column"
      justfyContent="space-between"
      alignItems="center"
      sx={{ width: "100vw" }}
      mt={4}
    >
      <Grid
        container
        justifyContent="space-around"
        alignItems="center"
        sx={{ width: "100%", ml: { xs: "0.2%" } }}
        spacing={1}
      >
        <Grid item xs={12}>
          <Typography
            variant="h4"
            sx={{ color: "#27bdff", fontWeight: 700 }}
            gutterBottom
            component={"span"}
          >
            {t("chefRequests")}
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <FormControl sx={{ width: "100%" }}>
            <Autocomplete
              disablePortal
              id="combo-box"
              onChange={(event, newValue) => {
                setValue(newValue);
              }}
              value={value}
              inputValue={inputValue}
              sx={{ minWidth: "100%", width: "100%" }}
              onInputChange={(event, newValue) => setInputValue(newValue)}
              options={restaurantData?.restaurants}
              getOptionLabel={(option) => option?.name}
              renderInput={(params) => (
                <TextField {...params} label="Restaurant" />
              )}
            />
          </FormControl>
        </Grid>
        <Grid item xs={4}>
          <Button
            variant="contained"
            sx={{ width: "100%" }}
            onClick={() =>
              isNew
                ? workInRestaurant({ id: value?.id })
                : quitRestaurant({
                    id: data?.user?.chefs[0]?.restaurant?.id,
                    chefId: auth()?.id,
                  })
            }
          >
            {isNew ? t("buttonSend") : t("buttonQuit")}
          </Button>
        </Grid>

        <Grid item xs={12}>
          <Typography
            variant="h6"
            sx={{ color: "#27bdff", fontWeight: 700 }}
            gutterBottom
            component={"span"}
          >
            {t("chefRequestsList")}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          {listChefRequestsResults?.data?.requests?.map((request) => (
            <Typography
              variant="body"
              sx={{ color: "#1976d2", fontWeight: 700 }}
              gutterBottom
              component={"span"}
            >{`${request?.userTwo?.restaurant?.[0]?.name}`}</Typography>
          ))}
          {listChefRequestsResults?.data?.requests?.length === 0 && (
            <Typography
              variant="body"
              sx={{ color: "#1976d2", fontWeight: 700 }}
              gutterBottom
              component={"span"}
            >
              {t("noPendingRequests")}
            </Typography>
          )}
        </Grid>
      </Grid>

      <Grid container xs justifyContent="center" alignItems="center" mt={2}>
        <Typography
          variant="h4"
          sx={{ color: "#27bdff", fontWeight: 700 }}
          gutterBottom
          component={"span"}
        >
          {t("mainImage")}
        </Typography>
        <label>
          <Input
            inputProps={{ accept: "image/*" }}
            id="icon-button-file"
            type="file"
            style={{ display: "none" }}
            onChange={(e) => {
              upload(e, "main");
            }}
          />
          <IconButton
            color="primary"
            aria-label="upload picture"
            component="span"
            style={{ marginTop: "-5px" }}
          >
            <PhotoCamera />
          </IconButton>
        </label>
      </Grid>
      <Grid item xs>
        <ImageList gap={48} cols={1} sx={{ marginX: 4 }}>
          <ImageListItem
            sx={{ boxShadow: "0 25px 50px -12px rgb(0 0 0 / 0.25)" }}
          >
            <img
              src={superImage?.image?.url}
              srcSet={superImage?.image?.url}
              alt={superImage?.image?.url}
            />
            <ImageListItemBar
              actionIcon={
                <IconButton
                  sx={{ color: "rgba(255, 0, 0, 1)" }}
                  aria-label={`info about ${superImage?.image?.name}`}
                  onClick={() => deletePhoto({ id: superImage?.image?.id })} //TODO: Delete function
                >
                  <ClearIcon
                    sx={{
                      width: { xs: 14, sm: 28 },
                      height: { xs: 14, sm: 28 },
                    }}
                  />
                </IconButton>
              }
              actionPosition="right"
              position="top"
              sx={{ backgroundColor: "rgb(0 0 0 / 0)" }}
            />
          </ImageListItem>
        </ImageList>
      </Grid>
      <Grid container xs justifyContent="center" alignItems="center" mt={2}>
        <Typography
          variant="h4"
          sx={{ color: "#27bdff", fontWeight: 700 }}
          gutterBottom
          component={"span"}
        >
          {t("otherImages")}
        </Typography>
        <label>
          <Input
            inputProps={{ accept: "image/*" }}
            id="icon-button-file"
            type="file"
            style={{ display: "none" }}
            onChange={(e) => {
              upload(e, "images");
            }}
          />
          <IconButton
            color="primary"
            aria-label="upload picture"
            component="span"
            style={{ marginTop: "-5px" }}
          >
            <PhotoCamera />
          </IconButton>
        </label>
      </Grid>
      <Grid item xs>
        <ImageList gap={48} cols={2} sx={{ marginX: 4 }}>
          {chefImages?.map((image) => (
            <ImageListItem
              key={image.image.id}
              sx={{ boxShadow: "0 25px 50px -12px rgb(0 0 0 / 0.25)" }}
            >
              <img
                src={image.image?.url}
                srcSet={image.image?.url}
                alt={image.image?.url}
              />
              <ImageListItemBar
                actionIcon={
                  <IconButton
                    sx={{ color: "rgba(255, 0, 0, 1)" }}
                    aria-label={`info about ${image.image?.name}`}
                    onClick={() => deletePhoto({ id: image.image?.id })} //TODO: Delete function
                  >
                    <ClearIcon
                      sx={{
                        width: { xs: 14, sm: 28 },
                        height: { xs: 14, sm: 28 },
                      }}
                    />
                  </IconButton>
                }
                actionPosition="right"
                position="top"
                sx={{ backgroundColor: "rgb(0 0 0 / 0)" }}
              />
            </ImageListItem>
          ))}
        </ImageList>
      </Grid>
      {showSpinner && (
        <Box
          sx={{
            display: "flex",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <CircularProgress size={100} />
        </Box>
      )}
    </Grid>
  );
};
