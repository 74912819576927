import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { removeToken, selectUserToken } from "services/authSlice";
import { removeKeyValue } from "services/storage";
import { useSignOut } from "react-auth-kit";

export const Logout = () => {
  const signOut = useSignOut();
  const token = useSelector(selectUserToken);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    signOut();
    removeKeyValue("token");
    dispatch(removeToken());
  }, []);

  useEffect(() => {
    if (!token) navigate("/");
  }, [token]);
  return <></>;
};
