import * as React from "react";
import Box from "@mui/joy/Box";
import {
  Card,
  CardContent,
  CardMedia,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import { ArrowBackIos, ArrowForwardIos } from "@mui/icons-material";
import { useGetAllPostsQuery } from "services/postsApi";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

export function PostsCarousel() {
  const { i18n } = useTranslation();
  const navigate = useNavigate();
  const { data } = useGetAllPostsQuery({ locale: i18n.language });
  const [posts, setPosts] = React.useState([]);

  const ref = React.useRef();

  React.useEffect(() => {
    if (data) {
      if (data?.posts?.length > 3) setPosts(data?.posts?.slice(0, 3));
      else setPosts(data?.posts);
    }
  }, [data]);

  const scrollLeft = () => (ref.current.scrollLeft -= 40);
  const scrollRight = () => (ref.current.scrollLeft += 40);

  return (
    <Box
      sx={{
        display: "flex",
      }}
      my={0.5}
    >
      <IconButton
        sx={{
          position: { xs: "absolute" },
          ml: 1,
          mt: { xs: 8, md: 12 },
          color: { xs: "#ff0000", sm: "#ff0000" },
          backgroundColor: "rgb(255,255,255,0.5)",
        }}
        onClick={() => scrollLeft()}
        onTouchStart={() => scrollLeft()}
      >
        <ArrowBackIos sx={{ width: { md: 35 }, height: { md: 35 } }} />
      </IconButton>
      <Box
        ref={ref}
        sx={{
          display: "flex",
          overflowY: "hidden",
          width: "100vw",
          maxWidth: "100vw",
          maxHeight: { xs: "19.7vh", sm: "20vh" },
          // transform: "rotate(180deg)",
          // "&::-webkit-scrollbar": {
          //   width: "12px",
          //   height: "0.5em",
          //   backgroundColor: "transparent",
          // },
          // "&::-webkit-scrollbar-track": {
          //   "-webkit-box-shadow": "inset 0 0 6px rgba(0,0,0,0.3)",
          //   borderRadius: "10px",
          //   backgroundColor: "transparent",
          // },
          // "&::-webkit-scrollbar-thumb": {
          //   borderRadius: "10px",
          //   "-webkit-box-shadow": "inset 0 0 6px rgba(0,0,0,.3)",
          //   backgroundColor: "#59addf",
          // },
        }}
      >
        {posts?.map((item, idx) => (
          <Box
            key={idx}
            sx={{
              height: "100vh",
              width: "100%",
              justifySelf: "flex-end",
            }}
          >
            <Card
              variant="outlined"
              sx={{
                marginLeft: "1px",
                marginRight: "1px",
                width: "auto",
                maxWidth: "42vw",
                minWidth: { xs: "42vw", sm: "10vw" },
                height: "100%",
                // transform: "rotate(180deg)",
              }}
              onClick={() => {
                window.scrollTo({ top: 0, behavior: "smooth" });
                navigate("/news/" + item?.id);
              }}
            >
              <CardMedia
                component="img"
                image={item?.images[0]?.image?.url}
                alt={item.title}
                sx={{
                  width: "100%",
                  "@media screen and (min-height: 300px)": {
                    height: "10vh",
                  },
                  "@media screen and (min-height: 450px)": {
                    height: 50,
                  },
                  "@media screen and (min-height: 500px)": {
                    height: 65,
                  },
                  "@media screen and (min-height: 550px)": {
                    height: 75,
                  },
                  "@media screen and (min-height: 600px)": {
                    height: 85,
                  },
                  "@media screen and (min-height: 650px)": {
                    height: 95,
                  },
                  "@media screen and (min-height: 700px)": {
                    height: 105,
                  },
                  "@media screen and (min-height: 750px)": {
                    height: 115,
                  },
                  "@media screen and (min-height: 800px)": {
                    height: 125,
                  },
                  "@media screen and (min-height: 850px)": {
                    height: 135,
                  },
                  "@media screen and (min-height: 900px)": {
                    height: 145,
                  },
                  "@media screen and (min-height: 950px)": {
                    height: 155,
                  },
                  "@media screen and (min-height: 1000px)": {
                    height: "calc(20vh - 45px)",
                  },
                }}
              />
              <CardContent
                sx={{
                  backgroundColor: "red",
                  "@media screen and (min-width: 0px)": {
                    paddingTop: "3px",
                  },
                  "@media screen and (min-width: 1020px)": {
                    paddingTop: "10px",
                  },
                }}
              >
                <Grid
                  style={{
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    width: "100%",
                  }}
                  container
                  justifyContent="center"
                  alignItems="center"
                >
                  <Grid item xs>
                    <Typography
                      noWrap
                      color="white"
                      textTransform={"capitalize"}
                    >
                      {item?.translations[0]?.title}
                    </Typography>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Box>
        ))}
      </Box>
      <IconButton
        sx={{
          position: { xs: "absolute" },
          right: 3,
          mt: { xs: 8, md: 12 },
          color: { xs: "#ff0000", sm: "#ff0000" },
          backgroundColor: "rgb(255,255,255,0.5)",
        }}
        onClick={() => scrollRight()}
        onTouchStart={() => scrollRight()}
      >
        <ArrowForwardIos sx={{ width: { md: 35 }, height: { md: 35 } }} />
      </IconButton>
    </Box>
  );
}
