import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
import TextField from "@mui/material/TextField";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { CalendarPicker } from "@mui/x-date-pickers";

export default function DayPicker(props) {
  const { value, setValue, setActiveStep, schedule } = props;
  const [disableDays, setDisableDays] = useState([]);

  useEffect(() => {
    const disableDays_ = [];
    schedule?.forEach((schedule) => {
      if (
        schedule?.startTime !== null &&
        schedule?.endTime !== null &&
        !disableDays_.includes(schedule?.day)
      )
        disableDays_.push(schedule?.day);
    });
    setDisableDays(disableDays_);
  }, [schedule]);
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <CalendarPicker
        shouldDisableDate={(date) => !disableDays?.includes(dayjs(date).day())}
        displayStaticWrapperAs="mobile"
        openTo="day"
        value={value}
        disablePast
        onChange={(newValue) => {
          setValue(newValue);
          setActiveStep(1);
        }}
        onAccept={() => {
          setActiveStep(1);
        }}
        renderInput={(params) => <TextField {...params} />}
      />
    </LocalizationProvider>
  );
}
