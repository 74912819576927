import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { setKeyValue } from "./storage";

const initialState = {
  token: "",
  user: null,
  conversation: null,
  notifications: [],
};

export const setTokenThunk = createAsyncThunk("auth/setToken", (token) => {
  setKeyValue("token", token)
    .then(() => {
      return;
    })
    .catch(() => {
      return;
    });
});

const authSlice = createSlice({
  name: "authReducer",
  initialState,
  reducers: {
    setToken(state, action) {
      state.token = action.payload;
      setKeyValue("token", action.payload);
    },
    setUser(state, action) {
      state.user = action.payload;
    },
    removeToken(state, action) {
      state.token = null;
      state.user = null;
    },
    setConversationId(state, action) {
      state.conversation = action.payload;
    },
    setNotifications(state, action) {
      state.notifications = action.payload;
    },
  },
});

export const selectUser = (state) => state.authReducer.user;
export const selectUserToken = (state) => state.authReducer.token;
export const selectConversation = (state) => state.authReducer.conversation;
export const selectNotifications = (state) => state.authReducer.notifications;
export const {
  setToken,
  setUser,
  removeToken,
  setConversationId,
  setNotifications,
} = authSlice.actions;
export default authSlice.reducer;
