import React, { useEffect } from "react";
import { useGetAllMyRestaurantsQuery } from "services/usersApi";
import { useAuthUser } from "react-auth-kit";
import { useNavigate } from "react-router-dom";
import { RestaurantBookings } from "./RestaurantBookings";

export const Restaurant = () => {
  const auth = useAuthUser();
  const navigate = useNavigate();
  const { data: restaurantData } = useGetAllMyRestaurantsQuery();

  useEffect(() => {
    if (!auth().active) navigate("/card");
  }, []);

  return <RestaurantBookings id={restaurantData?.restaurants[0]?.id} />;
};
