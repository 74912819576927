import React from "react";
import {
  Avatar,
  Typography,
  Button,
  IconButton,
  Input,
  InputLabel,
  CircularProgress,
  Box,
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import ReceiptIcon from "@mui/icons-material/Receipt";
import PersonIcon from "@mui/icons-material/Person";
import FavoriteIcon from "@mui/icons-material/Favorite";
import SettingsIcon from "@mui/icons-material/Settings";
import RestaurantIcon from "@mui/icons-material/Restaurant";
import { useState } from "react";
import { User } from "./User";
import { Billing } from "./Billing";
import { Favourite } from "./Favourite";
import { Settings } from "./Settings";
import { useGetSelfQuery, useUpdateUserMutation } from "services/usersApi";
import { useEffect } from "react";
import { useSnackbar } from "notistack";
import { useUploadAvatarUserMutation } from "services/uploadApi";
import { Restaurant } from "./Restaurant";
import { Chef } from "./Chef";
import { ThemeProvider } from "@mui/material";
import { createTheme } from "@mui/material/styles";
import { blueGrey } from "@mui/material/colors";
import PeopleIcon from "@mui/icons-material/People";
import { useTranslation } from "react-i18next";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import { CapacitorStripeProvider } from "@capacitor-community/stripe/dist/esm/react/provider";
import { MyCard } from "features/Card/MyCard";
import { Helmet } from "react-helmet";

export const theme = createTheme({
  palette: {
    red: {
      main: "#ff0000",
      light: "#ff3333",
      dark: "#b20000",
    },
    blueStrong: {
      main: "#007bff",
      light: "#3395ff",
      dark: "#0056b2",
    },
    blueLight: {
      main: "#27bdff",
      light: "#52caff",
      dark: "#1b84b2",
    },
  },
});

export const Account = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [activeTab, setActiveTab] = useState("");
  const { data, isSuccess, refetch } = useGetSelfQuery();
  const [updateUser, updateUserResults] = useUpdateUserMutation();
  const [uploadAvatar, uploadAvatarResults] = useUploadAvatarUserMutation();
  const [formData, setFormData] = useState({});
  const { t } = useTranslation();

  useEffect(() => {
    setFormData(data?.user);
    if (data?.user?.signUpComplete === true) setActiveTab("mycard");
    if (data?.user?.signUpComplete === false) {
      handleTabs();
    }
  }, [isSuccess, data]);

  const handleTabs = () => {
    if (data?.user?.signUpComplete === false) {
      if (activeTab === "") setActiveTab("account");
      if (activeTab === "account") setActiveTab("billing");
      if (activeTab === "billing") setActiveTab("mycard");
    }
  };

  useEffect(() => {
    if (updateUserResults.isSuccess) {
      if (data?.user?.signUpComplete === false) handleTabs();
      enqueueSnackbar(`Successfully updated`, {
        variant: "success",
        autoHideDuration: 2000,
      });
    }
    if (updateUserResults.isError) {
      enqueueSnackbar(`There was an error trying to update it`, {
        variant: "error",
        autoHideDuration: 2000,
      });
    }
    if (uploadAvatarResults.isSuccess) {
      enqueueSnackbar(`Successfully uploaded`, {
        variant: "success",
        autoHideDuration: 2000,
      });
      refetch();
    }
    if (uploadAvatarResults.isError) {
      enqueueSnackbar(`There was an error trying to upload it`, {
        variant: "error",
        autoHideDuration: 2000,
      });
    }
  }, [updateUserResults, uploadAvatarResults]);

  const upload = (e) => {
    const newAvatar = new FormData();
    newAvatar.append("avatar", e.target.files[0]);
    uploadAvatar({ data: newAvatar });
  };

  const handleContent = (activeTab) => {
    switch (activeTab) {
      case "account":
        return (
          <User
            formData={formData}
            setFormData={setFormData}
            updateUser={updateUser}
          />
        );
      case "billing":
        return (
          <Billing
            formData={formData}
            setFormData={setFormData}
            updateUser={updateUser}
          />
        );
      case "favourite":
        return <Favourite />;
      case "settings":
        return <Settings />;
      case "restaurant":
        return <Restaurant />;
      case "chef":
        return <Chef />;
      case "mycard":
        return (
          <CapacitorStripeProvider
            publishableKey="pk_test_7OgJtZLBdkHw3dZ5TB0l2nxQ"
            fallback={
              <Box
                sx={{
                  display: "flex",
                  position: "fixed",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                }}
              >
                <CircularProgress size={100} />
              </Box>
            }
          >
            <MyCard />
          </CapacitorStripeProvider>
        );
      default:
        return <></>;
    }
  };

  const renderRoleTab = () => {
    switch (data?.user?.role?.name) {
      case "owner":
        return (
          <Grid xs>
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              <Button
                variant="contained"
                startIcon={<RestaurantIcon />}
                sx={{
                  width: "100%",
                  minHeight: "5vh",
                  borderRadius: 0,
                  marginTop: 0,
                  color: "white",
                  display: { xs: "none", sm: "flex" },
                }}
                onClick={() => setActiveTab("restaurant")}
                color={activeTab === "restaurant" ? "blueStrong" : "blueLight"}
              >
                {t("restaurant")}
              </Button>
              <Button
                variant="contained"
                startIcon={<RestaurantIcon />}
                sx={{
                  width: "100%",
                  minHeight: "5vh",
                  borderRadius: 0,
                  marginTop: 0,
                  color: "white",
                  display: { xs: "flex", sm: "none" },
                }}
                onClick={() => setActiveTab("restaurant")}
                color={activeTab === "restaurant" ? "blueStrong" : "blueLight"}
              ></Button>
            </Grid>
          </Grid>
        );
      case "chef":
        return (
          <Grid xs>
            <Grid container xs justifyContent="center">
              <Button
                variant="contained"
                startIcon={<PeopleIcon />}
                sx={{
                  width: "100%",
                  minHeight: "5vh",
                  borderRadius: 0,
                  marginTop: 0,
                  color: "white",
                  display: { xs: "none", sm: "flex" },
                }}
                onClick={() => setActiveTab("chef")}
                color={activeTab === "chef" ? "blueStrong" : "blueLight"}
              >
                {t("chef")}
              </Button>
              <Button
                variant="contained"
                startIcon={<PeopleIcon />}
                sx={{
                  width: "100%",
                  minHeight: "5vh",
                  borderRadius: 0,
                  marginTop: 0,
                  color: "white",
                  display: { xs: "flex", sm: "none" },
                }}
                onClick={() => setActiveTab("chef")}
                color={activeTab === "chef" ? "blueStrong" : "blueLight"}
              ></Button>
            </Grid>
          </Grid>
        );
      default:
        return <></>;
    }
  };
  return (
    <ThemeProvider theme={theme}>
      <Helmet>
        <style>{"body { background-color: #CBEBFB; }"}</style>
      </Helmet>
      <Grid
        container
        direction="column"
        justfyContent="space-between"
        alignItems="center"
        sx={{
          mt: { xs: "80px" },
          pb: 15,
          flexFlow: "column",
          height: "calc(100vh - 80px)",
          position: "fixed",
          overflowY: "scroll",
          overflowX: "hidden",
        }}
      >
        {/* Subnavbar */}
        <Grid container xs={12}>
          <Grid xs>
            <Button
              variant="contained"
              startIcon={<PersonIcon />}
              sx={{
                width: "100%",
                minHeight: "5vh",
                borderRadius: 0,
                marginTop: 0,
                color: "white",
                display: { xs: "none", sm: "flex" },
              }}
              onClick={() => setActiveTab("account")}
              color={activeTab === "account" ? "blueStrong" : "blueLight"}
            >
              {t("account")}
            </Button>
            <Button
              variant="contained"
              startIcon={<PersonIcon />}
              sx={{
                width: "100%",
                minHeight: "5vh",
                borderRadius: 0,
                marginTop: 0,
                color: "white",
                display: { xs: "flex", sm: "none" },
              }}
              onClick={() => setActiveTab("account")}
              color={activeTab === "account" ? "blueStrong" : "blueLight"}
            ></Button>
          </Grid>
          {renderRoleTab()}
          <Grid xs>
            <Button
              variant="contained"
              startIcon={<ReceiptIcon />}
              sx={{
                width: "100%",
                minHeight: "5vh",
                borderRadius: 0,
                marginTop: 0,
                color: "white",
                display: { xs: "none", sm: "flex" },
              }}
              onClick={() => setActiveTab("billing")}
              color={activeTab === "billing" ? "blueStrong" : "blueLight"}
            >
              {t("billing")}
            </Button>
            <Button
              variant="contained"
              startIcon={<ReceiptIcon />}
              sx={{
                width: "100%",
                minHeight: "5vh",
                borderRadius: 0,
                marginTop: 0,
                color: "white",
                display: { xs: "flex", sm: "none" },
              }}
              onClick={() => setActiveTab("billing")}
              color={activeTab === "billing" ? "blueStrong" : "blueLight"}
            ></Button>
          </Grid>
          <Grid xs>
            <Button
              variant="contained"
              startIcon={<CreditCardIcon />}
              sx={{
                width: "100%",
                minHeight: "5vh",
                borderRadius: 0,
                marginTop: 0,
                color: "white",
                display: { xs: "none", sm: "flex" },
              }}
              onClick={() => setActiveTab("mycard")}
              color={activeTab === "mycard" ? "blueStrong" : "blueLight"}
            >
              {t("mycard")}
            </Button>
            <Button
              variant="contained"
              startIcon={<CreditCardIcon />}
              sx={{
                width: "100%",
                minHeight: "5vh",
                borderRadius: 0,
                marginTop: 0,
                color: "white",
                display: { xs: "flex", sm: "none" },
              }}
              onClick={() => setActiveTab("mycard")}
              color={activeTab === "mycard" ? "blueStrong" : "blueLight"}
            ></Button>
          </Grid>
        </Grid>
        {/* Page information */}
        {activeTab !== "restaurant" && (
          <Grid
            container
            xs={12}
            alignItems="center"
            sx={{ backgroundColor: "white", py: 2 }}
          >
            <Grid xs={12} sm={5.5}>
              <Typography
                variant="h4"
                textTransform="capitalize"
                sx={{ fontWeight: 700 }}
              >
                {activeTab}
              </Typography>
            </Grid>

            <Grid xs={12} sm>
              <InputLabel htmlFor="avatar-button-file">
                <Input
                  style={{ display: "none" }}
                  id="avatar-button-file"
                  name="avatar-button-file"
                  type="file"
                  inputProps={{ accept: "image/*" }}
                  onChange={(e) => {
                    upload(e);
                  }}
                />
                <IconButton
                  color="primary"
                  aria-label="upload picture"
                  component="span"
                >
                  <Avatar
                    src={formData?.profile?.avatar?.url}
                    alt=""
                    sx={{
                      width: { xs: 96, sm: 128 },
                      height: { xs: 96, sm: 128 },
                      position: "relative",
                      borderColor: "blueLight",
                      borderStyle: "solid",
                      borderWidth: { xs: "3px", sm: "5px" },
                    }}
                  >
                    {formData?.profile?.name?.charAt(0)?.toUpperCase() ?? ""}
                  </Avatar>
                </IconButton>
              </InputLabel>
            </Grid>

            <Grid container xs={12} sm={12} md={12} lg={5} mb={1}>
              <Grid xs={6}>
                <Button
                  variant={activeTab === "favourite" ? "contained" : "outlined"}
                  startIcon={<FavoriteIcon />}
                  sx={{
                    borderRadius: 0,
                    color: activeTab === "favourite" ? "white" : "red",
                  }}
                  onClick={() => {
                    if (data?.user?.active) setActiveTab("favourite");
                  }}
                  color="red"
                >
                  {t("favourite")}
                </Button>
              </Grid>

              <Grid xs={6}>
                <Button
                  variant={activeTab === "settings" ? "contained" : "outlined"}
                  startIcon={<SettingsIcon />}
                  sx={{
                    borderRadius: 0,
                    color: activeTab === "settings" ? "white" : blueGrey[900],
                  }}
                  onClick={() => {
                    setActiveTab("settings");
                  }}
                >
                  {t("settings")}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        )}
        {/* Content */}
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          sx={{
            width: "100vw",
            height: "100vh",
          }}
        >
          {handleContent(activeTab)}
        </Grid>
      </Grid>
    </ThemeProvider>
  );
};
