import React, { useEffect, useState } from "react";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import ImageListItemBar from "@mui/material/ImageListItemBar";
import ClearIcon from "@mui/icons-material/Clear";
import { CircularProgress, Grid, Input, Typography } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import PhotoCamera from "@mui/icons-material/PhotoCamera";
import { useUploadRestaurantPhotosMutation } from "services/uploadApi";
import { useSnackbar } from "notistack";
import {
  useDeleteRestaurantImageMutation,
  useGetRestaurantQuery,
} from "services/restaurantsApi";
import { Box } from "@mui/system";
import { useTranslation } from "react-i18next";

export const Images = ({ id }) => {
  const [showSpinner, setShowSpinner] = useState(false);
  const [deletePhoto, deleteResults] = useDeleteRestaurantImageMutation();
  const [uploadPhoto, results] = useUploadRestaurantPhotosMutation();
  const { data, refetch } = useGetRestaurantQuery(id);
  const { enqueueSnackbar } = useSnackbar();
  const [superImage, setSuperImage] = useState();
  const [restaurantImages, setRestaurantImages] = useState([]);
  const { t } = useTranslation();

  const upload = (e, type) => {
    const newPhoto = new FormData();
    newPhoto.append(type, e.target.files[0]);
    uploadPhoto({ id: id, data: newPhoto });
  };

  useEffect(() => {
    setSuperImage(
      data?.restaurant?.images?.filter((image) => image.main === true)?.length >
        0
        ? data?.restaurant?.images?.filter((image) => image.main === true)[0]
        : {}
    );
    setRestaurantImages(
      data?.restaurant?.images?.filter((image) => image.main === false)
    );
  }, [data]);

  useEffect(() => {
    if (results.isLoading || deleteResults.isLoading) {
      setShowSpinner(true);
    }
    if (results.isSuccess || deleteResults.isSuccess) {
      setShowSpinner(false);
      enqueueSnackbar(`Successfully uploaded`, {
        variant: "success",
        autoHideDuration: 2000,
      });
      refetch();
    }

    if (results.isError || deleteResults.isError) {
      setShowSpinner(false);
      enqueueSnackbar("There was an error", {
        variant: "error",
        autoHideDuration: 2000,
      });
    }
    // eslint-disable-next-line
  }, [results, deleteResults, enqueueSnackbar]);

  return (
    <>
      <Grid
        container
        direction="column"
        justfyContent="space-between"
        alignItems="center"
        sx={{ width: "100vw" }}
      >
        <Grid container xs justifyContent="center" alignItems="center" mt={2}>
          <Typography
            variant="h4"
            sx={{ color: "#27bdff", fontWeight: 700 }}
            gutterBottom
            component={"span"}
          >
            {t("coverImage")}
          </Typography>
          <label>
            <Input
              inputProps={{ accept: "image/*" }}
              id="icon-button-file"
              type="file"
              style={{ display: "none" }}
              onChange={(e) => {
                upload(e, "main");
              }}
            />
            <IconButton
              color="primary"
              aria-label="upload picture"
              component="span"
            >
              <PhotoCamera />
            </IconButton>
          </label>
        </Grid>
        <Grid container justifyContent="center" xs sx={{ width: "100vw" }}>
          <ImageList gap={48} cols={1} sx={{ marginX: 4 }}>
            <ImageListItem
              sx={{ boxShadow: "0 25px 50px -12px rgb(0 0 0 / 0.25)" }}
            >
              <img
                src={superImage?.file?.url}
                srcSet={superImage?.file?.url}
                alt={superImage?.title}
              />

              <ImageListItemBar
                actionIcon={
                  <IconButton
                    sx={{ color: "rgba(255, 0, 0, 1)" }}
                    aria-label={`info about ${superImage?.file?.name}`}
                    onClick={() =>
                      deletePhoto({ id: id, imageid: superImage?.file?.id })
                    }
                  >
                    <ClearIcon
                      sx={{
                        width: { xs: 14, sm: 28 },
                        height: { xs: 14, sm: 28 },
                      }}
                    />
                  </IconButton>
                }
                actionPosition="right"
                position="top"
                sx={{ backgroundColor: "rgb(0 0 0 / 0)" }}
              />
            </ImageListItem>
          </ImageList>
        </Grid>

        <Grid
          container
          xs
          justifyContent="center"
          alignItems="center"
          sx={{ width: "100vw" }}
          mt={2}
        >
          <Typography
            variant="h4"
            sx={{ color: "#27bdff", fontWeight: 700 }}
            gutterBottom
            component={"span"}
          >
            {t("otherImages")}
          </Typography>
          <label>
            <Input
              inputProps={{ accept: "image/*" }}
              id="icon-button-file"
              type="file"
              style={{ display: "none" }}
              onChange={(e) => {
                upload(e, "images");
              }}
            />
            <IconButton
              color="primary"
              aria-label="upload picture"
              component="span"
              style={{ marginTop: "-3px" }}
            >
              <PhotoCamera />
            </IconButton>
          </label>
        </Grid>
      </Grid>

      <Grid xs={12}>
        <ImageList gap={48} cols={2} sx={{ marginX: 4 }}>
          {restaurantImages?.map((image) => (
            <ImageListItem
              key={image.file.id}
              sx={{ boxShadow: "0 0  rgb(0 0 0 / 0.25)" }}
            >
              <img
                src={image.file?.url}
                srcSet={image.file?.url}
                alt={image.file?.url}
              />
              <ImageListItemBar
                actionIcon={
                  <IconButton
                    sx={{ color: "rgba(255, 0, 0, 1)" }}
                    aria-label={`info about ${image.file?.name}`}
                    onClick={() =>
                      deletePhoto({ id: id, imageid: image.file?.id })
                    }
                  >
                    <ClearIcon
                      sx={{
                        width: { xs: 14, sm: 28 },
                        height: { xs: 14, sm: 28 },
                      }}
                    />
                  </IconButton>
                }
                actionPosition="right"
                position="top"
                sx={{ backgroundColor: "rgb(0 0 0 / 0)" }}
              />
            </ImageListItem>
          ))}
        </ImageList>
      </Grid>
      {showSpinner && (
        <Box
          sx={{
            display: "flex",
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <CircularProgress size={100} />
        </Box>
      )}
    </>
  );
};
