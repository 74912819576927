import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useGetAllUnreadNotificationsQueryQuery } from "services/notificationsApi";
import { LocalNotifications } from "@capacitor/local-notifications";
import { useDispatch, useSelector } from "react-redux";
import { selectNotifications, setNotifications } from "services/authSlice";
export const NotificationsProvider = ({ auth, children }) => {
  const { t } = useTranslation();
  const notifications = useSelector(selectNotifications);
  const dispatch = useDispatch();
  const {
    data: notificationData,
    isSuccess: notificationIsSucess,
    isError: notificationIsError,
  } = useGetAllUnreadNotificationsQueryQuery(null, { pollingInterval: 5000 });

  useEffect(() => {
    LocalNotifications.requestPermissions();
    if (auth && notificationIsSucess) {
      if (notificationData.notifications?.length > 0) {
        const notificationsToPush = notificationData.notifications?.filter(
          (n) => !notifications?.map((n2) => n2?.id)?.includes(n?.id)
        );
        if (
          notifications.length > 0 &&
          notifications.length !== notificationData.notifications?.length
        )
          LocalNotifications.schedule({
            notifications: notificationsToPush?.map((n) => {
              let title = "";
              let body = "";
              if (n.type === "booking") {
                title = t("bookingTitle");
                body = n.content;
              }
              if (n.type === "story") {
                title = t("storyTitle");
                body = `${n?.story?.user?.profile?.name ?? ""} ${
                  n?.story?.user?.profile?.middleName ?? ""
                } ${n?.story?.user?.profile?.lastName ?? ""}`;
              }
              if (n.type === "message") {
                title = t("messageTitle");
                body = `${n?.relatedUser?.profile?.name ?? ""} ${
                  n?.relatedUser?.profile?.middleName ?? ""
                } ${n?.relatedUser?.profile?.lastName ?? ""}`;
              }
              if (n.type === "post") {
                title = t("postTitle");
                body = `${n?.content}`;
              }
              return {
                title: title,
                body: body,
                id: n?.id,
                schedule: { at: new Date(Date.now() + 1000 * 2) },
              };
            }),
          });
      }
      dispatch(setNotifications(notificationData.notifications));
    }
    if (notificationIsError) setNotifications([]);
  }, [notificationData, notificationIsSucess, notificationIsError]);
  return <>{children}</>;
};
