import React, { useEffect, useRef, useState } from "react";
import { useParams, Link, useNavigate } from "react-router-dom";
import Grid from "@mui/material/Unstable_Grid2";
import { Divider, Typography, Button, Box } from "@mui/material";
import { PostCarousel } from "features/Post/PostCarousel";
import { PostBar } from "features/Post/PostBar";
import {
  useGetPostMutationMutation,
  useLikePostMutation,
  useUnlikePostMutation,
} from "services/postsApi";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import { useAuthUser } from "react-auth-kit";
import { ArrowBackIos, ArrowForwardIos } from "@mui/icons-material";
import { PostsCarousel } from "features/Carousel/PostsCarousel";
import DOMPurify from "dompurify";

export function Post() {
  const navigate = useNavigate();
  const auth = useAuthUser();
  const { id } = useParams();
  const { t, i18n } = useTranslation();
  const [getPost, getPostResults] = useGetPostMutationMutation();
  const [likePost, likePostResults] = useLikePostMutation();
  const [unlikePost, unlikePostResults] = useUnlikePostMutation();
  const ref = useRef();

  useEffect(() => {
    if (!auth().active) navigate("/card");
    else
      getPost({
        id,
      });
  }, [id]);

  const handleLike = () => {
    likePost({ id: id });
  };

  const handleUnlike = () => {
    unlikePost({ id: id });
  };

  useEffect(() => {
    if (likePostResults.isSuccess) {
      getPost({
        id,
      });
    }
    if (unlikePostResults.isSuccess) {
      getPost({
        id,
      });
    }
  }, [likePostResults, unlikePostResults]);

  useEffect(() => {
    if (getPostResults.isSuccess) {
      if (getPostResults.data?.error) navigate(-1);
      setTitle(
        getPostResults?.data?.post?.translations?.filter(
          (translation) =>
            translation?.language ===
            (i18n.language.includes("-")
              ? i18n.language.split("-")?.[0]
              : i18n.language)
        )?.[0]?.title
      );
      setCategory(getPostResults?.data?.post?.category?.name ?? "");
    }

    if (getPostResults.isError) {
      navigate(-1);
    }
  }, [getPostResults]);

  const htmlDecode = (value) => {
    const doc = new DOMParser().parseFromString(value, "text/html");
    return doc.documentElement.textContent;
  };

  const sanitize = (html) => {
    return DOMPurify.sanitize(htmlDecode(html));
  };

  const [title, setTitle] = useState("");
  const [category, setCategory] = useState("");

  return (
    <Grid
      container
      sx={{
        mt: { xs: "100px" },
        pb: 15,
        flexFlow: "column",
        width: "100vw",
        position: { xs: "fixed", md: "fixed" },
        height: "calc(100vh - 100px)",
        overflowY: "scroll",
        overflowX: "hidden",
      }}
    >
      <Grid container justifyContent="center" mt={4} ref={ref} xs={12}>
        <Grid xs={11}>
          <Typography
            variant="h5"
            sx={{ color: "#3D91F4", fontWeight: 700 }}
            noWrap
          >
            {title}
          </Typography>
          <Divider />
        </Grid>
        <Grid xs={4} container justifyContent={"center"} alignItems="center">
          <Link
            to={"/news/" + (id - 1)}
            style={{
              textDecoration: "inherit",
              paddingTop: 0,
              paddingBottom: 0,
            }}
          >
            <Button
              variant="text"
              sx={{ color: "#3D91F4", fontWeight: 700, minWidth: "100px" }}
              startIcon={<ArrowBackIos />}
            >
              {t("previousArticle")}
            </Button>
          </Link>
        </Grid>
        <Grid xs={4} container justifyContent={"center"} alignItems="center">
          <Typography
            variant="h6"
            sx={{ color: "#000", fontWeight: 900 }}
            textTransform="capitalize"
          >
            {category}
          </Typography>
        </Grid>
        <Grid xs={4} container justifyContent={"center"} alignItems="center">
          <Link
            to={"/news/" + (+id + 1)}
            style={{
              textDecoration: "inherit",
              paddingTop: 0,
              paddingBottom: 0,
            }}
          >
            <Button
              variant="text"
              sx={{ color: "#3D91F4", fontWeight: 700, minWidth: "100px" }}
              endIcon={<ArrowForwardIos />}
            >
              {t("nextArticle")}
            </Button>
          </Link>
        </Grid>
        <Grid xs={12} pb={0}>
          <PostCarousel
            data={getPostResults?.data?.post?.images?.filter(
              (image) => image?.carousel === true
            )}
            video={getPostResults?.data?.post?.videoUrl}
          />
        </Grid>
        <Grid xs={12}>
          <PostBar
            likes={getPostResults?.data?.post?._count?.likes}
            like={getPostResults?.data?.like}
            handleLike={handleLike}
            handleUnlike={handleUnlike}
            views={getPostResults?.data?.post?.views}
            date={dayjs(getPostResults?.data?.post?.publishDate).format(
              "MM-DD-YYYY"
            )}
            title={
              getPostResults?.data?.post?.translations?.filter(
                (translation) => translation?.language === i18n.language
              )[0]?.title
            }
            subtitle={
              getPostResults?.data?.post?.translations?.filter(
                (translation) => translation?.language === i18n.language
              )[0]?.subtitle
            }
          />
        </Grid>
        <Grid
          xs={12}
          dangerouslySetInnerHTML={{
            __html: sanitize(
              getPostResults.data?.post?.translations?.filter(
                (translation) =>
                  translation?.language ===
                  (i18n.language.includes("-")
                    ? i18n.language.split("-")?.[0]
                    : i18n.language)
              )?.[0]?.content ?? ""
            ),
          }}
        />
        <Grid container xs={12} justifyContent="center">
          <Button
            variant="contained"
            sx={{
              width: { xs: "42%", sm: "15%" },
              backgroundColor: "#1396FF",
              borderRadius: 5,
            }}
            onClick={() => navigate("/news")}
          >
            {t("backToNews")}
          </Button>
        </Grid>
        <Box mt={4}>
          <PostsCarousel />
        </Box>
      </Grid>
    </Grid>
  );
}
