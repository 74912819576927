import React, { useEffect, useState } from "react";
import { IconButton, Modal, Box, Avatar, Button } from "@mui/material";
import AspectRatio from "@mui/joy/AspectRatio";
import { IconBar } from "features/Panel/PanelData/IconBar";
import { useGetRestaurantQuery } from "services/restaurantsApi";

import {
  useLikeRestaurantMutation,
  useUnLikeRestaurantMutation,
} from "services/restaurantsApi";

import RestaurantInfo from "./RetaurantInfo";
import RestaurantGallery from "./RestaurantGallery";
import RestaurantBooking from "./RestaurantBooking";
import { useCreateConversationMutation } from "services/contact";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import { Carousel } from "react-responsive-carousel";
import { YoutubeSlide } from "features/Post/PostCarousel";
import { RestaurantRatings } from "./RestaurantRatings";
import returnIcon from "assets/new_svg_icons/Post Iconos/Iconos Retorno.svg";
import Grid from "@mui/material/Unstable_Grid2"; // Grid version 2
import { useTranslation } from "react-i18next";
import { ArrowBackIos, ArrowForwardIos } from "@mui/icons-material";

export function RestaurantPanelData(props) {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { restaurant, toggleDrawer, map, setMap } = props;
  const { data: restaurantExtra, refetch } = useGetRestaurantQuery(
    restaurant?.id
  );
  const { t } = useTranslation();

  const [panelContent, setPanelContent] = useState("info");
  const [likeRestaurant, likeRestaurantResults] = useLikeRestaurantMutation();
  const [createConversation, createConversationResults] =
    useCreateConversationMutation();
  const [unLikeRestaurant, unLikeRestaurantResults] =
    useUnLikeRestaurantMutation();

  const handleCreateConversation = () => {
    createConversation({ id: restaurant?.id, restaurant: true });
  };

  const handleLike = () => {
    likeRestaurant({ id: restaurant?.id });
  };

  const handleUnlike = () => {
    unLikeRestaurant({ id: restaurant?.id });
    handleCloseModal();
  };

  useEffect(() => {
    if (likeRestaurantResults.isSuccess) {
      refetch();
    }
    if (unLikeRestaurantResults.isSuccess) {
      refetch();
    }
  }, [likeRestaurantResults, unLikeRestaurantResults]);

  useEffect(() => {
    if (createConversationResults.isSuccess) {
      navigate("/chat/" + createConversationResults.data?.id);
    }
    if (createConversationResults.isError) {
      enqueueSnackbar(`No user associated to this restaurant`, {
        variant: "error",
        autoHideDuration: 2000,
      });
    }
  }, [createConversationResults]);

  const handlePanel = () => {
    switch (panelContent) {
      case "info":
        return (
          <RestaurantInfo
            restaurant={restaurant}
            setPanelContent={setPanelContent}
            map={map}
            setMap={setMap}
          />
        );
      case "gallery":
        return (
          <RestaurantGallery
            restaurant={restaurant}
            sx={{ display: panelContent === "gallery" ? "inline" : "none" }}
            setPanelContent={setPanelContent}
          />
        );
      case "booking":
        return (
          <RestaurantBooking
            restaurant={restaurant}
            sx={{ display: panelContent === "booking" ? "inline" : "none" }}
            setPanelContent={setPanelContent}
            schedule={restaurantExtra?.restaurant?.schedule}
          />
        );
      case "ratings":
        return (
          <RestaurantRatings
            restaurant={restaurant}
            sx={{ display: panelContent === "booking" ? "inline" : "none" }}
            setPanelContent={setPanelContent}
          />
        );
      default:
        return (
          <RestaurantInfo
            restaurant={restaurant}
            setPanelContent={setPanelContent}
            map={map}
            setMap={setMap}
          />
        );
    }
  };

  const style = {
    position: "absolute",
    bottom: { xs: "0%", md: "50%" },
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "#26282F",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };
  const [open, setOpen] = React.useState(false);
  const handleOpenModal = () => setOpen(true);
  const handleCloseModal = () => setOpen(false);

  const arrowStyles = {
    position: "absolute",
    zIndex: 2,
    top: "calc(50% - 15px)",
    width: 30,
    height: 30,
    cursor: "pointer",
  };

  return (
    <>
      {panelContent !== "gallery" && panelContent !== "ratings" && (
        <>
          <div style={{ position: "relative" }}>
            <IconButton
              sx={{
                position: "absolute",
                top: "2%",
                left: "4%",
                zIndex: 3000,
                with: "100%",
              }}
              onClick={toggleDrawer(false)}
              disableRipple={true}
            >
              <img src={returnIcon} alt="" />
            </IconButton>
            <Carousel
              showThumbs={false}
              showStatus={false}
              renderArrowPrev={(onClickHandler, hasPrev, label) =>
                hasPrev && (
                  <IconButton
                    onClick={onClickHandler}
                    title={label}
                    sx={{
                      ...arrowStyles,
                      left: 15,
                      color: "red !important",
                      padding: "30px",
                      backgroundColor: "rgba(255,255,255,0.3) !important",
                    }}
                    elevation={4}
                  >
                    <ArrowBackIos />
                  </IconButton>
                )
              }
              renderArrowNext={(onClickHandler, hasNext, label) =>
                hasNext && (
                  <IconButton
                    onClick={onClickHandler}
                    title={label}
                    sx={{
                      ...arrowStyles,
                      right: 15,
                      color: "red !important",
                      padding: "30px",
                      backgroundColor: "rgba(255,255,255,0.3) !important",
                    }}
                    elevation={4}
                  >
                    <ArrowForwardIos />
                  </IconButton>
                )
              }
            >
              <AspectRatio maxWidth={500}>
                <img
                  src={restaurant?.images?.map((image) =>
                    image.main === true ? image.file.url : null
                  )}
                  srcSet={restaurant?.images?.map((image) =>
                    image.main === true ? image.file.url : null
                  )}
                  alt=""
                />
              </AspectRatio>
              <YoutubeSlide url={restaurantExtra?.restaurant?.videoUrl} />
            </Carousel>
          </div>
          {panelContent !== "booking" && (
            <IconBar
              like={restaurantExtra?.like}
              restaurantId={restaurant?.id}
              handleLike={handleLike}
              handleOpenModal={handleOpenModal}
              panelContent={panelContent}
              setPanelContent={setPanelContent}
              web={restaurantExtra?.restaurant?.contact?.website}
              handleChat={handleCreateConversation}
            />
          )}
          <Modal
            open={open}
            onClose={handleCloseModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <Grid
                container
                direction="column"
                justifyContent="center"
                alignItems="center"
              >
                <Grid xs>
                  <Avatar
                    sx={{
                      width: "64px",
                      height: "64px",
                      border: 1,
                      borderWidth: "2px",
                      borderColor: "#1291fc",
                    }}
                    src={restaurant?.images?.map((image) =>
                      image.main === true ? image.file.url : null
                    )}
                    srcSet={restaurant?.images?.map((image) =>
                      image.main === true ? image.file.url : null
                    )}
                    alt=""
                  />
                </Grid>
                <Grid xs>
                  <p>
                    <span style={{ color: "white", fontWeight: "700" }}>
                      {t("unlikeText")}
                    </span>{" "}
                    <span style={{ color: "#1291fc", fontWeight: "700" }}>
                      {restaurant?.name}
                    </span>
                  </p>
                </Grid>
                <Grid xs>
                  <Grid container direction="row">
                    <Grid xs>
                      <Button
                        onClick={handleUnlike}
                        variant="contained"
                        sx={{ backgroundColor: "red" }}
                      >
                        {" "}
                        {t("unlikeButton")}
                      </Button>
                    </Grid>
                    <Grid xs>
                      <Button onClick={handleCloseModal} variant="outlined">
                        {t("unlikeCancelButton")}
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </Modal>
        </>
      )}

      {handlePanel(panelContent)}
    </>
  );
}
