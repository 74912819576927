import {
  Avatar,
  Button,
  Card,
  CardContent,
  CardHeader,
  Grid,
  IconButton,
  Modal,
  Rating,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { ArrowBackIos, Clear, Close } from "@mui/icons-material";
import { red } from "@mui/material/colors";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import {
  useCreateRatingsMutation,
  useGetRatingsMutation,
} from "services/restaurantsApi";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";

export const RestaurantRatings = ({ restaurant, setPanelContent }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const [submit, submitResults] = useCreateRatingsMutation();
  const [getRatings, getRatingsResult] = useGetRatingsMutation();
  const [ratings, setRatings] = useState([]);
  const [open, setOpen] = useState(false);
  const [rating, setRating] = useState({
    cleanliness: 0,
    food: 0,
    service: 0,
    atmosphere: 0,
  });

  const groupBy = function (xs, key) {
    return xs.reduce(function (rv, x) {
      (rv[x[key]] = rv[x[key]] || []).push(x);
      return rv;
    }, {});
  };

  useEffect(() => {
    if (getRatingsResult.isSuccess) {
      setRatings(
        Object.values(groupBy(getRatingsResult.data?.ratings, "userId"))
      );
      if (getRatingsResult.data?.myRating) {
        setRating({
          cleanliness:
            getRatingsResult.data?.myRating?.filter(
              (rating) => rating?.name === "cleanliness"
            )[0]?.value ?? 0,
          food:
            getRatingsResult.data?.myRating?.filter(
              (rating) => rating?.name === "food"
            )[0]?.value ?? 0,
          service:
            getRatingsResult.data?.myRating?.filter(
              (rating) => rating?.name === "service"
            )[0]?.value ?? 0,
          atmosphere:
            getRatingsResult.data?.myRating?.filter(
              (rating) => rating?.name === "atmosphere"
            )[0]?.value ?? 0,
        });
      }
    }
  }, [getRatingsResult]);

  useEffect(() => {
    getRatings({ id: restaurant?.id });
  }, []);

  useEffect(() => {
    if (submitResults.isSuccess) {
      getRatings({ id: restaurant?.id });
      enqueueSnackbar(`Successfully submited`, {
        variant: "success",
        autoHideDuration: 2000,
      });
    }
    if (submitResults.isError) {
      enqueueSnackbar(`There was an error trying to submit it`, {
        variant: "error",
        autoHideDuration: 2000,
      });
    }
  }, [submitResults]);
  return (
    <Grid container sx={{ minWidth: { xs: "100vw", md: 500 } }}>
      <Grid
        item
        xs={12}
        container
        justifyContent="center"
        alignItems="center"
        py={2}
        sx={{ backgroundColor: "#1396FF" }}
      >
        <Grid item xs={1} container>
          <IconButton sx={{}} onClick={() => setPanelContent("info")}>
            <ArrowBackIos sx={{ color: "#fff" }} />
          </IconButton>
        </Grid>
        <Grid item xs={11} container sx={{}}>
          <Typography
            variant="h5"
            sx={{ color: "#fff", maxWidth: "80%" }}
            noWrap
          >
            {restaurant?.name}
          </Typography>
        </Grid>
      </Grid>
      <Grid item xs={12} pt={2} direction="row" container>
        <Grid item xs={6} container justifyContent="center">
          <Typography variant="h6">Rating average</Typography>
        </Grid>
        <Grid item xs={6} container justifyContent="center">
          <Typography variant="h6">
            {t("totalRatings")}: {getRatingsResult.data?.average?.total}
          </Typography>
        </Grid>
        <Grid item xs={12} sx={{ minHeight: "2em" }} />
        <Grid item xs={6} container justifyContent="center">
          <Grid item xs={12} container justifyContent="center">
            <Typography component="legend">{t("cleanliness")}</Typography>
          </Grid>
          <Rating
            sx={{ color: "#ff0001" }}
            value={getRatingsResult.data?.average?.cleanliness ?? 0}
            precision={0.1}
          />
        </Grid>
        <Grid item xs={6} container justifyContent="center">
          <Grid item xs={12} container justifyContent="center">
            <Typography component="legend">{t("food")}</Typography>
          </Grid>
          <Rating
            sx={{ color: "#ff0001" }}
            value={getRatingsResult.data?.average?.food ?? 0}
            precision={0.1}
          />
        </Grid>
        <Grid item xs={12} sx={{ minHeight: "1em" }} />
        <Grid item xs={6} container justifyContent="center">
          <Grid item xs={12} container justifyContent="center">
            <Typography component="legend">{t("service")}</Typography>
          </Grid>
          <Rating
            sx={{ color: "#ff0001" }}
            value={getRatingsResult.data?.average?.service ?? 0}
            precision={0.1}
          />
        </Grid>
        <Grid item xs={6} container justifyContent="center">
          <Grid item xs={12} container justifyContent="center">
            <Typography component="legend">{t("atmosphere")}</Typography>
          </Grid>
          <Rating
            sx={{ color: "#ff0001" }}
            value={getRatingsResult.data?.average?.atmosphere ?? 0}
            precision={0.1}
          />
        </Grid>
        <Grid
          item
          xs={12}
          container
          justifyContent="center"
          alignItems="center"
          sx={{ py: 2 }}
        >
          <Typography>{t("ratings")}</Typography>
        </Grid>
        <Grid
          item
          xs={12}
          container
          justifyContent="center"
          alignItems="center"
          spacing={2}
          sx={{ pb: 2 }}
        >
          {ratings?.map((item, idx) => (
            <Grid item xs={11} key={idx}>
              <Card>
                <CardHeader
                  avatar={
                    <Avatar
                      src={item?.[0]?.user?.profile?.avatar?.url}
                      sx={{ bgcolor: red[500] }}
                    >
                      {item?.[0]?.user?.profile?.name?.at(0)}
                    </Avatar>
                  }
                  title={
                    item?.[0]?.user?.profile?.name +
                    item?.[0]?.user?.profile?.middleName +
                    item?.[0]?.user?.profile?.lastName
                  }
                />
                <CardContent>
                  <Grid container>
                    <Grid item xs={6} container justifyContent="center">
                      <Grid item xs={12} container justifyContent="center">
                        <Typography component="legend">
                          {t("cleanliness")}
                        </Typography>
                      </Grid>
                      <Rating
                        sx={{ color: "#ff0001" }}
                        value={
                          item?.filter((n) => n?.name === "cleanliness")?.[0]
                            .value
                        }
                        precision={0.1}
                      />
                    </Grid>
                    <Grid item xs={6} container justifyContent="center">
                      <Grid item xs={12} container justifyContent="center">
                        <Typography component="legend">{t("food")}</Typography>
                      </Grid>
                      <Rating
                        sx={{ color: "#ff0001" }}
                        value={
                          item?.filter((n) => n?.name === "food")?.[0].value
                        }
                        precision={0.1}
                      />
                    </Grid>
                    <Grid item xs={12} sx={{ minHeight: "1em" }} />
                    <Grid item xs={6} container justifyContent="center">
                      <Grid item xs={12} container justifyContent="center">
                        <Typography component="legend">
                          {t("service")}
                        </Typography>
                      </Grid>
                      <Rating
                        sx={{ color: "#ff0001" }}
                        value={
                          item?.filter((n) => n?.name === "service")?.[0].value
                        }
                        precision={0.1}
                      />
                    </Grid>
                    <Grid item xs={6} container justifyContent="center">
                      <Grid item xs={12} container justifyContent="center">
                        <Typography component="legend">
                          {t("atmosphere")}
                        </Typography>
                      </Grid>
                      <Rating
                        sx={{ color: "#ff0001" }}
                        value={
                          item?.filter((n) => n?.name === "atmosphere")?.[0]
                            .value
                        }
                        precision={0.1}
                      />
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          direction="row"
        >
          <Button
            variant="contained"
            sx={{
              width: { xs: "65%", sm: "45%" },
              backgroundColor: "red",
              position: "sticky",
              top: "90%",
              borderRadius: 5,
              mb: 4,
            }}
            onClick={() => setOpen(true)}
          >
            {t("submit")}
          </Button>
        </Grid>
      </Grid>
      <Modal keepMounted open={open} onClose={() => setOpen(false)}>
        <Grid
          container
          sx={{
            minWidth: "100vw",
            minHeight: "100vh",
            backgroundColor: "#fff",
          }}
        >
          <Grid
            item
            xs={12}
            container
            sx={{ maxHeight: "5vh" }}
            justifyContent="center"
          >
            <Grid item xs={12} container justifyContent="center">
              <Typography variant="h5" sx={{ p: 2, color: "#1396FF" }}>
                {restaurant?.name}
              </Typography>
            </Grid>
            <IconButton
              onClick={() => setOpen(false)}
              sx={{ position: "absolute", top: "4%", right: "4%" }}
            >
              <Close sx={{ color: "#ff0001", width: 36, height: 36 }} />
            </IconButton>
          </Grid>
          <Grid item xs={12} container justifyContent="center">
            <Typography variant="h6" sx={{ p: 2, color: "#ff0001" }}>
              {t("howWouldYouRate")}
            </Typography>
          </Grid>
          <Grid item xs={12} container>
            <Grid
              item
              xs={12}
              container
              justifyContent="center"
              alignItems="center"
            >
              <Typography variant="h6" sx={{ color: "#000" }}>
                {t("cleanliness")}
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              container
              justifyContent="center"
              alignItems="center"
            >
              <Rating
                size="large"
                precision={0.5}
                value={rating.cleanliness}
                sx={{
                  color: "#ff0001",
                  fontSize: "4rem",
                  borderColor: "red",
                  emptyStar: "white",
                }}
                emptyIcon={
                  <StarBorderIcon
                    fontSize="inherit"
                    sx={{ color: "#ff0001" }}
                  />
                }
                onChange={(event, newValue) => {
                  setRating({ ...rating, cleanliness: newValue });
                }}
              />
            </Grid>
          </Grid>
          <Grid item xs={12} container>
            <Grid
              item
              xs={12}
              container
              justifyContent="center"
              alignItems="center"
            >
              <Typography variant="h6" sx={{ color: "#000" }}>
                {t("food")}
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              container
              justifyContent="center"
              alignItems="center"
            >
              <Rating
                size="large"
                precision={0.5}
                value={rating.food}
                sx={{
                  color: "#ff0001",
                  fontSize: "4rem",
                  borderColor: "red",
                  emptyStar: "white",
                }}
                emptyIcon={
                  <StarBorderIcon
                    fontSize="inherit"
                    sx={{ color: "#ff0001" }}
                  />
                }
                onChange={(event, newValue) => {
                  setRating({ ...rating, food: newValue });
                }}
              />
            </Grid>
          </Grid>
          <Grid item xs={12} container>
            <Grid
              item
              xs={12}
              container
              justifyContent="center"
              alignItems="center"
            >
              <Typography variant="h6" sx={{ color: "#000" }}>
                {t("service")}
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              container
              justifyContent="center"
              alignItems="center"
            >
              <Rating
                size="large"
                precision={0.5}
                value={rating.service}
                sx={{
                  color: "#ff0001",
                  fontSize: "4rem",
                  borderColor: "red",
                  emptyStar: "white",
                }}
                emptyIcon={
                  <StarBorderIcon
                    fontSize="inherit"
                    sx={{ color: "#ff0001" }}
                  />
                }
                onChange={(event, newValue) => {
                  setRating({ ...rating, service: newValue });
                }}
              />
            </Grid>
          </Grid>
          <Grid item xs={12} container>
            <Grid
              item
              xs={12}
              container
              justifyContent="center"
              alignItems="center"
            >
              <Typography variant="h6" sx={{ color: "#000" }}>
                {t("atmosphere")}
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              container
              justifyContent="center"
              alignItems="center"
            >
              <Rating
                size="large"
                value={rating.atmosphere}
                precision={0.5}
                sx={{
                  color: "#ff0001",
                  fontSize: "4rem",
                  borderColor: "red",
                  emptyStar: "white",
                }}
                emptyIcon={
                  <StarBorderIcon
                    fontSize="inherit"
                    sx={{ color: "#ff0001" }}
                  />
                }
                onChange={(event, newValue) => {
                  setRating({ ...rating, atmosphere: newValue });
                }}
              />
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            container
            justifyContent="center"
            alignItems="center"
          >
            <Button
              variant="contained"
              sx={{
                width: { xs: "65%", sm: "20%" },
                backgroundColor: "red",
                borderRadius: 5,
                mb: 5,
              }}
              onClick={() => {
                const data = [];
                for (const property in rating) {
                  data.push({ name: property, value: rating[property] });
                }
                submit({ id: restaurant?.id, ratings: data });
              }}
            >
              {t("submit")}
            </Button>
          </Grid>
        </Grid>
      </Modal>
    </Grid>
  );
};
