import React, { useEffect } from "react";
import { IconButton, Popper, Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { grey, yellow } from "@mui/material/colors";
import notificationIcon from "assets/notifications.svg";
import { Notifications } from "../../features/Notifications/Notifications";
import Badge from "@mui/material/Badge";
import { useGetAllUnreadNotificationsMutation } from "services/notificationsApi";
import { useAuthUser } from "react-auth-kit";

export function NotificationIcon({ auth, notifications }) {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    if (authUser() && authUser().active)
      setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const [getNotifications, notificationsResults] =
    useGetAllUnreadNotificationsMutation();
  const authUser = useAuthUser();

  useEffect(() => {
    if (auth) {
      getNotifications();
    }
  }, [auth, notifications]);

  useEffect(() => {
    if (notificationsResults.isError && auth) getNotifications();
  }, [notificationsResults]);

  const open = Boolean(anchorEl);
  const id = open ? "simple-popper" : undefined;
  return (
    <>
      <IconButton
        onClick={handleClick}
        sx={{
          color: grey[50],
          "&:hover": { color: yellow[500] },
          display: { xs: "none", md: "flex" },
        }}
      >
        <img src={notificationIcon} alt="" />
        <Badge
          badgeContent={notificationsResults?.data?.notifications.length ?? 0}
          color="primary"
          sx={{ position: "relative", top: "-10px", right: "15px" }}
        ></Badge>
      </IconButton>
      <IconButton
        onClick={() => {
          if (authUser() && authUser().active) navigate("/notifications");
        }}
        sx={{
          color: grey[50],
          "&:hover": { color: yellow[500] },
          display: { xs: "flex", md: "none" },
        }}
      >
        <img src={notificationIcon} alt="" />
      </IconButton>
      <Popper
        id={id}
        open={open}
        anchorEl={anchorEl}
        sx={{ display: { xs: "none", md: "block" } }}
      >
        <Box
          sx={{
            border: 1,
            p: 1,
            bgcolor: "background.paper",
            width: 500,
            height: "90vh",
            maxHeight: "90vh",
          }}
        >
          <Notifications />
        </Box>
      </Popper>
    </>
  );
}
