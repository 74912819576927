import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { API_URL } from "./env";
import { getValue } from "./storage";
export const categoriesApi = createApi({
  reducerPath: "categoriesApi",
  baseQuery: fetchBaseQuery({
    baseUrl: API_URL,
    prepareHeaders: (headers) => {
      const token = getValue("_auth");
      headers.set("Authorization", `Bearer ${token}`);
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getAllCategories: builder.query({
      query: () => {
        return { url: `categories` };
      },
    }),
    getCategory: builder.query({
      query: (id) => {
        return { url: `categories/${id}` };
      },
    }),
  }),
});

export const { useGetAllCategoriesQuery, useGetCategoryQuery } = categoriesApi;
