import React from "react";
import { Grid, Avatar, Typography, IconButton } from "@mui/material";
import FavoriteIcon from "@mui/icons-material/Favorite";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import ShareIcon from "@mui/icons-material/Share";

import Author from "../../assets/mtd-logo-icon.svg";
import { useTranslation } from "react-i18next";

export function PostBar({
  likes,
  like,
  handleLike,
  handleUnlike,
  views,
  date,
  title,
  subtitle,
}) {
  const { t } = useTranslation();
  return (
    <Grid
      container
      direction="row"
      spacing={1}
      justifyContent="center"
      alignContent="center"
      sx={{ backgroundColor: "#EBEBEB" }}
    >
      <Grid
        container
        item
        xs={12}
        sm={8}
        smOffset={2}
        direction="row"
        justifyContent="center"
        alignItems="center"
        mt={1}
      >
        <Grid xs={3}>
          <Avatar
            alt="Nombre del autor"
            src={Author}
            sx={{
              width: 128,
              height: 128,
              display: { xs: "none", sm: "flex" },
            }}
          />
        </Grid>
        <Grid
          container
          xs={11}
          sm={9}
          direction="column"
          alignItems="flex-start"
        >
          <Typography variant="subtitle1" sx={{ fontWeight: 700 }}>
            {title}
          </Typography>
          <Grid xs={12} container>
            <Typography variant="subtitle1">{subtitle}</Typography>
          </Grid>
        </Grid>
      </Grid>

      <Grid
        container
        item
        xs={12}
        sm={2}
        direction="row"
        justifyContent="center"
        alignItems="center"
        mb={2}
        sx={{ display: { xs: "flex", sm: "none" } }}
      >
        <Grid item xs={2} sx={{ marginLeft: "20px" }}>
          <Avatar
            alt="Nombre del autor"
            src={Author}
            sx={{
              width: 128,
              height: 128,
              display: { xs: "flex", sm: "none" },
            }}
          />
        </Grid>

        <Grid
          item
          xs={5}
          container
          direction={"row"}
          alignItems="center"
          justifyContent={"flex-end"}
        >
          <IconButton>
            {like ? (
              <FavoriteIcon
                sx={{ color: "red" }}
                onClick={() => handleUnlike()}
              />
            ) : (
              <FavoriteBorderIcon
                sx={{ color: "red" }}
                onClick={() => handleLike()}
              />
            )}
          </IconButton>
        </Grid>
        <Grid item xs={1}>
          <IconButton sx={{ color: "red" }}>
            <ShareIcon />
          </IconButton>
        </Grid>
        <Grid item xs>
          <Typography variant="body1">
            {views} {t("views")}
          </Typography>
          <Typography variant="body1">
            {t("likes")} {likes}
          </Typography>
          <Typography variant="body2">{date}</Typography>
        </Grid>
      </Grid>
      <Grid
        container
        item
        xs={12}
        sm={2}
        direction="row"
        justifyContent="center"
        alignItems="center"
        mb={2}
        sx={{ display: { xs: "none", sm: "flex" } }}
      >
        <Grid
          item
          xs={12}
          container
          justifyContent={"center"}
          alignItems="center"
        >
          <IconButton sx={{ color: "red" }}>
            <ShareIcon />
          </IconButton>
          <IconButton>
            {like ? (
              <FavoriteIcon
                sx={{ color: "red" }}
                onClick={() => handleUnlike()}
              />
            ) : (
              <FavoriteBorderIcon
                sx={{ color: "red" }}
                onClick={() => handleLike()}
              />
            )}
          </IconButton>
        </Grid>
        <Grid
          item
          xs={12}
          container
          justifyContent={"center"}
          alignItems="center"
        >
          <Typography variant="body1">
            {views} {t("views")}
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          container
          justifyContent={"center"}
          alignItems="center"
        >
          <Typography variant="body1">
            {likes} {t("likes")}
          </Typography>
        </Grid>

        <Typography variant="body2">{date}</Typography>
      </Grid>
    </Grid>
  );
}
