import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Unstable_Grid2";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useDeleteUserMutation } from "services/usersApi";
import { useSignOut } from "react-auth-kit";
import { useSnackbar } from "notistack";

export const Settings = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const [deleteAccount, deleteAccoountResults] = useDeleteUserMutation();
  const [open, setOpen] = useState(false);
  const signOut = useSignOut();

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (deleteAccoountResults.isSuccess) signOut();
    if (deleteAccoountResults.isError)
      enqueueSnackbar("Please contact the administrator", { variant: "error" });
  }, [deleteAccoountResults]);

  return (
    <Grid
      container
      xs={12}
      direction="row"
      justifyContent="space-around"
      alignItems="center"
    >
      <Grid xs>
        <Button variant="contained" onClick={() => setOpen(true)}>
          {t("deactiveAccount")}
        </Button>
      </Grid>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {t("confirmDeleteAccount")}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {t("deleteAccountConfirm")}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>{t("cancel")}</Button>
          <Button onClick={() => deleteAccount()} autoFocus>
            {t("delete")}
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
};
