import { createTheme } from '@mui/material';

export const TableTheme = createTheme({
  palette: {
    primary: {
      main: '#ff0000',
    },
    secondary: {
      main: '#59addf',
    },
  },
});

export const LoginTheme = createTheme({
  palette: {
    primary: {
      main: '#ff0000',
    },
    secondary: {
      main: '#59addf',
    },
  },
});