import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import DayPicker from "./RestaurantBooking/DayPicker";
import TimePicker from "./RestaurantBooking/TimePicker";
import TablePicker from "./RestaurantBooking/TablePicker";
import EventIcon from "@mui/icons-material/Event";
import ScheduleIcon from "@mui/icons-material/Schedule";
import TableBarIcon from "@mui/icons-material/TableBar";
import dayjs from "dayjs";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { useCreateBookingMutation } from "services/bookingsApi";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { Checkout } from "./RestaurantBooking/Checkout";

const steps = ["Select Day", "Select Hour", "Select table", "Checkout"];

export default function RestaurantBooking({
  restaurant,
  setPanelContent,
  schedule,
}) {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [activeStep, setActiveStep] = useState(0);
  const [skipped, setSkipped] = useState(new Set());
  const [date, setDate] = useState(null);
  const [table, setTable] = useState(null);
  const [makeBooking, makeBookingResults] = useCreateBookingMutation();

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  useEffect(() => {
    if (makeBookingResults.isSuccess) {
      enqueueSnackbar(`Successfully updated`, {
        variant: "success",
        autoHideDuration: 2000,
      });
      setActiveStep(3);
    }
    if (makeBookingResults.isError) {
      enqueueSnackbar(`There was an error trying to update it`, {
        variant: "error",
        autoHideDuration: 2000,
      });
    }
  }, [makeBookingResults]);
  const makeIt = () => {
    makeBooking({
      booking: {
        date: dayjs(date).format("YYYY-MM-DD hh:mm A"),
        guests: table,
      },
      id: restaurant?.id,
    });
    console.log(dayjs(date).format("YYYY-MM-DD hh:mm A"));
  };

  const handleBooking = () => {
    switch (activeStep) {
      case 0:
        return (
          <DayPicker
            value={date}
            setValue={setDate}
            setActiveStep={setActiveStep}
            schedule={schedule}
          />
        );
      case 1:
        return (
          <TimePicker
            value={date}
            setValue={setDate}
            setActiveStep={setActiveStep}
            date={date}
            schedule={schedule}
          />
        );
      case 2:
        return (
          <TablePicker
            value={table}
            setValue={setTable}
            setActiveStep={setActiveStep}
            makeBooking={makeIt}
          />
        );
      case 3:
        return <Checkout date={date} guests={table} />;
      default:
        return <></>;
    }
  };

  return (
    <Box sx={{ width: "100%", overflowX: "hidden", maxHeight: "100vh" }} mt={3}>
      <Stepper activeStep={activeStep}>
        {steps.map((label, index) => {
          const stepProps = {};
          const labelProps = {};

          if (isStepSkipped(index)) {
            stepProps.completed = false;
          }
          return (
            <Step
              key={label}
              {...stepProps}
              onClick={() => {
                if (index !== 3) setActiveStep(index);
              }}
            >
              <StepLabel {...labelProps}>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>

      {activeStep === steps.length ? (
        <React.Fragment>
          <Grid
            container
            justifyContent="space-between"
            direction="column"
            mt={2}
            xsOffset={1}
            spacing={2}
          >
            <Grid
              container
              direction="row"
              alignItems="center"
              justifyContent="space-around"
              xs
            >
              <Grid xs={2}>
                <EventIcon sx={{ color: "red" }} />
              </Grid>
              <Grid xs={10}>
                <Typography variant="body1">
                  {dayjs(date).format("YYYY-DD-MM")}{" "}
                </Typography>
              </Grid>
            </Grid>
            <Grid
              container
              direction="row"
              alignItems="center"
              justifyContent="space-around"
              xs
            >
              <Grid xs={2}>
                <ScheduleIcon sx={{ color: "red" }} />
              </Grid>
              <Grid xs={10}>
                <Typography variant="body1">
                  {dayjs(date).format("HH:mm A")}{" "}
                </Typography>
              </Grid>
            </Grid>
            <Grid
              container
              direction="row"
              alignItems="center"
              justifyContent="space-around"
              xs
            >
              <Grid xs={2}>
                <TableBarIcon sx={{ color: "red" }} />
              </Grid>
              <Grid xs={10}>
                <Typography variant="body1" textTransform={"capitalize"}>
                  {table ?? null} {" " + t("guests")}
                </Typography>
              </Grid>
            </Grid>
          </Grid>

          <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
            <Box sx={{ flex: "1 1 auto" }} />
            <Button onClick={handleReset}>Reset</Button>
            <Button
              onClick={() =>
                makeBooking({
                  booking: { date: date, guests: table },
                  id: restaurant?.id,
                })
              }
            >
              {t("makeReservation")}
            </Button>
          </Box>
        </React.Fragment>
      ) : (
        <React.Fragment>
          {handleBooking()}
          <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
            <Box sx={{ flex: "1 1 auto" }} />
          </Box>
        </React.Fragment>
      )}
    </Box>
  );
}
