import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import {
  useGetRestaurantBookingsMutation,
  useGetRestaurantBookingsQueryQuery,
} from "services/bookingsApi";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@mui/material";
import { ExpandMore } from "@mui/icons-material";
import { BookingCard } from "./BookingCard";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";

export const RestaurantBookings = ({ id }) => {
  const {
    data: bookingsData,
    isSuccess,
    isError,
  } = useGetRestaurantBookingsQueryQuery(id, {
    pollingInterval: 3000,
  });
  const { enqueueSnackbar } = useSnackbar();
  const [data, setData] = useState([]);
  const { t } = useTranslation();
  const [getBookings, getBookingsResults] = useGetRestaurantBookingsMutation();

  useEffect(() => {
    if (isSuccess && bookingsData?.length !== data?.length) {
      enqueueSnackbar(`New bookings!`, {
        variant: "success",
        autoHideDuration: 2000,
      });
      setData(bookingsData);
    }
  }, [isSuccess, isError, bookingsData]);

  useEffect(() => {
    if (getBookingsResults.isSuccess) {
      setData(getBookingsResults?.data);
    }
  }, [getBookingsResults]);

  return (
    <Grid
      container
      sx={{
        width: "100vw",
        mt: { xs: "100px" },
        pb: 15,
        height: "calc(100vh - 100px)",
        position: "fixed",
        overflowY: "scroll",
        overflowX: "hidden",
      }}
    >
      <Grid container justifyContent="center" alignItems="center">
        <Grid xs={12}>
          <Typography variant="h4" sx={{ color: "#27bdff", fontWeight: 700 }}>
            {t("restaurantBookings")}
          </Typography>
          <Typography
            variant="caption"
            sx={{ color: "#27bdff", fontWeight: 700 }}
          >
            BID*: Booking Unique Identifier
          </Typography>
        </Grid>

        <Grid xs={11} container>
          <Accordion sx={{ width: "100%" }}>
            <AccordionSummary
              expandIcon={<ExpandMore />}
              aria-controls="accpeted-bookings"
            >
              <Typography>
                {t("accepted")}
                {
                  data?.filter((booking) => booking?.state === "accepted")
                    ?.length
                }
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                spacing={2}
              >
                {data?.map((booking) => {
                  if (booking?.state === "accepted")
                    return (
                      <Grid xs={12} key={booking?.id} md={4}>
                        <BookingCard
                          booking={booking}
                          edit={true}
                          refetch={() => getBookings(id)}
                          restaurant={true}
                        />
                      </Grid>
                    );
                  return null;
                })}
              </Grid>
            </AccordionDetails>
          </Accordion>
        </Grid>
        <Grid xs={11} container>
          <Accordion sx={{ width: "100%" }}>
            <AccordionSummary
              expandIcon={<ExpandMore />}
              aria-controls="pending-bookings"
            >
              <Typography>
                {t("pending")}
                {
                  data?.filter((booking) => booking?.state === "pending")
                    ?.length
                }
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                spacing={2}
              >
                {data?.map((booking) => {
                  if (booking?.state === "pending")
                    return (
                      <Grid xs={12} key={booking?.id} md={4}>
                        <BookingCard
                          booking={booking}
                          edit={true}
                          refetch={() => getBookings(id)}
                          restaurant={true}
                        />
                      </Grid>
                    );
                  return null;
                })}
              </Grid>
            </AccordionDetails>
          </Accordion>
        </Grid>
        <Grid xs={11} container>
          <Accordion sx={{ width: "100%" }}>
            <AccordionSummary
              expandIcon={<ExpandMore />}
              aria-controls="rejected-bookings"
            >
              <Typography>
                {t("rejected")}
                {
                  data?.filter((booking) => booking?.state === "rejected")
                    ?.length
                }
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                spacing={2}
              >
                {data?.map((booking) => {
                  if (booking?.state === "rejected")
                    return (
                      <Grid xs={12} key={booking?.id} md={4}>
                        <BookingCard
                          booking={booking}
                          edit={false}
                          refetch={() => getBookings(id)}
                          restaurant={true}
                        />
                      </Grid>
                    );
                  return null;
                })}
              </Grid>
            </AccordionDetails>
          </Accordion>
        </Grid>
      </Grid>
    </Grid>
  );
};
