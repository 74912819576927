import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  TextField,
  Typography,
  IconButton,
} from "@mui/material";
import utc from "dayjs/plugin/utc";
import dayjs from "dayjs";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import EditIcon from "@mui/icons-material/Edit";
import CloseIcon from "@mui/icons-material/Close";
import { useUpdateBookingMutation } from "services/bookingsApi";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
dayjs.extend(utc);

export const BookingCard = ({ booking, edit, refetch, restaurant }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [booking_, setBooking] = useState(booking);
  const [editMode, setEditMode] = useState(false);
  const [updateBooking_, updateBookingResults] = useUpdateBookingMutation();

  const handleTimeChange = (newValue) => {
    setBooking({ ...booking, date: newValue });
  };

  const cancelBooking = () => {
    updateBooking_({
      id: booking?.id,
      booking: { ...booking, state: "rejected" },
    });
    refetch();
  };
  const acceptBooking = () => {
    updateBooking_({
      id: booking?.id,
      booking: { ...booking, state: "accepted" },
    });
    refetch();
  };

  const updateBooking = () => {
    updateBooking_({
      id: booking?.id,
      booking: booking_,
    });
  };

  useEffect(() => {
    if (updateBookingResults.isSuccess) {
      enqueueSnackbar(`Successfully updated`, {
        variant: "success",
        autoHideDuration: 2000,
      });
    }
    if (updateBookingResults.isError) {
      enqueueSnackbar(`There was an error trying to update it`, {
        variant: "error",
        autoHideDuration: 2000,
      });
    }
    //eslint-disable-next-line
  }, [updateBookingResults]);

  const { t } = useTranslation();

  return (
    <Card>
      <CardMedia
        component="img"
        image={
          restaurant
            ? booking_?.user?.profile?.avatar?.url
            : booking_?.restaurant?.images[0]?.file?.url
        }
        alt="image"
        sx={
          restaurant
            ? {
                position: "relative",
                height: 150,
                borderRadius: 100,
                width: 150,
                justifySelf: "center",
                left: "50%",
                transform: "translateX(-50%)",
                mt: 1,
              }
            : { height: 150 }
        }
      />
      <CardContent>
        <Grid container justifyContent="center" alignItems="center">
          <Grid xs={12}>
            <Typography variant="h6" sx={{ color: "#27bdff", fontWeight: 700 }}>
              BID:{booking_?.id}
            </Typography>
          </Grid>
          <Grid xs={12} container justifyContent="center">
            <Typography variant="h5" sx={{ color: "#27bdff", fontWeight: 700 }}>
              {restaurant
                ? booking_?.user?.profile?.name ?? booking_?.user?.email
                : booking_?.restaurant?.name}
            </Typography>
          </Grid>
          <Grid xs={2}>
            {editMode && edit && (
              <IconButton
                sx={{ color: "red" }}
                onClick={() => setEditMode(false)}
              >
                <CloseIcon />
              </IconButton>
            )}
            {!editMode && edit && (
              <IconButton
                sx={{ color: "red" }}
                onClick={() => setEditMode(true)}
              >
                <EditIcon />
              </IconButton>
            )}
          </Grid>
        </Grid>
        <Grid container justifyContent="center" alignItems="center" spacing={2}>
          <Grid xs>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DateTimePicker
                label="Date time"
                value={dayjs.utc(booking_?.date)}
                onChange={handleTimeChange}
                renderInput={(params) => (
                  <TextField {...params} variant="standard" />
                )}
                disabled={!editMode}
                sx={{
                  color: "red",
                }}
              />
            </LocalizationProvider>
          </Grid>
          <Grid xs>
            <TextField
              label="Guests"
              variant="standard"
              inputProps={{
                inputMode: "numeric",
                pattern: "[0-9]*",
              }}
              value={booking_?.guests}
              onChange={(e) =>
                setBooking({ ...booking_, guests: e.target.value })
              }
              disabled={!editMode}
            />
          </Grid>
        </Grid>
      </CardContent>
      <CardActions>
        {editMode && !restaurant && (
          <>
            <Button size="small" onClick={() => cancelBooking()}>
              {t("cancelBooking")}
            </Button>
            <Button size="small" onClick={() => updateBooking()}>
              {t("updateBooking")}
            </Button>
          </>
        )}
        {edit && (
          <>
            <Button size="small" onClick={() => cancelBooking()}>
              {t("cancel")}
            </Button>
            {editMode && (
              <Button size="small" onClick={() => updateBooking()}>
                {t("update")}
              </Button>
            )}
            <Button size="small" onClick={() => acceptBooking()}>
              {t("accept")}
            </Button>
          </>
        )}
      </CardActions>
    </Card>
  );
};
