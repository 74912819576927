import React, { useEffect } from "react";
import {
  Card,
  CardMedia,
  CardContent,
  Typography,
  CardActions,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemButton,
  IconButton,
  Grid,
} from "@mui/material";

import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Inventory2Icon from "@mui/icons-material/Inventory2";
import DeleteIcon from "@mui/icons-material/Delete";
import { red, blue } from "@mui/material/colors";
import chefLogo from "assets/chef-marker.svg";
import restaurantLogo from "assets/map-marker.svg";
import {
  useDeleteConversationMutation,
  useSetStatusMutation,
} from "services/contact";
import { useSnackbar } from "notistack";
import { ArrowBackIos } from "@mui/icons-material";
import BlockIcon from "@mui/icons-material/Block";
export function Chefcard(props) {
  const { enqueueSnackbar } = useSnackbar();
  const {
    image,
    profile,
    address,
    id,
    status,
    role,
    setOpen,
    restaurant,
    restaurantId,
    contactId,
  } = props;
  const [setStatus, setStatusResult] = useSetStatusMutation();
  const [deleteChat, deleteChatResults] = useDeleteConversationMutation();

  useEffect(() => {
    if (deleteChatResults.isSuccess) {
      enqueueSnackbar(`Successfully deleted`, {
        variant: "success",
        autoHideDuration: 2000,
      });
      navigate("/chat");
    }
    if (deleteChatResults?.isError) {
      enqueueSnackbar(`Could not perform the operation at the moment`, {
        variant: "error",
        autoHideDuration: 2000,
      });
    }
  }, [deleteChatResults]);

  useEffect(() => {
    if (setStatusResult?.isSuccess) {
      enqueueSnackbar(`Successfully updated`, {
        variant: "success",
        autoHideDuration: 2000,
      });
    }
    if (setStatusResult?.isError) {
      enqueueSnackbar(`Could not perform the operation at the moment`, {
        variant: "error",
        autoHideDuration: 2000,
      });
    }
  }, [setStatusResult]);

  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <Card
      sx={{
        minHeight: "100vh",
        width: "100vw",
        m: 0,
        overflow: "scroll",
        mt: { xs: "137px", sm: "80px" },
      }}
    >
      <Grid
        container
        sx={{
          backgroundColor: "#1291FC",
          minHeight: "5vh",
        }}
        justifyContent="flex-start"
        alignItems="center"
      >
        <IconButton
          sx={{ mx: 1 }}
          onClick={() => {
            setOpen(false);
          }}
        >
          <ArrowBackIos sx={{ color: "#fff" }} />
        </IconButton>
        <Typography
          sx={{ color: "#fff" }}
          variant="h6"
          onClick={() => {
            setOpen(false);
          }}
        >
          {t("back")}
        </Typography>
      </Grid>
      <CardMedia
        sx={{ height: { xs: 250, md: 400 } }}
        component="img"
        image={image}
        title=""
      />
      <CardContent sx={{ backgroundColor: "#F2F2F2" }}>
        <Grid container justifyContent="center" alignItems="center" xs={12}>
          <Grid
            item
            xs={12}
            justifyContent="center"
            alignItems="center"
            container
          >
            <Typography
              gutterBottom
              variant="h5"
              component="div"
              sx={{ textTransform: "capitalize" }}
            >
              {restaurant?.length > 0
                ? restaurant
                : (profile?.name ?? "") +
                  " " +
                  (profile?.middleName ?? "") +
                  " " +
                  (profile?.lastName ?? "")}
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            justifyContent="center"
            alignItems="center"
            container
          >
            <Typography
              variant="body2"
              fontWeight={700}
              sx={{ textTransform: "capitalize" }}
            >
              {address?.country ? address?.country + ", " : ""}
            </Typography>
            <Typography
              variant="body2"
              sx={{ textTransform: "capitalize", ml: 0.4 }}
            >
              {address?.city ?? ""}
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            justifyContent="center"
            alignItems="center"
            container
          >
            <Typography
              variant="body2"
              sx={{ color: "#1291FC", textTransform: "capitalize" }}
              fontWeight={700}
            >
              {role?.name === "owner" ? "restaurant" : role?.name}
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
      <CardActions>
        <List sx={{ overflowY: "scroll", width: "100%" }}>
          <ListItem sx={{ borderBottom: "1px solid #D0D0D0" }}>
            <ListItemButton
              onClick={() =>
                setStatus({
                  id: id,
                  status: status === "active" ? "archived" : "active",
                })
              }
            >
              <ListItemIcon sx={{ color: blue[500] }}>
                <Inventory2Icon />
              </ListItemIcon>
              <ListItemText
                primary={status === "active" ? t("archived") : t("unArchived")}
              />
            </ListItemButton>
          </ListItem>
          <ListItem
            sx={{
              borderBottom: "1px solid #D0D0D0",
              display: `${
                role?.name === "owner" || role?.name === "chef" ? "" : "hidden"
              }`,
            }}
          >
            <ListItemButton
              onClick={() =>
                navigate(
                  `${
                    restaurant?.length > 0
                      ? `/${restaurantId}`
                      : `/chefs_club/${contactId}`
                  }`
                )
              }
            >
              <ListItemIcon>
                <img
                  src={role?.name === "owner" ? restaurantLogo : chefLogo}
                  alt="chef logo position"
                />
              </ListItemIcon>
              <ListItemText primary={t("viewProfile")} />
            </ListItemButton>
          </ListItem>
          <ListItem sx={{ borderBottom: "1px solid #D0D0D0" }}>
            <ListItemButton>
              <ListItemIcon sx={{ color: red[500] }}>
                <BlockIcon />
              </ListItemIcon>
              <ListItemText primary={t("blockUser")} />
            </ListItemButton>
          </ListItem>
          <ListItem sx={{ borderBottom: "1px solid #D0D0D0" }}>
            <ListItemButton onClick={() => deleteChat({ id: id })}>
              <ListItemIcon sx={{ color: red[500] }}>
                <DeleteIcon />
              </ListItemIcon>
              <ListItemText primary={t("deleteChat")} />
            </ListItemButton>
          </ListItem>
        </List>
      </CardActions>
    </Card>
  );
}
