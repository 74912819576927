import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Avatar, IconButton } from "@mui/material";
import { useGetSelfMMutation } from "services/usersApi";
import { useSelector } from "react-redux";
import { selectUserToken } from "services/authSlice";
import { useAuthUser } from "react-auth-kit";

export function ProfileIcon() {
  const navigate = useNavigate();
  const token = useSelector(selectUserToken);
  const [data, setData] = useState({});
  const [getSelf, getSelfResults] = useGetSelfMMutation();
  const authUser = useAuthUser();

  useEffect(() => {
    if (token && token.length > 0 && authUser() && authUser().active) {
      getSelf();
    }
  }, [token]);

  useEffect(() => {
    if (getSelfResults.isSuccess) {
      setData(getSelfResults.data);
    }
    if (getSelfResults.isError) getSelf();
  }, [getSelfResults]);

  const getColorBasedOnRole = (role) => {
    if (role === "customer" || role === 10) {
      return "rgba(255, 0, 0, 1)";
    }
    if (role === "restaurant" || role === 20 || role === "owner") {
      return "rgba(12, 118, 208, 1)";
    }
    if (role === "chef" || role === 30) {
      return "rgba(1, 175, 14, 1)";
    }
    if (role === "business" || role === 40) {
      return "#E6E077";
    }
  };

  return (
    <IconButton
      onClick={() => {
        if (authUser() && authUser().active) navigate("/account");
      }}
    >
      <Avatar
        src={data?.user?.profile?.avatar?.url}
        alt=""
        sx={{
          border: "2px solid",
          borderColor: getColorBasedOnRole(data?.user?.role?.name),
          borderWidth: { xs: "2px", sm: "5px" },
        }}
      >
        {data?.user?.profile?.name ?? ""}
      </Avatar>
    </IconButton>
  );
}
