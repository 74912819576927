import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { API_URL } from "./env";
import { getValue } from "./storage";
export const postsApi = createApi({
  reducerPath: "postsApi",
  baseQuery: fetchBaseQuery({
    baseUrl: API_URL,
    prepareHeaders: (headers) => {
      const token = getValue("_auth");
      headers.set("Authorization", `Bearer ${token}`);
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getAllPosts: builder.query({
      query: ({ locale }) => {
        if (locale?.toLowerCase()?.includes("en"))
          return { url: `posts?locale=en` };
        else return { url: `posts?locale=${locale}` };
      },
    }),
    getPost: builder.query({
      query: ({ id }) => {
        return { url: `posts/${id}` };
      },
    }),
    getPostMutation: builder.mutation({
      query: ({ id }) => {
        return { url: `posts/${id}` };
      },
    }),
    likePost: builder.mutation({
      query: ({ id }) => ({
        url: `posts/${id}/like`,
        method: "POST",
      }),
    }),
    unlikePost: builder.mutation({
      query: ({ id }) => ({
        url: `posts/${id}/like`,
        method: "DELETE",
      }),
    }),
  }),
});

export const {
  useGetAllPostsQuery,
  useGetPostQuery,
  useLikePostMutation,
  useUnlikePostMutation,
  useGetPostMutationMutation,
} = postsApi;
