import React, { useEffect, useState } from "react";
import {
  Grid,
  Typography,
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Link,
  Rating,
} from "@mui/material";
import PhoneIcon from "@mui/icons-material/Phone";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import dayjs from "dayjs";
import {
  useGetRatingsMutation,
  useGetRestaurantQuery,
} from "services/restaurantsApi";
import KitchenTypeLogo from "assets/type-cousine.svg";
import ChefHatLogo from "assets/2023/chef.svg";
import { ExpandMore } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import ChatBubbleOutlineOutlinedIcon from "@mui/icons-material/ChatBubbleOutlineOutlined";

export default function RestaurantInfo(props) {
  const { restaurant, setPanelContent } = props;
  const { t } = useTranslation();
  const { data: restaurantExtra } = useGetRestaurantQuery(restaurant?.id);
  const [getRatings, getRatingsResult] = useGetRatingsMutation();
  const [enableBooking, setEnableBooking] = useState(false);
  const [rating, setRating] = useState({ rating: 0, total: 0 });
  const getDay = (dayNumber) => {
    switch (dayNumber) {
      case 1:
        return t("mondayLowerCase");
      case 2:
        return t("tuesdayLowerCase");
      case 3:
        return t("wednesdayLowerCase");
      case 4:
        return t("thursdayLowerCase");
      case 5:
        return t("fridayLowerCase");
      case 6:
        return t("saturdayLowerCase");
      case 7:
        return t("sundayLowerCase");
      default:
        return "";
    }
  };

  useEffect(() => {
    restaurantExtra?.restaurant?.schedule?.forEach((time) => {
      if (time?.startTime && time.endTime) setEnableBooking(true);
    });
  }, [restaurantExtra]);

  const getPhoneNumber = () => {
    let str = "";
    if (restaurantExtra?.restaurant?.contact?.phone?.countryCode)
      str += restaurantExtra?.restaurant?.contact?.phone?.countryCode + " ";
    if (restaurantExtra?.restaurant?.contact?.phone?.areaCode)
      str += "(" + restaurantExtra?.restaurant?.contact?.phone?.areaCode + ") ";
    if (restaurantExtra?.restaurant?.contact?.phone?.number)
      str += restaurantExtra?.restaurant?.contact?.phone?.number;
    return str;
  };

  useEffect(() => {
    if (getRatingsResult.isSuccess) {
      const rat =
        getRatingsResult.data?.average?.atmosphere +
        getRatingsResult.data?.average?.cleanliness +
        getRatingsResult.data?.average?.food +
        getRatingsResult.data?.average?.service;
      setRating({
        rating: rat > 0 ? rat / 4 : 0,
        total: getRatingsResult.data?.average?.total,
      });
    }
  }, [getRatingsResult]);

  useEffect(() => {
    getRatings({ id: restaurant?.id });
  }, []);

  return (
    <>
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        direction="row"
        spacing={2}
        pt={2}
        pl={2}
        pr={1}
      >
        <Grid item xs={7}>
          <Typography variant="h6">{restaurant?.name}</Typography>
        </Grid>

        <Grid item xs={1}>
          <PhoneIcon sx={{ color: "#3690FF" }} />
        </Grid>
        <Grid container item xs={4} direction="column">
          <Grid container direction="column">
            <Grid item xs>
              <Typography>{t("phoneNumber")}</Typography>
            </Grid>
            <Grid item xs>
              <Link href={`tel:${getPhoneNumber()}`} underline="none">
                <Typography variant="body2" sx={{ color: "#A50707" }}>
                  {getPhoneNumber()?.length > 0
                    ? getPhoneNumber()
                    : "Not Number"}
                </Typography>
              </Link>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        spacing={2}
        mt={1}
        pl={1}
        pr={1}
      >
        <Grid item xs={12} container alignItems="center">
          <Rating
            size="large"
            sx={{ color: "#ff0001", pl: 1 }}
            value={rating.rating}
            precision={0.1}
          />
          <ChatBubbleOutlineOutlinedIcon
            sx={{ width: 16, height: 16, pl: 2, color: "#1396FF" }}
          />
          <Typography fontSize="0.8em" color={"#1396FF"} sx={{ pl: 0.5 }}>
            Reviews {rating.total}
          </Typography>
        </Grid>
        <Grid item xs={1}>
          <LocationOnIcon sx={{ color: "#ff0000" }} />
        </Grid>
        <Grid item xs>
          <Typography>
            {`${restaurantExtra?.restaurant?.address?.address}, ${restaurantExtra?.restaurant?.address?.postalCode}, ${restaurantExtra?.restaurant?.address?.city}, ${restaurantExtra?.restaurant?.address?.country}`}
          </Typography>
        </Grid>
      </Grid>

      <Grid
        container
        justifyContent="space-around"
        alignItems="center"
        direction="row"
        spacing={1}
        mt={3}
        pl={1}
        pr={1}
      >
        <Grid
          item
          xs={5}
          container
          sx={{ borderRadius: 2, border: "1px solid gray" }}
        >
          <Grid item xs={4} sx={{ height: 55 }}>
            <img src={KitchenTypeLogo} alt="Type Cuisine Logo" />
          </Grid>

          <Grid item xs>
            <Grid container direction="column">
              <Grid item xs>
                <Typography>{t("cuisine")}</Typography>
              </Grid>
              <Grid item xs>
                <Typography
                  sx={{ color: "#1291FC", textTransform: "capitalize" }}
                >
                  {restaurant?.cuisine?.name}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          xs={5}
          container
          sx={{ borderRadius: 2, border: "1px solid gray" }}
        >
          <Grid item xs={4} sx={{ height: 55 }}>
            <img src={ChefHatLogo} alt="Type Cuisine Logo" />
          </Grid>

          <Grid item xs>
            <Grid container direction="column">
              <Grid item xs>
                <Typography>{t("chefType")}</Typography>
              </Grid>
              <Grid item xs>
                <Typography
                  noWrap
                  sx={{
                    color: "#1291FC",
                    textTransform: "capitalize",
                    maxWidth: { xs: "80px", sm: "120px" },
                  }}
                >
                  {restaurantExtra?.restaurant?.chefs?.length > 0
                    ? `${
                        restaurantExtra?.restaurant?.chefs?.[0]?.user?.profile
                          ?.name ?? ""
                      } ${
                        restaurantExtra?.restaurant?.chefs?.[0]?.user?.profile
                          ?.middleName ?? ""
                      } ${
                        restaurantExtra?.restaurant?.chefs?.[0]?.user?.profile
                          ?.name ?? ""
                      }`
                    : ""}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      {restaurantExtra?.restaurant?.description == null ? null : (
        <Grid
          container
          justifyContent="space-between"
          alignItems="flex-start"
          direction="column"
          spacing={1}
          mt={1}
          pl={1}
          pr={1}
        >
          <Grid item xs sx={{ minWidth: "100%" }}>
            <Accordion
              sx={{ minWidth: "100%", display: { xs: "inherit", sm: "none" } }}
            >
              <AccordionSummary
                expandIcon={<ExpandMore />}
                aria-controls="about"
              >
                <Typography sx={{ fontWeight: "bold" }}>
                  {t("about")}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid
                  container
                  direction="row"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Typography>
                    {restaurantExtra?.restaurant?.description}
                  </Typography>
                </Grid>
              </AccordionDetails>
            </Accordion>
            <Accordion
              sx={{ minWidth: "100%", display: { xs: "none", sm: "inherit" } }}
              defaultExpanded={true}
            >
              <AccordionSummary
                expandIcon={<ExpandMore />}
                aria-controls="about"
              >
                <Typography sx={{ fontWeight: "bold" }}>
                  {t("about")}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid
                  container
                  direction="row"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Typography>
                    {restaurantExtra?.restaurant?.description}
                  </Typography>
                </Grid>
              </AccordionDetails>
            </Accordion>
          </Grid>
        </Grid>
      )}

      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        direction="row"
        spacing={1}
        mt={1}
        pl={1}
        pr={1}
      >
        <Grid item xs={12} sx={{ pb: 4 }}>
          <Accordion
            defaultExpanded={true}
            sx={{ minWidth: "100%", display: { xs: "none", sm: "inherit" } }}
          >
            <AccordionSummary
              expandIcon={<ExpandMore />}
              aria-controls="open-close-time"
            >
              <Typography sx={{ fontWeight: "bold" }}>
                {t("openClose")}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container>
                <Grid
                  item
                  xs={6}
                  container
                  alignItems="center"
                  justifyContent="center"
                >
                  {restaurantExtra?.restaurant?.schedule
                    ?.slice()
                    ?.filter((a) => a?.meal === "lunch")
                    ?.sort((a, b) => a?.day - b?.day)
                    ?.map((menu, idx) => {
                      return (
                        <Grid item xs={8} my={0.5} key={idx}>
                          <Typography
                            color="black"
                            fontSize="0.9em"
                            textTransform="capitalize"
                            sx={{ fontWeight: 700 }}
                          >
                            {getDay(menu.day)} {menu.meal}
                          </Typography>
                          <Typography color="black" fontSize="0.8em">
                            {t("open")}{" "}
                            {menu.startTime
                              ? dayjs(menu.startTime).format("hh:mm a")
                              : t("closed")}
                          </Typography>
                          <Typography color="black" fontSize="0.8em">
                            {t("close")}{" "}
                            {menu.endTime
                              ? dayjs(menu.endTime).format("hh:mm a")
                              : t("closed")}
                          </Typography>
                        </Grid>
                      );
                    })}
                </Grid>
                <Grid
                  item
                  xs={6}
                  container
                  alignItems="center"
                  justifyContent="center"
                >
                  {restaurantExtra?.restaurant?.schedule
                    ?.slice()
                    ?.filter((a) => a?.meal === "dinner")
                    ?.sort((a, b) => a?.day - b?.day)
                    ?.map((menu, idx) => {
                      return (
                        <Grid item xs={8} my={0.5} key={idx}>
                          <Typography
                            color="black"
                            fontSize="0.9em"
                            textTransform="capitalize"
                            sx={{ fontWeight: 700 }}
                          >
                            {getDay(menu.day)} {menu.meal}
                          </Typography>
                          <Typography color="black" fontSize="0.8em">
                            {t("open")}{" "}
                            {menu.startTime
                              ? dayjs(menu.startTime).format("hh:mm a")
                              : t("closed")}
                          </Typography>
                          <Typography color="black" fontSize="0.8em">
                            {t("close")}{" "}
                            {menu.endTime
                              ? dayjs(menu.endTime).format("hh:mm a")
                              : t("closed")}
                          </Typography>
                        </Grid>
                      );
                    })}
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
          <Accordion
            sx={{ minWidth: "100%", display: { xs: "inherit", sm: "none" } }}
          >
            <AccordionSummary
              expandIcon={<ExpandMore />}
              aria-controls="open-close-time"
            >
              <Typography sx={{ fontWeight: "bold" }}>
                {t("openClose")}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container>
                <Grid
                  item
                  xs={6}
                  container
                  alignItems="center"
                  justifyContent="center"
                >
                  {restaurantExtra?.restaurant?.schedule
                    ?.slice()
                    ?.filter((a) => a?.meal === "lunch")
                    ?.sort((a, b) => a?.day - b?.day)
                    ?.map((menu, idx) => {
                      return (
                        <Grid item xs={8} my={0.5} key={idx}>
                          <Typography
                            color="black"
                            fontSize="0.9em"
                            textTransform="capitalize"
                            sx={{ fontWeight: 700 }}
                          >
                            {getDay(menu.day)} {menu.meal}
                          </Typography>
                          <Typography color="black" fontSize="0.8em">
                            {t("open")}{" "}
                            {menu.startTime
                              ? dayjs(menu.startTime).format("hh:mm a")
                              : t("closed")}
                          </Typography>
                          <Typography color="black" fontSize="0.8em">
                            {t("close")}{" "}
                            {menu.endTime
                              ? dayjs(menu.endTime).format("hh:mm a")
                              : t("closed")}
                          </Typography>
                        </Grid>
                      );
                    })}
                </Grid>
                <Grid
                  item
                  xs={6}
                  container
                  alignItems="center"
                  justifyContent="center"
                >
                  {restaurantExtra?.restaurant?.schedule
                    ?.slice()
                    ?.filter((a) => a?.meal === "dinner")
                    ?.sort((a, b) => a?.day - b?.day)
                    ?.map((menu, idx) => {
                      return (
                        <Grid item xs={8} my={0.5} key={idx}>
                          <Typography
                            color="black"
                            fontSize="0.9em"
                            textTransform="capitalize"
                            sx={{ fontWeight: 700 }}
                          >
                            {getDay(menu.day)} {menu.meal}
                          </Typography>
                          <Typography color="black" fontSize="0.8em">
                            {t("open")}{" "}
                            {menu.startTime
                              ? dayjs(menu.startTime).format("hh:mm a")
                              : t("closed")}
                          </Typography>
                          <Typography color="black" fontSize="0.8em">
                            {t("close")}{" "}
                            {menu.endTime
                              ? dayjs(menu.endTime).format("hh:mm a")
                              : t("closed")}
                          </Typography>
                        </Grid>
                      );
                    })}
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
        </Grid>
      </Grid>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        direction="row"
      >
        <Button
          variant="contained"
          sx={{
            width: { xs: "65%", sm: "10%" },
            backgroundColor: "red",
            position: "fixed",
            top: "95%",
            borderRadius: 5,
          }}
          onClick={() => setPanelContent("booking")}
          disabled={!enableBooking}
        >
          {enableBooking ? "Book" : "No schedule information"}
        </Button>
      </Grid>
    </>
  );
}
