import { Grid, Typography } from "@mui/material";
import React from "react";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { useTranslation } from "react-i18next";
export const Checkout = ({ date, guests }) => {
  const { t } = useTranslation();
  return (
    <Grid
      container
      justifyContent="center"
      mt={2}
      sx={{ backgroundColor: "#1976D2", pb: 4 }}
    >
      <Grid
        item
        xs={12}
        container
        justifyContent="space-around"
        direction="row"
        sx={{ py: 2, mx: 0.5 }}
      >
        <Grid
          item
          container
          xs={3}
          justifyContent="center"
          sx={{ border: "1px solid white" }}
        >
          <Typography sx={{ color: "#fff" }} variant="h6">{`${date?.format(
            "MMM"
          )}`}</Typography>
        </Grid>
        <Grid
          item
          container
          xs={3}
          justifyContent="center"
          sx={{ border: "1px solid white" }}
        >
          <Typography sx={{ color: "#fff" }} variant="h6">{`${date?.format(
            "DD ddd"
          )}`}</Typography>
        </Grid>
        <Grid
          item
          container
          xs={3}
          justifyContent="center"
          sx={{ border: "1px solid white" }}
        >
          <Typography sx={{ color: "#fff" }} variant="h6">{`${date?.format(
            "hh:mm A"
          )}`}</Typography>
        </Grid>
        <Grid
          item
          container
          xs={3}
          justifyContent="center"
          sx={{ border: "1px solid white" }}
        >
          <Typography
            sx={{ color: "#fff" }}
            variant="h6"
          >{`${guests} Pers`}</Typography>
        </Grid>
      </Grid>
      <Grid item xs={12} container justifyContent="center">
        <Grid
          item
          xs={12}
          container
          justifyContent={"center"}
          alignItems="center"
          sx={{ borderBottom: "1px solid #fff", py: 4 }}
        >
          <Typography variant="h5" sx={{ color: "#fff" }}>
            {t("thanksReservation")}
          </Typography>
        </Grid>
        <Grid
          item
          xs
          container
          justifyContent={"center"}
          alignItems="flex-start"
          sx={{ minHeight: "48vh" }}
        >
          <Typography variant="h6" sx={{ color: "#fff", mt: 4 }} align="center">
            {t("youWillReceiveConfirmation")}
          </Typography>

          <CheckCircleIcon
            sx={{ color: "#fff", width: 100, transform: "scale(5)" }}
            fontSize="large"
          />
        </Grid>
      </Grid>
    </Grid>
  );
};
