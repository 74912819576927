export const cities = [
  {
    city: true,
    name: "amsterdam",
    lat: 52.3730796,
    lng: 4.8924534,
    images: [
      {
        main: true,
        file: {
          url: "https://mytraveldinner-prod.s3.amazonaws.com/cities/Amsterdam-96bc97e4ffc921ab0a49f3ba9ee97ef7.png",
        },
      },
    ],
  },
  {
    city: true,
    name: "athens",
    lat: 37.9839412,
    lng: 23.7283052,
    images: [
      {
        main: true,
        file: {
          url: "https://mytraveldinner-prod.s3.amazonaws.com/cities/Athens-f9031c81ef0ce0dfc5bc77b23334a7ee.png",
        },
      },
    ],
  },
  {
    city: true,
    name: "boston",
    lat: 42.3554334,
    lng: -71.060511,
    images: [
      {
        main: true,
        file: {
          url: "https://mytraveldinner-prod.s3.amazonaws.com/cities/Boston-8c3d7d6d616888bf5f4254dfbc31ae66.png",
        },
      },
    ],
  },
  {
    city: true,
    name: "brisbane",
    lat: -27.4689682,
    lng: 153.0234991,
    images: [
      {
        main: true,
        file: {
          url: "https://mytraveldinner-prod.s3.amazonaws.com/cities/Brisbane-cadea01ad90780687b749961766b60c8.png",
        },
      },
    ],
  },
  {
    city: true,
    name: "brussels",
    lat: 50.8465573,
    lng: 4.351697,
    images: [
      {
        main: true,
        file: {
          url: "https://mytraveldinner-prod.s3.amazonaws.com/cities/Brussels-8e17c7831d1a21d9526798c96d7c8cf2.png",
        },
      },
    ],
  },
  {
    city: true,
    name: "florence",
    lat: 43.7698712,
    lng: 11.2555757,
    images: [
      {
        main: true,
        file: {
          url: "https://mytraveldinner-prod.s3.amazonaws.com/cities/Florence-48c916052d165f076f3eee1ce2fcf027.png",
        },
      },
    ],
  },
  {
    city: true,
    name: "hollywood",
    lat: 34.0980031,
    lng: -118.329523,
    images: [
      {
        main: true,
        file: {
          url: "https://mytraveldinner-prod.s3.amazonaws.com/cities/Hollywood-1955bc8f2cfd59129a56affe9b6c1d23.png",
        },
      },
    ],
  },
  {
    city: true,
    name: "dublin",
    lat: 53.3498006,
    lng: -6.2602964,
    images: [
      {
        main: true,
        file: {
          url: "https://mytraveldinner-prod.s3.amazonaws.com/cities/Dublin-da23430fd9732b48b1070dc8cc0b511c.png",
        },
      },
    ],
  },
  {
    city: true,
    name: "hong kong",
    lat: 22.2793278,
    lng: 114.1628131,
    images: [
      {
        main: true,
        file: {
          url: "https://mytraveldinner-prod.s3.amazonaws.com/cities/Hong_Kong-d26466fdcb9e1ad2381a8da38f17e5c3.png",
        },
      },
    ],
  },
  {
    city: true,
    name: "las vegas",
    lat: 36.1672559,
    lng: -115.148516,
    images: [
      {
        main: true,
        file: {
          url: "https://mytraveldinner-prod.s3.amazonaws.com/cities/Las_Vegas-0d8cfd816fb0209c6ffec0a574eef099.png",
        },
      },
    ],
  },
  {
    city: true,
    name: "lisbon",
    lat: 38.7077507,
    lng: -9.1365919,
    images: [
      {
        main: true,
        file: {
          url: "https://mytraveldinner-prod.s3.amazonaws.com/cities/Lisbon-489c176ef85662eedafeef948b50be05.png",
        },
      },
    ],
  },
  {
    city: true,
    name: "london",
    lat: 51.5073359,
    lng: -0.12765,
    images: [
      {
        main: true,
        file: {
          url: "https://mytraveldinner-prod.s3.amazonaws.com/cities/London-0493d04eb91ce2ccb8acac55c0a705e7.png",
        },
      },
    ],
  },
  {
    city: true,
    name: "madrid",
    lat: 40.4167047,
    lng: -3.7035825,
    images: [
      {
        main: true,
        file: {
          url: "https://mytraveldinner-prod.s3.amazonaws.com/cities/Madrid-9dbfa80c29c2700c64f753fb3d5c3b85.png",
        },
      },
    ],
  },
  {
    city: true,
    name: "marbella",
    lat: 36.508976,
    lng: -4.88562,
    images: [
      {
        main: true,
        file: {
          url: "https://mytraveldinner-prod.s3.amazonaws.com/cities/Marbella-f97c4bd51dc632c8352c4bb192ad007a.png",
        },
      },
    ],
  },
  {
    city: true,
    name: "miami",
    lat: 25.7741728,
    lng: -80.19362,
    images: [
      {
        main: true,
        file: {
          url: "https://mytraveldinner-prod.s3.amazonaws.com/cities/Miami-f90e697e384ec05c5a77618687068680.png",
        },
      },
    ],
  },
  {
    city: true,
    name: "milan",
    lat: 45.4641943,
    lng: 9.1896346,
    images: [
      {
        main: true,
        file: {
          url: "https://mytraveldinner-prod.s3.amazonaws.com/cities/Milan-46b11bd126c821e678388aa6114ba4cb.png",
        },
      },
    ],
  },
  {
    city: true,
    name: "montevideo",
    lat: -34.9058916,
    lng: -56.1913095,
    images: [
      {
        main: true,
        file: {
          url: "https://mytraveldinner-prod.s3.amazonaws.com/cities/Montevideo-6444187c68f265b81d0b5f18cacb2e71.png",
        },
      },
    ],
  },
  {
    city: true,
    name: "montreal",
    lat: 45.5031824,
    lng: -73.5698065,
    images: [
      {
        main: true,
        file: {
          url: "https://mytraveldinner-prod.s3.amazonaws.com/cities/Montreal-e5e59ab58060ecea29517bcf981ea1e5.png",
        },
      },
    ],
  },
  {
    city: true,
    name: "moscow",
    lat: 55.7504461,
    lng: 37.6174943,
    images: [
      {
        main: true,
        file: {
          url: "https://mytraveldinner-prod.s3.amazonaws.com/cities/Moscow-07ea30ffd18c1d278a85300cc4ab6995.png",
        },
      },
    ],
  },
  {
    city: true,
    name: "new york",
    lat: 40.7127281,
    lng: -74.0060152,
    images: [
      {
        main: true,
        file: {
          url: "https://mytraveldinner-prod.s3.amazonaws.com/cities/NYC-074210aae5afa01c3ba62d2f053ddc60.png",
        },
      },
    ],
  },
  {
    city: true,
    name: "playa del carmen",
    lat: 20.6308643,
    lng: -87.0779503,
    images: [
      {
        main: true,
        file: {
          url: "https://mytraveldinner-prod.s3.amazonaws.com/cities/Playa_del_Carmen-2187f2ae72487fc30965b1350320f357.png",
        },
      },
    ],
  },
  {
    city: true,
    name: "rio",
    lat: -22.9110137,
    lng: -43.2093727,
    images: [
      {
        main: true,
        file: {
          url: "https://mytraveldinner-prod.s3.amazonaws.com/cities/Rio-c3f3df7914eccc11ca64a68f25b5e6f3.png",
        },
      },
    ],
  },
  {
    city: true,
    name: "rome",
    lat: 41.8933203,
    lng: 12.4829321,
    images: [
      {
        main: true,
        file: {
          url: "https://mytraveldinner-prod.s3.amazonaws.com/cities/Rome-3d658d8973af600434bfbc1e23f56138.png",
        },
      },
    ],
  },
  {
    city: true,
    name: "san francisco",
    lat: 37.7790262,
    lng: -122.419906,
    images: [
      {
        main: true,
        file: {
          url: "https://mytraveldinner-prod.s3.amazonaws.com/cities/San_Fran-5c3635f05c54ebec89b4afa31e403d89.png",
        },
      },
    ],
  },
  {
    city: true,
    name: "santo domingo",
    lat: 18.4801972,
    lng: -69.942111,
    images: [
      {
        main: true,
        file: {
          url: "https://mytraveldinner-prod.s3.amazonaws.com/cities/Santo_Domingo-b1a6aedf330f04bff96b024c9934fa1b.png",
        },
      },
    ],
  },
  {
    city: true,
    name: "shangai",
    lat: 31.2322758,
    lng: 121.468715,
    images: [
      {
        main: true,
        file: {
          url: "https://mytraveldinner-prod.s3.amazonaws.com/cities/Shanghai-0ba26bd569becfbc5f7b6bf961fa6b0f.png",
        },
      },
    ],
  },
  {
    city: true,
    name: "tokio",
    lat: 35.6812665,
    lng: 139.757653,
    images: [
      {
        main: true,
        file: {
          url: "https://mytraveldinner-prod.s3.amazonaws.com/cities/Tokyo-107f53007780a540849c36d02bed2604.png",
        },
      },
    ],
  },
  {
    city: true,
    name: "waikiki",
    lat: 21.2793568,
    lng: -157.8285713,
    images: [
      {
        main: true,
        file: {
          url: "https://mytraveldinner-prod.s3.amazonaws.com/cities/Waikiki-2e78ad8f66ad07269e308ade4bfc53f4.png",
        },
      },
    ],
  },
  {
    city: true,
    name: "windstar",
    lat: 33.589538,
    lng: -117.7344882,
    images: [
      {
        main: true,
        file: {
          url: "https://mytraveldinner-prod.s3.amazonaws.com/cities/Windstar-f541fcddc4dac3aadfdbbd180968c1c5.png",
        },
      },
    ],
  },
  {
    city: true,
    name: "yaffo",
    lat: 32.8280039,
    lng: 34.9840066,
    images: [
      {
        main: true,
        file: {
          url: "https://mytraveldinner-prod.s3.amazonaws.com/cities/Yaffo-9291a49f3dcdc408126fe93a4973b697.png",
        },
      },
    ],
  },
];
