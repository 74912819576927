import { configureStore } from "@reduxjs/toolkit";
import { authApi } from "services/authApi";
import authSlice from "services/authSlice";
import { bookingsApi } from "services/bookingsApi";
import { contactsApi } from "services/contact";
import { notificationsApi } from "services/notificationsApi";
import { paymentsApi } from "services/paymentsApi";
import { uploadApi } from "services/uploadApi";

import { categoriesApi } from "../services/categoriesApi";
import { countriesApi } from "../services/countriesApi";
import { postsApi } from "../services/postsApi";
import { regionsApi } from "../services/regionsApi";
import { restaurantsApi } from "../services/restaurantsApi";
import { usersApi } from "../services/usersApi";

export const store = configureStore({
  reducer: {
    [restaurantsApi.reducerPath]: restaurantsApi.reducer,
    [usersApi.reducerPath]: usersApi.reducer,
    [countriesApi.reducerPath]: countriesApi.reducer,
    [regionsApi.reducerPath]: regionsApi.reducer,
    [categoriesApi.reducerPath]: categoriesApi.reducer,
    [postsApi.reducerPath]: postsApi.reducer,
    [uploadApi.reducerPath]: uploadApi.reducer,
    [authApi.reducerPath]: authApi.reducer,
    [bookingsApi.reducerPath]: bookingsApi.reducer,
    [contactsApi.reducerPath]: contactsApi.reducer,
    [paymentsApi.reducerPath]: paymentsApi.reducer,
    [notificationsApi.reducerPath]: notificationsApi.reducer,
    authReducer: authSlice,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(restaurantsApi.middleware)
      .concat(usersApi.middleware)
      .concat(countriesApi.middleware)
      .concat(regionsApi.middleware)
      .concat(categoriesApi.middleware)
      .concat(postsApi.middleware)
      .concat(uploadApi.middleware)
      .concat(authApi.middleware)
      .concat(bookingsApi.middleware)
      .concat(contactsApi.middleware)
      .concat(paymentsApi.middleware)
      .concat(notificationsApi.middleware),
});
