import React, { useEffect, useState } from "react";
import "./App.css";
import { Helmet } from "react-helmet";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Box, Typography } from "@mui/material";
import { Navbar } from "app/Navbar";
import { DoubleNavbar } from "app/DoubleNavbar";
import { Map } from "features/Map/Map";
import { Carousel } from "features/Carousel/Carousel";
import { CardGallery } from "features/Gallery/CardGallery";
import { Login } from "features/Login/Login";
import { Post } from "features/Post/Post";
import { useDispatch, useSelector } from "react-redux";
import { selectUserToken, setToken } from "services/authSlice";
import { Logout } from "features/Logout/Logout";
import { Account } from "features/Account/Account";
import { Bookings } from "features/Bookings/Bookings";
import { Restaurant } from "features/Bookings/Restaurant";
import { Chat } from "features/Chat/Chat";
import { SignUp } from "features/SignUp/SignUp";
import { ResetPassword } from "features/ResetPassword/ResetPassword";
import { useTranslation } from "react-i18next";
import { getValue } from "services/storage";
import { RecoverPassword } from "features/RecoverPassword/RecoverPassword";
import OneSignal from "onesignal-cordova-plugin";
import { AuthProvider, RequireAuth, useAuthUser } from "react-auth-kit";
import { useGetSelfMMutation } from "services/usersApi";
import { MyCard } from "features/Card/MyCard";
import { CapacitorStripeProvider } from "@capacitor-community/stripe/dist/esm/react/provider";
import { Search } from "features/Search/Search";
import { Notifications } from "features/Notifications/Notifications";
import { Contacts } from "features/Chat/Contacts";
import { SearchContacts } from "features/Chat/SearchContacts";
import { Chefcard } from "features/ChefCard/Chefcard";
import { Stories } from "features/Stories/Stories";
import { CssBaseline } from "@mui/material";
import { StoriesViewerPage } from "features/Stories/StoriesViewerPage";

function App() {
  // Panel
  const dispatch = useDispatch();
  const [sidebarIsOpened, setSidebarOpen] = useState(false);
  const [itemSelected, setItem] = useState();
  const token = useSelector(selectUserToken);
  const { t, i18n } = useTranslation();
  const [getSelf, getSelfResults] = useGetSelfMMutation();

  useEffect(() => {
    const tokenExists = () => {
      const localToken = getValue("_auth");
      if (localToken && localToken.length > 0) {
        dispatch(setToken(localToken));
      }
    };
    tokenExists();
    if (i18n.language.includes("-")) {
      i18n.changeLanguage(i18n.language.split("-")?.[0] ?? "en");
    }
  }, []);

  useEffect(() => {
    if (token !== null && token?.length > 0 && !getSelfResults.isSuccess) {
      getSelf();
    }
  }, [token]);

  useEffect(() => {
    if (getSelfResults.isSuccess) {
      function OneSignalInit() {
        OneSignal.setAppId("5dce1a20-9e39-4f91-b8e0-2feb10844a1e");
        OneSignal.promptForPushNotificationsWithUserResponse();
        OneSignal.setNotificationOpenedHandler(function (jsonData) {
          console.log(
            "notificationOpenedCallback: " + JSON.stringify(jsonData)
          );
        });
        OneSignal.setExternalUserId(getSelfResults.data?.user?.id + "");
        OneSignal.setNotificationWillShowInForegroundHandler(function (
          notificationReceivedEvent
        ) {
          notificationReceivedEvent.complete(
            notificationReceivedEvent.getNotification()
          );
        });
      }
      if (window?.cordova) OneSignalInit();
    }
    if (getSelfResults.isError) getSelf();
  }, [getSelfResults]);

  const auth = useAuthUser();

  useEffect(() => {
    if (getSelfResults.isSuccess) {
      if (getSelfResults.data?.user?.active !== auth()?.active) {
        localStorage.setItem(
          "_auth_state",
          JSON.stringify(getSelfResults.data?.user)
        );
        window.location.reload();
      }
    }
  }, [getSelfResults]);

  const [mapLocation, setMapLocation] = useState([22.34, -71.05]);
  const [zoom, setZoom] = useState(2);

  return (
    <BrowserRouter>
      <CssBaseline />
      <Helmet>
        <title>My Travel Dinner</title>
        <link
          rel="stylesheet"
          href="<NODE_MODULES_FOLDER>/react-responsive-carousel/lib/styles/carousel.min.css"
        />
      </Helmet>
      <div className="App">
        <AuthProvider
          authType={"localstorage"}
          authName={"_auth"}
          cookieDomain={window.location.hostname}
          cookieSecure={window.location.protocol === "https:"}
        >
          <Routes>
            <Route exact path="/auth/login" element={<Login />} />
            <Route exact path="/auth/signup" element={<SignUp />} />
            <Route
              exact
              path="/auth/reset/:token"
              element={<ResetPassword />}
            />
            <Route exact path="/auth/recover" element={<RecoverPassword />} />
            <Route
              exact
              path="/account"
              element={
                <RequireAuth loginPath={"/auth/login"}>
                  <Navbar />
                  <Account />
                </RequireAuth>
              }
            />
            <Route exact path="/auth/logout" element={<Logout />} />
            <Route
              exact
              path="/"
              element={
                <>
                  <DoubleNavbar />
                  <Map
                    sidebarIsOpened={sidebarIsOpened}
                    setSidebarOpen={setSidebarOpen}
                    itemSelected={itemSelected}
                    setItem={setItem}
                    mapLocation={mapLocation}
                    setMapLocation={setMapLocation}
                    zoom={zoom}
                    setZoom={setZoom}
                  />
                  <Stories />
                  <Carousel
                    setSidebarOpen={setSidebarOpen}
                    setItem={setItem}
                    mapLocation={mapLocation}
                    setMapLocation={setMapLocation}
                    zoom={zoom}
                  />
                </>
              }
            />
            <Route
              exact
              path="/:id"
              element={
                <RequireAuth loginPath={"/auth/login"}>
                  <DoubleNavbar />
                  <Map
                    sidebarIsOpened={sidebarIsOpened}
                    setSidebarOpen={setSidebarOpen}
                    itemSelected={itemSelected}
                    setItem={setItem}
                    mapLocation={mapLocation}
                    setMapLocation={setMapLocation}
                    zoom={zoom}
                    setZoom={setZoom}
                  />
                  <Stories />
                  <Carousel
                    setSidebarOpen={setSidebarOpen}
                    setItem={setItem}
                    mapLocation={mapLocation}
                    setMapLocation={setMapLocation}
                    zoom={zoom}
                  />
                </RequireAuth>
              }
            />
            <Route
              path="/chef_club/:id"
              element={
                <RequireAuth loginPath={"/auth/login"}>
                  <DoubleNavbar />
                  <Map
                    sidebarIsOpened={sidebarIsOpened}
                    setSidebarOpen={setSidebarOpen}
                    itemSelected={itemSelected}
                    setItem={setItem}
                    mapLocation={mapLocation}
                    setMapLocation={setMapLocation}
                    zoom={zoom}
                    setZoom={setZoom}
                  />
                  <Stories />
                  <Carousel
                    setSidebarOpen={setSidebarOpen}
                    setItem={setItem}
                    mapLocation={mapLocation}
                    setMapLocation={setMapLocation}
                    zoom={zoom}
                  />
                </RequireAuth>
              }
            />
            <Route
              path="/chef_club"
              element={
                <RequireAuth loginPath={"/auth/login"}>
                  <DoubleNavbar />
                  <Map
                    sidebarIsOpened={sidebarIsOpened}
                    setSidebarOpen={setSidebarOpen}
                    itemSelected={itemSelected}
                    setItem={setItem}
                    mapLocation={mapLocation}
                    setMapLocation={setMapLocation}
                    zoom={zoom}
                    setZoom={setZoom}
                  />
                  <Stories />
                  <Carousel
                    setSidebarOpen={setSidebarOpen}
                    setItem={setItem}
                    mapLocation={mapLocation}
                    setMapLocation={setMapLocation}
                    zoom={zoom}
                  />
                </RequireAuth>
              }
            />
            <Route
              path="/chef_card"
              element={
                <RequireAuth loginPath={"/auth/login"}>
                  <Navbar />
                  <Chefcard />
                </RequireAuth>
              }
            />
            <Route
              path="/news"
              element={
                <RequireAuth loginPath={"/auth/login"}>
                  <Navbar />
                  <CardGallery />
                </RequireAuth>
              }
            />
            <Route
              path="/partners"
              element={
                <RequireAuth loginPath={"/auth/login"}>
                  <Navbar />
                  <CardGallery />
                </RequireAuth>
              }
            />
            <Route
              path="/news/:id"
              element={
                <RequireAuth loginPath={"/auth/login"}>
                  <Navbar />
                  <Post />
                </RequireAuth>
              }
            />
            <Route
              path="/mybookings"
              element={
                <RequireAuth loginPath={"/auth/login"}>
                  <Navbar />
                  <Bookings />
                </RequireAuth>
              }
            />
            <Route
              path="/bookings"
              element={
                <RequireAuth loginPath={"/auth/login"}>
                  <Navbar />
                  <Restaurant />
                </RequireAuth>
              }
            />
            <Route
              path="/chat"
              element={
                <RequireAuth loginPath={"/auth/login"}>
                  <Box
                    component="div"
                    sx={{ display: { xs: "none", md: "flex" } }}
                  >
                    <Navbar />
                  </Box>
                  <Chat />
                </RequireAuth>
              }
            />
            <Route
              path="/contacts"
              element={
                <RequireAuth loginPath={"/auth/login"}>
                  <Contacts />
                </RequireAuth>
              }
            />
            <Route
              path="/contacts/search"
              element={
                <RequireAuth loginPath={"/auth/login"}>
                  <SearchContacts />
                </RequireAuth>
              }
            />
            <Route
              path="/chat/:id"
              element={
                <RequireAuth loginPath={"/auth/login"}>
                  <Chat />
                </RequireAuth>
              }
            />
            <Route
              path="/search"
              element={
                <RequireAuth loginPath={"/auth/login"}>
                  <Search setMapLocation={setMapLocation} setZoom={setZoom} />
                </RequireAuth>
              }
            />
            <Route
              path="/notifications"
              element={
                <RequireAuth loginPath={"/auth/login"}>
                  <Notifications />
                </RequireAuth>
              }
            />
            <Route
              path="/stories/:id"
              element={
                <RequireAuth loginPath={"/auth/login"}>
                  <StoriesViewerPage />
                </RequireAuth>
              }
            />

            <Route path="/how_it_works" element={<Navbar />} />

            <Route
              path="/card"
              element={
                <>
                  <CapacitorStripeProvider
                    publishableKey="pk_test_7OgJtZLBdkHw3dZ5TB0l2nxQ"
                    fallback={<p>Loading...</p>}
                  >
                    <Navbar />
                    <MyCard />
                  </CapacitorStripeProvider>
                </>
              }
            />
            <Route
              path="*"
              element={
                <>
                  <Typography
                    variant="h1"
                    component="h1"
                    sx={{
                      textAlign: "center",
                      position: "absolute",
                      top: { xs: "32%", md: "22%", lg: "32%" },
                      left: { xs: "40%", md: "50%", lg: "50%" },
                      transform: "translate(-50%, -50%)",
                    }}
                  >
                    {t("notFound")}
                  </Typography>
                </>
              }
            />
          </Routes>
        </AuthProvider>
      </div>
    </BrowserRouter>
  );
}

export default App;
